import { directive } from '../controllers/app';

directive('jsonViewer', [
  '$filter',
  'FriendlyIsoTime',
  function($filter, friendlyIsoTime) {
    return {
      restrict: 'E',
      scope: {
        data: '='
      },
      template: '<div></div>',
      link: function(scope, element) {
        // The json-viewer core, wrapped in closure, eliminating the chance
        // for a customization procedure to step on "private" feature.
        var render = window.jsonViewer;

        function identity(v) {
          return v;
        }
        var labelCustomizer =
          $filter('labelCustomizerLEIForView') ||
          function(path, rawOption, caseStr) {
            return undefined;
          };
        var humanize = $filter('humanize') || identity;
        var reParent = /^Parent(?=[A-Z])/;
        var reAddress = /Address(?=(?:[A-Z\s]|$))/;
        var reStatus = /Status(?=(?:[A-Z\s]|$))/;
        var wellKnownValues = {
          ACTIVE: 'success',
          ISSUED: 'success',
          PUBLISHED: 'info',
          ANNULLED: 'danger',
          RETIRED: 'danger',
          LAPSED: 'danger'
        };

        scope.$watch('data', function(data) {
          render(
            data,
            element,
            [],
            {
              renderNode: function renderNode(key, data, container, path, context) {
                var isLabelCustomized;
                path[path.length - 1] = reParent.test(key || '') ? '*.@.' + key : key;
                key = (isLabelCustomized = labelCustomizer([].concat('@.data', path), key)) || key;
                if (String(key).slice(-1) == '?') {
                  key = path[path.length - 1];
                  isLabelCustomized = undefined;
                }
                if (!isLabelCustomized) {
                  key = humanize(key || '');
                }
                // address transformation for "non-computer" display
                if (reAddress.test(key) && data.country) {
                  context.dataOverride = [
                    data.line1,
                    data.line2,
                    data.line3,
                    data.line4,
                    [data.city, data.region, data.postalCode].join(', ').replace(/(,\s)+/g, ', '),
                    data.country
                  ]
                    .join('\n')
                    .replace(/(\n\s*)+/g, '\n');
                } else {
                  // removing indices and "simple" object keys
                  if (context.siblings.index === context.siblings.data) {
                    context.levelNudge = context.levelNudge + 1;
                    return container;
                  }
                }
                // add headings whenever needed
                if (path.length - context.levelNudge === 1) {
                  context.containerClasses = 'card mb-3';
                  context.labelClasses = 'card-header';
                }
                return render.defaultRenderers.renderNode(key, data, container, path, context);
              },
              endRenderNode: function endRenderNode(label, data, container, path, context) {
                if (!reAddress.test(label) && context.siblings.index === context.siblings.data)
                  context.levelNudge = context.levelNudge - 1;
              },
              renderValue: function renderValue(label, data, formattedData, container, path, context) {
                if (label === 'password' || label === 'repeatPassword') formattedData = '*****';

                var el = render.defaultRenderers.renderValue(label, data, formattedData, container, path, context);
                if (reStatus.test(path[path.length - 1]))
                  el.addClass('badge badge-' + (wellKnownValues[data] || 'warning'));

                return el;
              }
            },
            {
              levelNudge: 0,
              friendlyIsoTime: friendlyIsoTime
            }
          );
          element.find('.-json-view-level-1 > div').addClass('card-body');
        });
      }
    };
  }
]);
