export default function makeOnFinish({
  $scope,
  clearCart,
  checkout,
  redirect,
  thankYou,
  alertOk,
  alertError,
  DefaultErrorHandler,
  LEI_ACTION
}) {
  const type2msg = {
    [LEI_ACTION.UPDATE]: 'bulkUpdate',
    [LEI_ACTION.TRANSFER]: 'bulkTransfer'
  };

  return async () => {
    // Check for duplicate LEIs
    const leiSet = new Set();
    if ($scope.data) {
      let duplicateLei;
      for (const { lei, type } of $scope.data) {
        if (!(type == LEI_ACTION.NEW && lei == 'N/A') && leiSet.has(lei)) {
          duplicateLei = lei;
          break;
        }
        leiSet.add(lei);
      }
      if (duplicateLei) {
        return alertError(null, '`%s` shows up more than once in your cart. Please remove the duplicate entries.', [
          duplicateLei
        ]);
      }
    }

    if ([LEI_ACTION.UPDATE, LEI_ACTION.TRANSFER].includes($scope.cartItemType)) {
      try {
        const { data } = await checkout($scope.cartItemType);

        $scope.hideForm = true;
        $scope.actions.data = [];
        clearCart();
        alertOk(type2msg[$scope.cartItemType], null, {
          submissionIds: data.join()
        });
        const cartItems = $scope.data;
        if (cartItems.length > 1) {
          thankYou(
            Object.values({
              // only show trackId (parent id) for bulk submissions
              action: 'NA',
              trackId: cartItems?.[0].submissionId,
              legalName: 'NA',
              lei: 'NA'
            })
          );
        } else {
          thankYou(
            Object.values({
              action: cartItems?.[0].type.toLowerCase(),
              trackId: data[0],
              legalName: cartItems?.[0].legalName,
              lei: cartItems?.[0].lei
            })
          );
        }
      } catch (err) {
        DefaultErrorHandler(err);
      }
    } else {
      redirect();
    }
  };
}
