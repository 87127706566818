import { factory } from './app';

factory('DraftCacheKey', [
  function() {
    return function(email, action, keyForType) {
      var key = email + '_' + action;
      if (keyForType) {
        key += '_' + keyForType;
      }
      return key;
    };
  }
]);
