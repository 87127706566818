import { filter } from '../utils/app';

filter('isFieldValid', function() {
  return function(form, field) {
    return (form.$submitted || form[field].$touched) && form[field].$invalid;
  };
});

filter('cardType', function() {
  return function(cardNumber) {
    var cards = {
      visa: new RegExp('^4'),
      mastercard: new RegExp('^(?:5[1-5]|222[1-9]|22[3-9]|2[3-7])'),
      amex: new RegExp('^3[47]'),
      discover: new RegExp('^6(?:0|5)'),
      'diners-club': new RegExp('^3(?:0|[68])'),
      jcb: new RegExp('^(?:21|18|35)')
    };

    var brand = undefined;

    Object.keys(cards).some(function(type) {
      if (cards[type].test(cardNumber)) {
        brand = type;
        return true;
      }
      return false;
    });
    console.log(brand);
    return brand;
  };
});
