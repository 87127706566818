'use strict';
var emailSchema = require('./email-schema');
var usernameSchema = require('./username-schema');
var _ = require('lodash');
var requiredString = require('./required-string');

function makeForgotPasswordSchema() {
  return {
    // Just check username exists or not,
    // do not leak LEI username regex
    // e.g. for reset password, unlock account, login
    // NOT for signup
    username: _.assign({}, usernameSchema.username, {
      checkFn: requiredString
    }),
    email: emailSchema.email
  };
}

module.exports = makeForgotPasswordSchema;
