import { controller } from './app';
import pageLabels from '../../../common/const/page-labels';
import leiProducts from '../../../common/const/lei-products';

// a controller controls LEI actions (update, renew, transfer) selection page
controller('LeiSelectionController', [
  '$scope',
  '$routeParams',
  'AlertMessenger',
  'Redirect',
  'PublicStorageService',
  'DefaultErrorHandler',
  function($scope, $routeParams, AlertMessenger, Redirect, PublicStorageService, DefaultErrorHandler) {
    $scope.action = $routeParams.type;
    $scope.labels = pageLabels[$scope.action + 'LEI'];
    $scope.maxBulkCount = 250; // fallback
    PublicStorageService.getMaxBulkCount()
      .then(function(res) {
        $scope.maxBulkCount = res.data;
      })
      .catch(DefaultErrorHandler);

    if (!$scope.action || !leiProducts.ACTION[$scope.action.toUpperCase()]) {
      Redirect.status(404);
    }

    AlertMessenger.reset();
  }
]);
