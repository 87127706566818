'use strict';
var lpick = require('lodash.pick');
var checkArgsType = require('@dtwebservices/check-args').checkArgsType;

var validator = require('validator');

var BConnectWorkflowError = require('./workflow-error');

var ATTR = {
  TERMS_OF_SERVICE: 'termsOfService',
  PRIVACY_POLICY: 'privacyPolicy',
  EMAILS: 'emails',
  ADMIN_REVIEW: 'adminReview',
  PRODUCT_TYPE: 'productType',
  HISTORY: 'history',
  PHONE_COUNTRY_CODE: 'phoneCountryCode',
  LANGUAGE_SELECTION: 'languageSelection'
};

var usStates = require('../helper/us-states');
var bCountryCode = require('../helper/country-code');
var phoneAreaCode = require('../helper/phone-area-code');

function sortAndLower(stringArr) {
  return stringArr
    .sort(function(a, b) {
      return a < b ? 1 : -1;
    })
    .map(function(s) {
      return s.toLowerCase();
    });
}

function getFirstDup(stringArr) {
  var map = {};

  for (var i = 0; i < stringArr.length; i += 1) {
    if (map[stringArr[i]]) {
      return stringArr[i];
    }
    map[stringArr[i]] = true;
  }

  return false;
}

function sortAndLowerEmails(emails) {
  var sortedLowerEmails = sortAndLower(emails);

  var dupEmail = getFirstDup(sortedLowerEmails);

  if (dupEmail) {
    throw new BConnectWorkflowError('E_EMAIL_DUPLICATED', dupEmail);
  }

  sortedLowerEmails.forEach(function(em) {
    if (!validator.isEmail(em)) {
      throw new BConnectWorkflowError('E_EMAIL_INVALID', em);
    }
  });

  return sortedLowerEmails;
}

function sortAndLowerDomains(domains) {
  var sortedLowerDomains = sortAndLower(domains);

  var dupDomain = getFirstDup(sortedLowerDomains);

  if (dupDomain) {
    throw new BConnectWorkflowError('E_DOMAIN_DUPLICATED', dupDomain);
  }

  sortedLowerDomains.forEach(function(dm) {
    if (!validateDomain(dm)) {
      throw new Error('Invalid domain: ' + dm);
    }
  });

  return sortedLowerDomains;
}

function checkEmailAgainstBlacklistDomainsFn(backlistDomains) {
  return function(em) {
    var blacklistDomainMap = backlistDomains.reduce(function(map, dm) {
      map[dm] = true;
      return map;
    }, {});

    var emailDomain = em.substr(em.indexOf('@') + 1);

    if (blacklistDomainMap[emailDomain]) {
      throw new BConnectWorkflowError('E_EMAIL_DOMAIN_BLACKLIST', em);
    }

    return em;
  };
}

function validateDomain(domain) {
  return validator.isFQDN(domain);
}

function makeHistoryItem(action, data) {
  checkArgsType(arguments, 'string', 'string');

  return {
    timestamp: new Date().toISOString(),
    action: action,
    data: data
  };
}

function makeAdminReviewItem(sdsk, note) {
  checkArgsType(arguments, 'string', 'string');

  return {
    sdsk: sdsk,
    note: note
  };
}

var bconnectFieldMap = {
  username: { required: true, maxlength: 80 },
  email: { required: true, maxlength: 100, trim: true },
  phone: { required: true, maxlength: 24 },
  password: { required: true },
  firstName: { required: true, maxlength: 50 },
  lastName: { required: true, maxlength: 50 },
  suffix: { maxlength: 16 },
  middleName: { maxlength: 50 },
  address1: { required: true, maxlength: 64 },
  address2: { maxlength: 64 },
  city: { required: true, maxlength: 32 },
  state: { required: true, maxlength: 32 },
  postalCode: { required: true, maxlength: 32 },
  country: { required: true },
  gender: {},
  birthYear: {},
  mobilePhone: {}, // DEPRECATED
  mobilePhoneValidated: {}, // DEPRECATED
  validationKey: {},
  validationTimestamp: {},
  organizationName: { maxlength: 48 },
  organizationNameValidated: {},
  organizationSizeMin: {},
  organizationSizeMax: {},
  industry: { required: true, maxlength: 48 },
  jobTitle: { required: true, maxlength: 128 },
  regionalFocus: { maxlength: 32 },
  householdIncomeMin: {},
  householdIncomeMax: {},
  currencyUnit: {},
  secretQuestion: { required: true },
  secretQuestionAnswer: { required: true },
  migrated: {},
  socialUserId: {},
  socialSite: {},
  termsOfService: { required: true, trim: true },
  privacyPolicy: { required: true, trim: true },
  internalMarketingFlag: { pref: true },
  externalMarketingFlag: { pref: true },
  promotionalOffersFlag: { pref: true },
  emailAlertsFlag: { pref: true },
  thirdPartyDataShareOptOutFlag: { pref: true },
  emailFormat: { pref: true },
  alertFrequency: { pref: true },
  alertTypes: { pref: true },
  primaryCommunication: { pref: true },
  newsCategories: { pref: true },
  tickersTraded: { pref: true }
};

bconnectFieldMap[ATTR.TERMS_OF_SERVICE] = { required: true, attr: true };
bconnectFieldMap[ATTR.PRIVACY_POLICY] = { required: true, attr: true };
bconnectFieldMap[ATTR.EMAILS] = { required: false, attr: true };
bconnectFieldMap[ATTR.ADMIN_REVIEW] = { required: false, attr: true };
bconnectFieldMap[ATTR.PRODUCT_TYPE] = { required: true, attr: true };
bconnectFieldMap[ATTR.HISTORY] = { required: false, attr: true };
bconnectFieldMap[ATTR.PHONE_COUNTRY_CODE] = { required: true, attr: true };
bconnectFieldMap[ATTR.LANGUAGE_SELECTION] = { required: false, attr: true };

function filterProfile(profileObj, includeTrim) {
  checkArgsType(arguments, 'object');

  var profileKeys = Object.keys(bconnectFieldMap).filter(function(fieldMapKey) {
    var fieldMapObj = bconnectFieldMap[fieldMapKey];
    return (!includeTrim || !fieldMapObj.trim) && !fieldMapObj.attr && !fieldMapObj.pref;
  });

  return lpick(profileObj, profileKeys);
}

function filterProfileForAttr(profileObj) {
  checkArgsType(arguments, 'object');

  var attrKeys = Object.keys(bconnectFieldMap).filter(function(fieldMapKey) {
    var fieldMapObj = bconnectFieldMap[fieldMapKey];
    return !!fieldMapObj.attr;
  });

  return lpick(profileObj, attrKeys);
}

function filterProfileForPref(profileObj) {
  checkArgsType(arguments, 'object');

  var prefKeys = Object.keys(bconnectFieldMap).filter(function(fieldMapKey) {
    var fieldMapObj = bconnectFieldMap[fieldMapKey];
    return !!fieldMapObj.pref;
  });

  return lpick(profileObj, prefKeys);
}

function validateState(stateVal, bconnectCountryCode) {
  var bconnectCountryCodeForUS = 1;

  if (bconnectCountryCode === bconnectCountryCodeForUS) {
    var usStateNotFound =
      usStates
        .map(function(st) {
          return st.abbr;
        })
        .indexOf(stateVal) === -1;

    if (usStateNotFound) {
      throw new BConnectWorkflowError('E_PROFILE_INVALID_US_STATE');
    }
  }

  return stateVal;
}

function validateCountry(countryCodeVal) {
  if (
    bCountryCode
      .map(function(cty) {
        return cty.code;
      })
      .indexOf(countryCodeVal) === -1
  ) {
    throw new BConnectWorkflowError('E_PROFILE_INVALID_COUNTRY');
  }

  return countryCodeVal;
}

function validateEmail(email) {
  if (!validator.isEmail(email)) {
    throw new BConnectWorkflowError('E_PROFILE_INVALID_EMAIL');
  }

  return email;
}

function validateProfileMaxlength(profileObj) {
  checkArgsType(arguments, 'object');

  var tooLongFields = Object.keys(bconnectFieldMap).filter(function(f) {
    var maxlength = bconnectFieldMap[f].maxlength;

    return maxlength && profileObj[f] && profileObj[f].length > maxlength;
  });

  if (!tooLongFields.length) {
    return true;
  }

  throw new BConnectWorkflowError('E_PROFILE_FIELD_TOO_LONG', tooLongFields);
}

module.exports = {
  US_STATES: usStates,
  COUNTRY_CODE: bCountryCode,
  PHONE_AREA_CODE: phoneAreaCode,
  ATTR: ATTR,
  FIELD_MAP: bconnectFieldMap,
  sortAndLowerEmails: sortAndLowerEmails,
  sortAndLowerDomains: sortAndLowerDomains,
  sortAndLower: sortAndLower,
  checkEmailAgainstBlacklistDomainsFn: checkEmailAgainstBlacklistDomainsFn,
  makeHistoryItem: makeHistoryItem,
  makeAdminReviewItem: makeAdminReviewItem,
  filterProfile: filterProfile,
  filterProfileForAttr: filterProfileForAttr,
  filterProfileForPref: filterProfileForPref,
  validateEmail: validateEmail,
  validateState: validateState,
  validateCountry: validateCountry,
  validateProfileMaxlength: validateProfileMaxlength
};
