import angular from 'angular';
import utils from './utils';
import controllers from './controllers';
import applets from './applets';
import routes from './appRoutes';

const __module__ = 'leiApp',
  app = angular.module(__module__, ['ngMessages', 'ngRoute', routes, utils, controllers, ...applets]),
  { factory, directive, config } = app;

export { app, factory, directive, config, __module__ as default };
