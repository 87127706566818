import { component } from '../app';
import template from '@lei-website-client/views/form/events.html';
import {
  generateLeiDiffReport,
  filterLeiDiffReport,
  getPath,
  setPath,
  leiDiff,
  generateExistingEventsReport,
  sortLeiDiffList,
  generateGroupedLeiDiffReport,
  toDateString,
  generateTempAction,
  addEventModelToGroupedLeiDiffReport,
  addComplexEventTypes,
  addComplexEventGroupTypes,
  addEventGroupIds,
  groupComplexEventsByType,
  getStatusFromEffectiveDate,
  prepareForBbds,
  revertInProgressFieldsForBbds,
  pruneUltimateWhenNoDirectParent,
  pruneSkipEventTypes,
  pruneEmptyRelationshipsBeforeDiff,
  addExtraEventFieldsToGroupedLeiDiffReport,
  addCallbackToEvents,
  mergeInflightGroupedLeiDiffReport,
  mergeDraftBbdsDataWithGroupedLeiDiffReportEvents,
  generateNewEventsByStatusChange,
  updateRunningEvents,
  getInProgressAndDoneFromExistingEvents,
  addLineageIdsToGroupedLeiDiffReportEvents,
  validateRequiredFields,
  deepClone,
  convertEventToBbdsEvent,
  debounceCall
} from '../utilities/lei-events-util';
import { WORKFLOW_TAB_STEPS } from '../../../utils/constants';
import { DS_NAME, EVENT_CATEGORIES } from '../../../../../common/const/entity-actions';
import { postprocess } from '../utilities';
import angular from 'angular';

export default class LeiEventsController {
  constructor($rootScope, $scope, $sce, $routeParams, FormService, LeiService) {
    //const effectiveDate = new Date();
    const vm = this;
    this.FormService = FormService;
    const { getEventIds } = LeiService;
    vm.getEventIds = getEventIds;
    vm.lei = $routeParams.id;
    vm.hideEventTabContent = true;
    this.$scope = $scope;

    // Edit Events
    vm.delta = [];

    //Existing Events
    vm.existingInProgressEvents = [];

    // Entity Action Events
    generateTempAction({ eventCallBackFn: vm._setWatcherForTempEvent($scope), generateLineageId: vm.getEventIds }).then(
      tempEventAction => (vm.tempAction = tempEventAction)
    );
    vm.addTempAction = false;

    // All Events
    vm.unbindEventWatchers = [];

    $rootScope.$on(
      'onChangeTab',
      debounceCall((event, selectedTab) => {
        this._displayEvents(event, selectedTab);
        if (selectedTab.id != 'EVENTS') return;

        //reset form
        vm.expandAddTempActionForm = true;
        vm.addAction = async () => {
          if (vm.addTempAction) {
            // reset/clear tempAction
            vm.tempAction = await generateTempAction({
              eventCallBackFn: vm._setWatcherForTempEvent($scope),
              generateLineageId: vm.getEventIds
            });
          }
          vm.addTempAction = !vm.addTempAction;
        };

        vm.getTempAction = () => vm.tempAction;

        vm._generateEventsModel(vm);
      }),
      100
    );
  }

  _setWatcherForTempEvent($scope) {
    return event =>
      $scope.$watch(
        () => (event && event.legalEntityEventEffectiveDate && event.legalEntityEventEffectiveDate.date) || false,
        date => {
          console.debug(
            'lei-event.js> _setWatcherForTempEvent() called event:',
            event,
            ' date: ',
            date,
            'isTransfer: ',
            this.state.isTransfer
          );
          if (!event || !event.legalEntityEventEffectiveDate || !event.legalEntityEventEffectiveDate.date) return;

          event.legalEntityEventEffectiveDate.dateString = toDateString(date);

          if (this.state.isTransfer && event.category === EVENT_CATEGORIES.EXISTING_EVENT)
            this.hasCancelledTransferEvent =
              "When transferring an LEI from a prior LOU to Bloomberg that has 'In-Progress' Entity Events recorded, we require that those events be marked as 'Withdrawn'. If desired, you can update the appropriate fields in this transfer request to have the event re-entered in the LEI record.";

          // For existing events, don't update status (transfers keep status as withdrawn)
          if (event.category === EVENT_CATEGORIES.EXISTING_EVENT) return;

          event.legalEntityEventStatus = getStatusFromEffectiveDate(date);
        }
      );
  }

  async _generateEventsModel(vm) {
    const formData =
      vm.formData &&
      vm.formData(vm.eventTab.id, { showErrors: false }).payload &&
      vm.formData(vm.eventTab.id, { showErrors: false }).payload[DS_NAME]['data'];

    console.debug('lei-events.js> formData', formData, ' vm.bbds.data:', vm.bbds.data);

    console.time('DELTA_TIMER');
    const bbdsData = deepClone(vm.bbds.data);

    if (bbdsData && formData) {
      pruneEmptyRelationshipsBeforeDiff(getPath(bbdsData, ['relationships']));
      pruneEmptyRelationshipsBeforeDiff(getPath(vm.bbds, ['data', 'relationships']));

      // generate delta report
      const diffInfo = leiDiff(formData, bbdsData);
      const leiDiffInfo = {};
      generateLeiDiffReport(diffInfo, undefined, leiDiffInfo);
      const filteredCollapsedDiffInfo = filterLeiDiffReport(leiDiffInfo);
      const groupedLeiDiffReport = generateGroupedLeiDiffReport(filteredCollapsedDiffInfo);
      const groupedLeiDiffReportWithEvents = addEventModelToGroupedLeiDiffReport(groupedLeiDiffReport);

      // process types to have accurate in flight and draft merging
      addComplexEventTypes(groupedLeiDiffReportWithEvents);
      addComplexEventGroupTypes(groupedLeiDiffReportWithEvents);
      console.debug('lei-events.js> addComplexEventGroupTypes() result:', groupedLeiDiffReportWithEvents);
      const groupedComplexLeiDiffReportWithEvents = groupComplexEventsByType(groupedLeiDiffReportWithEvents);

      // Take care of switching tabs and modified diffs
      vm.groupedLeiDiffReportWithAddedFields = mergeInflightGroupedLeiDiffReport(
        vm.groupedLeiDiffReportWithAddedFields,
        groupedComplexLeiDiffReportWithEvents
      );

      // When draft loaded
      if (this.draftPayload) {
        vm.groupedLeiDiffReportWithAddedFields = mergeDraftBbdsDataWithGroupedLeiDiffReportEvents(
          vm.draftPayload.data,
          vm.groupedLeiDiffReportWithAddedFields
        );
        console.log(
          'lei-events.js> _generateEventsModel() vm.groupedLeiDiffReportWithAddedFields:',
          vm.groupedLeiDiffReportWithAddedFields,
          ' mergedDraftBbdsDataWithGroupedLeiDiffReportEvents',
          vm.groupedLeiDiffReportWithAddedFields
        );
        this.draftPayload = undefined;
      }

      if (vm.groupedLeiDiffReportWithAddedFields) {
        // Reprocess types after inflight and draft changes are applied
        addComplexEventTypes(vm.groupedLeiDiffReportWithAddedFields);
        addComplexEventGroupTypes(vm.groupedLeiDiffReportWithAddedFields);
        console.debug('lei-events.js> addComplexEventGroupTypes() result:', vm.groupedLeiDiffReportWithAddedFields);
        const groupedComplexLeiDiffReportWithEvents2 = groupComplexEventsByType(vm.groupedLeiDiffReportWithAddedFields);

        vm.groupedLeiDiffReportWithAddedFields = addExtraEventFieldsToGroupedLeiDiffReport(
          groupedComplexLeiDiffReportWithEvents2
        );

        await addEventGroupIds(vm.groupedLeiDiffReportWithAddedFields, vm.getEventIds);
        await addLineageIdsToGroupedLeiDiffReportEvents(vm.groupedLeiDiffReportWithAddedFields, vm.getEventIds);

        // Unbind existing watchers
        if (vm.unbindEventWatchers.length) vm.unbindEventWatchers.forEach(eventWatcher => eventWatcher());

        vm.unbindEventWatchers = addCallbackToEvents(
          Object.values(vm.groupedLeiDiffReportWithAddedFields).map(({ event }) => event),
          {
            eventCallBackFn: vm._setWatcherForTempEvent(vm.$scope)
          }
        );
      }

      vm.delta = vm.groupedLeiDiffReportWithAddedFields
        ? sortLeiDiffList(Object.values(vm.groupedLeiDiffReportWithAddedFields))
        : [];
    } else {
      vm.groupedLeiDiffReportWithAddedFields = {};
      vm.delta = [];
    }
    console.timeEnd('DELTA_TIMER');
    console.log('lei-events.js> delta:', JSON.stringify(vm.delta));

    const existingEvents = getPath(vm.bbds.data, 'entity.legalEntityEvents.legalEntityEvent');

    if (existingEvents) {
      const { runningEvents } = getInProgressAndDoneFromExistingEvents(existingEvents);

      if (!vm.existingInProgressEvents || !vm.existingInProgressEvents.length)
        vm.existingInProgressEvents = generateExistingEventsReport(runningEvents);

      const eventWatchers = addCallbackToEvents(vm.existingInProgressEvents || [], {
        eventCallBackFn: vm._setWatcherForTempEvent(vm.$scope)
      });

      vm.unbindEventWatchers = [...vm.unbindEventWatchers, ...eventWatchers];
    }
    console.log('lei-events.js> vm.existingInProgressEvents: ', vm.existingInProgressEvents);
  }

  _displayEvents(event, selectedTab) {
    if (!this.eventTab) this.eventTab = WORKFLOW_TAB_STEPS.filter(item => item.id == 'EVENTS')[0];

    this.hideEventTabContent = selectedTab.id != this.eventTab.id;
  }

  _setPropsFromState() {
    this.FormService._payload(this.state)
      .then(bbdsPayload => {
        this.bbds = bbdsPayload;
      })
      .catch(e => console.error);
  }

  prepareExistingEvents(processedPayload, options) {
    // TODO: add exising events and action update
    const allEvents = getPath(
      processedPayload,
      [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'],
      []
    );
    const { runningEvents, doneEvents } = getInProgressAndDoneFromExistingEvents(allEvents);

    const modifiedRunningEvents = updateRunningEvents(runningEvents, this.existingInProgressEvents);

    const newCompletedOrWithdrawnEvents = generateNewEventsByStatusChange(
      runningEvents, // original (not yet modified)
      this.existingInProgressEvents, // modified
      processedPayload,
      options
    );

    if (doneEvents.length || modifiedRunningEvents.length || newCompletedOrWithdrawnEvents.length) {
      setPath(
        processedPayload,
        [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'],
        [...doneEvents, ...modifiedRunningEvents, ...newCompletedOrWithdrawnEvents]
      );
    }
  }

  prepareEditEntityEvents(processedPayload, options) {
    // Only process when events were generated by entity detail updates
    if (this.groupedLeiDiffReportWithAddedFields && !angular.equals(this.groupedLeiDiffReportWithAddedFields, {})) {
      const groupedLeiDiffsWithBbdsEvents = prepareForBbds(
        processedPayload,
        Object.values(this.groupedLeiDiffReportWithAddedFields),
        options
      );
      console.debug(
        `lei-events.js> prepareEntityEditUpdates() bbdsEventsList: ${groupedLeiDiffsWithBbdsEvents}, this.delta: ${this.delta}`
      );

      // Skip revert when saving draft
      if (options && options.revertInProgress)
        revertInProgressFieldsForBbds(processedPayload, groupedLeiDiffsWithBbdsEvents);

      pruneUltimateWhenNoDirectParent(processedPayload);

      const leiDiffsEventsWithSkippedEventTypes = pruneSkipEventTypes(groupedLeiDiffsWithBbdsEvents);
      const bbdsEvents = leiDiffsEventsWithSkippedEventTypes.map(({ bbdsEvent }) => bbdsEvent);
      const existingEvents = getPath(
        processedPayload,
        [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'],
        []
      );

      // TODO: Add BRE to stop new events for types having existing IN_PROGRESS.
      // TODO: May want to allow for array types when affected fields differ (ex: other entity names)
      const publishedExistingEvents = existingEvents.filter(
        ({ legalEntityEventRecordedDate }) => !!legalEntityEventRecordedDate
      );
      const combinedEvents = existingEvents ? publishedExistingEvents.concat(bbdsEvents) : bbdsEvents;

      if (combinedEvents.length) {
        setPath(
          processedPayload,
          [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'],
          existingEvents ? existingEvents.concat(bbdsEvents) : bbdsEvents
        );
      }
    }
  }

  prepareAddActionEvents(processedPayload) {
    if (this.addTempAction) {
      const groupedTempActionsWithBbdsEvents = prepareForBbds(processedPayload, [{ event: this.tempAction }]);
      console.log(
        `lei-events.js> prepareAddActionEvents() bbdsEventsList: ${groupedTempActionsWithBbdsEvents}, this.delta: ${this.delta}`
      );

      const tempActionsEvents = groupedTempActionsWithBbdsEvents.map(({ bbdsEvent }) => bbdsEvent);
      console.debug(
        `lei-events.js> prepareEntityEditUpdates() eventsList: ${groupedTempActionsWithBbdsEvents}, prunedGroupedComplexLeiDiffs: ${tempActionsEvents}`
      );

      const existingEvents = getPath(
        processedPayload,
        [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'],
        []
      );

      if (existingEvents.length || tempActionsEvents.length)
        setPath(
          processedPayload,
          [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'],
          [...existingEvents, ...tempActionsEvents]
        );
    }
  }

  $onInit() {
    console.log('lei-events.js> $onInit() Starting ');
    this._setPropsFromState();
    this.onLoad({
      read: (processedPayload, options) => {
        this.prepareExistingEvents(processedPayload, options);
        this.prepareEditEntityEvents(processedPayload, options);
        this.prepareAddActionEvents(processedPayload);
        // TODO: Preserve empty affected field values
        const events = deepClone(
          getPath(processedPayload, [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'], [])
        );
        postprocess(processedPayload);
        // TODO: Apply empty affected field values

        events.length
          ? setPath(processedPayload, [DS_NAME, 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'], events)
          : setPath(processedPayload, [DS_NAME, 'data', 'entity', 'legalEntityEvents'], undefined);
        return processedPayload;
      },
      init: draftPayload => {
        this.draftPayload = draftPayload;
      },
      bbds: () => this.bbds,
      errors: processedPayload => {
        console.log('lei-events.js> errors() processedPayload: ' + processedPayload);
        const validationErrors = [];
        if (this.groupedLeiDiffReportWithAddedFields && !angular.equals(this.groupedLeiDiffReportWithAddedFields, {}))
          validationErrors.push(
            ...validateRequiredFields(
              Object.values(this.groupedLeiDiffReportWithAddedFields).map(groupedLeiDiff => {
                const validationEvent = {
                  validationEvent: {
                    ...groupedLeiDiff.event,
                    ...convertEventToBbdsEvent(groupedLeiDiff.event, { affectedFieldsHelper: undefined })
                  }
                };
                return validationEvent;
              })
            )
          );
        if (this.addTempAction)
          validationErrors.push(
            ...validateRequiredFields([
              {
                validationEvent: {
                  ...this.tempAction,
                  ...convertEventToBbdsEvent(this.tempAction, { affectedFieldsHelper: undefined })
                }
              }
            ])
          );

        return validationErrors;
        // return [{severity: 'error', message:'Check if error occurs after raising alert!'}];
      }
    });
  }
}

component('leiEvents', {
  controller: LeiEventsController,
  controllerAs: '$leiEventsController',
  template,
  bindings: {
    onLoad: '<onload',
    state: '<',
    formData: '<formdata'
  }
});
