import { directive } from '../app';
import { FILE_UPLOAD, MESSAGES } from '../utilities';
import template from '@lei-website-client/views/form/file.html';

export default /* @ngInject */ function leiFormFile($timeout, AlertMessenger) {
  function link($scope) {
    // Register properties
    $scope.attachments = { refFiles: [], authorFiles: [], authorForm: [], party: 'first' };
    $scope.fileUpload = FILE_UPLOAD;

    $scope.auth = true;
    $scope.needRef = false; // need reference files if events are being added/updated or it's a fund

    function read(validate = true) {
      const filesToUpload = [...$scope.attachments.refFiles, ...$scope.attachments.authorForm];

      const fileErrors = [];
      if (validate && $scope.auth && !$scope.attachments.authorForm.length) {
        fileErrors.push({ severity: 'error', message: MESSAGES.authorizationForm.error });
      }

      if (validate && $scope.needRef && !$scope.attachments.refFiles.length) {
        fileErrors.push({ severity: 'error', message: MESSAGES.referenceFile.error });
      }

      return { filesToUpload, fileErrors };
    }

    function party() {
      return $scope.attachments.party;
    }

    function toggleAuth(value) {
      if (value == null) value = !$scope.auth;
      $scope.auth = value;
    }

    function setNeedRefFile(value) {
      $scope.needRef = value;
    }

    // Register actions
    $scope.dragAttachments = () => {
      const total = read(false).filesToUpload.reduce((sum, b) => sum + b.size, 0);
      if (total > FILE_UPLOAD.max_file_size) {
        return AlertMessenger.alert(MESSAGES.maxAttachmentsSizeSingle.error);
      }
    };

    $scope.dragAuthorFiles = () => {
      $scope.attachments.authorForm = $scope.attachments.authorFiles.slice(-1);
      $scope.dragAttachments();
    };

    $scope.deleteAttachment = (attachments, target) => {
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i].name === target.name) {
          attachments.splice(i, 1);
          break;
        }
      }
    };

    $timeout(() =>
      $scope.onLoad({
        read,
        party,
        toggleAuth,
        setNeedRefFile
      })
    );
  }

  return { restrict: 'E', scope: { onLoad: '<onload' }, link, template };
}

directive('leiFormFile', leiFormFile);
