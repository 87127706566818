'use strict';

module.exports = [
  // Belarus
  'BY',
  // Iran
  'IR',
  // Syria
  'SY',
  // Cuba
  'CU',
  // Korea, Democratic People's Republic of (North Korea)
  'KP',
  // Russian Federation
  'RU'
];
