'use strict';

const entitySection = 'lei-form > div:nth-of-type(1)';
const relationshipsSection = 'lei-form > div:nth-of-type(2)';
const contactSection = 'lei-form > div:nth-of-type(3)';
const filesSection = 'lei-form-file';
const entitySubSections = 'lei-form > div:nth-of-type(1) > div:nth-of-type(2) > div';
const sectionHeaders = 'lei-form > div > div:nth-of-type(1)';
const relationshipParents = relationshipsSection + ' > div:nth-of-type(2)';
const managingFundParent = relationshipParents + ' > div:nth-of-type(1)';
const umbrellaFundParent = relationshipParents + ' > div:nth-of-type(2)';
const masterFundParent = relationshipParents + ' > div:nth-of-type(3)';
const fundParentTitle = 'div:nth-of-type(1) > span > label';
const entityFormLabels = 'span > label';

// Display following language names based on jurisdiction/address countries
const LOCAL_LANGUAGE_NAMES = [
  'Arabic',
  'Bulgarian',
  'Belarusian',
  'Chinese',
  'Estonian',
  'French',
  'Georgian',
  'Japanese',
  'Khmer',
  'Korean',
  'Macedonian',
  'Polish',
  'Romanian',
  'Russian',
  'Thai',
  'Ukrainian',
  'Vietnamese'
];

const customizeLabel = labels => {
  const results = [];
  for (const label of labels) {
    results.push(...LOCAL_LANGUAGE_NAMES.map(lang => `${label} (${lang})`));
  }
  return results;
};

const allSectionsBut = sectionToShow => {
  return [entitySection, relationshipsSection, contactSection, filesSection].filter(
    section => section !== sectionToShow
  );
};

const sectionIdToLabel = {
  ENTITY: 'GENERAL',
  ADDRESSES: 'ADDRESSES',
  RELATIONSHIPS: 'RELATIONSHIPS',
  EVENTS: 'EVENTS',
  CONTACT: 'CONTACT',
  FILES: 'FILES'
};

module.exports.FORM_SECTIONS = {
  ENTITY: {
    HIDE: allSectionsBut(entitySection),
    SHOW: [entitySection],
    FIELDS: [
      'Legal Name',
      'Legal Name (Local Language)',
      'Legal Name (English)',
      'Other Entity Names',
      'Legal Jurisdiction Country',
      'Legal Jurisdiction Region',
      'Registration Authority',
      'Registration Entity ID',
      'Legal Form',
      'Other Legal Form',
      'Entity Category',
      'Entity Sub Category',
      'Fund Sub Category',
      'Location of Branch (Country)',
      'Location of Branch (Region)',
      'Entity Creation Date',
      'Fund Launch Date Is Not Available',
      'Website',
      'Other Entity IDs'
    ].concat(customizeLabel(['Legal Name'])),
    PATHS: []
  },
  ADDRESSES: {
    HIDE: allSectionsBut(entitySection),
    SHOW: [entitySection],
    FIELDS: [
      'Legal Address (Local Language)',
      'Headquarters Address (Local Language)',
      'Legal Address (English)',
      'Headquarters Address (English)',
      'Other Addresses (Local Language)'
    ].concat(customizeLabel(['Legal Address', 'Headquarters Address'])),
    PATHS: [
      'data|entity|legalAddressInLocalLanguage',
      'data|entity|headquartersAddressInLocalLanguage',
      'data|entity|legalAddressInEnglish',
      'data|entity|headquartersAddressInEnglish',
      'data|entity|otherAddressesInLocalLanguage'
    ]
  },
  RELATIONSHIPS: {
    HIDE: allSectionsBut(relationshipsSection),
    SHOW: [relationshipsSection],
    FIELDS: [],
    PATHS: ['data|relationships']
  },
  EVENTS: {
    HIDE: allSectionsBut(),
    SHOW: [],
    PATHS: [],
    FIELDS: ['Legal Entity Events']
  },
  CONTACT: {
    HIDE: allSectionsBut(contactSection),
    SHOW: [contactSection],
    FIELDS: [],
    PATHS: ['data|contact']
  },
  FILES: {
    HIDE: allSectionsBut(filesSection),
    SHOW: [filesSection],
    FIELDS: [],
    PATHS: []
  }
};

const entityFormFieldsFaqMap = {
  'Entity Category': '/docs/faq#what-are-the-definitions-of-the-enumerations-in-the-entity-category-field',
  'Fund Sub Category': '/docs/faq#what-are-the-sub-categories-for-funds',
  'Entity Creation Date': '/docs/faq#what-is-the-entity-creation-date',
  'Legal Address (English)': '/docs/faq#what-are-the-two-addresses',
  'Headquarters Address (English)': '/docs/faq#what-are-the-two-addresses',
  'Direct Parent': '/docs/faq#reporting-level-2-relationship-data',
  'Ultimate Parent': '/docs/faq#reporting-level-2-relationship-data',
  'Fund Manager Details': '/docs/faq#what-are-the-different-types-of-fund-management-relationships',
  'Umbrella Fund Details': '/docs/faq#what-are-the-different-types-of-fund-management-relationships',
  'Master Fund Details': '/docs/faq#what-are-the-different-types-of-fund-management-relationships',
  'What additional events should be reported?': '/docs/faq#what-are-the-types-of-events-that-i-should-be-reporting'
};

module.exports.ENTITY_SUBSECTIONS = entitySubSections;
module.exports.SECTION_HEADERS = sectionHeaders;
module.exports.SECTION_ID_TO_LABEL = sectionIdToLabel;
module.exports.FUND_PARENTS_SELECTORS = {
  MANAGING_FUND: managingFundParent,
  UMBRELLA_FUND: umbrellaFundParent,
  MASTER_FUND: masterFundParent,
  FUND_PARENT_TITLE: fundParentTitle,
  RELATIONSHIP_PARENTS: relationshipParents
};
module.exports.ENTITY_FORM_LABELS = entityFormLabels;
module.exports.ENTITY_FORM_FIELDS_FAQ_MAP = entityFormFieldsFaqMap;
