import { component } from '../app';
import template from '@lei-website-client/views/form/event-form.html';
import {
  LEGAL_ENTITY_EVENT_ACTION_TYPES,
  LEGAL_ENTITY_EVENT_GROUP_TYPES,
  LEGAL_ENTITY_EVENT_STATUSES,
  LEGAL_ENTITY_EVENT_SKIP_TYPE,
  VALIDATION_DOCUMENTS,
  LEGAL_ENTITY_EVENT_STATUS,
  EVENT_CATEGORIES
} from '../../../../../common/const/entity-actions';
import { convertToLabel, eventHasExtraFields } from '../utilities/lei-events-util';

export default class LeiEventFormController {
  constructor($document, $scope) {
    const vm = this;

    const generateDropdownOptions = itemList => itemList.map(item => ({ id: item, label: convertToLabel(item) }));
    vm.actionTypes = generateDropdownOptions(LEGAL_ENTITY_EVENT_ACTION_TYPES);
    vm.skipEventType = { id: LEGAL_ENTITY_EVENT_SKIP_TYPE, label: convertToLabel(LEGAL_ENTITY_EVENT_SKIP_TYPE) };
    vm.actionStatuses = generateDropdownOptions(LEGAL_ENTITY_EVENT_STATUSES);
    vm.actionGroupTypes = generateDropdownOptions(LEGAL_ENTITY_EVENT_GROUP_TYPES);
    vm.validationDocuments = generateDropdownOptions(VALIDATION_DOCUMENTS);
    vm.isReadonly = true;
    vm.peek = false;

    $scope.$watch('$leiEventFormCtrl.tempItem.legalEntityEventType.id', () => {
      vm.getExtraFields = eventHasExtraFields(this.tempItem.legalEntityEventType.id);
    });

    function showMissingFields($event) {
      if ($event.altKey && $event.shiftKey && $event.which == '81') {
        vm.peek = !vm.peek;
        $document.unbind('keydown', showMissingFields);
        $document.bind('keyup', rebindShowMissingFields);
      }
    }

    function rebindShowMissingFields($event) {
      console.log(`lei-event-form.js> constructor() keyup ctrlKey: ${$event.ctrlKey}, which: ${$event.which}`);
      $document.unbind('keyup', rebindShowMissingFields);
      $document.bind('keydown', showMissingFields);
    }

    //$document.bind('keydown', showMissingFields);
  }

  _consolidateTempEvent() {
    let formEvent = this.formData();

    // keep the user's previous selection of eventType (eg: data-correction)
    if (
      this.tempItem &&
      this.tempItem.derivedLegalEntityEventType &&
      this.tempItem.derivedLegalEntityEventType.id === formEvent.derivedLegalEntityEventType.id
    ) {
      let { legalEntityEventType, validationDocuments } = this.tempItem;

      formEvent.legalEntityEventType = legalEntityEventType;
      formEvent.validationDocuments = validationDocuments;
    }
    this.tempItem = formEvent;
  }

  _setPropsFromState() {
    console.log('lei-event-form.js> _setPropsFromState() Starting ');
    this.isTransfer = this.state.isTransfer;

    this._consolidateTempEvent();

    // Cancel in progress events for transfers. Cannot transfer affected fields.
    if (this.state.isTransfer && this.tempItem.category === EVENT_CATEGORIES.EXISTING_EVENT) {
      this.actionStatuses = [
        {
          id: LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED,
          label: convertToLabel(LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED)
        }
      ];
      this.tempItem.legalEntityEventStatus = {
        id: LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED,
        label: convertToLabel(LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED)
      };
    }
  }

  $onInit() {
    console.log('lei-event-form.js> $onInit() Starting ');
    this._setPropsFromState();
  }

  $onChanges(changesObj) {
    console.log('lei-event-form.js> $onChanges() Starting ');
    this._setPropsFromState();
  }
}

component('leiEventForm', {
  controller: LeiEventFormController,
  controllerAs: '$leiEventFormCtrl',
  template,
  bindings: {
    onLoad: '<onload',
    state: '<',
    formData: '<formdata'
  }
});
