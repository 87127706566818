'use strict';

module.exports = {
  lei: {
    $label: 'LEI',
    $placeholder: 'Type in LEI here, e.g. 2549004HPI362XQSKL61',
    $required: true,
    $maxlength: 20,
    $message: 'The LEI is required.'
  },
  owner: {
    $label: 'Original Username',
    $placeholder: 'The username this LEI is coming from.',
    $required: true,
    $message: 'The original username is required.'
  },
  user: {
    $label: 'Destination Username',
    $placeholder: 'The username this LEI is going to.',
    $required: true,
    $message: 'The destination username is required.'
  },
  email: {
    $label: 'Destination Email',
    $placeholder: "That user's email address (optional)."
  }
};
