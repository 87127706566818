import { factory } from './app';

factory('PublicStorageService', [
  '$http',
  function($http) {
    return {
      getSiteKeys: getSiteKeys,
      getTaxForCountry: getTaxForCountry,
      getMaxBulkCount: getMaxBulkCount
    };

    function getSiteKeys() {
      return $http.get('/public-store/site-keys');
    }

    function getTaxForCountry(countryCode) {
      return $http.get('/public-store/tax-for-country/' + countryCode);
    }

    function getMaxBulkCount(req, res) {
      return $http.get('/public-store/maxBulkCount');
    }
  }
]);
