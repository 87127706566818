import angular from 'angular';
import _ from 'lodash';
import { controller, component } from './app';
import {
  STAGES,
  STEPS,
  TRANSFER_RENEW_STAGES,
  TRANSFER_RENEW_STEPS,
  BULK_STAGES,
  BULK_STEPS,
  TAX_ENTITY_CATEGORY_MAP,
  STRIPE_BANK_MAP,
  STRIPE_CARD_MAP
} from '../utils/constants';
import { clientValidateVAT } from '../utils/clientVatValidator';
import leiProducts from '../../../common/const/lei-products';
import { schemaStore, checkStore } from '../../../common/validate';
import { isInvalidCountry, showInvalidCountry } from '../utils/cardUtils';
import { vatRequired, totalPrice, vatCountryWarning } from '../../../common/lib/pricing-utils';
import getCheckErrors from '../../../common/lib/get-check-messages';
import textHash from '../../../common/lib/text-hash';
import flashMessages from '../../../common/const/flash-message-data';
import pageLabels from '../../../common/const/page-labels';
import countriesNoZip from '../../../common/const/countries-no-zip';
import currencies from '../../../common/const/currencies';
import states from '../../../common/const/states';
import isoCountryCodesAlph2 from '../../../common/const/iso-country-codes-alpha2';
import taxIdRegionMappings from '../../../common/const/tax-id-region-mappings';

import views_addressSuggestions from '@lei-website-client/views/addressSuggestions.html';
import { TAX_ENTITY_CATEGORY_REGEX } from '../../../common/const/tax-entity-category-mappings';

component('addressDialog', {
  controller: [
    '$rootScope',
    function($rootScope) {
      var $ = window.jQuery;
      var $modal = $('#viewModal');
      var $ctrl = this;
      var onSetAddress = null;

      var show = function() {
        $modal.modal('show');
      };

      $ctrl.$onDestroy = $rootScope.$on('ADDRESS_DISPLAY', function(event, addressInfo, setAddress) {
        $ctrl.suggestedAddrList = addressInfo.suggestedAddrList;
        $ctrl.originalAddress = addressInfo.originalAddress;
        onSetAddress = setAddress;
        show();
      });

      $ctrl.$onInit = function() {
        $ctrl.chosenAddress = {};
        $modal.on('hide.bs.modal', angular.noop);
      };
      $ctrl.selectAddress = function() {
        onSetAddress($ctrl.chosenAddress.address);
      };
      $ctrl.cancelAddress = function() {
        onSetAddress();
      };
    }
  ],
  template: views_addressSuggestions
});

controller(
  'PayController',
  /* @ngInject */
  function PayController(
    $scope,
    $rootScope,
    $routeParams,
    $location,
    $sce,
    $q,
    $timeout,
    CardService,
    UserService,
    PublicStorageService,
    ProgressMessenger,
    Alert,
    ProgressAlert,
    injectStripeJS,
    DraftService,
    loginEmail,
    DefaultErrorHandler,
    ValidationService,
    $log
  ) {
    var vm = $scope;
    vm.hideConfirmation = true;
    vm.isSubmitted = false;
    vm.isShoppingCart = $location.url() == '/checkout';
    vm.showNameandLei = false;
    vm.suggestedAddrInfo = { originalAddress: {} };
    vm.blurb = {
      stripe_info: $sce.trustAsHtml(pageLabels.payment.stripe_info),
      tax_info: $sce.trustAsHtml('* ' + pageLabels.payment.tax_info),
      total_price: $sce.trustAsHtml('** ' + pageLabels.payment.total_price)
    };
    vm.wizardSteps = [];
    // update steps and set all previous step to finished state
    vm.$watch('currentStage', function(stage) {
      vm.wizardSteps = vm.wizardSteps.map(function(w) {
        w.active = stage >= w.stage;
        return w;
      });
    });

    var TRACKING_NUM = 10;
    const COUNTRIES_WITH_SPECIFIC_VAT_MSG = ['IN', 'TH'];

    // generate 10 years from current year
    vm.paymentTypeMap = leiProducts.PAYMENT_TYPES;
    vm.currencyMap = currencies;
    vm.CREDIT = leiProducts.CREDIT;
    vm.BILLME = leiProducts.BILLME;
    // properties bound to view
    // hold all payment form data to be sent to backend
    // prechecked business radio button
    // default to credit payment type
    vm.paymentInfo = {
      num: null,
      currency: 'USD',
      totalInCent: 0,
      taxInCent: 0,
      business: 'business',
      customCategory: '',
      billing: {},
      shipping: {},
      autoRenewOptIn: false,
      isShoppingCart: vm.isShoppingCart
    };
    vm.hideButton = false;

    // actions
    vm.updateTaxByCategory = updateTaxByCategory;
    vm.updateTax = updateTax;
    vm.pay = pay;
    vm.validateVAT = validateVAT;
    vm.handleVATError = {
      vat_test: category => {
        // param_in:
        //   - category - entity category selected on payment page (defined in common.const.tax-entity-category-mappings)
        // return:
        //   - true - payment from the shipping country requires VAT pattern match
        //   - false - payment from the shipping country does not require VAT pattern match
        if (!Object.keys(TAX_ENTITY_CATEGORY_REGEX).includes(vm.paymentInfo.shipping.country)) return true;
        return category !== '';
      },
      test: vat_number => {
        if (vat_number === 'N/A') return true;
        const country = vm.paymentInfo.shipping.country;
        vm.validVAT = clientValidateVAT.validateByCountry(vat_number, country);
        if (vm.validVAT && country === 'IN') {
          const region = vm.paymentInfo.shipping.state;
          vm.validVAT = isValidVATForIndiaRegion(vat_number, region);
        }
        return COUNTRIES_WITH_SPECIFIC_VAT_MSG.includes(country) ? vm.validVAT : true;
      }
    };
    vm.showVATError = showVATError;
    vm.showVATWarning = showVATWarning;
    vm.isInvalidCountry = isInvalidCountry;
    vm.showLocalLanguageCompanyVATWarning = showLocalLanguageCompanyVATWarning;
    vm.showCountrySpecificWarning = showCountrySpecificWarning;
    vm.vatCountryWarning = () =>
      vm.paymentInfo.shipping.country ? vatCountryWarning(vm.paymentInfo.shipping.country) : '';
    vm.vatOnCheck = vatOnCheck;
    vm.copyAddress = copyAddress;
    vm.clearBilling = clearBilling;
    vm.isDiscount = false;
    vm.formService = { stripe: undefined, element: undefined }; // Stripe v3 API stuff
    vm.formAction = {
      billing: {
        schema: getFromSchema,
        states: [],
        onCountryChange: () => {
          updateBillStates();
          showInvalidCountry(getFromSchema, vm);
        }
      },
      shipping: {
        schema: getFromSchema,
        states: [],
        onCountryChange: () => {
          updateTax();
          showInvalidCountry(getFromSchema, vm);
        },
        onLocalLangCompanyChange: showLocalLangCompanyWarn
      },
      card: { schema: getFromSchema, cards: [], onCardChange: updateCard, onCardError: disableButtonFn }
    };
    vm.formData = {
      countries: [],
      domicileCountries: [],
      states: {},
      noZip: countriesNoZip
    };

    // State/Province Map
    for (var c in states) {
      vm.formData.states[c] = states[c].sort(function strCmp(a, b) {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
    }

    // Schema
    var paymentAddressSchema = schemaStore.get('paymentAddress');
    function getFromSchema(field) {
      return paymentAddressSchema[field];
    }

    function atLeastOneFieldTouched(errType) {
      return vm.form.$error[errType].some(field => field.$touched);
    }

    vm.fieldsTouchedWithError = fieldsTouchedWithError;
    function fieldsTouchedWithError() {
      if (vm.form) {
        const err = vm.form.$error;
        return err && Object.keys(err).some(atLeastOneFieldTouched);
      }
      return vm.hideConfirmation;
    }

    vm.disableButton = false;
    function disableButtonFn(value) {
      vm.disableButton = value;
    }

    load();
    async function load() {
      return CardService.verify($routeParams.humanId, vm.isShoppingCart)
        .then(async function(res) {
          var submission = res.data;
          vm.submission = submission;

          const { data: finalBillingClass } = await UserService.getBillingClass(submission.isBulk ? 'BULK' : undefined);

          var isTransferRenew = submission.type === leiProducts.ACTION.TRANSFERRENEW;

          vm.isTransferRenew = isTransferRenew;
          vm.isMixed = submission.isMixed;

          vm.metaData = submission.metaData;
          vm.displayLabel = leiProducts.displayLabel;

          vm.productInfo = leiProducts[finalBillingClass + submission.type + 'LEI'].PRICING[vm.paymentInfo.currency];
          vm.productDescription = submission.isMixed
            ? 'My Shopping Cart'
            : leiProducts[finalBillingClass + submission.type + 'LEI'].productDescription;
          vm.wizardSteps = submission.isBulk ? BULK_STEPS : isTransferRenew ? TRANSFER_RENEW_STEPS : STEPS;

          vm.goBackLink = submission.isBulk ? undefined : decodeURIComponent($routeParams.goBackLink);

          vm.stages = submission.isBulk ? BULK_STAGES : isTransferRenew ? TRANSFER_RENEW_STAGES : STAGES;
          vm.currentStage = vm.stages.PAY;
          vm.paymentInfo.num = submission.count;
          vm.title = pageLabels.payment.title;
          vm.instruction = pageLabels.payment.instruction;

          if (!submission.isBulk || vm.paymentInfo.num < vm.productInfo.minimum) {
            vm.paymentInfo.type = vm.CREDIT;
          }

          vm.formData.countries = vm.formData.domicileCountries = isoCountryCodesAlph2;
          const userServicePromise = UserService.getSettings().then(function(res) {
            if (res?.data) {
              vm.paymentInfo.currency = res.data.billingCurrency;
              vm.paymentInfo.currencySymbol = vm.currencyMap[res.data.billingCurrency].symbol;
              vm.paymentInfo.decimal = vm.currencyMap[res.data.billingCurrency].omitCents ? 0 : 2;
              vm.paymentInfo.billingFrequency = res.data.billingFrequency;
              vm.paymentInfo.releaseBeforePay = res.data.releaseBeforePay;
              vm.paymentInfo.userPaymentTypes = res.data.paymentTypes && JSON.parse(`["${res.data.paymentTypes}"]`);

              vm.productInfo =
                leiProducts[finalBillingClass + submission.type + 'LEI'].PRICING[vm.paymentInfo.currency];
              vm.formData.domicileCountries =
                (res.data.domicileCountries &&
                  vm.formData.domicileCountries.filter(function(country) {
                    return res.data.domicileCountries.includes(country['alpha-2']);
                  })) ||
                vm.formData.domicileCountries;
              vm.paymentInfo.shipping.country =
                vm.formData.domicileCountries.length === 1 && vm.formData.domicileCountries[0]['alpha-2'];
              vm.paymentTypeMap =
                (vm.paymentInfo.userPaymentTypes && _.pick(vm.paymentTypeMap, vm.paymentInfo.userPaymentTypes)) ||
                vm.paymentTypeMap;
              vm.paymentInfo.type =
                vm.paymentInfo.userPaymentTypes &&
                vm.paymentInfo.userPaymentTypes.length === 1 &&
                vm.paymentInfo.userPaymentTypes[0];
            }
            vm.isDiscount =
              (vm.productInfo && vm.paymentInfo.num >= vm.productInfo.minimum) ||
              res.data.billingFrequency == 'MONTHLY';
          });

          const PublicStorageServicePromise = PublicStorageService.getSiteKeys()
            .then(function(res) {
              vm.formService.stripe = window.Stripe(res.data.stripePublicKey);
            })
            .catch(function() {
              ProgressMessenger.done(function() {
                Alert.error(null, flashMessages.loadStripe.error);
              });
            });

          const CardServicePromise = CardService.getCards().then(function(res) {
            var cards = res && res.data;
            if (cards) {
              vm.formAction.card.cards = cards.data;
              if (cards.domicileAddress) {
                _.merge(vm.paymentInfo.shipping, JSON.parse(cards.domicileAddress));
                vm.paymentInfo.vat = cards.vat;
                updateTax();
              }
            }
          });
          return Promise.all([userServicePromise, PublicStorageServicePromise, CardServicePromise]);
        })
        .then(() => {
          return showInvalidCountry(getFromSchema, vm);
        })
        .catch(DefaultErrorHandler)
        .catch(angular.noop);
    }

    function _setAddress(address) {
      if (address) {
        _.merge(vm.paymentInfo.shipping, address);
        vm.suggestedAddrInfo.originalAddress = angular.copy(vm.paymentInfo.shipping);
      } else {
        vm.suggestedAddrInfo.originalAddress = {};
      }
    }

    function _validateAddress() {
      if (
        vm.suggestedAddrInfo.originalAddress &&
        angular.equals(vm.suggestedAddrInfo.originalAddress, vm.paymentInfo.shipping)
      ) {
        return $q.resolve();
      }
      vm.suggestedAddrInfo.originalAddress = angular.copy(vm.paymentInfo.shipping);
      return ValidationService.validateAddress(vm.paymentInfo.shipping)
        .then(function(res) {
          if (res.data.status !== 'success' || res.data.data.length) {
            // if not a perfect address
            $rootScope.$emit(
              'ADDRESS_DISPLAY',
              { suggestedAddrList: res.data.data, originalAddress: vm.suggestedAddrInfo.originalAddress },
              _setAddress
            );
            throw new Error('Invalid Domicile Address');
          }
        })
        .catch(function(err) {
          if (err.message === 'Invalid Domicile Address') {
            ProgressMessenger.done();
            throw err;
          }
          console.error('Address validation error', err);
        });
    }

    function clearBilling() {
      vm.paymentInfo.billing = {};
    }

    function copyAddress() {
      clearBilling();
      _.merge(vm.paymentInfo.billing, vm.paymentInfo.shipping);
      _.get(vm, vm.paymentInfo.billing.localLangCompany) && delete vm.paymentInfo.billing.localLangCompany;
      updateBillStates();
    }

    function vatOnCheck() {
      vm.paymentInfo.vat = vm.paymentInfo.vat === 'N/A' ? null : 'N/A';
      vm.validVAT = vm.paymentInfo.vat === 'N/A';
    }

    function validateVAT() {
      if (vm.paymentInfo.vat === 'N/A') {
        vm.validVAT = true;
        return;
      }
      vm.validVAT = vm.handleVATError.test(vm.paymentInfo.vat);
    }

    function showVATError() {
      return (
        (vm.vatRequired && !vm.paymentInfo.vat) ||
        (COUNTRIES_WITH_SPECIFIC_VAT_MSG.includes(vm.paymentInfo.shipping.country) && !vm.validVAT)
      );
    }

    function showVATWarning() {
      return (
        vm.paymentInfo.vat && !vm.validVAT && !COUNTRIES_WITH_SPECIFIC_VAT_MSG.includes(vm.paymentInfo.shipping.country)
      );
    }

    // function definitions
    function updateCard() {
      if (vm.paymentInfo.card) {
        vm.formAction.card.cards.forEach(function(card) {
          if (card.id == vm.paymentInfo.card) {
            if (card.object === 'card') {
              vm.paymentInfo.type = vm.CREDIT;

              mapTo(card, vm.paymentInfo, STRIPE_CARD_MAP);
              vm.paymentInfo.cardNumber = '**********' + card.last4;
              vm.paymentInfo.expiryMonth = '' + card.exp_month;
              vm.paymentInfo.cvc = '***';
              updateBillStates();

              vm.paymentInfo.vat = card.metadata.vat;
              if (card?.metadata?.customCategory) {
                vm.paymentInfo.customCategory = card.metadata.customCategory;
              }

              if (card.metadata && card.metadata.shipping) {
                _.merge(vm.paymentInfo.shipping, JSON.parse(card.metadata.shipping));
              } else {
                vm.paymentInfo.shipping = {};
              }
            } else {
              vm.paymentInfo.type = 'ACH';
              mapTo(card, vm.paymentInfo, STRIPE_BANK_MAP);
              vm.paymentInfo.account_number = '**********' + card.last4;
            }
          }
        });
      } else {
        mapTo({}, vm.paymentInfo, STRIPE_CARD_MAP);
        vm.paymentInfo.shipping = {};
      }
    }

    function updateShippingStates(shippingCountry) {
      if (Object.prototype.hasOwnProperty.call(vm.formData.states, shippingCountry)) {
        vm.formAction.shipping.states = vm.formData.states[shippingCountry];
      } else {
        vm.formAction.shipping.states = [];
      }
    }

    function updateBillStates() {
      var billingCountry = vm.paymentInfo.billing.country;
      if (Object.prototype.hasOwnProperty.call(vm.formData.states, billingCountry)) {
        vm.formAction.billing.states = vm.formData.states[billingCountry];
      } else {
        vm.formAction.billing.states = [];
      }
    }

    function showLocalLangCompanyWarn() {
      const schema = getFromSchema('shipping.localLangCompany');
      schema.$showLocalLangCompanyWarn = !vm.paymentInfo.shipping.localLangCompany;
    }

    function updateTaxByCategory() {
      if (vm.paymentInfo.customCategory !== '') {
        vm.paymentInfo.business =
          TAX_ENTITY_CATEGORY_MAP[vm.paymentInfo.shipping.country][vm.paymentInfo.customCategory];
        disableButtonFn(false);
        updateTax();
      } else {
        disableButtonFn(true);
      }
    }

    async function updateTax() {
      disableButtonFn(true);
      const shippingCountry = vm.paymentInfo.shipping.country;
      clearLocalLanguageCompany(vm);
      updateShippingStates(shippingCountry);

      // reset the tax
      vm.paymentInfo.taxInCent = 0;

      if (!vm.submission || !shippingCountry) {
        return;
      }

      const { data: finalBillingClass } = await UserService.getBillingClass(
        vm.submission.isMixed || vm.submission.isBulk ? 'BULK' : undefined
      );

      vm.handleVATError.test(vm.paymentInfo.vat);

      const entity = vm.paymentInfo.business;
      vm.vatRequired = vatRequired(entity, shippingCountry);

      if (vm.vatRequired && vm.paymentInfo.vat == 'N/A') vm.paymentInfo.vat = null;

      try {
        const taxForCountry = await PublicStorageService.getTaxForCountry(shippingCountry);
        const taxData = taxForCountry.data;
        const taxRate = taxData.items ? taxData.items[0][entity + 'Tax'] : 0;

        // ENG4LEI-1201 - Monthly accounts should get bulk discount
        const hasDiscount = vm.paymentInfo.num >= 10 || vm.paymentInfo.billingFrequency == 'MONTHLY';

        // Mixed type pricing request
        if (vm.submission.isMixed) {
          const totalPricingInfo = Object.keys(vm.submission.metaData).reduce(
            (cummulativePricingInfo, type) => {
              if (vm.submission.metaData[type].num > 0) {
                const pricingInfoByType = totalPrice(
                  type,
                  true,
                  finalBillingClass,
                  vm.submission.metaData[type].num,
                  vm.submission.metaData[type].metaData,
                  taxRate,
                  vm.paymentInfo.currency,
                  hasDiscount
                );

                let pricingInfoByTypeNoDiscount = pricingInfoByType;
                if (hasDiscount == true)
                  pricingInfoByTypeNoDiscount = totalPrice(
                    type,
                    true,
                    finalBillingClass,
                    vm.submission.metaData[type].num,
                    vm.submission.metaData[type].metaData,
                    taxRate,
                    vm.paymentInfo.currency,
                    false
                  );

                cummulativePricingInfo.totalInCentNoDiscount += pricingInfoByTypeNoDiscount.totalInCent;
                cummulativePricingInfo.totalInCent += pricingInfoByType.totalInCent;
                cummulativePricingInfo.taxInCent += pricingInfoByType.taxInCent;
              }
              return cummulativePricingInfo;
            },
            { totalInCentNoDiscount: 0, totalInCent: 0, taxInCent: 0 }
          );

          vm.paymentInfo.totalInCentNoDiscount = totalPricingInfo.totalInCentNoDiscount;
          vm.paymentInfo.totalInCent = totalPricingInfo.totalInCent;
          vm.paymentInfo.taxInCent = totalPricingInfo.taxInCent;
        }
        // Single type pricing request
        else {
          var total = totalPrice(
            vm.submission.type,
            vm.submission.isBulk,
            finalBillingClass,
            vm.paymentInfo.num,
            vm.submission.metaData,
            taxRate,
            vm.paymentInfo.currency,
            hasDiscount
          );

          vm.paymentInfo.totalInCent = total.totalInCent;
          vm.paymentInfo.taxInCent = total.taxInCent;
          vm.productInfo.incrementalPrice = total.incrementalPrice;
          vm.productInfo.incrementalDiscountPrice = total.incrementalDiscountPrice;
        }

        vm.paymentInfo.tax = (vm.paymentInfo.taxInCent / 100).toFixed(2);
        vm.paymentInfo.total = vm.paymentInfo.totalInCent / 100;
        vm.paymentInfo.totalNoDiscount = vm.paymentInfo.totalInCentNoDiscount / 100;
      } catch (ex) {
        console.error(ex);
        Alert.error(null, flashMessages.updateTaxForCountry.error);
      }

      disableButtonFn(false);
    }

    function showLocalLanguageCompanyVATWarning() {
      return vm.paymentInfo.shipping.country === 'TW' && (vm.paymentInfo.vat == null || vm.paymentInfo.vat === 'N/A');
    }

    function showCountrySpecificWarning() {
      return vm.paymentInfo.shipping.country
        ? COUNTRIES_WITH_SPECIFIC_VAT_MSG.includes(vm.paymentInfo.shipping.country) && !vm.validVAT
        : false;
    }

    function showLocalLanguageCompany(vm) {
      return vm.paymentInfo.shipping.country === 'TW';
    }

    function clearLocalLanguageCompany(vm) {
      if (!showLocalLanguageCompany(vm)) vm.paymentInfo.shipping.localLangCompany = '';
    }

    function isValidVATForIndiaRegion(vatNumber, region) {
      let vatRegionCode = vatNumber;
      if (!vatRegionCode) return false;
      if (vatRegionCode.startsWith('IN')) vatRegionCode = vatRegionCode.substr(2);
      const prefix = taxIdRegionMappings['IN'][region];
      if (Array.isArray(prefix)) {
        return prefix.includes(vatRegionCode.substr(0, 2));
      } else {
        return prefix === vatRegionCode.substr(0, 2);
      }
    }

    function pay(valid) {
      if (!vm.formService.stripe) {
        ProgressAlert.alert(flashMessages.loadStripe.error);
        return;
      }

      if (vm.paymentInfo.shipping.country === 'IN' && !vm.paymentInfo.shipping.postal_code) {
        ProgressAlert.alert(flashMessages.postalCodeRequired.error);
        return;
      }
      if (!valid) {
        const invalidFieldNames = Object.getOwnPropertyNames(vm.form).filter(
          name => vm.form[name] && vm.form[name].$valid === false
        );
        return ProgressAlert.alert(flashMessages.fillInRequired.error + ': ' + invalidFieldNames);
      }

      var invalidErrors = getCheckErrors(checkStore.get('paymentAddressServer'), vm.paymentInfo);

      if (invalidErrors.length) return Alert.error(invalidErrors);

      Alert.reset();
      ProgressMessenger.reset().progress(20);
      // prevent user click twice
      disableButtonFn(true);

      // if user chooses existing card
      if (vm.paymentInfo.card) {
        _authorizePay();
      } else {
        var stripeCard = {};
        if (vm.paymentInfo.type === vm.CREDIT) {
          mapFrom(stripeCard, vm.paymentInfo, STRIPE_CARD_MAP);

          vm.formService.stripe
            .createToken(vm.formService.element, stripeCard)
            .then(stripeResponseHandler)
            .catch(function(ex) {
              $log.error('Error using card:', ex.message);
              Alert.error(null, flashMessages.stripeCallInternal.error);
            });
        } else {
          _authorizePay();
        }
      }
    }

    function stripeResponseHandler(response) {
      if (response.error) {
        // Show the errors on the form
        ProgressAlert.alert(response.error.message);
        disableButtonFn(false);
      } else {
        // response contains id and card, which contains additional card details
        vm.paymentInfo.stripeToken = response.token.id;
        _authorizePay();
      }
    }

    function _authorizePay() {
      return _validateAddress()
        .then(function() {
          return CardService.getCart().catch(angular.noop);
        })
        .then(function(data) {
          // parentWorkId is the first item in the cart
          // ref: https://bbgithub.dev.bloomberg.com/lei/lei-website/blob/pipeline/server/controllers/card.js#L372
          vm.parentId = _.get(data, 'data.cartItems.0.submissionId', undefined);
          // use humanId for bulk submission from excel
          vm.parentId = vm.parentId || $routeParams.humanId;
          if (vm.isShoppingCart && _.get(data, 'data.cartItems', []).length === 1) {
            // only one item in shopping cart
            vm.showNameandLei = true;
            vm.legalName = _.get(data, 'data.cartItems.0.legalName', '');
            vm.lei = _.get(data, 'data.cartItems.0.lei', '');
          } else if (!vm.isShoppingCart) {
            // not shopping cart
            if (vm.submission.isBulk && vm.submission.count !== 1) {
              // for bulk submissions that contains more than one item
              // it should hide name and lei
              vm.showNameandLei = false;
            } else {
              vm.showNameandLei = true;
              vm.legalName = $routeParams.legalName;
              vm.lei = Array.isArray(vm.submission.lei) ? vm.submission.lei[0] : vm.submission.lei || 'NA';
            }
          } else {
            // multiple items in shopping cart
            vm.showNameandLei = false;
          }
        })
        .then(function() {
          // Remove unnecessary fields, keep from adding to stripe
          const countryTypes = ['shipping', 'billing'];
          countryTypes.forEach(countryType => {
            delete vm.paymentInfo[countryType].message;
            delete vm.paymentInfo[countryType].error;
          });
          return CardService.pay(vm.paymentInfo, $routeParams.humanId);
        })
        .then(function(res) {
          var data = res.data;
          vm.hideButton = true;
          vm.currentStage = vm.stages.CONFIRM;
          var submissionId = data.data.id;

          if (Array.isArray(data.data)) {
            submissionId = data.data
              .slice(0, TRACKING_NUM)
              .map(function(charge) {
                return charge.id;
              })
              .join();

            if (data.data.length > TRACKING_NUM) {
              Alert.success('paymentBulk' + vm.paymentInfo.type);
              return;
            }
          }
          vm.trackId = submissionId;
          CardService.getCart().catch(angular.noop);
          DraftService.removeDraft(DraftService.getLatestDraftkeyForAction(vm.submission.type, textHash(loginEmail)));
          $rootScope.$emit('CART_ITEM_NUM', 0);
        }, DefaultErrorHandler)
        .then(function() {
          vm.isSubmitted = true;
          // Display thank-you confirmation
          // TRANSFER_NEW -> TRANSFER
          vm.url = `/leis/entry/${vm.submission.type.toLowerCase().split('_')[0]}`;
          vm.hideConfirmation = false;
          vm.location = function(url) {
            !url ? $location.url(vm.url) : $location.url(url);
          };
          ProgressMessenger.done();
        })
        .catch(function(err) {
          disableButtonFn(false);
        });
    }

    /*
     * map source to target based on mapping info
     * mapping contains source key -> target key
     */
    function mapTo(source, target, mapping) {
      Object.keys(mapping).forEach(function(key) {
        _.set(target, mapping[key], _.get(source, key));
      });
    }

    /*
     * map target to source based on mapping info
     * mapping contains source key -> target key
     */
    function mapFrom(source, target, mapping) {
      Object.keys(mapping).forEach(function(key) {
        var val = _.get(target, mapping[key]);
        if (val != null) {
          _.set(source, key, val);
        }
      });
    }
  }
);
