'use strict';

var algo = require('js-sha3').keccak_512;

module.exports = function hashLeiPassword(password) {
  if (!password) {
    return '';
  }

  if (typeof password !== 'string') {
    throw new TypeError('Password to be hashed must be a string');
  }

  var ret = 'b5cc11c7b2a50cbe75eedb73b1723d77b08f' + password + '1199503caa33b39ce0c16db3fa4fd09503ca';

  for (var i = 0; i < 5000; i += 1) {
    ret = algo(ret);
  }

  return ret;
};
