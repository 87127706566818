'use strict';
module.exports = {
  USD: {
    symbol: '$',
    omitCents: false
  },
  JPY: {
    symbol: '¥',
    omitCents: true,
    countries: ['JP']
  }
};
