import angular from 'angular';
import { controller } from '../app';
import { schemaStore, checkStore } from '../../../../common/validate';

controller('AdminAutoRenewController', [
  'AdminService',
  'ServiceErrorHandler',
  function(AdminService, ServiceErrorHandler) {
    var checkSearchUsername = checkStore.get('usernameOrLEI');

    var self = this;

    self.state = {
      vm: {},
      model: {},
      validate: {}
    };

    self.props = {};

    self.props.searchUsernameSchema = schemaStore.get('usernameOrLEISchema');

    self.props.handleSearchUsernameChange = function handleSearchUsernameChange() {
      var input = {
        usernameOrLEI: self.state.vm.username
      };
      var check = checkSearchUsername(input);
      self.state.validate = check;

      if (!check.$valid) {
        return;
      }

      self.state.username = self.state.vm.username;
    };

    self.props.handleSearchAutoRenewSubmit = function handleSearchAutoRenewSubmit() {
      var username = self.state.username;

      return AdminService.getAutoRenew(username)
        .then(function(res) {
          if (res && res.data && !angular.isArray(res.data)) {
            res.data = [res.data];
          }
          self.state.autorenews = res.data;
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };
  }
]);
