'use strict';

// ENG4LEI-1079: Ticket contains details for updated pricing
module.exports.NEWLEI = {
  productName: 'New LEI',
  productDescription: 'New LEI for my company',
  PRICING: {
    USD: {
      productPrice: 60.0,
      productPriceInCent: 6000
    },
    JPY: {
      productPrice: 7167.0,
      productPriceInCent: 716700
    }
  }
};

module.exports.RENEWLEI = {
  productName: 'Renew LEI',
  productDescription: 'Renew LEI for my company',
  PRICING: {
    USD: {
      productPrice: 40,
      productPriceInCent: 4000
    },
    JPY: {
      productPrice: 5513.0,
      productPriceInCent: 551300
    }
  }
};

module.exports.RENEWLEI_PRICING = {
  USD: {
    '1': { price: 3.33, priceInCent: 333 },
    '2': { price: 6.67, priceInCent: 667 },
    '3': { price: 10.0, priceInCent: 1000 },
    '4': { price: 13.33, priceInCent: 1333 },
    '5': { price: 16.67, priceInCent: 1667 },
    '6': { price: 20.0, priceInCent: 2000 },
    '7': { price: 23.33, priceInCent: 2333 },
    '8': { price: 26.67, priceInCent: 2667 },
    '9': { price: 30.0, priceInCent: 3000 },
    '10': { price: 33.33, priceInCent: 3333 },
    '11': { price: 36.67, priceInCent: 3667 },
    '12': { price: 40.0, priceInCent: 4000 }
  },
  JPY: {
    '1': { price: 460, priceInCent: 46000 },
    '2': { price: 920, priceInCent: 92000 },
    '3': { price: 1379, priceInCent: 137900 },
    '4': { price: 1839, priceInCent: 183900 },
    '5': { price: 2299, priceInCent: 229900 },
    '6': { price: 2757, priceInCent: 275700 },
    '7': { price: 3216, priceInCent: 321600 },
    '8': { price: 3676, priceInCent: 367600 },
    '9': { price: 4136, priceInCent: 413600 },
    '10': { price: 4596, priceInCent: 459600 },
    '11': { price: 5055, priceInCent: 505500 },
    '12': { price: 5513, priceInCent: 551300 }
  }
};

module.exports.TRANSFER_RENEWLEI = {
  productName: 'Renew LEI during transfer',
  productDescription: 'Renew LEI during transfer for my company',
  PRICING: {
    USD: {
      productPrice: 40.0,
      productPriceInCent: 4000
    },
    JPY: {
      productPrice: 5513.0,
      productPriceInCent: 551300
    }
  }
};

module.exports.BULKNEWLEI = {
  productName: 'Bulk New LEI',
  productDescription: 'Bulk New LEI for my company',
  PRICING: {
    USD: {
      productPrice: 60.0,
      productPriceInCent: 6000,
      productDiscountPrice: 55.0,
      productDiscountPriceInCent: 5500,
      minimum: 10
    },
    JPY: {
      productPrice: 7167.0,
      productPriceInCent: 716700,
      productDiscountPrice: 7167.0,
      productDiscountPriceInCent: 716700,
      minimum: Number.POSITIVE_INFINITY
    }
  }
};

module.exports.BULKRENEWLEI = {
  productName: 'Bulk Renew LEI',
  productDescription: 'Bulk Renew LEI for my company',
  PRICING: {
    USD: {
      productPrice: 40.0,
      productPriceInCent: 4000,
      productDiscountPrice: 35.0,
      productDiscountPriceInCent: 3500,
      minimum: 10
    },
    JPY: {
      productPrice: 5513.0,
      productPriceInCent: 551300,
      productDiscountPrice: 5513.0,
      productDiscountPriceInCent: 551300,
      minimum: Number.POSITIVE_INFINITY
    }
  }
};

module.exports.BULKRENEWLEI_PRICING = {
  USD: {
    '1': { price: 2.92, priceInCent: 292 },
    '2': { price: 5.83, priceInCent: 583 },
    '3': { price: 8.75, priceInCent: 875 },
    '4': { price: 11.67, priceInCent: 1167 },
    '5': { price: 14.58, priceInCent: 1458 },
    '6': { price: 17.5, priceInCent: 1750 },
    '7': { price: 20.42, priceInCent: 2042 },
    '8': { price: 23.33, priceInCent: 2333 },
    '9': { price: 26.25, priceInCent: 2625 },
    '10': { price: 29.17, priceInCent: 2917 },
    '11': { price: 32.08, priceInCent: 3208 },
    '12': { price: 35.0, priceInCent: 3500 }
  },
  JPY: {
    '1': { price: 460, priceInCent: 46000 },
    '2': { price: 920, priceInCent: 92000 },
    '3': { price: 1379, priceInCent: 137900 },
    '4': { price: 1839, priceInCent: 183900 },
    '5': { price: 2299, priceInCent: 229900 },
    '6': { price: 2757, priceInCent: 275700 },
    '7': { price: 3216, priceInCent: 321600 },
    '8': { price: 3676, priceInCent: 367600 },
    '9': { price: 4136, priceInCent: 413600 },
    '10': { price: 4596, priceInCent: 459600 },
    '11': { price: 5055, priceInCent: 505500 },
    '12': { price: 5513, priceInCent: 551300 }
  }
};

module.exports.BULKTRANSFER_RENEWLEI = {
  productName: 'Bulk Transfer Renew LEI',
  productDescription: 'Bulk Transfer Renew LEI for my company',
  PRICING: {
    USD: {
      productPrice: 40.0,
      productPriceInCent: 4000,
      productDiscountPrice: 35.0,
      productDiscountPriceInCent: 3500,
      minimum: 10
    },
    JPY: {
      productPrice: 5513.0,
      productPriceInCent: 551300,
      productDiscountPrice: 5513.0,
      productDiscountPriceInCent: 551300,
      minimum: Number.POSITIVE_INFINITY
    }
  }
};

// ENG4LEI-1420 & ENG4LEI-1421: Ticket contains details for agent pricing
module.exports.AGENTNEWLEI = {
  productName: 'Agent New LEI',
  productDescription: 'Agent New LEI for my company',
  PRICING: {
    USD: {
      productPrice: 30.0,
      productPriceInCent: 3000,
      productDiscountPrice: 30.0,
      productDiscountPriceInCent: 3000,
      minimum: 10
    },
    JPY: {
      productPrice: 7167.0,
      productPriceInCent: 716700,
      productDiscountPrice: 7167.0,
      productDiscountPriceInCent: 716700,
      minimum: Number.POSITIVE_INFINITY
    }
  }
};

//TODO: Actual AGENT pricing yet to be determined by LEI Business.  Current pricing used for testing.
module.exports.AGENTRENEWLEI = {
  productName: 'Agent Renew LEI',
  productDescription: 'Agent Renew LEI for my company',
  PRICING: {
    USD: {
      productPrice: 30.0,
      productPriceInCent: 3000,
      productDiscountPrice: 30.0,
      productDiscountPriceInCent: 3000,
      minimum: 10
    },
    JPY: {
      productPrice: 5513.0,
      productPriceInCent: 551300,
      productDiscountPrice: 5513.0,
      productDiscountPriceInCent: 551300,
      minimum: Number.POSITIVE_INFINITY
    }
  }
};

module.exports.AGENTRENEWLEI_PRICING = {
  USD: {
    '1': { price: 2.5, priceInCent: 250 },
    '2': { price: 5.0, priceInCent: 500 },
    '3': { price: 7.5, priceInCent: 750 },
    '4': { price: 10, priceInCent: 1000 },
    '5': { price: 12.5, priceInCent: 1250 },
    '6': { price: 15, priceInCent: 1500 },
    '7': { price: 17.5, priceInCent: 1750 },
    '8': { price: 20, priceInCent: 2000 },
    '9': { price: 22.5, priceInCent: 2250 },
    '10': { price: 25, priceInCent: 2500 },
    '11': { price: 27.5, priceInCent: 2750 },
    '12': { price: 30, priceInCent: 3000 }
  },
  JPY: {
    '1': { price: 460, priceInCent: 46000 },
    '2': { price: 920, priceInCent: 92000 },
    '3': { price: 1379, priceInCent: 137900 },
    '4': { price: 1839, priceInCent: 183900 },
    '5': { price: 2299, priceInCent: 229900 },
    '6': { price: 2757, priceInCent: 275700 },
    '7': { price: 3216, priceInCent: 321600 },
    '8': { price: 3676, priceInCent: 367600 },
    '9': { price: 4136, priceInCent: 413600 },
    '10': { price: 4596, priceInCent: 459600 },
    '11': { price: 5055, priceInCent: 505500 },
    '12': { price: 5513, priceInCent: 551300 }
  }
};

module.exports.AGENTTRANSFER_RENEWLEI = {
  productName: 'Agent Transfer Renew LEI',
  productDescription: 'Agent Transfer Renew LEI for my company',
  PRICING: {
    USD: {
      productPrice: 30.0,
      productPriceInCent: 3000,
      productDiscountPrice: 30.0,
      productDiscountPriceInCent: 3000,
      minimum: 10
    },
    JPY: {
      productPrice: 5513.0,
      productPriceInCent: 551300,
      productDiscountPrice: 5513.0,
      productDiscountPriceInCent: 551300,
      minimum: Number.POSITIVE_INFINITY
    }
  }
};

module.exports.ACTION = {
  NEW: 'NEW',
  RENEW: 'RENEW',
  UPDATE: 'UPDATE',
  TRANSFER: 'TRANSFER',
  TRANSFERRENEW: 'TRANSFER_RENEW',
  CHALLENGE: 'CHALLENGE',
  DEACTICVATE: 'DEACTIVATE'
};

module.exports.MODE = {
  SINGLE: 'SINGLE',
  EXCEL: 'EXCEL',
  SHOPPING_CART: 'SHOPPING_CART',
  API: 'API'
};

module.exports.STATUS = {
  NOT_PROCESSED: 'NOT_PROCESSED',
  UPLOAD_PENDING: 'UPLOAD_PENDING'
};

module.exports.TRANSFERABLES = ['ISSUED', 'LAPSED', 'EXPIRED'];
module.exports.PAYMENT_TYPES = { CREDIT: 'Credit Card', BILLME: 'Invoice Me' };
module.exports.ENTITY_TYPES = { BUSINESS: 'business', GOVERNMENT: 'public', INDIVIDUAL: 'individual' };
(module.exports.CREDIT = 'CREDIT'),
  (module.exports.BILLME = 'BILLME'),
  (module.exports.ALLOWED_STATUS_FOR_RENEW = ['ISSUED', 'LAPSED', 'PENDING_TRANSFER', 'PENDING_ARCHIVAL']);
module.exports.DISALLOWED_STATUS_FOR_SEARCH = ['PENDING_VALIDATION', 'CANCELLED', 'TRANSFERRED'];
module.exports.MAX_BULK_EXPORT = 2000;
module.exports.MAX_SEARCH_OFFSET = 10000;
module.exports.MAX_CLIENT_BULK_EXPORT = 5000;
module.exports.MINIMUM_AUTO_RENEW_BILLME = 10;
module.exports.MINIMUM_AUTO_RENEW_DAYS = 10;
module.exports.MAX_SHOPPING_CART_SIZE = 250;
module.exports.MINIMUM_MIXED_CART_SIZE_DISCOUNT = 10;
module.exports.GEN_EVENT_ID_LIMIT = 500;
module.exports.BILLING_CLASS = ['', 'AGENT'];

module.exports.displayLabel = {
  NEW: 'Create',
  RENEW: 'Renew',
  TRANSFER: 'Transfer',
  TRANSFER_RENEW: 'Transfer Renew',
  UPDATE: 'Update'
};

module.exports.DUMMY_LEI_ID = '25490000000000000000';
