'use strict';

var emailRegex = require('../const/email-regex');

module.exports = {
  email: {
    $label: 'Email',
    $placeholder: 'Email',
    $required: true,
    $pattern: emailRegex,
    $message: 'Please provide a valid email address.'
  }
};
