'use strict';

function makeTransferWaiverSchema() {
  return {
    acceptedTransferWaiver: {
      checkFn: function(val) {
        return val === true;
      },
      $message: 'You must agree to the Transfer Waiver to continue.'
    }
  };
}

module.exports = makeTransferWaiverSchema;
