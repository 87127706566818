import { factory } from './app';

factory('LeiDataEnricher', [
  'LeiService',
  function(LeiService) {
    /**
     * Enrich public lei data
     *
     * @param {object} leiData
     * @returns object or Promise<object>
     */
    const enrichPublicLeiData = async leiData => {
      // umbrella / master fund parent does exist
      const parentFundLeiId =
        leiData?.relationships?.umbrellaFundParent?.parentLei || leiData?.relationships?.masterFundParent?.parentLei;
      const isFund = leiData?.entity?.entityCategory === 'FUND';

      if (isFund && parentFundLeiId) {
        return LeiService.getPublicLeiDataFromAllSource(parentFundLeiId).then(res => {
          const parentLeiData = res && res.data && res.data.data;
          leiData.entity.legalName = `${parentLeiData.entity.legalName} - ${leiData.entity.legalName}`;
          // enrich the legal name
          return leiData;
        });
      }

      return leiData;
    };

    return {
      enrichPublicLeiData
    };
  }
]);
