'use strict';

var countries = require('../const/iso-country-codes-alpha2');
var states = require('../const/states');
var sanctionedCountryCodes = require('../const/sanctioned-iso-country-codes');
const sanctionedCountry2Regions = require('../const/sanctioned-regions');
var notSupportedCountryCodes = require('../const/not-supported-iso-country-codes');
var countriesNoZip = require('../const/countries-no-zip');
var CREDIT = require('../const/lei-products').CREDIT;
var PAYMENT_TYPES = require('../const/lei-products').PAYMENT_TYPES;
var flash_message = require('../const/flash-message-data');
var vatRequired = require('../lib/pricing-utils').vatRequired;
const {
  ADDRESS_LINE: ADDRESS_LINE_MAX_LENGTH,
  CITY: CITY_MAX_LENGTH,
  STATE: STATE_MAX_LENGTH,
  POSTAL_CODE: POSTAL_CODE_MAX_LENGTH
} = require('../const/address-max-length');

// Per SAP requirement
const ORGANIZATION_NAME_MAX_LENGTH = 160;
const SKIP_ASCII_CHECK_FIELDS = ['localLangCompany'];
const { TAX_ENTITY_CATEGORY_REGEX } = require('../const/tax-entity-category-mappings');

var nonEmptyString = require('./required-string');

var countryCodes = countries.map(function(country) {
  return country['alpha-2'];
});

var paymentTypes = Object.keys(PAYMENT_TYPES);

var inState = function inStatesMap(c, s) {
  return states[c].reduce(function toMap(prev, cur) {
    return prev || cur.abbr === s;
  }, false);
};

var isASCII = function isValidASCII(str) {
  // only check if there is input string
  if (typeof str !== 'string') {
    return true;
  }
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      return false;
    }
  }
  return true;
};

const _validateByCountryAndCategory = (vat_number, country, category) => {
  if (!Object.keys(TAX_ENTITY_CATEGORY_REGEX).includes(country)) return true;
  if (category === '') return false;
  const VATreg = new RegExp(TAX_ENTITY_CATEGORY_REGEX[country][category]);
  return VATreg ? VATreg.test(vat_number) : true;
};

var nonEmptyStringWithBilling = function(val, body) {
  return body.type !== CREDIT || nonEmptyString(val);
};

const isWithinCharLimit = function(val, limit) {
  return typeof val === 'string' && val.length <= limit;
};

// BUGs: need to trim values otherwise the input like ' ' can pass the checks
// @TODOs:
// 1. automatically including nonEmptyString checkFn if required=true
// 2. default placeholder to lable or label to placeholder
// define schema for generic address first, then apply it to any type of addresses
// but difficulty here is the custom message
function makePaymentAddressSchema() {
  return {
    type: {
      $label: 'Payment Method',
      $message: 'Please provide payment method you want to use.',
      $required: true,
      $placeholder: 'Payment Method',
      checkFn: function isDefinedPayment(val) {
        return typeof val === 'string' && paymentTypes.indexOf(val) !== -1;
      }
    },
    'shipping.company': {
      $label: 'Company',
      $placeholder: 'Company Name',
      $required: true,
      $message: 'Please enter a valid company name in your domicile address.',
      checkFn: function(val) {
        return typeof val === 'string' && val.length < ORGANIZATION_NAME_MAX_LENGTH;
      }
    },
    'shipping.localLangCompany': {
      $label: 'Local Language Company Name',
      $placeholder: 'Local Language Company Name',
      $warning: 'Local language company name and Tax/GUI number are required to issue GUI Invoice.',
      $showLocalLangCompanyWarn: true,
      $message: 'Please enter a valid local language company name in your domicile address.',
      checkFn: function(val, body) {
        return body.shipping.country !== 'TW' || (typeof val === 'string' && val.length < ORGANIZATION_NAME_MAX_LENGTH);
      }
    },
    'shipping.line1': {
      $label: 'Address Line 1',
      $placeholder: 'Street and number',
      $required: true,
      $message: `Please provide a valid address line 1 (max. ${ADDRESS_LINE_MAX_LENGTH} characters) in your domicile address.`,
      checkFn: function(val) {
        return nonEmptyString(val) && isWithinCharLimit(val, ADDRESS_LINE_MAX_LENGTH);
      }
    },
    'shipping.line2': {
      $label: 'Address Line 2',
      $placeholder: 'Apartment, suite, unit, building, floor, etc.',
      $required: false,
      $message: `Please provide a valid length address line 2 (max. ${ADDRESS_LINE_MAX_LENGTH} characters) in your domicile address.`,
      checkFn: function(val) {
        return !val || isWithinCharLimit(val, ADDRESS_LINE_MAX_LENGTH);
      }
    },
    'shipping.country': {
      $label: 'Country/Region',
      $placeholder: 'Country/Region Name',
      $message: 'Please provide country or region in your domicile address.',
      $warning:
        'Please note that since city value of Hong Kong has been entered, the expected value for country is Hong Kong..',
      $required: true,
      $options: countries,
      checkFn: function isDefinedCountryCode(val) {
        return typeof val === 'string' && countryCodes.indexOf(val) !== -1;
      }
    },
    'shipping.state': {
      $label: 'State/Province',
      $message: `Please provide a valid state or province (max. ${STATE_MAX_LENGTH} characters) in your domicile address.`,
      $required: false,
      $placeholder: 'State/Province',
      checkFn: function stateDefined(val, body) {
        return !val || isWithinCharLimit(val, STATE_MAX_LENGTH);
      }
    },
    'shipping.city': {
      $label: 'City',
      $message: `Please provide a valid city (max. ${CITY_MAX_LENGTH} characters) in your domicile address.`,
      $required: true,
      $placeholder: 'City',
      checkFn: function cityDefined(val) {
        return nonEmptyString(val) && isWithinCharLimit(val, CITY_MAX_LENGTH);
      }
    },
    'shipping.postal_code': {
      $label: 'Postal Code',
      $message: `Please provide a valid postal code (max. ${POSTAL_CODE_MAX_LENGTH} characters) in your domicile address.`,
      $required: false,
      $placeholder: 'Postal Code',
      checkFn: function(val, body) {
        return (
          countriesNoZip.indexOf(body.shipping.country) > -1 ||
          !val ||
          (typeof val === 'string' && isWithinCharLimit(val, POSTAL_CODE_MAX_LENGTH))
        );
      }
    },
    _shippingAsciiOnly: {
      checkFn: function isValidASCII(_val, body, report) {
        if (!body || !body.shipping) {
          return true;
        }

        return !Object.keys(body.shipping)
          .filter(key => !SKIP_ASCII_CHECK_FIELDS.includes(key))
          .some(key => !isASCII(body.shipping[key]));
      },
      $message: 'Please provide regular ASCII characters (eg. not åöí) only in your domicile address.'
    },
    _shippingCountrySanctioned: {
      checkFn: function countryIsNotSanctioned(_val, body, report) {
        if (!body || !body.shipping || !body.shipping.country) {
          return true;
        }

        return sanctionedCountryCodes.indexOf(body.shipping.country) === -1;
      },
      $message:
        'The domicile country you have chosen is currently considered a sanctioned nation and therefore is restricted.'
    },
    _shippingCountryNotSupported: {
      checkFn: function countryIsNotSupported(_val, body, report) {
        if (!body || !body.shipping || !body.shipping.country) {
          return true;
        }

        return notSupportedCountryCodes.indexOf(body.shipping.country) === -1;
      },
      $message: 'The domicile country you have chosen is currently not supported.'
    },
    _shippingCountryRequiresVAT: {
      checkFn: function vatRequirement(_val, body, report) {
        if (!body || !body.shipping || !body.shipping.country || !body.business) {
          return true;
        }
        var vat = body.vat;
        return !vatRequired(body.business, body.shipping.country) || (vat && vat != 'N/A');
      },

      $message: flash_message.vatRequired.error
    },
    _shippingCountryRequiresVatMatch: {
      checkFn: function matchVatRegex(_val, body, report) {
        if (!body || !body.shipping || !body.shipping.country || !body.business) {
          return true;
        }

        return _validateByCountryAndCategory(body.vat, body.shipping.country, body.customCategory);
      },
      $message:
        'Based on the Ghana entity category and domicile country, the provided Domestic Tax Number (VAT/GST) is not valid.'
    },
    _shippingState: {
      checkFn: function isOneOfState(_val, body, report) {
        if (!body || !body.shipping) {
          return true;
        }

        if (!(body.shipping.country in states)) {
          return true;
        }

        return inState(body.shipping.country, body.shipping.state);
      },
      $message: 'Please provide valid state or region in your domicile address.'
    },
    _sanctionedShippingZip: {
      checkFn: function sanctionedZip(_val, body, report) {
        if (!body || !body.shipping) {
          return true;
        }
        const shippingCountry = body.shipping.country;
        const shippingZip = body.shipping.postal_code;
        const sanctionedRegions = sanctionedCountry2Regions[shippingCountry];
        if (!sanctionedRegions || !shippingZip) {
          return true;
        }

        return !sanctionedRegions.zipPrefix.some(zip => shippingZip.indexOf(zip) === 0);
      },
      $message: 'The domicile postal code you have provided is currently not supported due to sanctions.'
    },
    _shippingAddress: {
      checkFn: function comprehensiveAddressValidate(_val, body, currentReport) {
        return (
          currentReport.shipping &&
          currentReport.shipping.line1.$valid &&
          currentReport.shipping.line2.$valid &&
          currentReport.shipping.country.$valid &&
          currentReport.shipping.state.$valid &&
          currentReport.shipping.city.$valid &&
          currentReport.shipping.postal_code.$valid
        );
      },
      $message: 'Please provide your full and valid domicile address.'
    },
    cardHolderName: {
      $required: true,
      $message: "Please provide regular ASCII characters (eg. not åöí) only for the card holder's name.",
      checkFn: function(val) {
        return !val || isASCII(val);
      }
    },

    'billing.company': {
      $label: 'Company',
      $placeholder: 'Company Name',
      $message: `Please enter a valid length company name (max. ${ORGANIZATION_NAME_MAX_LENGTH} characters) in your billing address.`,
      checkFn: function(val) {
        return !val || val.length < ORGANIZATION_NAME_MAX_LENGTH;
      }
    },
    'billing.line1': {
      $label: 'Address Line 1',
      $placeholder: 'Street and number',
      $required: true,
      $message: `Please provide a valid address line 1 (max. ${ADDRESS_LINE_MAX_LENGTH} characters) in your billing address.`,
      checkFn: function(val, body) {
        return nonEmptyStringWithBilling(val, body) && (!val || isWithinCharLimit(val, ADDRESS_LINE_MAX_LENGTH));
      }
    },
    'billing.line2': {
      $label: 'Address Line 2',
      $placeholder: 'Apartment, suite, unit, building, floor, etc.',
      $required: false,
      $message: `Please provide a valid length address line 2 (max. ${ADDRESS_LINE_MAX_LENGTH} characters) in your billing address.`,
      checkFn: function(val) {
        return !val || isWithinCharLimit(val, ADDRESS_LINE_MAX_LENGTH);
      }
    },
    'billing.country': {
      $label: 'Country/Region',
      $placeholder: 'Country/Region Name',
      $message: 'Please provide country or region in your billing address.',
      $required: true,
      $options: countries,
      $warning:
        'Please note that since city value of Hong Kong has been entered, the expected value for country is Hong Kong..',
      checkFn: function isDefinedCountryCode(val, body) {
        if (body.type !== CREDIT) {
          return true;
        }

        return typeof val === 'string' && countryCodes.indexOf(val) !== -1;
      }
    },
    'billing.state': {
      $label: 'State/Province',
      $message: `Please provide a valid state or province (max. ${STATE_MAX_LENGTH} characters) in your billing address.`,
      $required: true,
      $placeholder: 'State/Province',
      checkFn: function stateDefined(val, body) {
        return nonEmptyStringWithBilling(val, body) && (!val || isWithinCharLimit(val, STATE_MAX_LENGTH));
      }
    },
    'billing.city': {
      $label: 'City',
      $message: `Please provide a valid city (max. ${CITY_MAX_LENGTH} characters) in your billing address.`,
      $required: true,
      $placeholder: 'City',
      checkFn: function(val, body) {
        return nonEmptyStringWithBilling(val, body) && (!val || isWithinCharLimit(val, CITY_MAX_LENGTH));
      }
    },
    'billing.postal_code': {
      $label: 'Postal Code',
      $message: `Please provide a valid postal code (max. ${POSTAL_CODE_MAX_LENGTH} characters) in your billing address.`,
      $required: true,
      $placeholder: 'Postal Code',
      checkFn: function(val, body) {
        return (
          (countriesNoZip.indexOf(body.billing.country) > -1 || nonEmptyStringWithBilling(val, body)) &&
          (!val || isWithinCharLimit(val, POSTAL_CODE_MAX_LENGTH))
        );
      }
    },
    _billingAsciiOnly: {
      checkFn: function isValidASCII(_val, body, report) {
        if (!body || !body.billing) {
          return true;
        }

        return !Object.keys(body.billing)
          .filter(key => !SKIP_ASCII_CHECK_FIELDS.includes(key))
          .some(key => !isASCII(body.billing[key]));
      },
      $message: 'Please provide regular ASCII characters (eg. not åöí) only in your billing address.'
    },
    _billingCountrySanctioned: {
      checkFn: function countryIsNotSanctioned(_val, body, report) {
        if (!body || !body.billing || !body.billing.country) {
          return true;
        }

        return sanctionedCountryCodes.indexOf(body.billing.country) === -1;
      },
      $message:
        'The billing country you have chosen is currently considered a sanctioned nation and therefore is restricted.'
    },
    _billingCountryNotSupported: {
      checkFn: function countryIsNotSupported(_val, body, report) {
        if (!body || !body.billing || !body.billing.country) {
          return true;
        }

        return notSupportedCountryCodes.indexOf(body.billing.country) === -1;
      },
      $message: 'The billing country you have chosen is currently not supported.'
    },
    // Not needed for now
    // _billingCountryIsGhana: {
    //   checkFn: function matchVatRegex(_val, body, report) {
    //     if (!body || !body.billing || !body.billing.country || !body.business) {
    //       return true;
    //     }
    //     if (body.billing.country !== 'GH') return true;

    //     return _validateByCountryAndCategory(body.vat, body.billing.country, body.customCategory);
    //   },
    //   $message:
    //     'Based on the Ghana entity category and billing country, the provided Domestic Tax Number (VAT/GST) is not valid.'
    // },
    _billingState: {
      checkFn: function isOneOfState(_val, body, report) {
        if (!body || !body.billing) {
          return true;
        }

        if (!(body.billing.country in states)) {
          return true;
        }

        return inState(body.billing.country, body.billing.state);
      },
      $message: 'Please provide valid state or region in your billing address.'
    },
    _sanctionedBillingZip: {
      checkFn: function sanctionedZip(_val, body, report) {
        if (!body || !body.billing) {
          return true;
        }
        const billingCountry = body.billing.country;
        const billingZip = body.billing.postal_code;
        const sanctionedRegions = sanctionedCountry2Regions[billingCountry];
        if (!sanctionedRegions || !billingZip) {
          return true;
        }

        return !sanctionedRegions.zipPrefix.some(zip => billingZip.indexOf(zip) === 0);
      },
      $message: 'The billing postal code you have provided is currently not supported due to sanctions.'
    },
    _billingAddress: {
      checkFn: function comprehensiveAddressValidate(_val, body, currentReport) {
        if (body.type !== CREDIT) {
          return true;
        }

        return (
          currentReport.billing &&
          currentReport.billing.line1.$valid &&
          currentReport.billing.line2.$valid &&
          currentReport.billing.country.$valid &&
          currentReport.billing.state.$valid &&
          currentReport.billing.city.$valid &&
          currentReport.billing.postal_code.$valid
        );
      },
      $message: 'Please provide your full and valid billing address.'
    }
  };
}

module.exports = makePaymentAddressSchema;
