'use strict';

// Commented countries have no subdivisions or do not use them as per:
// https://www.informatica.com/products/data-quality/data-as-a-service/address-verification/interactive-country-map.html
// https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country
module.exports = {
  AU: require('./au-states'),
  BR: require('./br-states'),
  CA: require('./ca-states'),
  CH: require('./ch-states'),
  // 'CY': require( './cy-states' ),
  // 'DK': require( './dk-states' ),
  // 'EE': require( './ee-states' ),
  // 'GG': require( './gg-states' ),
  // 'HK': require( './hk-states' ),
  IN: require('./in-states'),
  JP: require('./jp-states'),
  MX: require('./mx-states'),
  // 'KY': require( './ky-states' ),
  // 'LU': require( './lu-states' ),
  // 'NO': require( './no-states' ),
  PA: require('./pa-states'),
  // 'RO': require( './ro-states' ),
  // 'SE': require( './se-states' ),
  // 'SG': require( './sg-states' ),
  US: require('@dtwebservices/node-bconnect/lib/helper/us-states')
  // 'VG': require( './vg-states' ),
};
