'use strict';

var emailSchema = require('./email-schema');
var usernameSchema = require('./username-schema');
var passwordSchema = require('./password-schema');

function _makePasswordSchema(opts) {
  const schema = {
    repeatPassword: {
      $label: 'Repeat Password',
      $placeholder: 'Repeat Password',
      $required: true,
      checkFn: opts.server
        ? passwordSchema.checkHashedPassword
        : (val, body) => body && body.password && body.password === val,
      $message: 'Please repeat the password. They must match exactly.'
    }
  };

  return Object.assign(schema, passwordSchema.make(opts));
}

function makeUserCredentialSchema(opts = {}) {
  return Object.assign(
    {
      username: usernameSchema.username,
      email: emailSchema.email
    },
    _makePasswordSchema(opts)
  );
}

module.exports = {
  client: makeUserCredentialSchema(),
  server: makeUserCredentialSchema({ server: true }),
  make: makeUserCredentialSchema
};
