'use strict';

module.exports = [
  'Account Issues',
  'Creating an LEI',
  'Updating an LEI',
  'Renewing an LEI',
  'Transfering an LEI',
  'Challenging an LEI',
  'Technical/Website',
  'Search',
  'General Questions'
];
