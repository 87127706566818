import { filter } from './app';

export const LABELS = {
  data: {
    entity: {
      legalNameInEnglish: 'Legal Name (English)',
      legalAddressInEnglish: 'Legal Address (English)',
      headquartersAddressInEnglish: 'Headquarters Address (English)',
      otherAddressesInLocalLanguage: 'Other Addresses (Local Language)',
      branchCountry: 'Location of Branch (Country)',
      branchRegion: 'Location of Branch (Region)',
      registrationAuthoritySource: {
        _this: 'Registration Authority',
        registrationAuthorityId: 'Registration Authority',
        otherRegistrationAuthorityId: 'Other Registration Authority',
        registrationAuthorityEntityId: 'Registration Entity ID'
      },
      otherEntityIds: {
        _this: 'Other Entity IDs',
        taxId: 'Tax ID',
        avid: 'AVID',
        bloombergId: 'Bloomberg ID'
      },
      entityLegalFormCode: 'Legal Form',
      legalForm: 'Other Legal Form',
      noCreationDateIsAvailable: 'Fund Launch Date Is Not Available'
    },
    relationships: {
      directParent: {
        ParentInformationNotProvided: {
          exceptionReasons: 'Non-Disclosure Reason(s)'
        },
        ParentInformation: {
          parentEntity: {
            ParentLEI: {
              lei: 'LEI'
            },
            ParentWithNoLEI: {
              registrationAuthority: {
                registrationAuthorityId: 'Registration Authority',
                otherRegistrationAuthorityId: 'Other Registration Authority',
                registrationAuthorityEntityId: 'Registration Entity ID'
              }
            }
          }
        }
      },
      ultimateParent: {
        ParentInformationNotProvided: {
          exceptionReasons: 'Non-Disclosure Reason(s)'
        },
        ParentInformation: {
          parentEntity: {
            ParentLEI: {
              lei: 'LEI'
            },
            ParentWithNoLEI: {
              registrationAuthority: {
                registrationAuthorityId: 'Registration Authority',
                otherRegistrationAuthorityId: 'Other Registration Authority',
                registrationAuthorityEntityId: 'Registration Entity ID'
              }
            }
          }
        }
      },
      branchParent: {
        headquartersLei: 'Headquarters LEI'
      }
    },
    registration: {
      validationAuthority: {
        validationAuthorityId: 'Validation Authority',
        validationAuthorityEntityId: 'Validation Entity ID'
      }
    },
    contact: {
      contactFirstName: 'First Name',
      contactLastName: 'Last Name',
      contactCompany: 'Company',
      contactEmail: 'Email',
      contactPosition: 'Position',
      contactPhone: 'Phone'
    }
  }
};

/**
 * @param {string} k
 * @param {string[]} chain
 * @returns {string[]}
 */
export function makeNextChain(k, chain) {
  let next;
  if (k == '_this') next = chain;
  // Signature of unions
  else if (k[0] == k[0].toUpperCase()) next = chain.concat('*', '@', k);
  else next = chain.concat(k);
  return next;
}

/**
 * @param {Record<string, any>} labels
 * @returns {Record<string, string>}
 */
export function makeLabelMap(labels) {
  const map = {};
  const stack = [
    {
      label: labels,
      chain: ['@']
    }
  ];

  while (stack.length) {
    const { label, chain } = stack.pop();

    /* istanbul ignore if */
    if (!label) continue;

    if (typeof label === 'string') {
      map[chain.join('.')] = label;
      continue;
    }

    Object.keys(label).forEach(k => stack.push({ label: label[k], chain: makeNextChain(k, chain) }));
  }

  return map;
}

const RE_INDEX = /^[0-9]+$/;
const RE_DOT = /\./g;

/**
 * @param {number|string} v
 * @returns {string}
 */
export function chainMapper(v) {
  let k;
  if (typeof v == 'number' || RE_INDEX.test(v)) k = '*';
  else if (v.slice(0, 4) == 'com.') k = [].concat('@', v.split(RE_DOT).slice(4)).join('.');
  else k = v;
  return k;
}

/**
 * @param {Record<string, string>} map
 * @param {string[]} keyChain
 * @param {string} v
 */
export function typeMapper(map, keyChain, v) {
  const chain = [].concat(
    keyChain,
    '@',
    String(v)
      .split(RE_DOT)
      .pop()
  );
  return map[chain.join('.')] || v;
}

filter('labelCustomizerLEIForView', function() {
  const map = makeLabelMap(LABELS);
  /**
   * @param {(string|number)[]} keyChain
   * @param {string[]} rawOptionStrArr
   * @param {string} caseStr
   * @returns {string|string[]}
   */
  function labelCustomizer(keyChain, rawOptionStrArr, caseStr) {
    const k = keyChain.map(chainMapper);
    return caseStr == 'union-types' ? rawOptionStrArr.map(typeMapper.bind(null, map, k)) : map[k.join('.')] || null;
  }
  return labelCustomizer;
});
