'use strict';

var GLOBAL_MAX_LENGTH = require('../const/global-max-length');
var _ = require('lodash');

function checkGlobalMaxLength(s) {
  return typeof s === 'string' ? s.length < GLOBAL_MAX_LENGTH : true;
}

function makeMessage(prop) {
  return 'The length of ' + _.startCase(prop) + ' must be within ' + GLOBAL_MAX_LENGTH + ' characters.';
}

module.exports = checkGlobalMaxLength;
module.exports.message = makeMessage;

// use like gmax.addon(requiredString)
module.exports.addon = function(schema, customProps) {
  var props = Object.keys(schema).filter(function(k) {
    return k.indexOf('_') !== 0 && !schema[k].$maxlength;
  });

  var addedSchema = {};

  props.forEach(function(p) {
    addedSchema['__GlobalMaxLength__' + p] = {
      checkFn: function(_val, body) {
        if (!body) {
          return true;
        }

        return checkGlobalMaxLength(body[p]);
      },
      $message: makeMessage(schema[p].$label || p)
    };
  });

  return _.assign({}, schema, addedSchema);
};
