'use strict';

var requiredStringCheckFn = require('./required-string');

module.exports = function makeTwoFactorCodeSchema() {
  return {
    twoFactorCode: {
      $label: 'Two-Factor Login Code',
      $placeholder: 'Check your latest emails for the code',
      checkFn: requiredStringCheckFn,
      $message: 'Please check your latest emails and provide your two-factor login code.'
    }
  };
};
