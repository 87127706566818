'use strict';

// Mexico
module.exports = [
  { abbr: 'AGU', name: 'AGUASCALIENTES' },
  { abbr: 'BCN', name: 'BAJA CALIFORNIA' },
  { abbr: 'BCS', name: 'BAJA CALIFORNIA SUR' },
  { abbr: 'CAM', name: 'CAMPECHE' },
  { abbr: 'CHP', name: 'CHIAPAS' },
  { abbr: 'CHH', name: 'CHIHUAHUA' },
  { abbr: 'CMX', name: 'CIUDAD DE MEXICO' },
  { abbr: 'COA', name: 'COAHUILA DE ZARAGOZA' },
  { abbr: 'COL', name: 'COLIMA' },
  { abbr: 'DUR', name: 'DURANGO' },
  { abbr: 'GUA', name: 'GUANAJUATO' },
  { abbr: 'GRO', name: 'GUERRERO' },
  { abbr: 'HID', name: 'HIDALGO' },
  { abbr: 'JAL', name: 'JALISCO' },
  { abbr: 'MEX', name: 'MEXICO' },
  { abbr: 'MIC', name: 'MICHOACAN DE OCAMPO' },
  { abbr: 'MOR', name: 'MORELOS' },
  { abbr: 'NAY', name: 'NAYARIT' },
  { abbr: 'NLE', name: 'NUEVO LEON' },
  { abbr: 'OAX', name: 'OAXACA' },
  { abbr: 'PUE', name: 'PUEBLA' },
  { abbr: 'QUE', name: 'QUERETARO' },
  { abbr: 'ROO', name: 'QUINTANA ROO' },
  { abbr: 'SLP', name: 'SAN LUIS POTOSI' },
  { abbr: 'SIN', name: 'SINALOA' },
  { abbr: 'SON', name: 'SONORA' },
  { abbr: 'TAB', name: 'TABASCO' },
  { abbr: 'TAM', name: 'TAMAULIPAS' },
  { abbr: 'TLA', name: 'TLAXCALA' },
  { abbr: 'VER', name: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
  { abbr: 'YUC', name: 'YUCATAN' },
  { abbr: 'ZAC', name: 'ZACATECAS' }
];
