'use strict';

var algo = require('js-sha3').keccak_512;

module.exports = function textHash(text) {
  if (!text) {
    return '';
  }

  if (typeof text !== 'string') {
    throw new TypeError('Text to be hashed must be a string');
  }

  return algo(text);
};
