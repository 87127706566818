'use strict';

function makeCustomerAgreementSchema() {
  return {
    acceptedCustomerAgreement: {
      checkFn: function(val) {
        return val === true;
      },
      $message: 'You must agree to the Service Customer Agreement to continue.'
    }
  };
}

module.exports = makeCustomerAgreementSchema;
