import angular from 'angular';
import { controller } from './app';
import { schemaStore, checkStore } from '../../../common/validate';
import feedbackCategories from '../../../common/const/feedback-categories';

// Controller for LEI site feedback in or out
controller('FeedbackController', [
  '$scope',
  '$location',
  '$routeParams',
  '$filter',
  'recaptchaResult',
  'phoneBackfill',
  'UserService',
  'FeedbackService',
  'ServiceErrorHandler',
  'AccessLevel',
  'Alert',
  'AlertMessenger',
  function(
    $scope,
    $location,
    $routeParams,
    $filter,
    recaptchaResult,
    phoneBackfill,
    UserService,
    FeedbackService,
    ServiceErrorHandler,
    AccessLevel,
    Alert,
    AlertMessenger
  ) {
    var assign = angular.extend;

    var checkFeedback = checkStore.get('feedback');

    var self = this;

    self.props = {
      feedbackCategories,
      recaptchaResult,
      feedbackSchema: schemaStore.get('feedback')
    };

    self.state = {
      disableButton: true,
      vm: {},
      model: {},
      ui: {},
      validate: {}
    };

    if (AccessLevel.get() !== AccessLevel.OPEN) {
      UserService.getUserInfo()
        .then(function(response) {
          self.state.vm.email = response.data.email;
        })
        .catch(ServiceErrorHandler);

      UserService.getUserProfile()
        .then(phoneBackfill)
        .then(function(response) {
          var userProfile = response.data;
          self.state.vm.firstName = userProfile.firstName;
          self.state.vm.lastName = userProfile.lastName;
          self.state.vm.phone = $filter('accountFullPhone')([userProfile.phoneCountryCode, userProfile.phone]);
        })
        .catch(ServiceErrorHandler);
    }

    self.state.vm.category = $routeParams.category;

    self.props.handleFeedbackChange = function handleFeedbackChange() {
      var input = {
        category: self.state.vm.category,
        firstName: self.state.vm.firstName,
        lastName: self.state.vm.lastName,
        email: self.state.vm.email,
        phone: self.state.vm.phone,
        summary: self.state.vm.summary,
        details: self.state.vm.details
      };

      var check = checkFeedback(input);
      self.state.validate = assign({}, check);
      self.state.model = assign({}, input);
      self.state.disableButton = !check.$valid;
    };

    self.props.handleFeedbackSubmit = function handleFeedbackSubmit() {
      self.state.disableButton = true;
      var feedbackObj = self.state.model;
      var check = checkFeedback(feedbackObj);

      self.state.ui.showAllErrors = true;
      self.state.validate = assign({}, check);

      const grecaptcha = window.grecaptcha;

      if (!grecaptcha) {
        return Alert.error('recaptchaRefresh');
      }

      var captchaResponse = grecaptcha.getResponse();

      if (!captchaResponse) {
        grecaptcha.reset();
        self.state.disableButton = false;
        return Alert.error('recaptcha');
      }

      return FeedbackService.postFeedback(feedbackObj, captchaResponse)
        .then(function() {
          self.state.submitted = true;
          Alert.success('feedback');
        })
        .catch(function(error) {
          self.state.disableButton = false;
          ServiceErrorHandler({ alert: 'data' })(error);
        })
        .finally(grecaptcha.reset);
    };

    self.props.reset = function reset() {
      $location.replace('.');
    };

    AlertMessenger.info(
      'For any users whose LEIs are being transferred from GMEI/DTCC during the August 2023 transition, please visit this FAQ '
    );
  }
]);
