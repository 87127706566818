'use strict';

module.exports = {
  TO_REMOVE: [
    'LEGAL_OBSTACLES',
    'CONSENT_NOT_OBTAINED',
    'BINDING_LEGAL_COMMITMENTS',
    'DETRIMENT_NOT_EXCLUDED',
    'DISCLOSURE_DETRIMENTAL'
  ]
};
