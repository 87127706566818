import angular from 'angular';
import { factory, controller } from '../app';
import { checkStore, schemaStore } from '../../../../common/validate';

factory('ResetPasswordStore', [
  'ReduxStoreFactory',
  'ResetPasswordReducer',
  function(ReduxStoreFactory, ResetPasswordReducer) {
    var initialState = {
      model: {
        username: null,
        email: null,

        resetPasswordKey: null,
        newPassword: null
      },
      validate: {},
      ui: {
        resetKeySent: false,
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('ResetPasswordStore', ResetPasswordReducer, initialState);
  }
]);

factory('ResetPasswordActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['UPDATE', 'VALIDATE', 'UI_SHOW_ALL_ERRORS', 'RESET_KEY_SENT']);
  }
]);

factory('ResetPasswordAction', [
  'ResetPasswordActionType',
  function(ResetPasswordActionType) {
    return {
      showAllErrors: function(showOrNot) {
        return {
          type: ResetPasswordActionType.UI_SHOW_ALL_ERRORS,
          data: !!showOrNot
        };
      },
      resetKeySent: function(sentOrNot) {
        return {
          type: ResetPasswordActionType.RESET_KEY_SENT,
          data: !!sentOrNot
        };
      },
      update: function(vm) {
        return {
          type: ResetPasswordActionType.UPDATE,
          data: vm
        };
      },
      validate: function(validateObj) {
        return {
          type: ResetPasswordActionType.VALIDATE,
          data: validateObj
        };
      }
    };
  }
]);

factory('ResetPasswordReducer', [
  'ResetPasswordActionType',
  function(ResetPasswordActionType) {
    var assign = angular.extend;

    function modelReducer(state, action) {
      if (action.type === ResetPasswordActionType.UPDATE) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function validateReducer(state, action) {
      if (action.type === ResetPasswordActionType.VALIDATE) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function showAllErrorsReducer(state, action) {
      if (action.type === ResetPasswordActionType.UI_SHOW_ALL_ERRORS) {
        return action.data;
      }

      return state;
    }

    function resetKeySentReducer(state, action) {
      if (action.type === ResetPasswordActionType.RESET_KEY_SENT) {
        return action.data;
      }

      return state;
    }

    return function r(state, action) {
      return {
        model: modelReducer(state.model, action),
        validate: validateReducer(state.validate, action),
        ui: {
          showAllErrors: showAllErrorsReducer(state.ui.showAllErrors, action),
          resetKeySent: resetKeySentReducer(state.ui.resetKeySent, action)
        }
      };
    };
  }
]);

controller('ResetPasswordController', [
  'UserService',
  'ServiceErrorHandler',
  'ResetPasswordStore',
  'ResetPasswordAction',
  'Alert',
  'Redirect',
  function(UserService, ServiceErrorHandler, ResetPasswordStore, ResetPasswordAction, Alert, Redirect) {
    var checkResetPassword = checkStore.get('resetPassword');
    var checkForgotPassword = checkStore.get('forgotPassword');

    var self = this;

    self.props = {};
    self.props.resetPasswordSchema = schemaStore.get('resetPassword');
    self.props.forgotPasswordSchema = schemaStore.get('forgotPassword');

    self.state = angular.extend({}, ResetPasswordStore.getState(), {
      vm: {}
    });

    ResetPasswordStore.reset().subscribe(function(state) {
      self.state.model = state.model;
      self.state.validate = state.validate;
      self.state.ui = state.ui;
    });

    (function resetState() {
      ResetPasswordStore.dispatch(ResetPasswordAction.resetKeySent(false));
    })();

    self.props.handleResetPasswordChange = function handleResetPasswordChange() {
      var vm = self.state.vm;
      var check = checkResetPassword(vm);

      var updateAction = ResetPasswordAction.update(self.state.vm);

      ResetPasswordStore.dispatch(updateAction);

      ResetPasswordStore.dispatch(ResetPasswordAction.validate(check));
    };

    self.props.handleForgotPasswordChange = function handleForgotPasswordChange() {
      var vm = self.state.vm;
      var check = checkForgotPassword(vm);

      ResetPasswordStore.dispatch(ResetPasswordAction.validate(check));

      if (!check.$valid) {
        return;
      }

      var updateAction = ResetPasswordAction.update(self.state.vm);

      ResetPasswordStore.dispatch(updateAction);
    };

    self.props.handleForgotPassword = function handleForgotPassword() {
      var model = ResetPasswordStore.getState().model;

      return UserService.generateResetPasswordKey(model.username, model.email)
        .then(function() {
          ResetPasswordStore.dispatch(ResetPasswordAction.resetKeySent(true));

          Alert.success('passwordResetKey');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };

    self.props.handleResetPassword = function handleResetPassword() {
      var model = ResetPasswordStore.getState().model;

      return UserService.resetPassword(model.username, model.email, model.resetPasswordKey, model.newPassword)
        .then(Redirect.loginAfterResetPassword)
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };
  }
]);
