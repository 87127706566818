import { component, directive, controller } from '../app';
import { getBaseActionUrl } from '../../utils/route';
import currencies from '../../../../common/const/currencies';
import leiProducts from '../../../../common/const/lei-products';
import pageLabels from '../../../../common/const/page-labels';
import makeOnFinish from './on-finish';
import _ from 'lodash';

const LEI_ACTION = leiProducts.ACTION;

export const main = [
  '$scope',
  '$rootScope',
  '$q',
  '$sce',
  'CardService',
  'Redirect',
  'DefaultErrorHandler',
  'Alert',
  'UserService',
  function($scope, $rootScope, $q, $sce, CardService, Redirect, DefaultErrorHandler, Alert, UserService) {
    $scope.data = null;
    $scope.cartItemType = null;
    $scope.productInfo = {};
    $scope.paymentInfo = {};

    $scope.isDiscount = false;

    $scope.actions = {
      onFinish: makeOnFinish.call(this, {
        $scope,
        clearCart: () => {
          $scope.paymentInfo.num = 0;
          CardService.clearCart();
        },
        checkout: CardService.checkout.bind(CardService),
        redirect: Redirect.checkout.bind(Redirect),
        thankYou: Redirect.thankYou.bind(Redirect),
        alertOk: Alert.success.bind(Alert),
        alertError: Alert.error.bind(Alert),
        DefaultErrorHandler,
        LEI_ACTION
      }),
      async onFetch(refresh) {
        if (!refresh && $scope.data != null) return $q.resolve($scope.data);
        return CardService.getCart()
          .then(async function(res) {
            $scope.loading = false;
            var cartItems = res.data.cartItems || [];
            if (cartItems.length) {
              $scope.cartItemType = cartItems[0].type;
              let isFree = [LEI_ACTION.UPDATE, LEI_ACTION.TRANSFER].includes($scope.cartItemType);
              $scope.submitButtonLabel = isFree ? 'Submit' : 'Checkout';

              if (!isFree) {
                const { data: finalBillingClass } = await UserService.getBillingClass('BULK');
                $scope.productInfo =
                  leiProducts[finalBillingClass + cartItems[0].type + 'LEI'].PRICING[res.data.billingCurrency] || {};

                // ENG4LEI-1201 - Monthly accounts should get bulk discount
                const userSetting = await UserService.getSettings();
                const isMonthly = _.get(userSetting, ['data', 'billingFrequency']) === 'MONTHLY';

                $scope.isDiscount =
                  finalBillingClass === 'BULK' // Excludes Validation Agent
                    ? ($scope.productInfo && $scope.paymentInfo.num >= $scope.productInfo.minimum) || isMonthly
                    : false;
              }
            }
            $scope.paymentInfo = res.data.pricingInfo || {};
            $scope.paymentInfo.num = cartItems.length;
            $scope.paymentInfo.currencySymbol = currencies[res.data.billingCurrency].symbol;
            $scope.paymentInfo.decimal = currencies[res.data.billingCurrency].omitCents ? 0 : 2;
            $scope.paymentInfo.currency = res.data.billingCurrency;

            return ($scope.data = cartItems.map(function(value, index) {
              return Object.assign(
                {
                  price: $scope.paymentInfo[value.submissionId] / 100 || '--',
                  currency: $scope.paymentInfo.currency,
                  currencySymbol: $scope.paymentInfo.currencySymbol,
                  decimal: $scope.paymentInfo.decimal
                },
                value,
                {
                  displayType:
                    {
                      NEW: 'Create',
                      RENEW: 'Renew',
                      TRANSFER: 'Transfer',
                      TRANSFER_RENEW: 'Transfer Renew',
                      UPDATE: 'Update'
                    }[value.type] || value.type
                }
              );
            }));
          }, DefaultErrorHandler)
          .catch(function(ex) {
            return $scope.data || [];
          }); // swallow
      },
      onRemove(id) {
        return CardService.deleteCart(id)
          .then(function() {
            return $scope.actions.onFetch(true);
          }, DefaultErrorHandler)
          .catch(function() {
            return $scope.data || [];
          }); // swallow
      },

      onEmpty() {
        $rootScope.$emit('showConfirmDialog');
      },

      emptyCart() {
        return CardService.deleteCart()
          .then(function() {
            $scope.paymentInfo.num = 0;
            return $rootScope.$emit(CardService.EMPTY_CART);
          }, DefaultErrorHandler)
          .catch(function() {
            return $scope.data || [];
          }); // swallow
      }
    };

    $scope.blurb = [
      pageLabels.payment.stripe_info,
      '* ' + pageLabels.payment.tax_info,
      '** ' + pageLabels.payment.total_price
    ].map(function(value) {
      return $sce.trustAsHtml(value);
    });
  }
];

controller('CartController', main);

export /* @ngInject */ function grid($scope, $rootScope, $timeout, CardService, gridInit, gridPageSize, gridMaxPages) {
  var $ctrl = this;

  $scope.data = [];

  $ctrl.firstPage = 0;
  $ctrl.lastPage = 0;
  $ctrl.loading = true;

  $rootScope.$on(CardService.EMPTY_CART, () => {
    $scope.data = [];
  });

  function getDataError() {
    return $ctrl.gridApi.infiniteScroll.dataLoaded();
  }

  $scope.actions = {
    onRemove(id) {
      return $ctrl.actions
        .onRemove(id)
        .then(function(data) {
          $ctrl.gridApi.infiniteScroll.saveScrollPercentage();
          $scope.data = [];
          for (var page = $ctrl.firstPage; page <= $ctrl.lastPage; page++)
            $scope.data = $scope.data.concat(getPage(data, page));
          return $ctrl.gridApi.infiniteScroll
            .dataLoaded($ctrl.firstPage > 0, $ctrl.lastPage < gridMaxPages)
            .then(function() {
              checkDataLength('down');
            });
        })
        .catch(getDataError);
    }
  };

  function getFirstData() {
    return $ctrl.actions
      .onFetch(true)
      .then(function(data) {
        $scope.data = $scope.data.concat(getPage(data, $ctrl.lastPage));
      })
      .catch(getDataError)
      .then(function() {
        $ctrl.loading = false;
      });
  }

  function getDataDown() {
    return $ctrl.actions
      .onFetch()
      .then(function(data) {
        $ctrl.lastPage++;
        $ctrl.gridApi.infiniteScroll.saveScrollPercentage();
        $scope.data = $scope.data.concat(getPage(data, $ctrl.lastPage));
        return $ctrl.gridApi.infiniteScroll
          .dataLoaded($ctrl.firstPage > 0, $ctrl.lastPage < gridMaxPages)
          .then(function() {
            checkDataLength('up');
          });
      })
      .catch(getDataError);
  }

  function getDataUp() {
    return $ctrl.actions
      .onFetch()
      .then(function(data) {
        $ctrl.firstPage--;
        $ctrl.gridApi.infiniteScroll.saveScrollPercentage();
        $scope.data = getPage(data, $ctrl.firstPage).concat($scope.data);
        return $ctrl.gridApi.infiniteScroll
          .dataLoaded($ctrl.firstPage > 0, $ctrl.lastPage < gridMaxPages)
          .then(function() {
            checkDataLength('down');
          });
      })
      .catch(getDataError);
  }

  function getPage(data, page) {
    var res = [];
    for (var i = page * gridPageSize; i < (page + 1) * gridPageSize && i < data.length; ++i) res.push(data[i]);

    return res;
  }

  function checkDataLength(discardDirection) {
    // work out whether we need to discard a page, if so discard from the direction passed in
    if ($ctrl.lastPage - $ctrl.firstPage > 3) {
      // we want to remove a page
      $ctrl.gridApi.infiniteScroll.saveScrollPercentage();

      if (discardDirection === 'up') {
        $scope.data = $scope.data.slice(gridPageSize);
        $ctrl.firstPage++;
        $timeout(function() {
          // wait for grid to ingest data changes
          $ctrl.gridApi.infiniteScroll.dataRemovedTop($ctrl.firstPage > 0, $ctrl.lastPage < gridMaxPages);
        });
      } else {
        $scope.data = $scope.data.slice(0, gridPageSize * gridMaxPages);
        $ctrl.lastPage--;
        $timeout(function() {
          // wait for grid to ingest data changes
          $ctrl.gridApi.infiniteScroll.dataRemovedBottom($ctrl.firstPage > 0, $ctrl.lastPage < gridMaxPages);
        });
      }
    }
  }

  $ctrl.$onInit = function() {
    return gridInit($scope, {
      enableColumnMenus: false,
      columnDefs: [
        {
          field: '_btn',
          displayName: '',
          width: 120,
          cellTemplate: '<cart-grid-actions row="row"></cart-grid-actions>'
        },
        { field: 'displayType', displayName: 'Type', width: 100 },
        { field: 'lei', displayName: 'LEI', width: 200 },
        { field: 'legalName', displayName: 'Legal Name' },
        { field: 'addTime', displayName: 'Added On', width: 100, cellFilter: "date:'yyyy-MM-dd'" },
        {
          field: 'price',
          displayName: 'Price',
          width: 100,
          cellFilter: 'currency : row.entity.currencySymbol : row.entity.decimal'
        }
      ]
    }).then(function(gridApi) {
      $ctrl.gridApi = gridApi;
      $ctrl.gridApi.infiniteScroll.on.needLoadMoreData($scope, getDataDown);
      $ctrl.gridApi.infiniteScroll.on.needLoadMoreDataTop($scope, getDataUp);
      return getFirstData().then(function() {
        $timeout(function() {
          // timeout needed to allow digest cycle to complete,and grid to finish ingesting the data
          gridApi.infiniteScroll.resetScroll($ctrl.firstPage > 0, $ctrl.lastPage < 4);
        });
      });
    });
  };
}

import views_cart_grid from '@lei-website-client/views/cart/grid.html';

component('cartGrid', {
  template: views_cart_grid,
  controller: grid,
  bindings: {
    actions: '<'
  }
});

import views_cart_gridActions from '@lei-website-client/views/cart/gridActions.html';
directive('cartGridActions', function() {
  return {
    restrict: 'E',
    template: views_cart_gridActions,
    scope: {
      row: '<'
    },
    link($scope) {
      $scope.getBaseActionUrl = getBaseActionUrl;
    }
  };
});
