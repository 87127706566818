'use strict';

var requiredString = require('./required-string');

module.exports = function makeRequiredStringSchema(key, options) {
  var ret = {};

  ret[key] = {
    $label: options.$label,
    $placeholder: options.$placeholder || '',
    checkFn: requiredString,
    $message: 'Please provide ' + options.$label
  };

  return ret;
};
