import angular from 'angular';
import _ from 'lodash';
import { controller, factory } from '../app';
import { STRIPE_CARD_MAP } from '../../utils/constants';
import { schemaStore, checkStore } from '../../../../common/validate';
import flashMessages from '../../../../common/const/flash-message-data';
import states from '../../../../common/const/states';
import countriesNoZip from '../../../../common/const/countries-no-zip';
import currencies from '../../../../common/const/currencies';
import isoCountryCodesAlph2 from '../../../../common/const/iso-country-codes-alpha2';
import getCheckErrors from '../../../../common/lib/get-check-messages';
import { showInvalidCountry } from '../../utils/cardUtils';

factory('ProgressAlert', [
  'AlertMessenger',
  'ProgressMessenger',
  function(AlertMessenger, ProgressMessenger) {
    return {
      reset(progress) {
        AlertMessenger.reset();
        progress && ProgressMessenger.progress(progress);
      },
      success(msg) {
        ProgressMessenger.done(function() {
          AlertMessenger.success(msg);
        });
      },
      alert(msg) {
        ProgressMessenger.done(function() {
          AlertMessenger.alert(msg);
        });
      },
      cancel() {
        ProgressMessenger.done();
      }
    };
  }
]);

controller(
  'AccountCardsController',
  /* @ngInject */
  function($scope, CardService, UserService, PublicStorageService, Alert, ProgressAlert, DefaultErrorHandler, $log) {
    // we are lazy here so pointing to directly to scope
    var vm = $scope;
    // generate 10 years from current year
    vm.years = Array.from({ length: 20 }).map(function(num) {
      return num + new Date().getFullYear();
    });

    // hold all payment form data to be sent to backend
    // and for phase I, we may only support credit card payment type
    vm.paymentInfo = { type: 'credit', currency: 'USD', totalInCent: 0, taxInCent: 0, billing: {}, shipping: {} };
    vm.hideButton = false;
    vm.currencyMap = currencies;

    // actions
    vm.addNewCard = addNewCard;
    vm.deleteCard = deleteCard;
    vm.updateCard = updateCard;
    vm.copyAddress = copyAddress;
    vm.clearBilling = clearBilling;
    vm.validateVAT = validateVAT;
    vm.formService = { stripe: undefined, element: undefined }; // Stripe v3 API stuff
    vm.formAction = {
      billing: {
        schema: getFromSchema,
        states: [],
        onCountryChange: () => {
          updateStates.call(null, 'billing');
          showInvalidCountry(getFromSchema, vm, disableButtonFn);
        }
      },
      shipping: {
        schema: getFromSchema,
        states: [],
        onCountryChange: () => {
          updateStates.call(null, 'shipping');
          showInvalidCountry(getFromSchema, vm, disableButtonFn);
        }
      },
      card: { schema: getFromSchema, cards: [], onCardChange: displayCard, onCardError: disableButtonFn }
    };
    vm.formData = {
      countries: [],
      states: {},
      noZip: countriesNoZip
    };

    // State/Province Map
    for (var c in states) {
      vm.formData.states[c] = states[c].sort(function strCmp(a, b) {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
    }

    function updateStates(type) {
      var country = vm.paymentInfo[type].country;
      if (Object.prototype.hasOwnProperty.call(vm.formData.states, country)) {
        vm.formAction[type].states = vm.formData.states[country];
      } else {
        vm.formAction[type].states = [];
      }
    }

    vm.disableButton = false;
    function disableButtonFn(value) {
      vm.disableButton = value;
    }

    // Schema
    var paymentAddressSchema = schemaStore.get('paymentAddress');
    function getFromSchema(field) {
      return paymentAddressSchema[field];
    }

    // init screen
    load();
    function load() {
      vm.formData.countries = vm.formData.domicileCountries = isoCountryCodesAlph2;

      PublicStorageService.getSiteKeys()
        .then(function(res) {
          vm.formService.stripe = window.Stripe(res.data.stripePublicKey);
        })
        .catch(function() {
          Alert.error('loadStripe');
        })
        .catch(angular.noop);

      UserService.getSettings()
        .then(function(res) {
          if (res && res.data) {
            vm.paymentInfo.currency = res.data.billingCurrency;
            var domicileCountries = res.data.domicileCountries;
            vm.formData.domicileCountries =
              (domicileCountries &&
                vm.formData.domicileCountries.filter(function(country) {
                  return domicileCountries.indexOf(country['alpha-2']) !== -1;
                })) ||
              vm.formData.domicileCountries;
          }
        })
        .catch(function(ex) {
          console.error(ex);
        });

      return _loadCards();
    }

    function _loadCards() {
      return CardService.getCards()
        .then(function(res) {
          vm.formAction.card.cards = res.data.data;
        })
        .catch(DefaultErrorHandler)
        .catch(angular.noop);
    }
    // function definitions
    function displayCard() {
      if (vm.paymentInfo.card) {
        vm.formAction.card.cards.forEach(function(card) {
          if (card.id == vm.paymentInfo.card && card.object === 'card') {
            vm.paymentInfo.type = 'credit';
            mapTo(card, vm.paymentInfo, STRIPE_CARD_MAP);
            vm.paymentInfo.shipping = card.metadata.shipping ? JSON.parse(card.metadata.shipping) : {};
            vm.paymentInfo.vat = card.metadata.vat;
            vm.paymentInfo.cardNumber = '**********' + card.last4;
            vm.paymentInfo.expiryMonth = '' + card.exp_month;
            vm.paymentInfo.cvc = '***';
            showInvalidCountry(getFromSchema, vm, disableButtonFn);
          }
        });
      } else {
        mapTo({}, vm.paymentInfo, STRIPE_CARD_MAP);
      }
    }

    function clearBilling() {
      vm.paymentInfo.billing = {};
    }

    function copyAddress() {
      clearBilling();
      _.merge(vm.paymentInfo.billing, vm.paymentInfo.shipping);
      _.get(vm, vm.paymentInfo.billing.localLangCompany) && delete vm.paymentInfo.billing.localLangCompany;
      updateStates('billing');
    }

    function validateVAT() {
      return CardService.validateVAT(vm.paymentInfo.vat)
        .then(function(res) {
          vm.validVAT = res.data;
        })
        .catch(angular.noop);
    }

    function deleteCard() {
      ProgressAlert.reset(20);
      // prevent user click twice
      disableButtonFn(true);
      var cardId = vm.paymentInfo.card;
      if (cardId) {
        CardService.deleteCard(cardId)
          .then(function(res) {
            var resp = res.data;
            if (resp.sources) {
              vm.formAction.card.cards = resp.sources.data;
            } else {
              vm.formAction.card.cards = [];
            }
            disableButtonFn(false);
            ProgressAlert.success(flashMessages.deleteCard.success);
          })
          .catch(DefaultErrorHandler);
      }
    }

    function updateCard() {
      ProgressAlert.reset(20);

      const invalidErrors = getCheckErrors(checkStore.get('accountCardServer'), vm.paymentInfo);
      if (invalidErrors.length) {
        return Alert.error(invalidErrors);
      } else {
        // prevent user click twice
        disableButtonFn(true);
      }

      var cardId = vm.paymentInfo.card;
      if (cardId) {
        var stripeCard = {};
        mapFrom(stripeCard, vm.paymentInfo, STRIPE_CARD_MAP);
        stripeCard.metadata = { shipping: JSON.stringify(vm.paymentInfo.shipping), vat: vm.paymentInfo.vat };
        delete stripeCard.number;
        delete stripeCard.cvc;
        CardService.updateCard(cardId, stripeCard)
          .catch(DefaultErrorHandler)
          .then(function() {
            disableButtonFn(false);
            ProgressAlert.success(flashMessages.updateCard.success);
            _loadCards();
          })
          .catch(function() {
            disableButtonFn(false);
          });
      }
    }

    function clearCardInfo() {
      vm.paymentInfo.cardNumber = undefined;
      vm.paymentInfo.cvc = undefined;
      vm.paymentInfo.expiryMonth = undefined;
      vm.paymentInfo.expiryYear = undefined;

      const billing = vm.paymentInfo.billing;
      for (let itemName in billing) {
        billing[itemName] = !billing[itemName] ? undefined : billing[itemName];
      }
    }

    function addNewCard(valid) {
      $log.info('addNewCard called');
      if (!vm.formService.stripe) {
        ProgressAlert.alert(flashMessages.loadStripe.error);
        return;
      }

      if (!valid) {
        ProgressAlert.alert(flashMessages.fillInRequired.error);
        return;
      }

      ProgressAlert.reset(20);

      const invalidErrors = getCheckErrors(checkStore.get('accountCardServer'), vm.paymentInfo);
      if (invalidErrors.length) {
        return Alert.error(invalidErrors);
      } else {
        // prevent user click twice
        disableButtonFn(true);
      }

      var stripeCard = {};
      if (vm.paymentInfo.type === 'credit') {
        clearCardInfo();
        mapFrom(stripeCard, vm.paymentInfo, STRIPE_CARD_MAP);
        vm.formService.stripe
          .createToken(vm.formService.element, stripeCard)
          .then(stripeResponseHandler)
          .catch(function(ex) {
            disableButtonFn(false);
            $log.error('Error adding card:', ex.message);
            Alert.error('stripeCallInternal');
          });
      }
    }

    function stripeResponseHandler(response) {
      if (response.error) {
        // Show the errors on the form
        ProgressAlert.alert(response.error.message);
        disableButtonFn(false);
      } else {
        // response contains id and card, which contains additional card details
        vm.paymentInfo.stripeToken = response.token.id;
        _postData();
      }
    }

    function _postData() {
      return CardService.addCard(vm.paymentInfo)
        .then(function() {
          vm.hideButton = true;
          disableButtonFn(false);
          ProgressAlert.success(flashMessages.addCard.success);
          return _loadCards();
        }, DefaultErrorHandler)
        .catch(function() {
          disableButtonFn(false);
        });
    }

    /**
     * map source to target based on mapping info
     * mapping contains source key -> target key
     */
    function mapTo(source, target, mapping) {
      Object.keys(mapping).forEach(function(key) {
        _.set(target, mapping[key], _.get(source, key));
      });
    }

    /**
     * map target to source based on mapping info
     * mapping contains source key -> target key
     */
    function mapFrom(source, target, mapping) {
      Object.keys(mapping).forEach(function(key) {
        _.set(source, key, _.get(target, mapping[key]));
      });
    }
  }
);
