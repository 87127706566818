'use strict';
const NOT_EMPTY_NOT_BLANK = /(.|\s)*\S(.|\s)*/;

const DS_NAME = 'com.bloomberg.ds.LEISubmissionv2';

module.exports.DS_NAME = DS_NAME;

module.exports.LEGAL_ENTITY_EVENT_ACTION_TYPES = [
  'ABSORPTION',
  'ACQUISITION_BRANCH',
  'BANKRUPTCY',
  'BREAKUP',
  'DEMERGER',
  'DISSOLUTION',
  'INSOLVENCY',
  'LIQUIDATION',
  'MERGERS_AND_ACQUISITIONS',
  'SPINOFF',
  'VOLUNTARY_ARRANGEMENT'
];

module.exports.LEGAL_ENTITY_EVENT_SKIP_TYPE = 'DATA_CORRECTION';

module.exports.LEGAL_ENTITY_EVENT_TYPES = [
  'CHANGE_HQ_ADDRESS',
  'CHANGE_LEGAL_ADDRESS',
  'CHANGE_LEGAL_FORM',
  'CHANGE_LEGAL_NAME',
  'CHANGE_OTHER_NAMES',
  'MERGERS_AND_ACQUISITIONS',
  'TRANSFORMATION_BRANCH_TO_SUBSIDIARY',
  'TRANSFORMATION_SUBSIDIARY_TO_BRANCH',
  'TRANSFORMATION_UMBRELLA_TO_STANDALONE'
];

module.exports.LEGAL_ENTITY_EVENT_STATUSES = ['IN_PROGRESS', 'COMPLETED', 'WITHDRAWN_CANCELLED'];

module.exports.LEGAL_ENTITY_EVENT_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  WITHDRAWN_CANCELLED: 'WITHDRAWN_CANCELLED'
};

module.exports.LEGAL_ENTITY_EVENT_GROUP_TYPES = [
  'CHANGE_LEGAL_FORM_AND_NAME',
  'COMPLEX_CHANGE_LEGAL_FORM',
  'REVERSE_TAKEOVER',
  'STANDALONE'
];

module.exports.VALIDATION_DOCUMENTS = [
  'ACCOUNTS_FILING',
  'CONTRACTS',
  'OTHER_OFFICIAL_DOCUMENTS',
  'REGULATORY_FILING',
  'SUPPORTING_DOCUMENTS'
];

module.exports.EVENT_CATEGORIES = {
  EVENT: 'EVENT',
  EXISTING_EVENT: 'EXISTING_EVENT',
  ACTION: 'ACTION'
};

module.exports.EVENT_LABEL_OVERRIDES = {
  'Change Hq Address': 'Change HQ Address',
  'Withdrawn Cancelled': 'Withdrawn/Cancelled'
};

module.exports.AFFECTED_FIELD_XPATH_TERM_OVERRIDES = {
  [DS_NAME]: '[$SCHEMA]',
  '[$SCHEMA]': DS_NAME
};

module.exports.AFFECTED_FIELD_WITH_NUMBER_VALUE = ['percentageOwned'];

module.exports.EVENT_RELATIONSHIP_PARENTS = ['branchParent', 'directParent', 'ultimateParent'];

// Used to exclude updates that are not tied to events
// When provided crumb is a parent, child nodes will be grouped based regex matches
module.exports.INCLUDE_CRUMB_LIST = [
  /^Entity > Entity Legal Form Code$/,
  /^Entity > Legal Form$/,
  /^Entity > Headquarters Address In English/,
  /^Entity > Legal Address In English/,
  /^Entity > Other Addresses In Local Language > Other Address > \d/,
  /^Entity > Legal Name|Entity > Legal Name In English$/,
  /^Entity > Other Entity Names > Other Entity Name > \d/,
  /^Entity > Legal Jurisdiction/,
  ///^Entity > Entity Status$/,
  // Branch to Subsidiary when:
  //   1. Category changes as Branch -> General
  //   2. Branch Parent becomes null, Direct Parent or Ultimate Parent becomes LEI Parent or Non Public exception
  // Subsidiary to Branch when:
  //   1. Category changes General -> Branch
  //   2. Branch Parent is added, Direct Parent or Ultimate Parent were LEI Parent or Non Public exception
  /^Entity > Entity Category$/,
  /^Relationships > Branch Parent/,
  /^Relationships > Direct Parent/,
  /^Relationships > Ultimate Parent/,
  /^Registration > Registration Status$/
];

module.exports.EXCLUDE_CRUMB_LIST = [
  /^Relationships > .* > _resolves$/
  /*  /^Relationships > .* > \d > Start Date$/,
  /^Relationships > .* > \d > End Date$/,
  /^Relationships > .* > \d > Period Type$/,
  /^Relationships > .* > Percentage Owned$/,
  /^Relationships > .* > \d > Standard$/,
  /^Relationships > .* > Relationship Registration > /,
  /^Relationships > .* > Relationship Status$/*/
];

// Mappings for simple event type (based on changed field)
module.exports.EVENT_CRUMB_TO_EVENT_TYPE_MAP = {
  //CHANGE_LEGAL_FORM: /^Entity > Entity Legal Form Code$/
  //CHANGE_HQ_ADDRESS: /^Entity > Headquarters Address In English/,
  //CHANGE_LEGAL_ADDRESS: /^Entity > Legal Address In English/,
  //CHANGE_LEGAL_NAME: /^Entity > Legal Name|Legal Name In English/,
  //CHANGE_OTHER_NAMES: /^Entity > Other Entity Names > Other Entity Name > \d > Name$/
};

// Mappings for complex event types where multiple fields, from disparate crumb lists, changed to specific values
module.exports.COMPLEX_EVENT_TYPE_MAP = {
  TRANSFORMATION_BRANCH_TO_SUBSIDIARY: {
    // Branch to Subsidiary when:
    //   1. Category changes as Branch -> General
    //   2. Branch Parent becomes null, Ultimate Parent becomes LEI Parent or Non Public exception
    groupCrumb: 'Entity > Entity Category',
    orGroup: [
      {
        // test Parent Lei
        andGroup: [
          {
            field: /^Entity > Entity Category$/,
            new: /^GENERAL$/,
            orig: /^BRANCH$/
          },
          {
            field: /^Relationships > Branch Parent > Headquarters Lei/,
            new: undefined, // is undefined
            orig: NOT_EMPTY_NOT_BLANK // match any non empty & non space value
          },
          {
            field: /^Relationships > Direct Parent > Parent Information > Parent Entity > Parent Lei > Lei|Relationships > Ultimate Parent > Parent Information > Parent Entity > Parent Lei > Lei$/,
            new: NOT_EMPTY_NOT_BLANK, // match any non empty & non space value
            orig: undefined // was undefined
          },
          {
            // catch all child crumbs to process IN_PROGRESS for revertInProgressFieldsForBbds
            field: /^Relationships > Direct Parent|Relationships > Ultimate Parent/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        // test Parent NON_PUBLIC|NO_LEI exception
        andGroup: [
          {
            field: /^Entity > Entity Category$/,
            new: /^GENERAL$/,
            orig: /^BRANCH$/
          },
          {
            field: /^Relationships > Branch Parent > Headquarters Lei/,
            new: undefined,
            orig: NOT_EMPTY_NOT_BLANK // match any non empty & non space value
          },
          {
            field: /^Relationships > Direct Parent > Parent Information Not Provided > Exception Reasons > \d > Reason|Relationships > Ultimate Parent > Parent Information Not Provided > Exception Reasons > \d > Reason$/,
            new: /^NON_PUBLIC|NO_LEI$/,
            orig: undefined
          }
        ]
      }
    ]
  },
  TRANSFORMATION_SUBSIDIARY_TO_BRANCH: {
    // Subsidiary to Branch when:
    //   1. Category changes General -> Branch
    //   2. Branch Parent is added, Direct Parent and Ultimate Parent were LEI Parent or Non Public exception
    groupCrumb: 'Entity > Entity Category',
    orGroup: [
      {
        // test Parent Lei
        andGroup: [
          {
            field: /^Entity > Entity Category$/,
            new: /^BRANCH$/,
            orig: /^GENERAL$/
          },
          {
            field: /^Relationships > Branch Parent > Headquarters Lei/,
            new: NOT_EMPTY_NOT_BLANK, // match any non empty & non space value
            orig: undefined // was undefined
          },
          {
            field: /^Relationships > Direct Parent > Parent Information > Parent Entity > Parent Lei > Lei|Relationships > Ultimate Parent > Parent Information > Parent Entity > Parent Lei > Lei$/,
            new: undefined, // is now undefined
            orig: NOT_EMPTY_NOT_BLANK // match any non empty & non space value
          },
          {
            // catch all child crumbs to process IN_PROGRESS for revertInProgressFieldsForBbds
            field: /^Relationships > Direct Parent|Relationships > Ultimate Parent/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        // test Parent NON_PUBLIC|NO_LEI exception
        andGroup: [
          {
            field: /^Entity > Entity Category$/,
            new: /^BRANCH$/,
            orig: /^GENERAL$/
          },
          {
            field: /^Relationships > Branch Parent > Headquarters Lei/,
            new: NOT_EMPTY_NOT_BLANK, // match any non empty & non space value
            orig: undefined
          },
          {
            field: /^Relationships > Direct Parent > Parent Information Not Provided > Exception Reasons > \d > Reason|Relationships > Ultimate Parent > Parent Information Not Provided > Exception Reasons > \d > Reason$/,
            new: undefined,
            orig: /^NON_PUBLIC|NO_LEI$/
          }
        ]
      }
    ]
  },
  //TRANSFORMATION_UMBRELLA_TO_STANDALONE: << Use offline to generate
  // TODO: Add all types from pg 89
  CHANGE_LEGAL_FORM: {
    groupCrumb: 'Entity > Entity Legal Form Code',
    orGroup: [
      // 4.13.5 > The third Use Case (Example 6) - new entity - use offline to generate - 2021-06-21_State-Transition-Validation-Rules_v2.3_final%20(5).pdf
      {
        // 4.13.5 > The third Use Case (Example 6) - retired entity - 2021-06-21_State-Transition-Validation-Rules_v2.3_final%20(5).pdf
        andGroup: [
          {
            field: /^Entity > Entity Legal Form Code$/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: true // bypass regex validation - match any value
          },
          {
            field: /^Entity > Legal Form|Entity > Legal Jurisdiction Country|Entity > Legal Jurisdiction Region|Entity > Legal Name|Entity > Legal Name In English|Entity > Other Entity Names > Other Entity Name > \d/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: true // bypass regex validation - match any value
          },
          // TODO: Requires deactivate to work
          {
            field: /^Registration > Registration Status$/,
            new: /^RETIRED$/,
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        // 4.13.5 > The first Use Case (Example 4) or The second Use Case (Example 5) - 2021-06-21_State-Transition-Validation-Rules_v2.3_final%20(5).pdf
        andGroup: [
          {
            field: /^Entity > Entity Legal Form Code$/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: true // bypass regex validation - match any value
          },
          {
            field: /^Entity > Legal Form|Entity > Legal Jurisdiction/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        // 4.13.5 the first Use Case (Example 4) - 2021-06-21_State-Transition-Validation-Rules_v2.3_final%20(5).pdf
        andGroup: [
          {
            field: /^Entity > Entity Legal Form Code$/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: true // bypass regex validation - match any value
          }
        ]
      }
    ]
  },
  CHANGE_LEGAL_NAME: {
    groupCrumb: 'Entity > Legal Name',
    orGroup: [
      {
        andGroup: [
          {
            field: /^Entity > Legal Name|Entity > Legal Name In English$/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          },
          {
            field: /^Entity > Other Entity Names > Other Entity Name > \d/,
            new: /^PREVIOUS_LEGAL_NAME$/,
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        andGroup: [
          {
            field: /^Entity > Legal Name|Entity > Legal Name In English$/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      }
    ]
  },
  CHANGE_OTHER_NAMES: {
    groupCrumb: 'Entity > Other Entity Names > Other Entity Name',
    orGroup: [
      {
        andGroup: [
          {
            field: /^Entity > Other Entity Names > Other Entity Name > \d/,
            new: /^TRADING_OR_OPERATING_NAME$/,
            orig: true // bypass regex validation - match any value
          }
        ]
      }
    ]
  },
  CHANGE_LEGAL_ADDRESS: {
    groupCrumb: 'Entity > Legal Address In English',
    orGroup: [
      {
        andGroup: [
          {
            field: /^Entity > Legal Address In English/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          },
          {
            field: /^Entity > Other Addresses In Local Language > Other Address > \d/,
            new: /ALTERNATIVE_LANGUAGE_LEGAL_ADDRESS/, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        andGroup: [
          {
            field: /^Entity > Legal Address In English/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      }
    ]
  },
  CHANGE_HQ_ADDRESS: {
    groupCrumb: 'Entity > Headquarters Address In English',
    orGroup: [
      {
        andGroup: [
          {
            field: /^Entity > Headquarters Address In English/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          },
          {
            field: /^Entity > Other Addresses In Local Language > Other Address > \d/,
            new: /ALTERNATIVE_LANGUAGE_HEADQUARTERS_ADDRESS/, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      },
      {
        andGroup: [
          {
            field: /^Entity > Headquarters Address In English/,
            new: true, // bypass regex validation - match any value
            orig: true // bypass regex validation - match any value
          }
        ]
      }
    ]
  }
};

// Mappings for complex event group types where multiple fields, from disparate crumb lists, changed to specific values
module.exports.COMPLEX_EVENT_GROUP_TYPE_MAP = {
  CHANGE_LEGAL_FORM_AND_NAME: {
    orGroup: [
      {
        andGroup: [
          {
            field: /^Entity > Entity Legal Form Code$/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: NOT_EMPTY_NOT_BLANK
          },
          {
            field: /^Entity > Legal Name$/,
            new: NOT_EMPTY_NOT_BLANK, // was undefined
            orig: NOT_EMPTY_NOT_BLANK
          }
        ]
      }
    ]
  },
  COMPLEX_CHANGE_LEGAL_FORM: {
    orGroup: [
      {
        // Existing entity being retired
        andGroup: [
          {
            field: /^Entity > Entity Legal Form Code$/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: NOT_EMPTY_NOT_BLANK
          },
          {
            field: /^Entity > Successor Entity > Successor Lei|Entity > Successor Entity > Successor Entity Name/,
            new: NOT_EMPTY_NOT_BLANK,
            orig: undefined // was undefined
          },
          // TODO: Requires deactivation to work
          {
            field: /^Registration > Registration Status/,
            new: /^RETIRED/,
            orig: true // bypass regex validation - match any value
          }
        ]
      } /*,
      { // 4.13.5 > The third Use Case (Example 6) - new entity - use offline to generate - 2021-06-21_State-Transition-Validation-Rules_v2.3_final%20(5).pdf
        New entity being created
        TODO: Add offline to generate
      }*/
    ]
  }
  /*REVERSE_TAKEOVER: {
    // TODO: Not implemented must look at spec under 4.13.20. Legal Entity Event – Reverse Takeover
  }*/
};

// Mappings for complex event group types where multiple fields, from disparate crumb lists, changed to specific values
module.exports.EVENTS_TO_GROUP_TYPE_MAP = {
  CHANGE_LEGAL_FORM_AND_NAME: {
    orGroup: [
      {
        andGroup: [
          {
            legalEntityEventType: /^CHANGE_LEGAL_FORM$/
          },
          {
            legalEntityEventType: /^CHANGE_LEGAL_NAME$/
          }
        ]
      }
    ]
  }
  /*
  COMPLEX_CHANGE_LEGAL_FORM: {
    orGroup: [
      {
        // TODO: Requires deactivation to work
      },
      { // 4.13.5 > The third Use Case (Example 6) - new entity - use offline to generate - 2021-06-21_State-Transition-Validation-Rules_v2.3_final%20(5).pdf
        New entity being created
        // TODO: Add offline to generate
      }
    ]
  }*/
  /*REVERSE_TAKEOVER: {
    // TODO: Not implemented must look at spec under 4.13.20. Legal Entity Event – Reverse Takeover
  }*/
};

module.exports.UNREALIZED_EVENT_TYPE = 'UNREALIZED_EVENT_TYPE';

const INACTIVE_ENTITY_STATUS = {
  internal: true,
  baseXpath: ['entity', 'entityStatus'],
  newValue: 'INACTIVE'
};

module.exports.LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP = {
  // add field to UI and use for affected fields
  // formKeys map to event-form ng-if to decide whether to show additional fields
  // array of objects and array of baseXpath strings defined to make extensible
  ABSORPTION: [
    {
      negateConditional: false,
      conditionalQuestion: '* For this Absorption is your Legal Entity the one being absorbed?',
      required: true,
      formKey: 'successorEntityType',
      baseXpath: ['entity', 'successorEntity']
    },
    INACTIVE_ENTITY_STATUS
  ],
  DEMERGER: [
    {
      negateConditional: true,
      conditionalQuestion:
        'For this Demerger, is your entity the newly formed legal entity as a result of the demerger?',
      required: true,
      formKey: 'successorEntityType',
      baseXpath: ['entity', 'successorEntity']
    },
    INACTIVE_ENTITY_STATUS
  ],
  ACQUISITION_BRANCH: [
    {
      negateConditional: false,
      conditionalQuestion:
        '* For this International Branch acquisition, is this Branch the legal entity being acquired?',
      required: true,
      formKey: 'successorEntityType',
      baseXpath: ['entity', 'successorEntity']
    },
    INACTIVE_ENTITY_STATUS
  ],
  BREAKUP: [
    {
      negateConditional: false,
      conditionalQuestion: '* For this Breakup, is your entity the original entity that is ceasing to exist?',
      required: true,
      formKey: 'successorEntityType',
      baseXpath: ['entity', 'successorEntity']
    },
    INACTIVE_ENTITY_STATUS
  ],
  MERGERS_AND_ACQUISITIONS: [
    {
      negateConditional: false,
      conditionalQuestion: '* For this Event Type has your legal entity ceased to exist?',
      required: true,
      formKey: 'successorEntityType',
      baseXpath: ['entity', 'successorEntity']
    },
    INACTIVE_ENTITY_STATUS
  ],
  DISSOLUTION: [INACTIVE_ENTITY_STATUS]
};

// Field is required when no requiredWhenFieldNameExists conditional is defined.  Otherwise, when requiredWhen... field and value exist then it's required.
module.exports.LEGAL_ENTITY_EVENT_REQUIRED_FIELDS = {
  EVENT: {
    legalEntityEventType: { message: 'Event Type is required.' },
    legalEntityEventEffectiveDate: { message: 'Effective Date is required.' },
    validationDocuments: { message: 'Validation Document Type is required.' }
  },
  ACTION: {
    legalEntityEventType: { message: 'Event Type is required.' },
    legalEntityEventEffectiveDate: { message: 'Effective Date is required.' },
    validationDocuments: { message: 'Validation Document Type is required.' },
    // Check whether action event requires extra fields (LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP)
    successorEntityConditional: {
      requiredWhenFieldNameExists: 'conditionalQuestion',
      message: 'An answer is required.'
    },
    successorEntityType: {
      requiredWhenFieldNameExists: 'successorEntityConditional',
      requiredWhenFieldValue: true,
      message: 'Answer required: Does the successor entity have an LEI?'
    },
    successorEntityName: {
      requiredWhenFieldNameExists: 'successorEntityType',
      requiredWhenFieldValue: 'successorEntityName',
      message: 'Successor Entity Name is required.'
    },
    successorLEI: {
      requiredWhenFieldNameExists: 'successorEntityType',
      requiredWhenFieldValue: 'successorLEI',
      message: 'Successor LEI is required.'
    }
  }
};
