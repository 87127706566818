import { component } from '../app';
import _ from 'lodash';
import { getAction, getFormPageLabels, makeOnStateChangeSelector } from '../utilities';
import template from '@lei-website-client/views/form/footer.html';
import { WORKFLOW_TAB_STEPS } from '../../../utils/constants';
import { DS_NAME } from '../utilities/shared';

export default class FormFooterController {
  /* @ngInject */
  constructor($rootScope, $routeParams, LeiService, AlertMessenger) {
    this.$rootScope = $rootScope;
    this.leiId = $routeParams.id;
    this.Service = LeiService;
    this.AlertMessenger = AlertMessenger;
    this.skipWarnings = false;

    this.nextTab = WORKFLOW_TAB_STEPS[1];

    this._setDisabledFromState = makeOnStateChangeSelector.call(
      this,
      ['disabled', 'submitted', 'valid', 'compliant'],
      () => {
        this.isDisabled = this.state.disabled || (this.state.submitted && !(this.state.valid && this.state.compliant));
      }
    );

    this._setCompliantFromState = makeOnStateChangeSelector.call(this, ['agreement', 'transferWaiver'], () => {
      this.state.compliant = this.state.agreement && (!this.state.isTransfer || this.state.transferWaiver);
    });

    this._setLabelsFromState = makeOnStateChangeSelector.call(this, ['action'], () => {
      this.labels = { ...getFormPageLabels(this.state) };
      if (this.state.isTransfer) this.labels.actionButton = this.labels[`${this.state.action}Button`];
    });

    $rootScope.$on('onChangeTab', async (event, { id: selectedTabId }) => {
      //  Update "Next" button
      const action = getAction(this.state);
      const steps = this._getTabSteps(action);
      console.log(
        'lei-form-footer> Event onChangeTab caught for FormFooterController:' + selectedTabId + ' action:' + action
      );
      this.nextTab = this._updateNextButton(selectedTabId, steps);
    });
  }

  _getCurrentTab(nextTab) {
    const action = getAction(this.state);
    const steps = this._getTabSteps(action);

    const nextTabIndex = steps.indexOf(nextTab);
    if (nextTabIndex <= 0) return null;
    return steps[nextTabIndex - 1]?.id;
  }

  async _runSectionValidations(currentTabId) {
    console.log('lei-form-footer.js> _runSectionValidations() Starting\n');
    // schema validations
    const { payload, hasErrors } = this.formData(currentTabId);
    const formPayload = _.get(payload, DS_NAME);
    if (!formPayload || hasErrors) return false;

    if (['FILES'].includes(currentTabId)) return true; // no bre validations for files

    // bre validations
    return await this.Service.validateFormSection(
      getAction(this.state),
      this.leiId,
      {},
      formPayload,
      currentTabId,
      this.skipWarnings
    ).catch(err => {
      if (err.status >= 400 && err.status < 500 && err.data.length) this.AlertMessenger.alert(err.data);
      return false;
    });
  }

  _updateNextButton(selectedTabId, steps) {
    const currentTabIndex = steps.map(({ id }) => id == selectedTabId).indexOf(true);
    if (currentTabIndex < steps.length - 1) {
      console.log('lei-form-footer> onChangeTab nextTab: ' + JSON.stringify(this.nextTab));
      return steps[currentTabIndex + 1];
    }
  }

  _getTabSteps(action) {
    return (action || 'NEW').toUpperCase() != 'NEW'
      ? WORKFLOW_TAB_STEPS
      : WORKFLOW_TAB_STEPS.filter(item => item.id != 'EVENTS');
  }

  async switchTabs(nextTab) {
    this.AlertMessenger.reset();
    try {
      const currentTabId = this._getCurrentTab(nextTab);
      const isSectionValid = await this._runSectionValidations(currentTabId);
      this.skipWarnings = true;
      if (isSectionValid) this.$rootScope.$emit('requestChangeTab', nextTab);
    } catch (err) {
      this.AlertMessenger.alert(
        'There was a problem validating your section. Please try again later or contact an administrator.'
      );
    }
  }

  $doCheck() {
    this._setDisabledFromState();
    this._setCompliantFromState();
    this._setLabelsFromState();
  }

  $onInit() {
    this._setDisabledFromState();
    this._setCompliantFromState();
    this._setLabelsFromState();
  }
}

component('leiFormFooter', {
  controller: FormFooterController,
  template,
  bindings: {
    onSave: '<onsave',
    onSubmit: '<onsubmit',
    state: '<',
    formData: '<formdata'
  }
});
