'use strict';

module.exports = {
  usernameOrLEI: {
    $label: 'Username or LEI',
    $placeholder: 'Username or LEI',
    $required: true,
    $pattern: /^(?:[A-Za-z0-9_-]{4,32}|[0-9A-Z]{18}[0-9]{2})$/,
    $message:
      'Please provide a valid username or LEI. Username must be between 4 and 32 characters, and can contain (1) letters from A-Z, (2) letters from a-z, (3) numbers from 0-9, and special characters such as - and _. NOTE: Usernames are case sensitive.'
  }
};
