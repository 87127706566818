'use strict';

var emailRegex = require('../const/email-regex');
var MAX_EMAILS_COUNT = require('../const/max-emails-count');
var MAX_EMAILS_TOTAL_SIZE = require('../const/max-emails-total-size');

function atLeastOneEmail(emails) {
  return Array.isArray(emails) && emails.length;
}

function atLeastOneEmailWillRemainAfterRemoval(emails, email) {
  return emails.filter(function(em) {
    return em !== email;
  }).length;
}

function isEmail(email) {
  return emailRegex.test(email);
}

function inEmails(emails, email) {
  return emails.indexOf(email) !== -1;
}

function makeAccountAddEmailSchema() {
  return {
    emails: {
      checkFn: atLeastOneEmail,
      $message: 'There should be at least one email in this account'
    },
    _validEmail: {
      checkFn: function(_val, body) {
        return isEmail(body.newEmail);
      },
      $message: 'Please provide a valid email.'
    },
    _duplicate: {
      checkFn: function(_val, body) {
        return !inEmails(body.emails, body.newEmail);
      },
      $message: 'The email you are adding already exists in this account.'
    },
    _maxEmailsTotalSize: {
      checkFn: function(_val, body) {
        return JSON.stringify(body.emails.concat(body.newEmail)).length <= MAX_EMAILS_TOTAL_SIZE;
      },
      $message: 'Total length of all emails cannot exceed ' + MAX_EMAILS_TOTAL_SIZE + ' characters.'
    },
    _maxEmails: {
      checkFn: function(_val, body) {
        return body.emails.length <= MAX_EMAILS_COUNT;
      },
      $message: 'There can be up to ' + MAX_EMAILS_COUNT + ' emails.'
    },
    newEmail: {
      checkFn: function(val, body, report) {
        return (
          report._validEmail.$valid &&
          report._duplicate.$valid &&
          report._maxEmails.$valid &&
          report._maxEmailsTotalSize.$valid
        );
      }
    }
  };
}

function makeAccountRemoveEmailSchema() {
  return {
    emails: {
      checkFn: atLeastOneEmail
    },
    emailToRemove: {
      checkFn: function validEmailAndNotExisting(val, body) {
        return isEmail(val) && inEmails(body.emails, val);
      }
    },
    _willNotEmpty: {
      checkFn: function willNotEmptyAccountEmails(_val, body, report) {
        var skipCheck = true;
        return !report.emails.$valid || !report.emailToRemove.$valid
          ? skipCheck
          : atLeastOneEmailWillRemainAfterRemoval(body.emails, body.emailToRemove);
      }
    }
  };
}

module.exports.makeAccountAddEmailSchema = makeAccountAddEmailSchema;
module.exports.makeAccountRemoveEmailSchema = makeAccountRemoveEmailSchema;
