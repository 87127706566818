'use strict';

const passwordSchema = require('./password-schema');
var requiredString = require('./required-string');
var _ = require('lodash');

function makeResetPasswordSchema(opts = {}) {
  const schema = {
    resetPasswordKey: {
      $label: 'Reset Key',
      $placeholder: 'Reset Key',
      $required: true,
      checkFn: requiredString,
      $message: 'Please provide reset password key. You should receive it in your email.'
    },
    newPassword: _.assign({}, passwordSchema.make(opts).password, {
      $label: 'New Password',
      $placeholder: 'New Password'
    })
  };

  opts.server &&
    _.assign(schema, {
      username: {
        $label: 'Username',
        checkFn: requiredString,
        $message: 'Please provide your username.'
      },
      email: {
        $label: 'Email',
        checkFn: requiredString,
        $message: 'Please provide your email.'
      }
    });

  return schema;
}

module.exports = {
  client: makeResetPasswordSchema(),
  server: makeResetPasswordSchema({ server: true }),
  make: makeResetPasswordSchema
};
