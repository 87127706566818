'use strict';

module.exports = [
  'AE', // United Arab Emirates
  'AT', // Austria
  'BE', // Belgium
  'CY', // Cyprus
  'DK', // Denmark
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'GH', // Ghana
  'IE', // Ireland
  'IT', // Italy
  'LU', // Luxembourg
  'MC', // Monaco
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'RU', // Russia
  'SA', // Saudi Arabia
  'SE', // Sweden
  'SK', // Slovakia
  'TR' // Turkey
];
