import angular from 'angular';
import { factory } from './app';

factory('ReduxActionTypeFactory', [
  function() {
    return function ReduxActionTypeFactory(keys) {
      var typeMap = {};

      var key;
      for (var i = 0; i < keys.length; i += 1) {
        key = keys[i];

        if (typeof typeMap[key] !== 'undefined') {
          throw new Error('ActionType[' + key + '] already defined');
        }

        typeMap[key] = key;
      }

      return typeMap;
    };
  }
]);

factory('ReduxUIActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['CHANGE', 'VALIDATE', 'SHOW_ALL_ERRORS']);
  }
]);

factory('ReduxUIAction', [
  'ReduxUIActionType',

  function(ReduxUIActionType) {
    var ret = {};

    ret.change = function changeAction(val) {
      return {
        type: ReduxUIActionType.CHANGE,
        data: val
      };
    };

    ret.validate = function validateAction(validateObj) {
      return {
        type: ReduxUIActionType.VALIDATE,
        data: validateObj
      };
    };

    ret.showAllErrors = function showAllErrorsAction(showOrNot) {
      return {
        type: ReduxUIActionType.SHOW_ALL_ERRORS,
        data: !!showOrNot
      };
    };

    return ret;
  }
]);

factory('ReduxUIReducer', [
  'ReduxUIActionType',
  'ReduxReducerFactory',
  function(ReduxUIActionType, ReduxReducerFactory) {
    var ret = {};

    ret.change = ReduxReducerFactory.assign(ReduxUIActionType.CHANGE);

    ret.validate = ReduxReducerFactory.assign(ReduxUIActionType.VALIDATE);

    ret.showAllErrors = ReduxReducerFactory.value(ReduxUIActionType.SHOW_ALL_ERRORS);

    return ret;
  }
]);

factory('ReduxReducerFactory', [
  function() {
    var ret = {};
    var assign = angular.extend;

    ret.value = function makeValueReducer(actionType) {
      if (typeof actionType !== 'string' || !actionType) {
        throw Error('Making valueReducer, ' + 'actionType must be string, got ', actionType);
      }

      return function valueReducer(state, action) {
        if (action.type === actionType) {
          return action.data;
        }

        return state;
      };
    };

    ret.assign = function makeAssignReducer(actionType) {
      if (typeof actionType !== 'string' || !actionType) {
        throw Error('Making assignReducer, ' + 'actionType must be string, got ', actionType);
      }

      return function assignReducer(state, action) {
        if (action.type === actionType) {
          return assign({}, state, action.data);
        }

        return state;
      };
    };

    return ret;
  }
]);

factory('ReduxStoreFactory', [
  '$rootScope',
  function($rootScope) {
    function Store(name, reducer, state) {
      this.name = name;
      this.reducer = reducer;
      this.state = state;

      this._initialState = angular.copy(state);
    }

    Store.prototype.reset = function resetState() {
      this.state = angular.copy(this._initialState);
      return this;
    };

    Store.prototype.getState = function getState() {
      return this.state;
    };

    Store.prototype.subscribe = function subscribe(callback) {
      $rootScope.$on(this.name, function(event, data) {
        callback(data);
      });
    };

    Store.prototype.dispatch = function dispatch(action) {
      var newState = this.reducer(this.state, action);
      this.state = newState;
      $rootScope.$emit(this.name, newState);
    };

    return function ReduxStoreFactory(name, reducer, initialState) {
      if (ReduxStoreFactory[name]) {
        throw new Error('ReduxStoreFactory[' + name + '] already defined');
      }

      var ret = new Store(name, reducer, initialState);

      ReduxStoreFactory[name] = ret;

      return ret;
    };
  }
]);
