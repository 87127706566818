import angular from 'angular';
import { factory, controller } from '../app';
import { checkStore, schemaStore } from '../../../../common/validate';
import getCheckErrors from '../../../../common/lib/get-check-messages';

factory('ProfileStore', [
  'ReduxStoreFactory',
  'ProfileReducer',
  function(ReduxStoreFactory, ProfileReducer) {
    var initialState = {
      profile: null,
      profileView: null,
      profileValidate: {},
      ui: {
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('ProfileStore', ProfileReducer, initialState);
  }
]);

factory('ProfileActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['CHANGE', 'PROFILE_VALIDATE', 'UI_SHOW_ALL_ERRORS']);
  }
]);

factory('ProfileAction', [
  'ProfileActionType',
  function(ProfileActionType) {
    return {
      showAllErrors: function(showOrNot) {
        return {
          type: ProfileActionType.UI_SHOW_ALL_ERRORS,
          data: !!showOrNot
        };
      },
      profileChange: function(profileUpdatePart) {
        return {
          type: ProfileActionType.CHANGE,
          data: profileUpdatePart
        };
      },
      profileValidate: function(validateObj) {
        return {
          type: ProfileActionType.PROFILE_VALIDATE,
          data: validateObj
        };
      }
    };
  }
]);

factory('ProfileReducer', [
  'ProfileActionType',
  function(ProfileActionType) {
    var assign = angular.extend;

    function profileReducer(state, action) {
      if (action.type === ProfileActionType.CHANGE) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function profileValidateReducer(state, action) {
      if (action.type === ProfileActionType.PROFILE_VALIDATE) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function showAllErrorsReducer(state, action) {
      if (action.type === ProfileActionType.UI_SHOW_ALL_ERRORS) {
        return action.data;
      }

      return state;
    }

    return function r(state, action) {
      return {
        profile: profileReducer(state.profile, action),
        profileView: profileReducer(state.profileView, action),
        profileValidate: profileValidateReducer(state.profileValidate, action),
        ui: {
          showAllErrors: showAllErrorsReducer(state.showAllErrors, action)
        }
      };
    };
  }
]);

// Controller for updating user profile
controller('ProfileController', [
  'UserService',
  'ServiceErrorHandler',
  'Alert',
  'ProfileStore',
  'ProfileAction',
  'ProfileActionType',
  'userProfile',
  function(UserService, ServiceErrorHandler, Alert, ProfileStore, ProfileAction, ProfileActionType, userProfile) {
    var log = console.log.bind(console, '[ProfileController]');

    var checkUpdateProfile = checkStore.get('updateProfile');

    var self = this;

    self.props = {
      onProfilePartChange: onProfilePartChange,
      handleProfileFormSubmit: handleProfileFormSubmit,
      updateProfileSchema: schemaStore.get('updateProfile')
    };
    getApiKey()
      .then(data => (self.props.apiKeyText = data))
      .catch(angular.noop);

    self.state = {};

    ProfileStore.subscribe(function(state) {
      self.state.profile = state.profile;
      self.state.profileView = state.profileView;
      self.state.profileValidate = state.profileValidate;
      self.state.ui = state.ui;
    });

    function getApiKey() {
      return UserService.getApiKeyByUsermail()
        .then(res => {
          return res.data || '';
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    }

    function onProfilePartChange(user) {
      log('onProfilePartChange', user);
      var changeAction = ProfileAction.profileChange(user);
      ProfileStore.dispatch(changeAction);

      var check = checkUpdateProfile(user);
      var vaildateAction = ProfileAction.profileValidate(check);
      ProfileStore.dispatch(vaildateAction);
    }

    function handleProfileFormSubmit() {
      Alert.reset();

      var profile = ProfileStore.getState().profile;

      var invalidErrors = getCheckErrors(checkUpdateProfile, profile);

      if (invalidErrors.length) {
        var showAllErrorsAction = ProfileAction.showAllErrors(true);
        ProfileStore.dispatch(showAllErrorsAction);

        Alert.error(invalidErrors);
        return;
      }

      return UserService.updateUserProfile(profile)
        .then(function() {
          Alert.success('updateProfile');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    }

    (function loadProfileData(profile) {
      var initialStateAction = ProfileAction.profileChange(profile);
      ProfileStore.dispatch(initialStateAction);
    })(userProfile);
  }
]);
