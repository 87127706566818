'use strict';

var MAX_FEEDBACK_LENGTH = 2048;
var requiredString = require('./required-string');
var feedbackCategories = require('../const/feedback-categories');
var emailRegex = require('../const/email-regex');

function makeFeedbackSchema() {
  return {
    category: {
      $label: 'Category',
      $placeholder: 'Category',
      $options: feedbackCategories,
      checkFn: function(val) {
        return feedbackCategories.indexOf(val) !== -1;
      },
      $message: 'Please select a category.'
    },
    firstName: {
      $label: 'First Name',
      $placeholder: 'First Name',
      checkFn: requiredString,
      $message: 'Please provide your first name.'
    },
    lastName: {
      $label: 'Last Name',
      $placeholder: 'Last Name',
      checkFn: requiredString,
      $message: 'Please provide your last name.'
    },
    email: {
      $label: 'Email',
      $placeholder: 'Email',
      checkFn: requiredString,
      $message: 'Please provide your email.'
    },
    phone: {
      $label: 'Phone',
      $placeholder: 'Phone',
      checkFn: requiredString,
      $message: 'Please provide your phone number with area code.'
    },
    summary: {
      $label: 'Summary',
      $placeholder: 'Summary',
      checkFn: requiredString,
      $message: 'Please provide a summary.'
    },
    details: {
      $label: 'Details',
      $placeholder: 'Details (max. ' + MAX_FEEDBACK_LENGTH + ' characters)',
      $maxlength: MAX_FEEDBACK_LENGTH,
      checkFn: requiredString,
      $message: 'Please provide feedback details.'
    },
    _validEmail: {
      checkFn: function(_val, body, report) {
        var skip = true;

        return report.email.$valid ? emailRegex.test(body.email) : skip;
      },
      $message: 'Please provide a valid email adress'
    },
    _detailsMaxLength: {
      checkFn: function(_val, body, report) {
        var skip = true;

        return report.details.$valid ? body.details.length < MAX_FEEDBACK_LENGTH : skip;
      },
      $message: 'Details must be within ' + MAX_FEEDBACK_LENGTH + ' characters.'
    }
  };
}

module.exports = makeFeedbackSchema;
