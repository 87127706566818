import { controller } from './app';

controller(
  'ThankYouController',
  /* @ngInject */
  function ThankYouController($scope, $routeParams, $location, injectStripeJS) {
    var vm = $scope;
    vm.action = $routeParams.action.toLowerCase().split('_')[0];
    vm.showNameandLei = vm.action.toLowerCase() !== 'na';
    vm.trackId = $routeParams.trackId;
    vm.legalName = $routeParams.legalName;
    vm.lei = $routeParams.lei;
    vm.url = `/leis/entry/${vm.action}`;
    vm.location = function(url) {
      !url ? $location.url(vm.url) : $location.url(url);
    };
  }
);
