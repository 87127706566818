'use strict';

const HEADER_NAME = 'x-lei-access-level';

module.exports.HEADER_NAME = module.exports.ACCESS_HEADER = HEADER_NAME;

module.exports.OPEN = 0;
module.exports.LOGIN = 1;
module.exports.TWO_FACTOR = 2;
module.exports.ADMIN = 3;
