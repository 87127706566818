import angular from 'angular';
import { directive } from './app';

directive('recaptcha', [
  '$window',
  function($window) {
    return {
      restrict: 'E',
      scope: {
        siteKey: '@'
      },
      template: '<div id="recaptcha" ng-if="siteKey"><div class="g-recaptcha" data-sitekey="{{siteKey}}"></div></div>',
      link: function(scope, element, attrs) {
        scope.$watch('siteKey', function(val) {
          if (!val) {
            return;
          }

          injectRecaptcha();
        });

        function injectRecaptcha() {
          var recaptchaScript = document.createElement('script');
          recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
          element.append(angular.element(recaptchaScript));
        }
      }
    };
  }
]);
