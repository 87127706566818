'use strict';

var MapStore = require('../lib/map-store');

var schemaStore = new MapStore(
  function getSchemaCheck(key, map) {
    if (typeof map[key] !== 'object' || !map[key]) {
      throw new TypeError('Schema not found: ' + key);
    }
  },
  function saveSchemaCheck(key, val, map) {
    if (map[key]) {
      throw new Error('Schema already exists: ' + key);
    }

    if (typeof val !== 'object') {
      throw new TypeError('Should save a object schema: ' + key);
    }
  }
);

module.exports = schemaStore;
