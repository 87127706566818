'use strict';

var emailRegex = require('../const/email-regex');
var requiredStringCheckFn = require('./required-string');
var passwordSchema = require('./password-schema');

function makeLoginSchema(opts = {}) {
  return Object.assign(
    {
      username: {
        $label: 'Username',
        $placeholder: 'Your Institutional Account Username',
        checkFn: requiredStringCheckFn,
        $message: 'Please provide your username. NOTE: Usernames are case sensitive.'
      },
      email: {
        $label: 'Email',
        $placeholder: 'Your Email',
        checkFn: requiredStringCheckFn,
        $message: 'Please provide your email.'
      },
      _validEmail: {
        checkFn: function(_val, body, report) {
          var skip = true;

          if (!report.email.$valid) {
            return skip;
          }

          return emailRegex.test(body.email);
        },
        $message: 'Please provide a valid email.'
      }
    },
    passwordSchema.make(Object.assign(opts, { login: true }))
  );
}

module.exports = {
  client: makeLoginSchema(),
  server: makeLoginSchema({ server: true }),
  make: makeLoginSchema
};
