'use strict';

// include 27 EU countries and Indian VAT exp
// See https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html#validation-euvat-solution

export const clientValidateVAT = {
  validateByCountry: (vat_number, country) => {
    let VATreg;

    switch (country) {
      case 'AT': //Austria
        VATreg = new RegExp('^(AT)?U[0-9]{8}$');
        break;
      case 'BE': //Belgium
        VATreg = new RegExp('^(BE)?0[0-9]{9}$');
        break;
      case 'BG': //Bulgaria
        VATreg = new RegExp('^(BG)?[0-9]{9,10}$');
        break;
      case 'CY': //Cyprus
        VATreg = new RegExp('^(CY)?[0-9]{8}L$');
        break;
      case 'CZ': //Czech Republic
        VATreg = new RegExp('^(CZ)?[0-9]{8,10}$');
        break;
      case 'DE': //Germany
        VATreg = new RegExp('^(DE)?[0-9]{9}$');
        break;
      case 'DK': //Denmark
        VATreg = new RegExp('^(DK)?[0-9]{8}$');
        break;
      case 'EE': //Estonia
        VATreg = new RegExp('^(EE)?[0-9]{9}$');
        break;
      case 'EL': //Greece
        VATreg = new RegExp('^(EL)?[0-9]{9}$');
        break;
      case 'GR': //Greece
        VATreg = new RegExp('^(GR)?[0-9]{9}$');
        break;
      case 'ES': //Spain (exc. Canary Islands)
        VATreg = new RegExp('^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$');
        break;
      case 'FI': //Finland
        VATreg = new RegExp('^(FI)?[0-9]{8}$');
        break;
      case 'FR': //France
        VATreg = new RegExp('^(FR)?[0-9A-Z]{2}[0-9]{9}$');
        break;
      case 'GB': //Great Britain
        VATreg = new RegExp('^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$');
        break;
      case 'HU': //Hungary
        VATreg = new RegExp('^(HU)?[0-9]{8}$');
        break;
      case 'IE': //Ireland
        VATreg = new RegExp('^(HU)?[0-9]S[0-9]{5}L$');
        break;
      case 'IN': //India
        VATreg = new RegExp('^(IN)?[0-9]{2}[0-9A-Z]{13}$');
        break;
      case 'IT': //Italy
        VATreg = new RegExp('^(IT)?[0-9]{11}$');
        break;
      case 'LT': //Lithuania
        VATreg = new RegExp('^(LT)?([0-9]{9}|[0-9]{12})$');
        break;
      case 'LU': //Luxembourg
        VATreg = new RegExp('^(LU)?[0-9]{8}$');
        break;
      case 'LV': //Latvia
        VATreg = new RegExp('^(LV)?[0-9]{11}$');
        break;
      case 'MT': //Malta
        VATreg = new RegExp('^(MT)?[0-9]{8}$');
        break;
      case 'NL': //Netherlands
        VATreg = new RegExp('^(NL)?[0-9]{9}B[0-9]{2}$');
        break;
      case 'PL': //Poland
        VATreg = new RegExp('^(PL)?[0-9]{10}$');
        break;
      case 'PT': //Portugal
        VATreg = new RegExp('^(PT)?[0-9]{9}$');
        break;
      case 'RO': //Romania
        VATreg = new RegExp('^(RO)?[0-9]{2,10}$');
        break;
      case 'SE': //Sweden
        VATreg = new RegExp('^(SE)?[0-9]{12}$');
        break;
      case 'SI': //Slovenia
        VATreg = new RegExp('^(SI)?[0-9]{8}$');
        break;
      case 'SK': //Slovakia
        VATreg = new RegExp('^(SK)?[0-9]{10}$');
        break;
      case 'TH': //Thailand
        VATreg = new RegExp('^(TH)?[0-9A-Z]{13}$');
    }

    return VATreg ? VATreg.test(vat_number) : true;
  }
};
