'use strict';
var _ = require('lodash');

function asteriskLabel(schemaObj) {
  var label = schemaObj.$label;

  if (!label || !schemaObj.$required || label.indexOf('*') === 0) {
    return schemaObj;
  }

  return _.assign({}, schemaObj, { $label: '* ' + label });
}

function asteriskPlaceholder(schemaObj) {
  var placeholder = schemaObj.$placeholder;

  if (!placeholder || !schemaObj.$required || placeholder.indexOf('*') === 0) {
    return schemaObj;
  }

  return _.assign({}, schemaObj, { $placeholder: '* ' + placeholder });
}

function makeFn(options) {
  return function asteriskForWholeSchmea(schema) {
    var opts = options || {};
    var fn = asteriskLabel;

    if (opts.placeholder) {
      fn = asteriskPlaceholder;
    }

    var ret = {};

    Object.keys(schema).forEach(function(key) {
      var sch = schema[key];
      ret[key] = fn(sch);
    });

    return ret;
  };
}

module.exports.addon = makeFn();
module.exports.addon.placeholder = makeFn({ placeholder: true });
