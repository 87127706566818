'use strict';

const paymentAddressSchema = require('./payment-address-schema');

function makeAccountCardSchema() {
  const accountCardSchema = paymentAddressSchema();
  delete accountCardSchema.type;
  return accountCardSchema;
}

module.exports = makeAccountCardSchema;
