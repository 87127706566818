import { factory, config } from './app';

config([
  'localStorageServiceProvider',
  function(localStorageServiceProvider) {
    localStorageServiceProvider.setPrefix('localstorage-');
  }
]);

factory('TimedCache', [
  'localStorageService',
  function(localStorageService) {
    var DEFAULT_EXPIRE_TIME = 14 * 24 * 3600 * 1000;

    function get(cacheKey) {
      console.log('get: cacheKey =', cacheKey);
      return localStorageService.get(cacheKey);
    }

    function set(cacheKey, content, expireInMilliSeconds) {
      var expire_val = expireInMilliSeconds ? expireInMilliSeconds : DEFAULT_EXPIRE_TIME;
      var now = Date.now();
      var expireCacheKey = 'expire-' + cacheKey;

      console.log('set: expire at ', now + expire_val, ', cacheKey =', cacheKey, ', content =', content);
      localStorageService.set(expireCacheKey, now + expire_val);
      localStorageService.set(cacheKey, content);
    }

    function remove(cacheKey) {
      var expireCacheKey = 'expire-' + cacheKey;

      console.log('remove: cacheKey =', cacheKey);
      localStorageService.remove(expireCacheKey);
      localStorageService.remove(cacheKey);
    }

    function getExpireDate(cacheKey) {
      var expireCacheKey = 'expire-' + cacheKey;
      return localStorageService.get(expireCacheKey);
    }

    function allStorage() {
      return localStorageService.keys().filter(function(key) {
        if (key.indexOf('expire-') === -1) {
          return isKeyValid(key);
        }
        return false;
      });
    }

    function isKeyValid(cacheKey) {
      var now = Date.now();
      var expireCacheKey = 'expire-' + cacheKey;
      var expire = localStorageService.get(expireCacheKey);
      console.log('check: cacheKey =', cacheKey);
      if (expire && expire - now > 0) {
        console.log('cached! cacheKey =', cacheKey);
        return true;
      }

      console.log('not cached or expired, cacheKey =', cacheKey);
      localStorageService.remove(cacheKey);
      localStorageService.remove(expireCacheKey);
      return false;
    }

    return {
      get: get,
      set: set,
      isKeyValid: isKeyValid,
      remove: remove,
      allStorage: allStorage,
      getExpireDate: getExpireDate
    };
  }
]);
