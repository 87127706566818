'use strict';

module.exports.check = function(attachedFilesHash, attachmentsList) {
  var missingFiles = [];
  attachmentsList.forEach(function(attachments) {
    attachments &&
      attachments.forEach(function(fileName) {
        if (!attachedFilesHash[fileName]) {
          missingFiles.push(fileName);
        }
      });
  });
  return missingFiles;
};
