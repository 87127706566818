import { factory } from './app';
import { NO_CACHE } from '../utils/constants';

factory('AdminService', [
  '$http',
  'Upload',
  function($http, Upload) {
    return {
      ping: ping,
      saveNote: saveNote,
      sendMessage: sendMessage,
      activateAccount: activateAccount,
      deactivateAccount: deactivateAccount,
      getUser: getUser,
      getUsersByEmail: getUsersByEmail,
      getSubmission: getSubmission,
      getAutoRenew: getAutoRenew,
      addAccountEmail: addAccountEmail,
      removeAccountEmail: removeAccountEmail,
      bulkCreate: bulkCreate,
      getApiKeyByUsermail: getApiKeyByUsermail,
      generateNewApiKey: generateNewApiKey,
      removeApiKey: removeApiKey,
      validateExcelForUser: validateExcelForUser,
      ownershipTransfer: ownershipTransfer
    };

    function ping() {
      return $http({ method: 'GET', url: '/adm1n/ping', headers: NO_CACHE });
    }

    function activateAccount(username, note) {
      var options = {
        method: 'PUT',
        url: '/adm1n/account/activate/' + username,
        data: {
          note: note
        }
      };

      return $http(options);
    }

    function deactivateAccount(username, note) {
      var options = {
        method: 'PUT',
        url: '/adm1n/account/deactivate/' + username,
        data: {
          username: username,
          note: note
        }
      };

      return $http(options);
    }

    function sendMessage(username, message) {
      var options = {
        method: 'POST',
        url: '/adm1n/message/' + username,
        data: {
          message: message
        }
      };

      return $http(options);
    }

    function saveNote(username, note, sdsk) {
      var options = {
        method: 'POST',
        url: '/adm1n/note/' + username,
        data: {
          sdsk: sdsk,
          note: note
        }
      };

      return $http(options);
    }

    function getUser(username) {
      var options = {
        method: 'GET',
        url: '/adm1n/user/' + username,
        headers: NO_CACHE
      };

      return $http(options);
    }

    function getUsersByEmail(email) {
      var options = {
        method: 'GET',
        url: '/adm1n/usersByEmail/' + email,
        headers: NO_CACHE
      };

      return $http(options);
    }

    function getSubmission(submission) {
      if (!submission) {
        throw Error('Non-null value expected');
      }

      var options = {
        method: 'GET',
        url: '/adm1n/submission/' + encodeURI(submission)
      };

      return $http(options);
    }

    function getAutoRenew(username) {
      if (!username) {
        throw Error('Non-null value expected');
      }

      var options = {
        method: 'GET',
        url: '/adm1n/autoRenew/' + username
      };

      return $http(options);
    }

    function addAccountEmail(email, onBehalfOfUserName) {
      var options = {
        method: 'PUT',
        url: '/adm1n/account/emails',
        data: {
          email: email,
          onBehalfOfUserName: onBehalfOfUserName
        }
      };

      return $http(options);
    }

    function removeAccountEmail(email, onBehalfOfUserName) {
      var options = {
        method: 'POST',
        url: '/adm1n/account/emails/remove',
        data: {
          email: email,
          onBehalfOfUserName: onBehalfOfUserName
        }
      };

      return $http(options);
    }

    function getApiKeyByUsermail(username, email) {
      var options = {
        method: 'GET',
        url: '/adm1n/account/api-key',
        params: {
          username: username,
          email: email
        }
      };
      return $http(options);
    }

    function generateNewApiKey(username, email) {
      var options = {
        method: 'POST',
        url: '/adm1n/account/api-key',
        data: {
          username: username,
          email: email
        }
      };
      return $http(options);
    }

    function removeApiKey(username, email) {
      var options = {
        method: 'DELETE',
        url: '/adm1n/account/api-key',
        params: {
          username: username,
          email: email
        }
      };
      return $http(options);
    }

    function validateExcelForUser(file) {
      return Upload.upload({
        url: '/adm1n/validateExcelForUser',
        data: { file: file }
      });
    }

    function bulkCreate(payloads) {
      var options = {
        method: 'POST',
        url: '/adm1n/bulkCreate',
        data: {
          payloads: payloads
        }
      };

      return $http(options);
    }

    function ownershipTransfer(data) {
      var options = {
        method: 'POST',
        url: '/adm1n/ownershipTransfer',
        data: data
      };
      return $http(options);
    }
  }
]);
