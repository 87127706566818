'use strict';

var MapStore = require('../lib/map-store');

var checkStore = new MapStore(
  function getFnCheck(key, map) {
    if (typeof map[key] !== 'function') {
      throw new TypeError('Function not found: ' + key);
    }
  },
  function saveFnCheck(key, val, map) {
    if (map[key]) {
      throw new Error('Function already exists: ' + key);
    }

    if (typeof val !== 'function') {
      throw new TypeError('Should save a function: ' + key);
    }
  }
);

module.exports = checkStore;
