'use strict';

/*
 * map Stripe card source info to vm.payment model
 * vm.payment.shipping and billing are modeled after stripe shipping address
 */
module.exports.STRIPE_CARD_MAP = {
  name: 'cardHolderName',
  number: 'cardNumber',
  cvc: 'cvc',
  exp_month: 'expiryMonth',
  exp_year: 'expiryYear',
  address_country: 'billing.country',
  address_zip: 'billing.postal_code',
  address_city: 'billing.city',
  address_line1: 'billing.line1',
  address_line2: 'billing.line2',
  address_state: 'billing.state'
  // metadata has been deprecated
  // 'metadata.companyName': 'billing.company'
};

module.exports.STRIPE_BANK_MAP = {
  country: 'billing.country',
  currency: 'currency',
  account_holder_name: 'cardHolderName',
  account_holder_type: 'account_holder_type',
  routing_number: 'routing_number',
  account_number: 'account_number',
  address_zip: 'billing.postal_code',
  address_city: 'billing.city',
  address_line1: 'billing.line1',
  address_line2: 'billing.line2',
  address_state: 'billing.state'
};
