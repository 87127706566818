import angular from 'angular';
import { factory, controller } from '../app';

factory('SignupStore', [
  'ReduxStoreFactory',
  'SignupReducer',
  function(ReduxStoreFactory, SignupReducer) {
    var initialState = {
      profile: null,
      credential: null,
      credentialValidate: {},
      signupValidate: {},
      ui: {
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('SignupStore', SignupReducer, initialState);
  }
]);

factory('SignupActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['PROFILE_CHANGE', 'SIGNUP_VALIDATE', 'UI_SHOW_ALL_ERRORS']);
  }
]);

factory('SignupAction', [
  'SignupActionType',
  function(SignupActionType) {
    return {
      showAllErrors: function(showOrNot) {
        return {
          type: SignupActionType.UI_SHOW_ALL_ERRORS,
          data: !!showOrNot
        };
      },
      signupValidate: function(validateObj) {
        return {
          type: SignupActionType.SIGNUP_VALIDATE,
          data: validateObj
        };
      },
      profileChange: function(profileUpdatePart) {
        return {
          type: SignupActionType.PROFILE_CHANGE,
          data: profileUpdatePart
        };
      }
    };
  }
]);

factory('SignupReducer', [
  'SignupActionType',
  function(SignupActionType) {
    var assign = angular.extend;

    function assigner(actionType) {
      return function shallowCopyOnActionType(state, action) {
        if (action.type === actionType) {
          return assign({}, state, action.data);
        }

        return state;
      };
    }

    var profileReducer = assigner(SignupActionType.PROFILE_CHANGE);

    var signupValidateReduceer = assigner(SignupActionType.SIGNUP_VALIDATE);

    function showAllErrorsReducer(state, action) {
      if (action.type === SignupActionType.UI_SHOW_ALL_ERRORS) {
        return action.data;
      }

      return state;
    }

    return function r(state, action) {
      return {
        profile: profileReducer(state.profile, action),
        signupValidate: signupValidateReduceer(state.signupValidate, action),
        ui: {
          showAllErrors: showAllErrorsReducer(state.ui.showAllErrors, action)
        }
      };
    };
  }
]);

import { schemaStore, checkStore } from '../../../../common/validate';
import getCheckMessages from '../../../../common/lib/get-check-messages';
controller('SignUpController', [
  'UserService',
  'ServiceErrorHandler',
  'Alert',
  'Redirect',
  'recaptchaResult',
  'SignupStore',
  'SignupAction',
  function(UserService, ServiceErrorHandler, Alert, Redirect, recaptchaResult, SignupStore, SignupAction) {
    var log = console.log.bind(console, '[SignUpController]');
    var checkSignup = checkStore.get('signup');
    var signupGetCheckMessages = getCheckMessages.bind(null, checkSignup);

    var self = this;

    self.props = {};
    self.props.recaptchaResult = recaptchaResult;

    self.props.userProfileSchema = schemaStore.get('newProfile');
    self.props.userCredentialSchema = schemaStore.get('credential');

    self.state = {
      profile: null,
      profileView: null,
      disableSubmit: false
    };

    SignupStore.reset().subscribe(function(state) {
      self.state.profile = state.profile;
      self.state.ui = state.ui;
      self.state.signupValidate = state.signupValidate;
    });

    self.props.onProfilePartChange = function onProfilePartChange(profile) {
      log('onProfilePartChange', profile);
      var action = SignupAction.profileChange(profile);
      SignupStore.dispatch(action);

      var check = checkSignup(profile);
      var validateAction = SignupAction.signupValidate(check);
      SignupStore.dispatch(validateAction);
    };

    self.props.handleSignup = function handleSignup() {
      self.state.disableSubmit = true;
      var profile = SignupStore.getState().profile;

      var check = checkSignup(profile);
      var validateAction = SignupAction.signupValidate(check);
      SignupStore.dispatch(validateAction);

      var showAllErrorsAction = SignupAction.showAllErrors(true);
      SignupStore.dispatch(showAllErrorsAction);

      var signupValidationErrorMessages = signupGetCheckMessages(profile);

      if (signupValidationErrorMessages.length) {
        self.state.disableSubmit = false;
        return Alert.error(signupValidationErrorMessages);
      }

      const grecaptcha = window.grecaptcha;

      if (!grecaptcha) {
        self.state.disableSubmit = false;
        return Alert.error('recaptchaRefresh');
      }

      let captchaResponse = grecaptcha.getResponse();

      if (!captchaResponse) {
        grecaptcha.reset();
        self.state.disableSubmit = false;
        return Alert.error('recaptcha');
      }

      return UserService.signUp(profile, captchaResponse)
        .then(function() {
          Redirect.loginAfterSignup();
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop)
        .finally(() => {
          grecaptcha.reset();
          self.state.disableSubmit = false;
        });
    };
  }
]);
