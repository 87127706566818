import angular from 'angular';
import { factory, controller } from '../app';
import { checkStore, schemaStore } from '../../../../common/validate';

factory('ForgotUsernameStore', [
  'ReduxStoreFactory',
  'ForgotUsernameReducer',
  function(ReduxStoreFactory, ForgotUsernameReducer) {
    var initialState = {
      model: {
        email: null
      },
      validate: {},
      ui: {
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('ForgotUsernameStore', ForgotUsernameReducer, initialState);
  }
]);

factory('ForgotUsernameActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['UPDATE', 'VALIDATE', 'UI_SHOW_ALL_ERRORS']);
  }
]);

factory('ForgotUsernameAction', [
  'ForgotUsernameActionType',
  function(ForgotUsernameActionType) {
    return {
      showAllErrors: function(showOrNot) {
        return {
          type: ForgotUsernameActionType.UI_SHOW_ALL_ERRORS,
          data: !!showOrNot
        };
      },
      update: function(vm) {
        return {
          type: ForgotUsernameActionType.UPDATE,
          data: vm
        };
      },
      validate: function(validateObj) {
        return {
          type: ForgotUsernameActionType.VALIDATE,
          data: validateObj
        };
      }
    };
  }
]);

factory('ForgotUsernameReducer', [
  'ForgotUsernameActionType',
  function(ForgotUsernameActionType) {
    var assign = angular.extend;

    function modelReducer(state, action) {
      if (action.type === ForgotUsernameActionType.UPDATE) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function validateReducer(state, action) {
      if (action.type === ForgotUsernameActionType.VALIDATE) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function showAllErrorsReducer(state, action) {
      if (action.type === ForgotUsernameActionType.UI_SHOW_ALL_ERRORS) {
        return action.data;
      }

      return state;
    }

    return function r(state, action) {
      return {
        model: modelReducer(state.model, action),
        validate: validateReducer(state.validate, action),
        ui: {
          showAllErrors: showAllErrorsReducer(state.ui.showAllErrors, action)
        }
      };
    };
  }
]);

controller('ForgotUsernameController', [
  'recaptchaResult',
  'UserService',
  'ServiceErrorHandler',
  'ForgotUsernameStore',
  'ForgotUsernameAction',
  'Redirect',
  function(recaptchaResult, UserService, ServiceErrorHandler, ForgotUsernameStore, ForgotUsernameAction, Redirect) {
    var checkForgotUsername = checkStore.get('forgotUsername');

    var self = this;

    self.props = {};
    self.props.recaptchaResult = recaptchaResult;
    self.props.forgotUsernameSchema = schemaStore.get('forgotUsername');

    self.state = angular.extend({}, ForgotUsernameStore.getState(), {
      vm: {}
    });

    ForgotUsernameStore.reset().subscribe(function(state) {
      self.state.model = state.model;
      self.state.validate = state.validate;
      self.state.ui = state.ui;
    });

    self.props.handleForgotUsernameChange = function handleForgotUsernameChange() {
      var vm = self.state.vm;
      var check = checkForgotUsername(vm);

      ForgotUsernameStore.dispatch(ForgotUsernameAction.validate(check));
      if (!check.$valid) {
        return;
      }
      ForgotUsernameStore.dispatch(ForgotUsernameAction.update(self.state.vm));
    };

    self.props.handleForgotUsername = function handleForgotUsername() {
      var model = ForgotUsernameStore.getState().model;

      return UserService.handleForgotUsername(model.email)
        .then(Redirect.loginAfterSentUsername)
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };
  }
]);
