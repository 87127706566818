import _ from 'lodash';
import { controller } from './app';

controller('LeiViewerController', [
  '$scope',
  'label',
  'id',
  'data',
  'humanId',
  function($scope, label, id, data, humanId) {
    $scope.title = label + ': ' + (humanId || id);
    $scope.leiViewerData = customizeViewer(data);

    function customizeViewer(payload) {
      // clean events fields
      const events = _.get(payload, 'entity.legalEntityEvents.legalEntityEvent', []);

      const customEvents = filterAndSortEvents(events).map((evt, idx) => ({
        [`legalEntityEvent#${idx + 1}`]: {
          eventType: evt.legalEntityEventType,
          effectiveDate: evt.legalEntityEventEffectiveDate,
          recordedDate: evt.legalEntityEventRecordedDate,
          validationDocuments: evt.validationDocuments,
          eventStatus: evt.legalEntityEventStatus
        }
      }));

      _.set(payload, 'entity.legalEntityEvents.legalEntityEvent', undefined);
      _.set(payload, 'entity.legalEntityEvents', customEvents);

      return payload;
    }

    function filterAndSortEvents(events) {
      // in-progress with completed/withdrawn related events
      const doneLineageIds = new Set();

      events.forEach(evt => {
        if (['COMPLETED', 'WITHDRAWN_CANCELLED'].includes(evt.legalEntityEventStatus) && evt.lineageId) {
          doneLineageIds.add(evt.lineageId);
        }
      });

      const eventsToShow = events.filter(evt => {
        // keep completed events
        if (evt.legalEntityEventStatus === 'COMPLETED') return true;

        // remove withdrawn-cancelled events
        if (evt.legalEntityEventStatus === 'WITHDRAWN_CANCELLED') return false;

        // keep in-progress that haven't been paired yet
        return !doneLineageIds.has(evt.lineageId);
      });

      // sort by recorded-date (newest date on the top)
      eventsToShow.sort((evt1, evt2) => {
        return !!evt2.legalEntityEventRecordedDate && !!evt1.legalEntityEventRecordedDate
          ? new Date(evt2.legalEntityEventRecordedDate) - new Date(evt1.legalEntityEventRecordedDate)
          : -1;
      });

      return eventsToShow;
    }
  }
]);
