'use strict';

// Australia: 8 L1 subdivisions
module.exports = [
  { abbr: 'NSW', name: 'New South Wales' },
  { abbr: 'WA', name: 'Western Australia' },
  { abbr: 'NT', name: 'Northern Territory' },
  { abbr: 'VIC', name: 'Victoria' },
  { abbr: 'TAS', name: 'Tasmania' },
  { abbr: 'ACT', name: 'Australian Capital Territory' },
  { abbr: 'SA', name: 'South Australia' },
  { abbr: 'QLD', name: 'Queensland' }
];
