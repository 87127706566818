'use strict';
const { TAX_ENTITY_CATEGORY_REGEX } = require('../const/tax-entity-category-mappings');
/*
 * pricing engine for LEI products
 */

const moment = require('./moment'),
  ValidationError = require('./validation-error'),
  leiProducts = require('../const/lei-products'),
  countriesVatRequired = require('../const/countries-vat-required');

const LAPSED_STATUS = 'LAPSED';

const {
  ACTION: LEI_ACTION,
  ENTITY_TYPES: { BUSINESS: BUSINESS_ENTITY }
} = leiProducts;

/*
 * months between last renew date or init reg date AND today
 */
function monthsAfter(renewDateUTCString) {
  const submissionDate = new Date();
  const renewDate = moment.addYears(new Date(renewDateUTCString), -1);
  return moment.getMonthsBetween(renewDate, submissionDate);
}

function renewPrice(renewDateUTCString, status, hasDiscount, currency, billingClass) {
  const pricingTable = leiProducts[billingClass + 'RENEWLEI_PRICING'];
  const defaultPricingTable = leiProducts['RENEWLEI_PRICING'];
  const priceTable = hasDiscount || billingClass != 'BULK' ? pricingTable[currency] : defaultPricingTable[currency];
  return status === LAPSED_STATUS ? priceTable[12] : priceTable[Math.min(12, monthsAfter(renewDateUTCString))];
}
module.exports.renewPrice = renewPrice;

/**
 * Calculates the next date of renewal
 * @param {string} type - Type of the workitem
 * @param {Date} renewDate - Date of renewal
 * @param {?Date} [referenceDate=null] - Date of sumission/acceptance
 * @returns {Date}
 */
function nextRenewDate(type, renewDate, referenceDate) {
  let result = renewDate;

  if (type === 'CREATE' || type === LEI_ACTION.NEW)
    // Everything is named create but the internals
    result = moment.addMonths(referenceDate, 12);
  else if (type === LEI_ACTION.TRANSFERRENEW || type === LEI_ACTION.RENEW) {
    const monthsUsed = moment.getMonthsBetween(moment.addMonths(renewDate, -12), referenceDate);
    result =
      monthsUsed >= 12
        ? moment.addMonths(Math.max(referenceDate, renewDate), 12)
        : moment.addMonths(renewDate, monthsUsed);
  } else throw new ValidationError(`Invalid action type ${type} given.`);

  return result;
}
module.exports.nextRenewDate = nextRenewDate;

function totalPrice(type, isCart, billingClass, count, submissionMeta, taxRate, currency, hasDiscount) {
  return totalPriceWithLog({ type, isCart, billingClass, count, submissionMeta, taxRate, currency, hasDiscount });
}
module.exports.totalPrice = totalPrice;

function totalPriceWithLog(
  { type, isCart, billingClass, count, submissionMeta, taxRate, currency, hasDiscount },
  logger = console
) {
  currency = currency || 'USD';
  var totalInCent, taxInCent, incrementalPrice, incrementalDiscountPrice, taxInCentUnit, totalBaseInCent;

  // bulk - pricing from lei-products.js that begins with BULK* (ex: BULKNEWLEI)
  // isMixed - cart containing different types of cart items (ex: Creates & Renews)
  var productInfo = leiProducts[billingClass + type + 'LEI'].PRICING[currency];
  hasDiscount = hasDiscount || count >= productInfo.minimum;
  var base = hasDiscount ? productInfo.productDiscountPriceInCent : productInfo.productPriceInCent;

  var bulkRenewTotalInCentUnit = [],
    bulkRenewTaxInCentUnit = [],
    bulkRenewBaseInCentUnit = [],
    bulkRenewTaxRate = [];

  if (type === LEI_ACTION.RENEW || type === LEI_ACTION.TRANSFERRENEW) {
    base = 0;
    incrementalPrice = 0;
    incrementalDiscountPrice = 0;
    if (isCart) {
      submissionMeta.renewDate.forEach(function(date, index) {
        const _incrementalPrice = renewPrice(date, submissionMeta.leiStatus[index], false, currency, billingClass);
        const _incrementalDiscountPrice = renewPrice(
          date,
          submissionMeta.leiStatus[index],
          true,
          currency,
          billingClass
        );

        if (!_incrementalPrice && logger) {
          const pricingTable = leiProducts[billingClass + 'RENEWLEI_PRICING'];
          const priceTable = pricingTable[currency];

          logger.error('Error: incrementalPrice is undefined:', {
            renewDateUTCString: date,
            status: submissionMeta.leiStatus[index],
            hasDiscount: false,
            currency,
            monthsAfter: monthsAfter(date),
            priceTable,
            _incrementalPrice
          });
        }

        incrementalPrice += _incrementalPrice.price;
        incrementalDiscountPrice += _incrementalDiscountPrice.price;

        var _incrementalActualPrice = hasDiscount ? _incrementalDiscountPrice : _incrementalPrice;
        var renewTax = Math.round((taxRate * _incrementalActualPrice.priceInCent) / (1000 * 100));
        base += _incrementalActualPrice.priceInCent;

        bulkRenewTaxInCentUnit.push(renewTax);
        bulkRenewTotalInCentUnit.push(_incrementalActualPrice.priceInCent + renewTax);
        bulkRenewBaseInCentUnit.push(_incrementalActualPrice.priceInCent);
        bulkRenewTaxRate.push(taxRate);
      });

      taxInCent = Math.round((taxRate * base) / (1000 * 100));
      totalInCent = base + taxInCent;
      totalBaseInCent = base;
    } else {
      const _incrementalPrice = renewPrice(
        submissionMeta.renewDate,
        submissionMeta.leiStatus,
        hasDiscount, // ENG4LEI-1201 - Monthly accounts should get bulk discount
        currency,
        billingClass
      );
      base = _incrementalPrice.priceInCent;
      incrementalPrice = _incrementalPrice.price;
      incrementalDiscountPrice = _incrementalPrice.price;
    }
  }

  if (!totalInCent) {
    taxInCentUnit = Math.round((taxRate * base) / (1000 * 100));
    taxInCent = taxInCentUnit * count;
    totalInCent = base * count + taxInCent;
    totalBaseInCent = base * count;
  }

  const total = {
    totalInCent: totalInCent,
    taxInCent: taxInCent,
    totalBaseInCent: totalBaseInCent,
    incrementalPrice: incrementalPrice,
    incrementalDiscountPrice: incrementalDiscountPrice,
    taxRate: taxRate
  };

  const emptyArray = Array.from({ length: count });

  if (isCart) {
    if (type === LEI_ACTION.RENEW || type === LEI_ACTION.TRANSFERRENEW) {
      total.totalInCentUnit = bulkRenewTotalInCentUnit;
      total.taxInCentUnit = bulkRenewTaxInCentUnit;
      total.baseInCentUnit = bulkRenewBaseInCentUnit;
      total.taxRate = bulkRenewTaxRate;
    } else {
      total.totalInCentUnit = emptyArray.map(function() {
        return base + taxInCentUnit;
      });

      total.taxInCentUnit = emptyArray.map(function() {
        return taxInCentUnit;
      });

      total.baseInCentUnit = emptyArray.map(function() {
        return base;
      });

      total.taxRate = emptyArray.map(function() {
        return taxRate;
      });
    }
  } else {
    total.totalInCentUnit = base + taxInCentUnit;
    total.taxInCentUnit = taxInCentUnit;
    total.baseInCentUnit = base;
    total.taxRate = taxRate;
  }

  return total;
}
module.exports.totalPriceWithLog = totalPriceWithLog;

function vatRequired(entity, countryCode) {
  // ENG4LEI-1368 - Ghanan requires VAT for all categories
  if (Object.keys(TAX_ENTITY_CATEGORY_REGEX).includes(countryCode) || entity == BUSINESS_ENTITY) {
    return countriesVatRequired.indexOf(countryCode) != -1;
  }

  return false;
}
module.exports.vatRequired = vatRequired;

const COUNTRY_VAT_MSG_MAPPING = {
  IN:
    'Please provide your 15-digit GSTIN number, with the first 2 digits of your GSTIN matching with the state code of your Customer address. In case of SEZ unit GSTIN, we would be billing with IGST. Further, we would request you to note the GST paid against the invoice raised would not be refunded by Bloomberg.',
  TH:
    'Please provide 13-digits Tax ID number. Your firm will be considered as B2C if you do not have a domestic tax number.'
};

function vatCountryWarning(countryCode) {
  if (countryCode && Object.keys(COUNTRY_VAT_MSG_MAPPING).includes(countryCode)) {
    return COUNTRY_VAT_MSG_MAPPING[countryCode];
  }
  return '';
}

module.exports.vatCountryWarning = vatCountryWarning;
