'use strict';

const { months } = require('../lib/moment');

var base = {
  paymentType: {
    $label: 'Payment Type',
    $placeholder: 'Select',
    $message: 'Please select payment type.',
    $required: true
  },

  validDate: {
    $label: 'renewal date',
    $placeholder: 'Provide renewal date (mm/dd)',
    $message: 'Please provide a valid renewal date (mm/dd).',
    checkFn: function(val, body) {
      // when both month and year are null/undefined/NaN return true (credit card update)
      if ((body.month === null || isNaN(body.month)) && (body.day === null || isNaN(body.day))) return true;

      const month = +body.month - 1;
      const day = +body.day;

      return !isNaN(month) && !isNaN(day) && day > 0 && months[month] && day <= months[month].days;
    }
  },

  card: {
    $label: 'Credit Card',
    $placeholder: 'Select Credit Card',
    $message: 'Please select credit card.',
    checkFn: function(val, body) {
      return body.paymentType == 'BILLME' || val;
    }
  },

  noFeb29: {
    $label: 'renewal date',
    $placeholder: 'Provide renewal date (mm/dd)',
    $message: "Renewal date can't be Feburary 29.",
    checkFn: function(val, body) {
      return !(body.month == 2 && body.day == 29);
    }
  },

  month: {
    $label: 'Month',
    $placeholder: 'Select month',
    $message: 'Please select month.'
  },

  day: {
    $label: 'Day',
    $placeholder: 'Select day',
    $message: 'Please select day.'
  },

  vat: {
    $label: 'VAT/GST',
    $placeholder: 'Domestic Tax Number',
    $message: 'Please provide VAT/GST number.',
    $required: false
  }
};

module.exports.single = Object.assign({}, base, {
  lei: {
    $label: 'LEI',
    $placeholder: 'Provide LEI',
    $required: true,
    $message: 'Please provide LEI.'
  }
});

module.exports.batch = Object.assign({}, base, {
  leis: {
    $label: 'LEIs',
    $placeholder: 'Provide comma-separated list of LEIs',
    $required: true,
    $message: 'Please provide comma-separated list of LEIs.'
  }
});

module.exports.all = base;
