import { ACTION as LEI_ACTION } from '../../../common/const/lei-products';

export function getBaseActionUrl(type, finalId) {
  var routeConfigs = {
    [LEI_ACTION.NEW]: { route: 'new', requiredId: false },
    [LEI_ACTION.RENEW]: { route: 'renew', requiredId: true },
    [LEI_ACTION.UPDATE]: { route: 'update', requiredId: true },
    [LEI_ACTION.TRANSFER]: { route: 'transfer', requiredId: true },
    [LEI_ACTION.CHALLENGE]: { route: 'challenge', requiredId: true }
  };
  routeConfigs['TRANSFER_RENEW'] = routeConfigs['TRANSFER'];
  if (Object.keys(routeConfigs).indexOf(type) === -1) return '';
  var url = '/leis/' + routeConfigs[type].route;
  if (routeConfigs[type].requiredId) {
    url += '/' + finalId;
  }
  return url;
}
