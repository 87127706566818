import angular from 'angular';
import { controller } from '../app';
import { schemaStore, checkStore } from '../../../../common/validate';

controller('AdminSubmissionController', [
  'AdminService',
  'ServiceErrorHandler',
  function(AdminService, ServiceErrorHandler) {
    var checkSearchSubmissionId = checkStore.get('searchSubmissionId');

    var self = this;

    self.state = {
      vm: {},
      model: {},
      validate: {}
    };

    self.props = {};

    self.props.searchSubmissionIdSchema = schemaStore.get('searchSubmissionId');

    self.props.handleSearchSubmissionIdChange = function handleSearchSubmissionIdChange() {
      var submissionIdInput = {
        submissionId: self.state.vm.submissionId
      };
      var check = checkSearchSubmissionId(submissionIdInput);
      self.state.validate = check;

      if (!check.$valid) {
        return;
      }

      self.state.submissionId = self.state.vm.submissionId;
    };

    self.props.handleSearchSubmissionIdSubmit = function handleSearchSubmissionIdSubmit() {
      var submissionId = self.state.submissionId;

      return AdminService.getSubmission(submissionId)
        .then(function(res) {
          if (res && res.data && !angular.isArray(res.data)) {
            res.data = [res.data];
          }
          self.state.submissions = res.data;
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };
  }
]);
