'use strict';
const requiredStringCheckFn = require('./required-string');
const passwordRegex = require('../const/password-regex');

const checkHashedPassword = val => requiredStringCheckFn(val) && val.length === 128;

function makePasswordSchema(opts) {
  const password = {
    $label: 'Password',
    $placeholder: 'Password',
    $required: true,
    $message: opts.login
      ? 'Please provide your password.'
      : 'Please provide a valid password. Password must be at least 8 characters, and must contain all of: (1) letter from A-Z, (2) letter from a-z, (3) number from 0-9, (4) special character from ! @ . # $ % ^ & * ( ) _ +'
  };

  if (opts.server) password.checkFn = checkHashedPassword;
  else if (opts.login) password.checkFn = requiredStringCheckFn;
  else password.$pattern = passwordRegex;

  return { password };
}

module.exports = {
  checkHashedPassword,
  make: makePasswordSchema
};
