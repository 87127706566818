import { factory } from './app';

factory('ValidationService', [
  '$http',
  function($http) {
    return {
      validateAddress: validateAddress
    };

    function validateAddress(address) {
      var options = {
        method: 'POST',
        url: '/validate/address/',
        data: {
          address: address
        }
      };

      return $http(options);
    }
  }
]);
