import angular from 'angular';
import _, { get } from 'lodash';
import { service } from './app';
import { Merger, makeSimpleServerUID } from '@dtwebservices/react-bbds-form';
import {
  ACTION,
  exceptionReasonsToRemove,
  getAction,
  mustBeRenewed,
  preprocess,
  addContactFromProfile
} from './utilities';
import { DS_NAME } from './utilities/shared';
import {
  applyLineageIdsToGroupedEvents,
  groupEventsByType,
  sortEventsByRecordedDate
} from '../../../../common/lib/common-events-util';

export default class FormService {
  /* @ngInject */
  constructor(
    $exceptionHandler,
    $http,
    $location,
    $q,
    Alert,
    DefaultErrorHandler,
    DraftCacheKey,
    DraftService,
    LeiService,
    UserService
  ) {
    this.$exceptionHandler = $exceptionHandler;
    this.$http = $http;
    this.$location = $location;
    this.$q = $q;
    this.DraftCacheKey = DraftCacheKey;
    this.Alert = Alert;
    this.DefaultErrorHandler = DefaultErrorHandler;
    this.DraftService = DraftService;
    this.LeiService = LeiService;
    this.UserService = UserService;
  }

  _getCacheKey(state, isManual) {
    const action = state.isTransfer ? ACTION.TRANSFER : getAction(state),
      getKey = this.DraftCacheKey.bind(null, state.email, action);

    let key;

    // not new
    if (!state.isNew) key = getKey(state.id);
    // overwrite manual save draft
    else if (state.draft) key = getKey(state.draft);
    // create autosave save draft for new
    else if (!isManual) key = getKey();
    // create manual save draft
    else {
      state.draft = Math.floor(new Date().getTime() / 1000).toString();
      key = getKey(state.draft);
    }

    return key;
  }

  error(state, error, info) {
    return this.$exceptionHandler(error, info);
  }

  hasAutoRenew(lei) {
    return this.UserService.getAutoRenewalByLei(lei).then(
      res => !!res.data.length,
      () => false
    );
  }

  _datamodel() {
    return this.$http.get('/form').then(res => res.data);
  }

  _getPayloadFromState(state) {
    const cachedSchemaData = state.step === '0' ? this.LeiService.leiPayLoad() : null;

    if (state.draft !== undefined) {
      // if draft is empty, autosaved
      const key = this._getCacheKey(state, Boolean(state.draft));
      return this.DraftService.decryptAndGetDraft(key)
        .then(res => {
          state.available = null;
          return res;
        })
        .catch(error => {
          this.Alert.error(error == 'draftExpire' ? error : 'loadDraft');
          return this.$q.reject(error);
        });
    }
    if (cachedSchemaData) return this.$q.resolve({ data: cachedSchemaData });
    if (state.isClone || state.isCart) return this.UserService.getLeiSubmission(state.submission, true);

    return this.$q.reject();
  }

  _payload(state) {
    const promise = function() {
      // new
      if (state.isNew) return this.$q.resolve({});

      // transfer, transferRenew
      if (state.isTransfer)
        return this.LeiService.getGleifLei(state.id, state.action).then(res =>
          this.UserService.getUserProfile().then(
            async ({ data }) => {
              console.log('service.js> _payload() before applyLineageIdsToGroupedEvents() data:', data);
              const legalEntityEventList = get(res.data, ['data', 'entity', 'legalEntityEvents', 'legalEntityEvent']);

              if (legalEntityEventList && legalEntityEventList.length) {
                const sortedEvents = sortEventsByRecordedDate(
                  get(res.data, ['data', 'entity', 'legalEntityEvents', 'legalEntityEvent'])
                );
                const groupedEventsByType = groupEventsByType(sortedEvents);
                await applyLineageIdsToGroupedEvents(groupedEventsByType, this.LeiService.getEventIds);
                console.log('service.js> _payload() after applyLineageIdsToGroupedEvents() data:', res.data);
              }

              addContactFromProfile(data, res.data);
              return res;
            },
            () => res
          )
        );

      // update, renew
      return this.LeiService.getLeiForForm(state.id, state.action);
    }.call(this);

    return promise.then(({ data }) =>
      this._getPayloadFromState(state)
        .then(
          res => [data, res.data],
          () => [data]
        )
        .then(args => preprocess(...args))
    );
  }

  _customizeStoreAndModel(store, model) {
    /*
     * events
     */
    _.set(store, ['data', 'data|entity|legalEntityEvents|legalEntityEvent', 'state'], undefined);

    const events = _.get(model, ['data', 'entity', 'legalEntityEvents', 'legalEntityEvent'], undefined);
    if (!events || events.length == 0) _.set(model, ['data', 'entity', 'legalEntityEvents'], undefined);

    /*
     * exception reasons
     * remove no-longer-needed options
     */
    const parents = ['directParent', 'ultimateParent'];
    const exceptionPath = `${DS_NAME}.ParentInformationNotProvided|exceptionReasons`;
    const directExceptionReasons = _.get(
      store,
      ['data', `data|relationships|${parents[0]}|${exceptionPath}`, 'base', '0|reason', 'options'],
      []
    );
    const ultimateExceptionReasons = _.get(
      store,
      ['data', `data|relationships|${parents[1]}|${exceptionPath}`, 'base', '0|reason', 'options'],
      []
    );

    const modelDirectExceptionPath = [
      'data',
      'relationships',
      'directParent',
      `${DS_NAME}.ParentInformationNotProvided`,
      'exceptionReasons'
    ];
    const modelDirectExceptionReasons = _.get(model, modelDirectExceptionPath, []);

    if (modelDirectExceptionReasons.length === 0) {
      _.set(model, modelDirectExceptionPath, [{ reason: 'NON_CONSOLIDATING' }]);
    }

    let index = -1;

    for (let reason of exceptionReasonsToRemove) {
      index = directExceptionReasons.findIndex(({ key }) => key === reason);
      if (index >= 0) directExceptionReasons.splice(index, 1); // remove item

      index = ultimateExceptionReasons.findIndex(({ key }) => key === reason);
      if (index >= 0) ultimateExceptionReasons.splice(index, 1); // remove item
    }
  }

  init(state) {
    return this.$q
      .all([this._datamodel(), this._payload(state)])
      .then(([store, model]) => {
        this._customizeStoreAndModel(store, model);

        // get lapsed state
        state.isLapsed = mustBeRenewed(model);

        // Restore key for cart edit
        _.set(model, 'key.submissionId', state.isCart ? state.submission : null);

        const merger = new Merger({ model, UID: makeSimpleServerUID() });
        merger.transform(store);

        return store;
      })
      .catch(this.DefaultErrorHandler);
  }

  /**
   * Obtains the latest draft key ID.
   */
  getDraft(state) {
    const lastKey = state.isNew
      ? this.DraftService.getLatestDraftkeyForAction(ACTION.NEW, state.email)
      : this._getCacheKey(state);

    if (lastKey == undefined || !this.DraftService.hasDraft(lastKey)) return null;

    // Key format is EMAIL_ACTION for autosaves and EMAIL_ACTION_ID otherwise.
    // Obtain ID (empty string if null)
    return lastKey.split('_')[2] || '';
  }

  onDerive(fields, model, callback) {
    const opts = {
      method: 'POST',
      url: '/form/derive',
      data: { model, fields }
    };

    return this.$http(opts)
      .then(res => callback(res.data), this.DefaultErrorHandler)
      .catch(angular.noop);
  }

  _removeDraft(state, isManual) {
    const key = this._getCacheKey(state, isManual);
    return this.DraftService.removeDraft(key);
  }

  saveDraft(payload, state, isManual) {
    if (state.isNew && isManual) this._removeDraft(state, false);
    if (!state.dirty || !payload) return this.$q.resolve();
    if (Object.prototype.hasOwnProperty.call(payload, state.schema)) payload = payload[state.schema];
    const key = this._getCacheKey(state, isManual);
    return this.DraftService.encryptAndSaveDraft(payload, key, !isManual).then(() => {
      state.dirty = false;
    });
  }

  submit(state, ...args) {
    this.LeiService.leiPayLoad(args[0] == null ? null : Object.values(args[0])[0]);
    return this.LeiService[state.action](...args).catch(this.DefaultErrorHandler);
  }
}

service('FormService', FormService);
