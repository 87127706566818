'use strict';

const checkStore = require('./check-store');
var schemaStore = require('./schema-store');
var makeChecker = require('../lib/make-checkers');
var checkProps = require('@dtwebservices/check-props');
var asteriskPlaceholder = require('../lib/asterisk-label').addon.placeholder;
var globalMaxLength = require('./global-max-length').addon;

function produceSchema(schema /* , adders...*/) {
  var adders = Array.prototype.slice.call(arguments, 1);

  return adders.reduce(function(state, action) {
    return action(state);
  }, schema);
}

var makeRequiredStringSchema = require('./required-string-schema');

var makeUserProfileSchema = require('./user-profile-schema');
var makeUserCredentialSchema = require('./user-credential-schema');

var makeResetPasswordSchema = require('./reset-password-schema');
var makeUsernameEmailSchema = require('./username-email-schema');
var makeForgotUsernameSchema = require('./forgot-username-schema');

var makeLoginSchema = require('./login-schema');
var makeTwoFactorCodeSchema = require('./two-factor-code-schema');
var makeUnlockAccountSchema = require('./unlock-account-schema');
var makeFeedbackSchema = require('./feedback-schema');
var makeAdminNoteSchema = require('./admin-note-schema');
var makeAdminMessageSchema = require('./admin-message-schema');
var makeCustomerAgreementSchema = require('./customer-agreement-schema');
var makeTransferWaiverSchema = require('./transfer-waiver-schema');
var makePaymentAddressSchema = require('./payment-address-schema');
var makeAccountCardSchema = require('./account-card-schema');
var autoRenewSchema = require('./auto-renew-schema');
var ownershipTransferSchema = require('./ownership-transfer-schema');
var usernameOrLEISchema = require('./username-lei-schema');

var updateProfileSchema = produceSchema(makeUserProfileSchema('update'), asteriskPlaceholder, globalMaxLength);

var newProfileSchema = produceSchema(makeUserProfileSchema('new'), asteriskPlaceholder, globalMaxLength);

var credentialSchema = produceSchema(makeUserCredentialSchema.client, asteriskPlaceholder, globalMaxLength);
var credentialServerSchema = produceSchema(makeUserCredentialSchema.server, asteriskPlaceholder, globalMaxLength);

var resetPasswordSchema = produceSchema(makeResetPasswordSchema.client, globalMaxLength);
var forgotUsernameSchema = produceSchema(makeForgotUsernameSchema(), globalMaxLength);
var resetPasswordServerSchema = produceSchema(makeResetPasswordSchema.server, globalMaxLength);
var forgotPasswordSchema = produceSchema(makeUsernameEmailSchema(), globalMaxLength);
var usernameEmailSchema = produceSchema(makeUsernameEmailSchema(), globalMaxLength);

var makeAccountAddEmailSchema = require('./user-account-emails-schema').makeAccountAddEmailSchema;
var makeAccountRemoveEmailSchema = require('./user-account-emails-schema').makeAccountRemoveEmailSchema;

var accountAddEmailSchema = produceSchema(makeAccountAddEmailSchema(), globalMaxLength);
var accountRemoveEmailSchema = produceSchema(makeAccountRemoveEmailSchema(), globalMaxLength);

var loginSchema = produceSchema(makeLoginSchema.client, globalMaxLength);
var loginServerSchema = produceSchema(makeLoginSchema.server, globalMaxLength);
var twoFactorCodeSchema = produceSchema(makeTwoFactorCodeSchema(), globalMaxLength);
var unlockAccountSchema = produceSchema(makeUnlockAccountSchema(), globalMaxLength);
var unlockAccountServerSchema = produceSchema(makeUnlockAccountSchema.server(), globalMaxLength);
var feedbackSchema = produceSchema(makeFeedbackSchema(), globalMaxLength);
var adminNoteSchema = produceSchema(makeAdminNoteSchema());
var adminMessageSchema = produceSchema(makeAdminMessageSchema());
var customerAgreementSchema = produceSchema(makeCustomerAgreementSchema());
var transferWaiverSchema = produceSchema(makeTransferWaiverSchema());

var bulkTransferSchema = Object.assign({}, makeCustomerAgreementSchema(), makeTransferWaiverSchema());

var searchUsernameSchema = produceSchema(
  makeRequiredStringSchema('username', {
    $label: 'Username',
    $placeholder: 'Username'
  }),
  globalMaxLength
);
var searchEmailSchema = produceSchema(
  makeRequiredStringSchema('email', require('./email-schema').email),
  globalMaxLength
);
var searchSubmissionIdSchema = produceSchema(
  makeRequiredStringSchema('submissionId', {
    $label: 'Submission ID',
    $placeholder: 'Submission ID'
  }),
  globalMaxLength
);

var paymentAddressSchema = produceSchema(makePaymentAddressSchema(), globalMaxLength);
const accountCardSchema = produceSchema(makeAccountCardSchema(), globalMaxLength);

schemaStore.save('updateProfile', updateProfileSchema);
schemaStore.save('newProfile', newProfileSchema);
schemaStore.save('credential', credentialSchema);
schemaStore.save('credentialServer', credentialServerSchema);
schemaStore.save('accountAddEmail', accountAddEmailSchema);
schemaStore.save('accountRemoveEmail', accountRemoveEmailSchema);
schemaStore.save('forgotUsername', forgotUsernameSchema);
schemaStore.save('resetPassword', resetPasswordSchema);
schemaStore.save('resetPasswordServer', resetPasswordServerSchema);
schemaStore.save('forgotPassword', forgotPasswordSchema);
schemaStore.save('login', loginSchema);
schemaStore.save('loginServer', loginServerSchema);
schemaStore.save('twoFactorCode', twoFactorCodeSchema);
schemaStore.save('unlockAccount', unlockAccountSchema);
schemaStore.save('unlockAccountServer', unlockAccountServerSchema);
// usernameEmail only checks if username exists or not,
// does not check username regex
// checks email exists and email regex,
// use in login, reset password key gen, unlock account
schemaStore.save('usernameEmail', usernameEmailSchema);
schemaStore.save('feedback', feedbackSchema);
schemaStore.save('adminNote', adminNoteSchema);
schemaStore.save('adminMessage', adminMessageSchema);
schemaStore.save('searchUsername', searchUsernameSchema);
schemaStore.save('searchEmail', searchEmailSchema);
schemaStore.save('searchSubmissionId', searchSubmissionIdSchema);
schemaStore.save('customerAgreement', customerAgreementSchema);
schemaStore.save('transferWaiverSchema', transferWaiverSchema);
schemaStore.save('bulkTransferSchema', bulkTransferSchema);
schemaStore.save('paymentAddress', paymentAddressSchema);
schemaStore.save('accountCardSchema', accountCardSchema);
schemaStore.save('autoRenewSchemaSingle', autoRenewSchema.single);
schemaStore.save('autoRenewSchemaBatch', autoRenewSchema.batch);
schemaStore.save('ownershipTransfer', ownershipTransferSchema);
schemaStore.save('usernameOrLEISchema', usernameOrLEISchema);

var signupCheckers = [].concat(makeChecker(credentialSchema)).concat(makeChecker(newProfileSchema));
var signupServerCheckers = [].concat(makeChecker(credentialServerSchema)).concat(makeChecker(newProfileSchema));

checkStore.save('updateProfile', checkProps(makeChecker(updateProfileSchema)));
checkStore.save('newProfile', checkProps(makeChecker(newProfileSchema)));
checkStore.save('credential', checkProps(makeChecker(credentialSchema)));
checkStore.save('accountAddEmail', checkProps(makeChecker(accountAddEmailSchema)));
checkStore.save('accountRemoveEmail', checkProps(makeChecker(accountRemoveEmailSchema)));
checkStore.save('signup', checkProps(signupCheckers));
checkStore.save('signupServer', checkProps(signupServerCheckers));

checkStore.save('resetPassword', checkProps(makeChecker(resetPasswordSchema)));
checkStore.save('resetPasswordServer', checkProps(makeChecker(resetPasswordServerSchema)));
checkStore.save('forgotPassword', checkProps(makeChecker(forgotPasswordSchema)));
checkStore.save('login', checkProps(makeChecker(loginSchema)));
checkStore.save('loginServer', checkProps(makeChecker(loginServerSchema)));
checkStore.save('twoFactorCode', checkProps(makeChecker(twoFactorCodeSchema)));
checkStore.save('unlockAccount', checkProps(makeChecker(unlockAccountSchema)));
checkStore.save('unlockAccountServer', checkProps(makeChecker(unlockAccountServerSchema)));
checkStore.save('usernameEmail', checkProps(makeChecker(usernameEmailSchema)));
checkStore.save('feedback', checkProps(makeChecker(feedbackSchema)));
checkStore.save('adminNote', checkProps(makeChecker(adminNoteSchema)));
checkStore.save('adminMessage', checkProps(makeChecker(adminMessageSchema)));
checkStore.save('searchUsername', checkProps(makeChecker(searchUsernameSchema)));
checkStore.save('searchEmail', checkProps(makeChecker(searchEmailSchema)));
checkStore.save('searchSubmissionId', checkProps(makeChecker(searchSubmissionIdSchema)));
checkStore.save('customerAgreement', checkProps(makeChecker(customerAgreementSchema)));
checkStore.save('transferWaiver', checkProps(makeChecker(transferWaiverSchema)));
checkStore.save('bulkTransfer', checkProps(makeChecker(bulkTransferSchema)));
checkStore.save('paymentAddressServer', checkProps(makeChecker(paymentAddressSchema)));
checkStore.save('accountCardServer', checkProps(makeChecker(accountCardSchema)));
checkStore.save('autoRenewSingle', checkProps(makeChecker(autoRenewSchema.single)));
checkStore.save('autoRenewBatch', checkProps(makeChecker(autoRenewSchema.batch)));
checkStore.save('autoRenewAll', checkProps(makeChecker(autoRenewSchema.all)));
checkStore.save('forgotUsername', checkProps(makeChecker(forgotUsernameSchema)));
checkStore.save('usernameOrLEI', checkProps(makeChecker(usernameOrLEISchema)));

var emailSchema = require('./email-schema');
checkStore.save('email', checkProps(makeChecker(emailSchema)));

module.exports.checkStore = checkStore;
module.exports.schemaStore = schemaStore;
