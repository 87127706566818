import { controller } from './app';

controller('RefreshController', [
  '$window',
  '$timeout',
  function($window, $timeout) {
    $timeout(function() {
      $window.location.href = '/?refresh=' + Date.now();
    }, 1000);
  }
]);
