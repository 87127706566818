import { directive } from '../controllers/app';

directive('linkTabs', [
  function() {
    return {
      scope: {
        // options = [{ label, link }, ...]
        tabs: '=',
        pills: '<?',
        activeIndex: '<'
      },
      template:
        '' +
        '<ul class="nav" ng-class="{ \'nav-tabs\': !pills, \'nav-pills\': pills }">' +
        '<li ng-repeat="t in tabs track by $index" class="nav-item" ng-class="{ \'active\': activeIndex === $index }">' +
        '<a class="nav-link" ng-href="{{t.link}}" ng-bind="t.label" reset-alert-on-click></a>' +
        '</li>' +
        '</ul>'
    };
  }
]);

directive('profileLinkTabs', [
  function() {
    return {
      restrict: 'E',
      scope: {
        activeIndex: '='
      },
      template: '<div link-tabs class="section-tabs" tabs="tabs" active-index="activeIndex"></div>',
      link: function(scope, iElement, iAttrs) {
        scope.tabs = [
          { label: 'Profile', link: '/users/account/profile' },
          { label: 'Emails', link: '/users/account/emails' },
          { label: 'Payment', link: '/users/account/cards' }
        ];
      }
    };
  }
]);

directive('adminLinkTabs', [
  function() {
    return {
      restrict: 'E',
      scope: {
        activeIndex: '='
      },
      template: '<div link-tabs class="section-tabs" tabs="tabs" active-index="activeIndex"></div>',
      link: function(scope) {
        scope.tabs = [
          { label: 'User Accounts', link: '/adm1ns/account' },
          { label: 'User Submissions', link: '/adm1ns/submission' },
          { label: 'User Auto Renew', link: '/adm1ns/autorenew' },
          { label: 'Account Creation', link: '/adm1ns/user' },
          { label: 'Ownership Transfer', link: '/adm1ns/lei' },
          { label: 'FAQ', link: '/adm1ns/faq' }
        ];
      }
    };
  }
]);
