const CHK_ARGUMENTS = require('./check-arguments-constants');

checkArguments.isInternalArgumentCheckEnabled = true;
checkArguments.ANY = {};

module.exports = checkArguments;

function getErrorMessage(index, actualArgument, expectType) {
  return (
    'Wrong type of argument, index: ' +
    (index + 1) +
    ', actual: ' +
    (actualArgument === null ? 'null' : typeof actualArgument) +
    (actualArgument && actualArgument.constructor
      ? ' - ' + actualArgument.constructor.name
      : '') +
    ', expect: ' +
    (expectType === undefined ? undefined : expectType.name || expectType)
  );
}

/**
     * Check whether the actual number and type of arguments satisfies the expected number and type of arguments.
     * @param actualArguments
     * @param expectRequiredTypes
     * @param expectOptionalTypes
     * @param isAlwaysCheck
     * @public
     */
function checkArguments(
  actualArguments,
  expectRequiredTypes,
  expectOptionalTypes,
  isAlwaysCheck
) {
  if (checkArguments.isInternalArgumentCheckEnabled || isAlwaysCheck) {
    var errorMessage;
    if (!expectRequiredTypes) {
      expectRequiredTypes = [];
    }
    if (!expectOptionalTypes) {
      expectOptionalTypes = [];
    }

    // check number of arguments
    if (
      actualArguments.length >
        expectRequiredTypes.length + expectOptionalTypes.length ||
      actualArguments.length < expectRequiredTypes.length
    ) {
      errorMessage =
        'Wrong number of arguments, actual: ' +
        actualArguments.length +
        ', expect: ' +
        expectRequiredTypes.length +
        (expectOptionalTypes.length > 0
          ? ' to ' + (expectRequiredTypes.length + expectOptionalTypes.length)
          : '') +
        ' arguments';

      console.error(errorMessage); //Didn't import logger to avoid circular dependency
      throw new TypeError(errorMessage);
    } else {
      for (var index = 0; index < actualArguments.length; index++) {
        var actualArgument = actualArguments[index];
        var isRequired = index < expectRequiredTypes.length;
        var expectType = isRequired
          ? expectRequiredTypes[index]
          : expectOptionalTypes[index - expectRequiredTypes.length];

        if (!isRequired) {
          // NOSONAR
          if (expectType instanceof Array) {
            expectType = expectType.concat(undefined);
          } else {
            expectType = [undefined, expectType];
          }
        }

        if (expectType instanceof Array) {
          // NOSONAR
          // multiple type choices
          var isMatched = expectType.some(function(
            type // NOSONAR
          ) {
            return isInstanceOf(actualArgument, type);
          });

          if (!isMatched) {
            // no match
            errorMessage = getErrorMessage(index, actualArgument, expectType);

            console.error(errorMessage); //Didn't import logger to avoid circular dependency
            throw new TypeError(errorMessage);
          }
        } else if (!isInstanceOf(actualArgument, expectType)) {
          // single type choice
          errorMessage = getErrorMessage(index, actualArgument, expectType);

          console.error(errorMessage); //Didn't import logger to avoid circular dependency
          throw new TypeError(errorMessage);
        }
      }
    }
  }
}

/**
     * Check whether object is instance of specified type
     * @param object
     * @param type Either string or Class
     * @returns {boolean}
     * @private
     */
function isInstanceOf(object, type) {
  if (typeof type === CHK_ARGUMENTS.TYPEOF.STRING) {
    return (
      typeof object === type ||
      (object && object.constructor && object.constructor.name === type)
    );
  }

  switch (type) {
    case checkArguments.ANY:
      return true;
    case String:
      return (
        typeof object === CHK_ARGUMENTS.TYPEOF.STRING ||
        object instanceof String
      );
    case Number:
      return (
        typeof object === CHK_ARGUMENTS.TYPEOF.NUMBER ||
        object instanceof Number
      );
    case Boolean:
      return (
        typeof object === CHK_ARGUMENTS.TYPEOF.BOOLEAN ||
        object instanceof Boolean
      );
    case Function:
      return (
        typeof object === CHK_ARGUMENTS.TYPEOF.FUNCTION ||
        object instanceof Function
      );
    case Object:
      return (
        (typeof object === CHK_ARGUMENTS.TYPEOF.OBJECT &&
          object !== null &&
          !Array.isArray(object)) ||
        object instanceof Object
      );
    case Array:
      return Array.isArray(object);
    case undefined:
      return object === undefined;
    case null:
      return object === null;
    default:
      return object instanceof type;
  }
}
