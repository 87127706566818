'use strict';

var _ = require('lodash');

var TEN_DAYS_IN_MS = 10 * 24 * 60 * 60 * 1000;

function mustBeRenewed(gleifData) {
  var status = _.get(gleifData, 'data.registration.registrationStatus');
  var renewDate = _.get(gleifData, 'data.registration.nextRenewalDate');

  return mustBeRenewedLei(status, renewDate);
}

function mustBeRenewedLei(status, renewDate) {
  return 'LAPSED' === status || Date.parse(renewDate) - Date.now() <= TEN_DAYS_IN_MS;
}

module.exports.mustBeRenewed = mustBeRenewed;
module.exports.mustBeRenewedLei = mustBeRenewedLei;
