import angular from 'angular';
import { factory } from '../app';

factory('AccountEmailsStore', [
  'ReduxStoreFactory',
  'AccountEmailsReducer',
  function(ReduxStoreFactory, AccountEmailsReducer) {
    var initialState = {
      model: {
        emails: [],
        newEmail: null
      },
      validate: {
        newEmail: false
      },
      ui: {
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('AccountEmailsStore', AccountEmailsReducer, initialState);
  }
]);

factory('AccountEmailsActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory([
      'NEW_EMAIL',
      'SET_EMAILS',
      'ADD_EMAIL',
      'REMOVE_EMAIL',
      'VALIDATE_NEW_EMAIL',
      'UI_SHOW_ALL_ERRORS'
    ]);
  }
]);

factory('AccountEmailsAction', [
  'AccountEmailsActionType',
  function(AccountEmailsActionType) {
    return {
      addEmail: function(newEmail) {
        return {
          type: AccountEmailsActionType.ADD_EMAIL,
          data: newEmail
        };
      },
      setEmails: function(emails) {
        return {
          type: AccountEmailsActionType.SET_EMAILS,
          data: emails
        };
      },
      newEmail: function(newEmail) {
        return {
          type: AccountEmailsActionType.NEW_EMAIL,
          data: newEmail
        };
      },
      removeEmail: function(emailToBeRemoved) {
        return {
          type: AccountEmailsActionType.REMOVE_EMAIL,
          data: emailToBeRemoved
        };
      },
      validate: function(validateObj) {
        return {
          type: AccountEmailsActionType.VALIDATE_NEW_EMAIL,
          data: validateObj
        };
      },
      showAllErrors: function(showOrNot) {
        return {
          type: AccountEmailsActionType.UI_SHOW_ALL_ERRORS,
          data: !!showOrNot
        };
      }
    };
  }
]);

factory('AccountEmailsReducer', [
  'AccountEmailsActionType',
  function(AccountEmailsActionType) {
    var assign = angular.extend;

    function emailsReducer(state, action) {
      if (action.type === AccountEmailsActionType.ADD_EMAIL) {
        return [].concat(state).concat(action.data);
      }

      if (action.type === AccountEmailsActionType.REMOVE_EMAIL) {
        return [].concat(state).filter(function(em) {
          return em !== action.data;
        });
      }

      if (action.type === AccountEmailsActionType.SET_EMAILS) {
        return [].concat(action.data);
      }

      return state;
    }

    function newEmailReducer(state, action) {
      if (action.type === AccountEmailsActionType.NEW_EMAIL) {
        return action.data;
      }

      return state;
    }

    function validateReducer(state, action) {
      if (action.type === AccountEmailsActionType.VALIDATE_NEW_EMAIL) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function showAllErrorsReducer(state, action) {
      if (action.type === AccountEmailsActionType.UI_SHOW_ALL_ERRORS) {
        return action.data;
      }

      return state;
    }

    return function r(state, action) {
      return {
        model: {
          emails: emailsReducer(state.model.emails, action),
          newEmail: newEmailReducer(state.model.newEmail, action)
        },
        validate: validateReducer(state.validate, action),
        ui: {
          showAllErrors: showAllErrorsReducer(state.ui.showAllErrors, action)
        }
      };
    };
  }
]);
