'use strict';

module.exports = [
  // Åland Islands
  'AX',
  // bonaire, Sint Eustatius and Saba
  'BQ',
  // Saint Barthélemy
  'BL',
  // Saint Martin (French part)
  'MF',
  // Korea (Republic of),  South Korea
  'KR'
];
