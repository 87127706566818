import { factory } from './app';

factory('Redirect', [
  '$location',
  'Alert',
  'ProgressMessenger',
  '$httpParamSerializer',
  function($location, Alert, ProgressMessenger, $httpParamSerializer) {
    // path: base path
    // (optional) params: params to join to base bath, like /base/user-123/user@abc.com
    // options, { success/alert: 'flash-message-data-key' }, to display alert after redirect
    function baseRedirect(path, params, options) {
      var opts = options || {};

      if (opts) {
        ProgressMessenger.reset();
        Alert.reset();

        if (typeof opts.alert === 'string' && opts.alert) {
          Alert.error(opts.alert);
        } else if (typeof opts.success === 'string' && opts.success) {
          Alert.success(opts.success);
        }
      }

      var fullPath = path;

      if (Array.isArray(params)) {
        fullPath = fullPath + '/' + params.join('/');
      }

      $location.url(fullPath).replace();
    }

    var attemptedPath;

    // call Redirect.remember() to save current path
    // e.g. when we need redirect user to login,
    // but want to take them back when they login again
    // see indexAfterLogin below
    function remember() {
      attemptedPath = $location.path();
    }

    // internal fn, call recall() in specific Redirect.xyz()
    // to get saved path and clear it
    function recall() {
      var recallPath = attemptedPath;

      attemptedPath = null;

      return recallPath;
    }

    return {
      remember: remember,
      index: baseRedirect.bind(null, '/'),
      logoff: baseRedirect.bind(null, '/'),
      indexAfterLogin: function indexAfterLoginRedirect() {
        var recalled = recall();
        var redirectPath = recalled || '/';
        var redirectOption = recalled
          ? {
              success: 'redirectedAfterLogin'
            }
          : null;

        return baseRedirect(redirectPath, null, redirectOption);
      },
      status: function statusRedirect(code, rejection) {
        var messages = [];
        var prev = $location.url();
        var q;

        if (rejection) {
          messages = [].concat(rejection.data);
          var flag = messages.some(function(m) {
            return typeof m != 'string';
          });
          if (flag) {
            throw new Error('Redirect.status() must take {data: <string>}, got' + rejection);
          }
        }

        q = $httpParamSerializer({
          message: messages.join(' '),
          prev: prev
        });

        return baseRedirect('/' + code + '?' + q);
      },
      unlockAccount: function unlockAccountRedirect(username, email) {
        return baseRedirect('/users/unlock-account', [username, email], {
          alert: 'locked'
        });
      },
      loginAfterUnlockAccount: function loginAfterUnlockAccountRedirect() {
        return baseRedirect('/users/login', null, { success: 'unlock' });
      },
      loginAfterResetPassword: function loginAfterResetPasswordRedirect() {
        return baseRedirect('/users/login', null, { success: 'resetPassword' });
      },
      loginAfterSentUsername: function loginAfterSentUsernameRedirect() {
        return baseRedirect('/users/login', null, { success: 'sentUsername' });
      },
      loginAfterSignup: function loginAfterSignupRedirect() {
        return baseRedirect('/users/login', null, { success: 'signup' });
      },
      loginAfter401: function loginAfter401Redirect() {
        return baseRedirect('/users/login', null, { alert: 'redirectedToLogout' });
      },
      payment: function payment(submissionId) {
        return baseRedirect('/pay', [].concat(submissionId));
      },
      checkout: function checkout() {
        return baseRedirect('/checkout');
      },
      cart: function addToCart() {
        return baseRedirect('/users/cart', null, { success: 'addToCart' });
      },
      thankYou: function thankYou(leiInfo) {
        return baseRedirect('/thank-you', leiInfo);
      }
    };
  }
]);
