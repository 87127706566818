import { controller } from '../controllers/app';
import { checkStore, schemaStore } from '../../../common/validate';

controller('AccountEmailsDirectiveController', [
  '$scope',
  'UserService',
  'AdminService',
  'ServiceErrorHandler',
  'AccountEmailsStore',
  'AccountEmailsAction',
  'Alert',
  function($scope, UserService, AdminService, ServiceErrorHandler, AccountEmailsStore, AccountEmailsAction, Alert) {
    var accountEmails = $scope.accountEmails;
    var loginEmail = $scope.loginEmail;
    var onBehalfOfUserName = $scope.onBehalfOfUserName;

    var log;
    if (__DEV__) log = console.log.bind(console, '[AccountEmailsDirectiveController]');
    else log = function() {};

    var checkAccountAddEmail = checkStore.get('accountAddEmail');
    var checkAccountRemoveEmail = checkStore.get('accountRemoveEmail');

    var self = this;

    self.props = {};
    self.props.loginEmail = loginEmail;
    self.props.accountAddEmailSchema = schemaStore.get('accountAddEmail');
    self.props.accountRemoveEmailSchema = schemaStore.get('accountRemoveEmail');

    self.state = {};

    AccountEmailsStore.subscribe(function(state) {
      self.state.model = state.model;
      self.state.validate = state.validate;
      self.state.ui = state.ui;
    });

    (function initEmails(emails) {
      var setEmailsAction = AccountEmailsAction.setEmails(emails);
      AccountEmailsStore.dispatch(setEmailsAction);
    })(accountEmails);

    function validateAdd() {
      var viewObj = {
        emails: AccountEmailsStore.getState().model.emails,
        newEmail: self.state.view.newEmail
      };

      // always validate on change
      var check = checkAccountAddEmail(viewObj);
      var validateAction = AccountEmailsAction.validate(check);
      AccountEmailsStore.dispatch(validateAction);

      return check;
    }

    self.props.handleNewEmailChange = function() {
      var addCheck = validateAdd();

      // update model only when valid
      if (!addCheck.$valid) {
        return;
      }

      var newEmail = self.state.view.newEmail;
      var newEmailAction = AccountEmailsAction.newEmail(newEmail);
      AccountEmailsStore.dispatch(newEmailAction);
    };

    self.props.handleAddEmail = function() {
      var newEmail = AccountEmailsStore.getState().model.newEmail;

      return (onBehalfOfUserName ? AdminService : UserService)
        .addAccountEmail(newEmail, onBehalfOfUserName)
        .then(function() {
          var addAction = AccountEmailsAction.addEmail(newEmail);
          AccountEmailsStore.dispatch(addAction);
          Alert.success('addEmail');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };

    self.props.handleRemoveEmail = function(email) {
      var input = {
        emails: self.state.model.emails,
        emailToRemove: email
      };

      var checkRemoveEmail = checkAccountRemoveEmail(input);
      var validateAction = AccountEmailsAction.validate(checkRemoveEmail);
      AccountEmailsStore.dispatch(validateAction);

      if (!checkRemoveEmail.$valid) {
        return;
      }

      log('call remove email here', AccountEmailsStore.getState());

      return (onBehalfOfUserName ? AdminService : UserService)
        .removeAccountEmail(email, onBehalfOfUserName)
        .then(function() {
          var removeAction = AccountEmailsAction.removeEmail(email);
          AccountEmailsStore.dispatch(removeAction);
          Alert.success('removeEmail');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };
  }
]);
