'use strict';

const ValidationError = require('./validation-error');
const NON_LEAP_YEAR = 1970;

const months = [
  { name: 'January', abbr: 'Jan' },
  { name: 'February', abbr: 'Feb' },
  { name: 'March', abbr: 'Mar' },
  { name: 'April', abbr: 'Apr' },
  { name: 'May', abbr: 'May' },
  { name: 'June', abbr: 'Jun' },
  { name: 'July', abbr: 'Jul' },
  { name: 'August', abbr: 'Aug' },
  { name: 'September', abbr: 'Sep' },
  { name: 'October', abbr: 'Oct' },
  { name: 'November', abbr: 'Nov' },
  { name: 'December', abbr: 'Dec' }
];

months.forEach((x, i) => {
  x.value = `${i + 1}`;
  x.label = `${x.abbr} (${('0' + x.value).substr(-2)})`;
  x.days = new Date(NON_LEAP_YEAR, x.value, 0).getDate();
});

const getDays = month => {
  if (isNaN(+month) || !months[month]) return [];

  return Array.from({ length: months[month].days }, (_, i) => i + 1);
};

const getYears = (length = 20) => {
  const fullYear = new Date().getUTCFullYear();
  return Array.from({ length }, (_, i) => i + fullYear);
};

//apparently add 1 month n times is different from add n months
//to make it consistent with getMonthsBetween
const addMonths = (date, months) => {
  const copy = new Date(date);
  var day = copy.getUTCDate();
  copy.setUTCMonth(copy.getUTCMonth() + +months);
  if (copy.getUTCDate() != day) {
    copy.setUTCDate(0);
  }
  return copy;
};

/**
 * Add `n` years to a date without side effects.
 * @param {Date} date date to add years to.
 * @param {Number} years number of years to add
 * @returns {Date}
 */
const addYears = (date, years) => {
  const tmp = new Date(+date);
  tmp.setUTCFullYear(tmp.getUTCFullYear() + years);
  return tmp;
};

/**
 * Create a date with a bit more flexibility
 * @param {?(Date|Number|String)} value the object to start a new date with
 * @returns {Date}
 * @throws {TypeError} if a bad date is created.
 */
const init = (value = null) => {
  if (value != null && !isNaN(+value)) return new Date(+value);

  if (!value) return new Date();

  const d = new Date(value);

  if (isNaN(d)) throw new ValidationError(`Invalid date '${value}' passed.`);

  return d;
};

/**
 * Returns Date in ISO format
 * @param {Date} date
 */
const toYYYYMMDD = date => {
  const d = init(date).toISOString();
  return d.substring(0, d.indexOf('T')).replace(/-/g, '');
};

/**
 * Get the number of months between two dates
 * @param {Date} startDate date from
 * @param {Date} endDate date to
 * @returns {Number}
 */
function getMonthsBetween(startDate, endDate) {
  let safeStartDate = new Date(+startDate);
  let months = 0;
  while (+safeStartDate < +endDate) {
    months++;
    safeStartDate = addMonths(startDate, months);
  }
  return months;
}

module.exports = {
  init,
  months,
  getDays,
  getYears,
  addMonths,
  addYears,
  toYYYYMMDD,
  getMonthsBetween
};
