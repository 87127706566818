'use strict';

module.exports.LOCAL_LANGUAGE_COUNTRIES_MAP = {
  BG: 'bg',
  BY: 'be',
  CN: 'zh',
  EE: 'et',
  FR: 'fr',
  GE: 'ka',
  JP: 'ja',
  KH: 'km',
  KR: 'ko',
  MD: 'ro',
  MK: 'mk',
  PL: 'pl',
  QA: 'ar',
  RU: 'ru',
  TH: 'th',
  UA: 'uk',
  VN: 'vi'
};
