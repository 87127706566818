import angular from 'angular';

const __module__ = 'leiApp.utils',
  app = angular.module(__module__, [
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.infiniteScroll',
    'ui.grid.resizeColumns',
    'ngFileUpload',
    'LocalStorageModule',
    'ngCookies',
    'sprintf'
  ]),
  { factory, directive, service, filter, component, constant } = app;

export { app, factory, directive, service, filter, component, constant, __module__ as default };
