import _ from 'lodash';
import { KeyChain } from '@dtwebservices/react-bbds-form';
import { LABELS as BASE_LABELS } from '../../../utils/label-customizer';

export const LABELS = _.merge({}, BASE_LABELS, {
  data: {
    relationships: {
      directParent: {
        ParentInformationNotProvided: {
          _this: "My direct parent doesn't have an LEI, or I do not have a direct parent"
        },
        ParentInformation: {
          _this: 'My direct parent has an LEI',
          parentEntity: {
            _this: 'Parent Entity',
            ParentLEI: {
              _this: 'Yes'
            },
            ParentWithNoLEI: {
              _this: 'No'
            }
          }
        }
      },
      ultimateParent: {
        ParentInformationNotProvided: {
          _this: "My ultimate parent doesn't have an LEI, or I do not have an ultimate parent"
        },
        ParentInformation: {
          _this: 'My ultimate parent has an LEI',
          parentEntity: {
            _this: 'Parent Entity',
            ParentLEI: {
              _this: 'Yes'
            },
            ParentWithNoLEI: {
              _this: 'No'
            }
          }
        }
      },
      managingFundParent: {
        _this: 'Fund Manager Details',
        isSelfManaged: {
          _this: 'How is this fund managed?'
        },
        parentLei: {
          _this: 'Fund Manager LEI'
        }
      },
      umbrellaFundParent: {
        _this: 'Umbrella Fund Details',
        parentLei: {
          _this: 'Umbrella Fund  LEI'
        }
      },
      masterFundParent: {
        _this: 'Master Fund Details',
        parentLei: {
          _this: 'Master Fund LEI'
        }
      }
    }
  }
});

export default chain => {
  const label = _.get(
    LABELS,
    chain
      .join(true)
      .split(KeyChain.DEFAULT_SEPARATOR)
      .map(k => {
        k = k.split('.');
        return k[k.length - 1];
      })
      .join('.')
  );

  return label && typeof label !== 'string' ? label._this : label;
};
