import angular from 'angular';
import { controller } from './app';

controller('MainController', [
  '$scope',
  'AlertMessenger',
  function($scope, AlertMessenger) {
    angular.element('.carousel').carousel();
    $scope.cdnPrefix = __CDN__;
    AlertMessenger.info(
      'For any users whose LEIs are being transferred from GMEI/DTCC during the August 2023 transition, please visit this FAQ '
    );
  }
]);
