'use strict';

var usernameRegex = require('../const/username-regex');

module.exports = {
  username: {
    $label: 'Username',
    $placeholder: 'Username',
    $required: true,
    $pattern: usernameRegex,
    $message:
      'Please provide a valid username. Username must be between 4 and 32 characters, and can contain (1) letters from A-Z, (2) letters from a-z, (3) numbers from 0-9, and special characters such as - and _. NOTE: Usernames are case sensitive.'
  }
};
