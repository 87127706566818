'use strict';

module.exports = [
  {
    abbr: 'AB',
    name: 'Alberta'
  },

  {
    abbr: 'BC',
    name: 'British Columbia'
  },

  {
    abbr: 'MB',
    name: 'Manitoba'
  },

  {
    abbr: 'NB',
    name: 'New Brunswick'
  },

  {
    abbr: 'NF',
    name: 'Newfoundland'
  },

  {
    abbr: 'NS',
    name: 'Nova Scotia'
  },

  {
    abbr: 'NT',
    name: 'Northwest Territory'
  },

  {
    abbr: 'NU',
    name: 'Nunavut'
  },

  {
    abbr: 'ON',
    name: 'Ontario'
  },

  {
    abbr: 'PE',
    name: 'Prince Edward Island'
  },

  {
    abbr: 'QC',
    name: 'Quebec'
  },

  {
    abbr: 'SK',
    name: 'Saskatchewan'
  },

  {
    abbr: 'YT',
    name: 'Yukon Territory'
  }
];
