export function isInvalidCountry(types, vm) {
  const isInvalid = types
    .filter(type => vm.paymentInfo[type].city)
    .some(
      type =>
        ['hong kong', 'hongkong', 'hk'].includes(vm.paymentInfo[type].city.toLowerCase()) &&
        vm.paymentInfo[type].country === 'CN'
    );

  return isInvalid;
}

export function showInvalidCountry(getFromSchema, vm, disableButtonFn) {
  const countryTypes = ['shipping', 'billing'];
  countryTypes.forEach(countryType => {
    const schema = getFromSchema(countryType + '.country');
    vm.paymentInfo[countryType].message = isInvalidCountry([countryType], vm)
      ? schema.$warning
      : 'Please provide country or region in your domicile address.';
    vm.paymentInfo[countryType].error = isInvalidCountry([countryType], vm);
  });
  disableButtonFn && disableButtonFn(isInvalidCountry(countryTypes, vm));
}
