const _ = require('lodash');
const { EVENTS_TO_GROUP_TYPE_MAP, LEGAL_ENTITY_EVENT_STATUS } = require('../const/entity-actions');

module.exports = {
  setGroupTypeForEvents,
  setGroupIdForEvents,
  setLineageIdForEvents,
  addDerivedEventFieldsToPayloads,
  addDerivedEventFieldsToPayload,
  sortEventsByRecordedDate,
  groupEventsByType,
  applyLineageIdsToGroupedEvents,
  deepClone
};

/**
 * Determine legalEntityEventGroupType from provided events for Excel Bulk and API clients.
 * @param events (bbds format)
 * @param applyToEventFields
 * @param complexEventTypeMap
 */
function setGroupTypeForEvents(
  events,
  applyToEventFields,
  { complexEventTypeMap = EVENTS_TO_GROUP_TYPE_MAP } = { complexEventTypeMap: EVENTS_TO_GROUP_TYPE_MAP }
) {
  const eventsWithComplexTypes = {};
  // Ex: Object.entries(EVENTS_TO_GROUP_TYPE_MAP) = [
  //   key: CHANGE_LEGAL_FORM_AND_NAME,
  //   value: { orGroup: [{ andGroup: [{}, {}, ...]}, { andGroup: [{}, {}, ...]}, ...]}
  // ]
  const complexTypes = Object.entries(complexEventTypeMap).filter(([eventType, rules]) => {
    const successfullOr = rules.orGroup.some(({ andGroup }) => {
      const successfullAnd = andGroup.every(andItem => {
        console.debug(
          'lei-events-util.js> addComplexEventTypes() checking for ${eventType} with RULE>> andItem:',
          andItem
        );

        const success = Object.entries(andItem).every(([fieldName, fieldValue]) => {
          // TODO: Make sure only 1 event is reported per type.
          const eventFound = events.find(event => {
            return fieldValue.test(event[fieldName]);
          });
          // Keep track of matched regex by complex type
          if (eventFound)
            eventsWithComplexTypes[eventType]
              ? eventsWithComplexTypes[eventType].push(eventFound)
              : (eventsWithComplexTypes[eventType] = [eventFound]);

          return !!eventFound;
        });
        success &&
          console.debug(
            'lei-events-util.js> addComplexEventTypes() SUCCESS:',
            success,
            'eventsWithComplexTypes:',
            eventsWithComplexTypes
          );

        return success;
      });
      // When every check is not successful clear results
      if (!successfullAnd) eventsWithComplexTypes[eventType] = undefined;

      return successfullAnd;
    });
    return successfullOr;
  });

  console.debug(`lei-events-util.js> addComplexEventTypes() complexTypes: ${complexTypes}`);

  complexTypes.forEach(complexType => {
    // If every regex is satisfied for a complex type then add that type to the associated events
    Object.keys(complexEventTypeMap).forEach(complexEventType => {
      if (complexType && complexEventType === complexType[0] && eventsWithComplexTypes[complexEventType]) {
        eventsWithComplexTypes[complexType[0]].forEach(event => {
          const eventType = complexType[0];
          applyToEventFields.forEach(eventField => {
            event[eventField] = eventType;
          });
        });
      }
    });
  });

  // return value, used by test cases
  return complexTypes ? complexTypes.map(complexType => complexType[0]) : undefined;
}

/**
 * Helper function to add group ids to each group type mapping
 * @param complexEventGroupTypeMap
 * @param generateIds
 * @returns {Promise<function(*=): any>}
 * @private
 */
async function _addEventGroupIdsFn(complexEventGroupTypeMap, generateIds) {
  // Clone event group type map to prevent modifying original mapping (constant)
  const complexEventGroupTypeMapClone = deepClone(complexEventGroupTypeMap);
  const rules = Object.values(complexEventGroupTypeMapClone);
  const eventIdInfoResult = await generateIds(rules.length);
  const eventIds = eventIdInfoResult.data || eventIdInfoResult;

  rules.forEach(rule => {
    rule.legalEntityEventGroupId = eventIds.pop().humanReadableId;
  });

  return event => {
    console.debug('common-events-util> _addEventGroupIdsFn()() event:', event);
    const isGroupFieldInfo =
      complexEventGroupTypeMapClone[event.legalEntityEventGroupType.id || event.legalEntityEventGroupType];
    console.debug('common-events-util> _addEventGroupIdsFn()() groupFieldInfo:', isGroupFieldInfo);
    return isGroupFieldInfo
      ? Object.assign({ legalEntityEventGroupId: isGroupFieldInfo.legalEntityEventGroupId }, event)
      : event;
  };
}

/**
 * Determine legalEntityEventGroupId from provided events for Excel Bulk and API clients.
 * @param events (bbds format)
 * @param generateIds function used to generate ids
 * @param complexEventTypeMap
 */
async function setGroupIdForEvents(
  events,
  generateIds,
  { complexEventGroupTypeMap } = { complexEventGroupTypeMap: EVENTS_TO_GROUP_TYPE_MAP }
) {
  const hasGroupEvents = events.some(({ legalEntityGroupType }) => legalEntityGroupType != 'STANDALONE');

  if (hasGroupEvents) {
    const addEventGroupIdFn = await _addEventGroupIdsFn(complexEventGroupTypeMap, generateIds);

    events.forEach((event, index) => {
      if (event.legalEntityGroupType != 'STANDALONE') {
        const newEvent = addEventGroupIdFn(event);
        events[index] = newEvent;
      }
    });
    console.debug('lei-events-util.js> addComplexEventTypes() events:', events);
  }

  return events;
}

/**
 * Determine lineageId from provided events for Excel Bulk and API clients.
 * @param events (bbds format)
 * @param generateIds function used to generate ids
 */
async function setLineageIdForEvents(events, generateIds) {
  const eventIdInfoResult = await generateIds(events.length);
  const eventIds = eventIdInfoResult.data || eventIdInfoResult;

  events.forEach((event, index) => {
    event.lineageId = eventIds.pop().humanReadableId;
  });

  return events;
}

/**
 * Add derived group type, group id, and recorded date to events for multiple payloads.  Added for bulk excel.
 * @param payloads
 * @param generateIds
 * @returns {Promise<*>}
 */
async function addDerivedEventFieldsToPayloads(payloads, generateIds) {
  /// default path to events for excel bulk request
  const pathToEvents = ['payload', 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent'];
  const payloadsWithEvents = payloads.filter(payload => {
    const events = _.get(payload, ['payload', 'data', 'entity', 'legalEntityEvents', 'legalEntityEvent']);

    return !!events && !!events.length;
  });
  const recordedDate = new Date().toISOString();
  await Promise.all(
    payloadsWithEvents.map(async payload => {
      await addDerivedEventFieldsToPayload(payload, generateIds, { recordedDate, pathToEvents });
    })
  );
  return payloads;
}

/**
 * Add derived group type, group id, and recorded date to events for single payload.  Added for api requests.
 * @param payload
 * @param generateIds
 * @param recordedDate
 * @param pathToEvents
 * @returns {Promise<*>}
 */
async function addDerivedEventFieldsToPayload(
  payload,
  generateIds,
  {
    recordedDate = new Date().toISOString(),
    // default path to events for api request
    pathToEvents = ['payload', 'entity', 'legalEntityEvents', 'legalEntityEvent']
  } = {
    recordedDate: new Date().toISOString(),
    // default to events path for api request
    pathToEvents: ['payload', 'entity', 'legalEntityEvents', 'legalEntityEvent']
  }
) {
  const legalEntityEventList = _.get(payload, pathToEvents, []);
  // Add default values
  legalEntityEventList.forEach(legalEntityEvent => {
    // TODO: Consider adding warning if current value is not standalone
    legalEntityEvent.legalEntityEventGroupType = 'STANDALONE';
    // TODO: Consider adding warning if current value is not completed
    legalEntityEvent.legalEntityEventStatus = 'COMPLETED';
    legalEntityEvent.legalEntityEventRecordedDate = recordedDate
      ? recordedDate
      : legalEntityEvent.legalEntityEventRecordedDate;
  });
  // Add event group fields
  setGroupTypeForEvents(legalEntityEventList, ['legalEntityEventGroupType'], {
    complexEventGroupTypeMap: EVENTS_TO_GROUP_TYPE_MAP
  });
  await setGroupIdForEvents(legalEntityEventList, generateIds, {
    complexEventGroupTypeMap: EVENTS_TO_GROUP_TYPE_MAP
  });

  await setLineageIdForEvents(legalEntityEventList, generateIds);

  return payload;
}

/**
 * Sort list of events by recorded date.
 * @param events [{legalEntityEventType: <type1>, legalEntityEventRecordedDate: <t2>}, {legalEntityEventType: <type2>, legalEntityEventRecordedDate: <t1>}, ...]
 * @returns [{legalEntityEventType: <type1>, legalEntityEventRecordedDate: <t1>}, {legalEntityEventType: <type2>, legalEntityEventRecordedDate: <t2>}, ...]
 */
function sortEventsByRecordedDate(events) {
  const sortedEvents = events.sort((eventA, eventB) => {
    const eventARecordedDate = new Date(eventA.legalEntityEventRecordedDate);
    const eventBRecordedDate = new Date(eventB.legalEntityEventRecordedDate);
    return eventARecordedDate > eventBRecordedDate ? 1 : eventARecordedDate < eventBRecordedDate ? -1 : 0;
  });
  return sortedEvents;
}

/**
 * Group list of sorted events by legal entity event group type;
 * @param sortedEvents [{legalEntityEventType: <type1>, legalEntityEventRecordedDate: <t1>}, {legalEntityEventType: <type2>, legalEntityEventRecordedDate: <t2>}, ...]
 * @returns  {<legalEntityEventType1>: [event1, event2, ...], <legalEntityEventType2>: [event1, event2, ...], etc.}
 */
function groupEventsByType(sortedEvents) {
  const eventsByType = sortedEvents.reduce((accum, sortedEvent) => {
    const existingEventTypeList = _.get(accum, [sortedEvent.legalEntityEventType], []);
    accum[sortedEvent.legalEntityEventType] = [...existingEventTypeList, sortedEvent];
    return accum;
  }, {});

  return eventsByType;
}

/**
 * Apply the same lineage id to grouped and sorted events
 * @param sortedEvents {<legalEntityEventType1>: [{legalEntityEventStatus: IN_PROGRESS, ...}, {legalEntityEventStatus: COMPLETED, ...}, ...], <legalEntityEventType2>: [event1, event2, ...], etc.}
 * @returns {<legalEntityEventType1>: [{legalEntityEventStatus: IN_PROGRESS, ...}, {legalEntityEventStatus: COMPLETED, lineageId: <id1>, ...}, ...], <legalEntityEventType2>: [event1, event2, ...], etc.}
 */
async function applyLineageIdsToGroupedEvents(groupedSortedEvents, generateIds) {
  const eventIdInfoResult = await generateIds(_.flatten(Object.values(groupedSortedEvents)).length);
  const eventIds = eventIdInfoResult.data || eventIdInfoResult;
  const doneEvents = [LEGAL_ENTITY_EVENT_STATUS.COMPLETED, LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED];
  Object.entries(groupedSortedEvents).map(([legalEntityEventType, events]) => {
    let previousEvent;
    events.forEach(event => {
      if (
        previousEvent &&
        previousEvent.legalEntityEventStatus === LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS &&
        doneEvents.includes(event.legalEntityEventStatus)
      ) {
        event.lineageId = previousEvent.lineageId;
      } else {
        event.lineageId = eventIds.pop().humanReadableId;
      }

      previousEvent = event;
    });
  });
}

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}
