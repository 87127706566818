'use strict';

var requiredStringCheckFn = require('./required-string');

module.exports = function makeTwoFactorCodeSchema() {
  return {
    unlockAccountCode: {
      $label: 'Unlock Account Code',
      $placeholder: 'Check your email for the code',
      checkFn: requiredStringCheckFn,
      $message: 'Please provide your Unlock Account Code'
    }
  };
};

module.exports.server = function makeTwoFactorCodeServerSchema() {
  return {
    username: {
      $label: 'Username',
      checkFn: requiredStringCheckFn
    },
    unlockAccountCode: {
      $label: 'Unlock Account Code',
      $placeholder: 'Check your email for the code',
      checkFn: requiredStringCheckFn,
      $message: 'Please provide your Unlock Account Code'
    }
  };
};
