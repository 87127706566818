'use strict';

module.exports = {
  max_bulk_size: 50,
  max_file_size: 1048576 * 12,
  max_file_size_per_submission: 1048576 * 2,
  mb_in_byte: 1048576,
  file_accept_types: '.pdf,.xlsx,.xls,.csv,.doc,.docx',
  ref_file_accept_types: '.pdf,.xlsx,.xls,.csv,.doc,.docx',
  auth_accept_types: '.pdf,.doc,.docx',
  excel_accept_type: '.xlsx',
  upload_instructions: 'Please use the above section to upload any reference documents related to your submission.',
  ref_upload_instructions:
    "Please use the above section to upload any reference documents related to your submission.  (This may include items such as the entity's certificate of incorporation, or a corporate filing)",
  auth_upload_instructions:
    'Please use the above section to upload a signed authorization form related to your submission. This is a requirement of the Global LEI Foundation. If you are a direct representative of your entity, you may use the Self Authorization Form. If applying on behalf of another entity, you should use the Third Party Authorization Form.',
  auth_doc_link: '/assets/resources/Bloomberg_LEI_Webportal_Third_party_auth_form.docx',
  self_auth_doc_link: '/assets/resources/Bloomberg_LEI_Webportal_Self_auth_form.docx',
  third_auth_doc_link: '/assets/resources/Bloomberg_LEI_Webportal_Third_party_auth_form.docx',
  max_ownership_transfer_size: 500,
  max_lei_in_transfer_export: 250
};
