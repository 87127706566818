'use strict';

var MAX_NOTE_CHARACTERS = 2000;
var requiredString = require('./required-string');
var globalMaxLength = require('./global-max-length');

module.exports = function makeAdminNoteSchema() {
  return {
    note: {
      $label: 'Admin Notes',
      $placeholder: 'Admin Notes (max. ' + MAX_NOTE_CHARACTERS + ')',
      checkFn: requiredString,
      $message: 'Please provide admin notes.'
    },
    sdsk: {
      $label: 'SDSK #',
      $placeholder: 'Optional',
      checkFn: function(val) {
        if (val) {
          return globalMaxLength(val);
        } else {
          return true;
        }
      }
    },
    _noteMaxLength: {
      checkFn: function(_val, body, report) {
        if (!report.note.$valid) {
          return true;
        }

        return body.note.length < MAX_NOTE_CHARACTERS;
      },
      $message: 'Note must be within ' + MAX_NOTE_CHARACTERS + ' characters.'
    }
  };
};
