'use strict';

/*
 * map Ghana entity categories to regular entity categories
 */
module.exports.TAX_ENTITY_CATEGORY_MAP = {
  GH: {
    private_corporate: 'business', //Private Corporate Customers
    government_agency: 'public', //Government agencies (Ministries, Departments, Agencies)
    foreign_mission: 'public', //Foreign Missions
    public_institution: 'public', //Public Institutions, Trusts, Co-operatives
    private_individual: 'individual' //Private individuals
  }
};

module.exports.TAX_ENTITY_CATEGORY_REGEX = {
  GH: {
    // c + 10 characters (all numbers or 9 numbers + 1 digit) - all case sensitive
    private_corporate: '^[Cc]((?!(?:\\d*[A-Za-z]){2})[^\\W_]{10})$',
    // g + 10 characters (all numbers or 9 numbers + 1 digit) - all case sensitive
    government_agency: '^[Gg]((?!(?:\\d*[A-Za-z]){2})[^\\W_]{10})$',
    // q + 10 characters (all numbers or 9 numbers + 1 digit) - all case sensitive
    foreign_mission: '^[Qq]((?!(?:\\d*[A-Za-z]){2})[^\\W_]{10})$',
    // v + 10 characters (all numbers or 9 numbers + 1 digit) - all case sensitive
    public_institution: '^[Vv]((?!(?:\\d*[A-Za-z]){2})[^\\W_]{10})$',
    // gha + dash + 9 digits + dash + 1 digit - all case sensitive
    private_individual: '^([Gg][Hh][Aa]\\-)\\d{9}\\-\\d{1}$'
  }
};
