import angular from 'angular';
import { factory, controller } from '../app';
import { checkStore, schemaStore } from '../../../../common/validate';

var assign = angular.extend;

factory('UnlockStore', [
  'ReduxStoreFactory',
  'UnlockReducer',
  function(ReduxStoreFactory, UnlockReducer) {
    var initialState = {
      model: {
        unlockAccountCode: null
      },
      validate: {},
      ui: {
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('UnlockStore', UnlockReducer, initialState);
  }
]);

factory('UnlockActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory(['UPDATE']);
  }
]);

factory('UnlockAction', [
  'UnlockActionType',
  'ReduxUIAction',
  function(UnlockActionType, ReduxUIAction) {
    return assign({}, ReduxUIAction, {
      update: function(vm) {
        return {
          type: UnlockActionType.UPDATE,
          data: vm
        };
      }
    });
  }
]);

factory('UnlockReducer', [
  'ReduxUIReducer',
  'ReduxReducerFactory',
  'UnlockActionType',
  function(ReduxUIReducer, ReduxReducerFactory, UnlockActionType) {
    var modelReducer = ReduxReducerFactory.assign(UnlockActionType.UPDATE);

    return function r(state, action) {
      return {
        model: modelReducer(state.model, action),
        validate: ReduxUIReducer.validate(state.validate, action),
        ui: {
          showAllErrors: ReduxUIReducer.showAllErrors(state.ui.showAllErrors, action)
        }
      };
    };
  }
]);

controller('UnlockAccountController', [
  'username',
  'UserService',
  'ServiceErrorHandler',
  'UnlockStore',
  'UnlockAction',
  'Redirect',
  function(username, UserService, ServiceErrorHandler, UnlockStore, UnlockAction, Redirect) {
    var checkUnlockAccount = checkStore.get('unlockAccount');

    var self = this;

    self.props = {};
    self.props.unlockAccountSchema = schemaStore.get('unlockAccount');

    self.state = angular.extend({}, UnlockStore.getState(), {
      vm: {}
    });

    function updateSelfState(state) {
      self.state.model = state.model;
      self.state.validate = state.validate;
      self.state.ui = state.ui;
    }

    UnlockStore.reset().subscribe(updateSelfState);

    self.props.reset = function reset() {
      self.state.vm = {};

      UnlockStore.reset();

      var resetState = UnlockStore.getState();
      updateSelfState(resetState);
    };

    self.props.handleUnlockAccountCodeChange = function handleUnlockAccountCodeChange() {
      var input = {
        unlockAccountCode: self.state.vm.unlockAccountCode
      };

      var check = checkUnlockAccount(input);

      UnlockStore.dispatch(UnlockAction.validate(check));

      if (!check.$valid) {
        return;
      }

      var updateAction = UnlockAction.update(self.state.vm);

      UnlockStore.dispatch(updateAction);
    };

    self.props.handleUnlockAccountCodeSubmit = function handleUnlockAccountCodeSubmit() {
      var model = UnlockStore.getState().model;

      return UserService.validateUnlockCode(username, model.unlockAccountCode)
        .then(Redirect.loginAfterUnlockAccount)
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };
  }
]);
