import { directive } from '../controllers/app';

directive('validationMessage', [
  function() {
    return {
      restrict: 'E',
      scope: {
        form: '=',
        field: '=',
        show: '=',
        showRequired: '=',
        message: '@',
        patternMessage: '@',
        maxlengthMessage: '@'
      },
      template:
        ' <div ng-show="show || (form.$submitted || field.$touched) && field.$invalid">' +
        ' <small ng-show="field.$error.required" class="text-danger" ng-bind="message"></small>' +
        ' <small ng-show="field.$error.pattern" class="text-danger" ng-bind="patternMessage"></small>' +
        ' <small ng-show="field.$error.maxlength" class="text-danger" ng-bind="maxlengthMessage"></small>' +
        ' </div>'
    };
  }
]);
