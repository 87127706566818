import { factory, directive, config } from './app';

var VERSION_HEADER_KEY = 'x-lei-version';
var VERSION_EXPIRED_EVENT = 'lei-version-expired';

function headers(res, name) {
  return res && res.headers && res.headers(name);
}

import { ACCESS_HEADER } from '../../common/const/access-level';
factory('AccessLevelInterceptor', [
  'AccessLevel',
  function(AccessLevel) {
    return {
      response: function(res) {
        var log = console.log.bind(console, '[AccessLevelInterceptor]');
        var level = headers(res, ACCESS_HEADER);

        if (level != null) {
          AccessLevel.change(+level);
          log('level', +level, 'path', res.config.url);
        }

        return res;
      }
    };
  }
]);

factory('VersionInterceptor', [
  '$rootScope',
  function($rootScope) {
    var latestVersion;

    function warnFn(latestVersion) {
      $rootScope.$emit(VERSION_EXPIRED_EVENT, latestVersion);
    }

    return {
      response: function(res) {
        var log = console.log.bind(console, '[VersionInterceptor]');
        // latestVersion is always fresh per deployment
        latestVersion = headers(res, VERSION_HEADER_KEY);

        // may be stale in this SPA
        var localVersion = document.head.getAttribute('version');

        if (latestVersion) {
          log('latest: ' + latestVersion + ', local: ' + localVersion);

          var local = localVersion.split('.');
          var latest = latestVersion.split('.');
          // only trigger warning when local version is smaller than latest
          if (local[0] < latest[0] || local[1] < latest[1] || local[2] < latest[2]) {
            warnFn();
          }
        }

        return res;
      }
    };
  }
]);

directive('versionWarning', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    return {
      restrict: 'E',
      scope: {
        leiVersionExpired: '=?'
      },
      replace: true,
      template:
        '' +
        '<div class="alert alert-warning version-warning-alert" role="alert"' +
        ' ng-if="leiVersionExpired">' +
        'Bloomberg LEI website has been updated. Please save your data on this page and ' +
        '<a href="/upgrade">click here to refresh</a>.' +
        '</div>',
      link: function(scope, element) {
        $rootScope.$on(VERSION_EXPIRED_EVENT, function(event, version) {
          $timeout(function() {
            scope.leiVersionExpired = true;
          });
        });
      }
    };
  }
]);

factory('NotAvailableInterceptor', [
  '$q',
  'Alert',
  function($q, Alert) {
    return {
      responseError: function(rej) {
        var log = console.log.bind(console, '[NotAvailableInterceptor]');
        log(rej.status);

        if (rej.status === 502 || rej.status === 503 || rej.status === 504) {
          return Alert.error('service');
        }

        return $q.reject(rej);
      }
    };
  }
]);

config([
  '$httpProvider',
  function($httpProvider) {
    $httpProvider.interceptors.push('VersionInterceptor');
    $httpProvider.interceptors.push('AccessLevelInterceptor');
    $httpProvider.interceptors.push('NotAvailableInterceptor');
  }
]);

if (__DEV__) {
  // This file only gets built into lei.app.js when running gulp dev.
  // Other env - client will not have this code at all.

  factory('DevDebugInterceptor', [
    function() {
      var log = console.log.bind(console, '[DevDebugInterceptor]');

      return {
        response: function(res) {
          var debugStr = res.headers('x-lei-debug');

          if (debugStr) {
            log(debugStr);
          }

          return res;
        }
      };
    }
  ]);

  config([
    '$httpProvider',
    function($httpProvider) {
      $httpProvider.interceptors.push('DevDebugInterceptor');
    }
  ]);
}
