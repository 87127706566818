import _ from 'lodash';
import { controller } from '../app';
import { BULK_UPDATE_STAGES as STAGE, BULK_UPDATE_STEPS } from '../../utils/constants';
import fileUpload from '../../../../common/const/file-upload';

// Controller for creating new user in bulk
controller('BulkUserController', [
  '$scope',
  'AdminService',
  'ProgressMessenger',
  'AlertMessenger',
  'Alert',
  'StateManager',
  function($scope, AdminService, ProgressMessenger, AlertMessenger, Alert, StateManager) {
    // variables
    // action can be new, update, renew
    $scope.action = 'update';
    $scope.secLabel = 'Bulk User Account Creation';

    $scope.fileUpload = fileUpload;

    var FORMAT = { EXCEL: 'EXCEL', JSON: 'JSON', NONE: 'NONE' };
    $scope.STAGE = STAGE;
    $scope.FORMAT = FORMAT;
    $scope.currentStage = STAGE.SUBMIT;
    $scope.currentFormat = FORMAT.EXCEL;
    $scope.excel = { models: { file: {} } };
    $scope.bulkAttachments = { models: { files: [] } };

    $scope.activeFragment = 0;

    // actions
    $scope.publishBulkPayload = publishBulkPayload;
    $scope.updateSelectedPayload = updateSelectedPayload;

    $scope.wizardSteps = BULK_UPDATE_STEPS;

    $scope.$watch('currentStage', function(stage) {
      $scope.wizardSteps = $scope.wizardSteps.map(function(w) {
        w.active = stage >= w.stage;
        return w;
      });
    });

    $scope.excelTemplateUrl = '/views/user.xlsx';

    // the dropdown change event is not propagated to model somehow
    function updateSelectedPayload(index) {
      $scope.activeFragment = index;
    }

    /**
     * only accept .xlsx format
     */
    $scope.dragWorkbookCb = function(x, xx, newFiles) {
      $scope.excel.models.file =
        _.find(newFiles, function(f) {
          return /\.xlsx$/.test(f.name);
        }) || {};
    };

    $scope.handleExcelWorkbook = function(excelWorkbook) {
      if (!(excelWorkbook || {}).name) {
        return AlertMessenger.alert('Please select an Excel workbook to upload.');
      }

      StateManager.lockUI();
      $scope.uploading = StateManager.uiIsLocked();
      ProgressMessenger.reset().progress(25);
      AlertMessenger.info('This may take several seconds...');

      var validationService = AdminService.validateExcelForUser;

      validationService(excelWorkbook)
        .then(function(data) {
          $scope.bulkPayload = data.data.map(function(p) {
            return p.payload.data;
          });
          $scope.currentStage = STAGE.PUBLISH;
          $scope.activeFragment = 0;

          AlertMessenger.reset();
        })
        .catch(function(err) {
          console.log(err);

          if (err.status >= 400 && err.status < 500) {
            AlertMessenger.alert(err.data);
          } else {
            AlertMessenger.alert(
              'There was a problem with your Excel submission.  Please try again later or contact an administrator.'
            );
          }

          $scope.bulkPayload = null;
        })
        .finally(function() {
          StateManager.unlockUI();
          ProgressMessenger.done();
          $scope.uploading = StateManager.uiIsLocked();
        });
    };

    /**
     * create accounts in bulk
     * @return {Promise}        Promise by of the creation
     */
    function publishBulkPayload(bulkPayload) {
      AlertMessenger.reset();
      ProgressMessenger.reset().progress(20);

      return AdminService.bulkCreate(bulkPayload)
        .then(function(data) {
          console.log(data);
          $scope.successSubmissionIds = true;
          $scope.currentStage = STAGE.CONFIRM;
          Alert.success('bulkUserCreate');
        })
        .catch(function(ex) {
          console.log(ex);
          AlertMessenger.alert(ex.data);
        });
    }
  }
]);
