import { KeyChain } from '@dtwebservices/react-bbds-form';
import angular from 'angular';
import { flatten, get, set } from 'lodash';
import { controller } from './app';
import {
  //  DRAFT_INTERVAL,
  checkCustomerConsent,
  FORM_SECTIONS,
  getAction,
  getEntityAdditionalSchemaErrors,
  ifAuthorization,
  ifNew,
  ifTransfer,
  ifUpdate,
  labelCustomizer,
  makeOnApply,
  makeOnStateChangeSelector,
  onEntityCategoryChange,
  textHash
} from './utilities';
import { deepClone, leiDiff } from './utilities/lei-events-util';
import { DS_NAME, ENTITY_FIELDS_FOR_EVENT } from './utilities/shared';

const EVENTS_PATH_WO_DS_NAME = ['data', 'entity', 'legalEntityEvents'];
const EVENTS_ARRAY_PATH_WO_DS_NAME = [...EVENTS_PATH_WO_DS_NAME, 'legalEntityEvent'];
const EVENTS_PATH = [DS_NAME, ...EVENTS_PATH_WO_DS_NAME];
const EVENTS_ARRAY_PATH = [...EVENTS_PATH, 'legalEntityEvent'];

export default class FormController {
  /* @ngInject */
  constructor(
    $interval,
    $location,
    $routeParams,
    $scope,
    Alert,
    AlertMessenger,
    FormService,
    ProgressMessenger,
    Redirect,
    loginEmail
  ) {
    const { id, step, fill, cart, loadDraft } = $routeParams;

    this.Service = FormService;
    this.Alert = Alert;
    this.AlertMessenger = AlertMessenger;
    this.ProgressMessenger = ProgressMessenger;
    this.Redirect = Redirect;

    this._onApply = makeOnApply($scope);
    this._legalName = 'legalName';

    const action = (function() {
      const path = $location.path().split('/');
      return path.length > 1 ? path[2] : 'view';
    })();

    this.state = {
      id,
      step,
      submission: fill || cart || null,
      isClone: !!fill,
      isCart: !!cart,
      isNew: ifNew({ action }),
      isUpdate: ifUpdate({ action }),
      isTransfer: ifTransfer({ action }),
      isLapsed: false,
      renewOptIn: false,
      needPayment: false,
      needAuthorization: false,
      needRefFile: false,
      email: textHash(loginEmail),
      action,
      draft: loadDraft,
      available: !loadDraft,
      hasAutoRenew: false,
      disabled: false,
      valid: true,
      dirty: false,
      warn: true,
      schema: null,
      submitted: false,
      compliant: false,
      transferWaiver: false,
      agreement: false,
      visitedEventTab: false,
      goldenCopy: null
    };

    this._draftInterval = function() {
      let interval = this.state.isCart || this.state.draft ? null : $interval(() => true); //this._saveDraft().catch(angular.noop), DRAFT_INTERVAL);

      function cancelInterval() {
        if (!interval) return;
        $interval.cancel(interval);
        interval = null;
      }

      $scope.$on('$destroy', cancelInterval);
      return cancelInterval;
    }.call(this);

    // Create instance bound methods
    this._setPropsFromState = makeOnStateChangeSelector.call(this, ['action'], this._setPropsFromState);
    ['registerFile', 'registerForm', 'registerEvents', 'onSave', 'onSubmit', 'onLoad', 'onGetFormData'].forEach(
      key => (this[key] = this[key].bind(this))
    );
  }

  /******************
   * Helper Methods *
   ******************/

  _saveDraft(isManual) {
    const processedPayload = this.formApi.read(false);
    const processedPayloadWithEvents =
      (this.state.action || 'NEW').toUpperCase() == 'NEW'
        ? processedPayload
        : this.eventsApi.read(processedPayload, { recordedDate: null, revertInProgress: false, applyCompleted: false });
    this._legalName = get(processedPayload, [DS_NAME, 'data', 'entity', 'legalName']);
    return this.Service.saveDraft(processedPayloadWithEvents, this.state, isManual);
  }

  _needRefFile(processedPayload, bbdsEvents = []) {
    const entityCategory = get(processedPayload, [DS_NAME, 'data', 'entity', 'entityCategory']);
    const isFund = entityCategory === 'FUND' && ifAuthorization(this.state); // for create, transfer & transfer-renew

    // if event added
    const submissionEvents = get(processedPayload, EVENTS_ARRAY_PATH, []);
    const eventAdded = submissionEvents.length !== bbdsEvents.length;

    return isFund || eventAdded;
  }

  _replaceFormEventsWithBbdsEvents(processedPayload, bbdsEvents = []) {
    bbdsEvents ? set(processedPayload, EVENTS_ARRAY_PATH, bbdsEvents) : set(processedPayload, EVENTS_PATH, undefined);
  }

  _canBeEventChange(payload) {
    if ((this.state.action || 'NEW').toUpperCase() !== 'NEW') {
      const entityDiff = leiDiff(this.state.goldenCopy.data.entity, payload[DS_NAME].data.entity);
      for (const field in entityDiff) {
        if (ENTITY_FIELDS_FOR_EVENT.includes(field)) {
          return true;
        }
      }
    }
    return false;
  }

  _getLeiInfo(trackId) {
    if (!this.state.id && !this.state.goldenCopy && !this.state.goldenCopy.key)
      return {
        action: 'NA',
        trackId: 'NA',
        legalName: 'NA',
        lei: 'NA'
      };

    const { action, id } = this.state;
    return {
      action: action || 'NA',
      trackId: trackId || 'NA',
      // legalName could be part of updates; use updated legalName from the event;
      legalName: this._legalName,
      lei: id || 'NA'
    };
  }

  _getDataToSubmit({ addToCart = false }) {
    const processedPayload = this.formApi.read();

    this.onLock(true);

    // TODO: formApi shouldn't drop legalEntityRecordedDate
    const bbdsEvents = get(this.eventsApi.bbds(), EVENTS_ARRAY_PATH_WO_DS_NAME);
    this._replaceFormEventsWithBbdsEvents(processedPayload, bbdsEvents);

    // Check for event errors
    const tempProcessedPayload = deepClone(processedPayload);
    const tempProcessedPayloadWithEvents =
      (this.state.action || 'NEW').toUpperCase() == 'NEW'
        ? tempProcessedPayload
        : this.eventsApi.read(tempProcessedPayload, {
            recordedDate: new Date(),
            revertInProgress: true,
            applyCompleted: true
          });

    const eventErrors = flatten(this.eventsApi.errors(tempProcessedPayloadWithEvents));
    const errors = eventErrors;
    console.log(`controller.js> _getDataToSubmit tempProcessedPayloadWithEvents: ${tempProcessedPayloadWithEvents}`);

    // require visiting "EVENTS" tab
    if (!this.state.visitedEventTab && this._canBeEventChange(tempProcessedPayloadWithEvents)) {
      errors.push({
        severity: 'error',
        message: 'You are required to visit the Entity Events tab before you can make this submission.'
      });
    }

    // require ref file for funds
    const needRef = this._needRefFile(tempProcessedPayloadWithEvents, bbdsEvents);
    this.fileApi.setNeedRefFile(needRef);

    errors.push(...getEntityAdditionalSchemaErrors(this.formApi, this.state.isNew));

    if (!this.state.valid) {
      for (const key in this.errors) {
        const chain = new KeyChain(key),
          label = labelCustomizer(chain) || chain.title;

        if (!this._isErrorToIgnore(tempProcessedPayloadWithEvents, chain)) {
          errors.push(
            ...[].concat(this.errors[key]).map(e => ({ severity: 'error', message: e.replace('This field', label) }))
          );
        }
      }
      this.Alert.reset();

      if (errors && errors.length > 0) {
        this.Alert.error(errors);
        return null;
      } else {
        this.state.valid = true;
      }
    }

    const { filesToUpload, fileErrors } = this.fileApi.read();
    errors.push(...fileErrors);

    const customerConsent = {
      acceptedCustomerAgreement: this.state.agreement || false,
      acceptedTransferWaiver: this.state.transferWaiver || false
    };
    errors.push(...checkCustomerConsent(customerConsent, this.state.isTransfer));

    const options = { party: this.fileApi.party(), addToCart: !!addToCart, skipWarnings: !this.state.warn };

    this.AlertMessenger.reset();

    if (errors.length) {
      this.AlertMessenger.alert(errors);
      return null;
    }

    // Prepare events for submit
    const processedPayloadWithEvents =
      (this.state.action || 'NEW').toUpperCase() == 'NEW'
        ? processedPayload
        : this.eventsApi.read(processedPayload, {
            recordedDate: new Date(),
            revertInProgress: true,
            applyCompleted: true
          });

    // when eventsApi.read() returns empty events replace with undefined to abide by schema
    const events = get(processedPayloadWithEvents, EVENTS_ARRAY_PATH, undefined);
    if (!events || events.length == 0) set(processedPayloadWithEvents, EVENTS_PATH, undefined);

    console.log(`controller.js> _getDataToSubmit eventsPayload: ${processedPayloadWithEvents}`);

    return [processedPayloadWithEvents, customerConsent, this.state.id, options, filesToUpload];
  }

  _belongsToTab(key, fieldLabel, tabId) {
    return (
      FORM_SECTIONS[tabId].FIELDS.includes(fieldLabel) || FORM_SECTIONS[tabId].PATHS.some(path => key.startsWith(path))
    );
  }

  _isErrorToIgnore(payload, chain) {
    const entity = get(payload, [DS_NAME, 'data', 'entity']);
    const fundParent = /(managingFundParent|umbrellaFundParent|masterFundParent)/g;

    // ignore fund-parents errors if it's not a fund
    // needed because the parents are technically on the DOM, but with display=none
    return entity.entityCategory !== 'FUND' && fundParent.test(chain.toString());
  }

  _filterValidationsByTab(tabId, payload) {
    if (!this.errors) return [];
    const errors = [];

    for (const key in this.errors) {
      const chain = new KeyChain(key),
        label = labelCustomizer(chain) || chain.title;
      if (tabId === 'RELATIONSHIPS' && this._isErrorToIgnore(payload, chain)) {
        continue;
      }

      if (this._belongsToTab(key, label, tabId)) {
        errors.push(
          ...[]
            .concat(this.errors[key])
            .map(e => ({ severity: 'error', message: `[${tabId}]: ${e.replace('This field', label)}` }))
        );
      }
    }
    return errors;
  }
  /*********************
   * Interface Methods *
   *********************/
  onGetFormData(
    tabId,
    { showErrors = true, revertInProgress = false, applyCompleted = false } = {
      showErrors: true,
      revertInProgress: false,
      applyCompleted: false
    }
  ) {
    if (!this.formApi) return {};

    let payload = this.formApi.read();

    if (!payload) return {};

    const bbdsEvents = get(this.eventsApi.bbds(), EVENTS_ARRAY_PATH_WO_DS_NAME);

    // TODO: formApi shouldn't drop legalEntityRecordedDate
    this._replaceFormEventsWithBbdsEvents(payload, bbdsEvents);

    if (getAction(this.state) !== 'NEW' && this.eventsApi) {
      // By default do not apply updates since returned payload is used to load events tab
      payload = this.eventsApi.read(payload, { recordedDate: new Date(), revertInProgress, applyCompleted });
    }

    if (tabId === 'EVENTS') this.state.visitedEventTab = true;

    this.onLock(true);

    // require ref file for funds
    const needRef = this._needRefFile(payload, bbdsEvents);
    this.fileApi.setNeedRefFile(needRef);

    const errors = [];
    if (tabId === 'ENTITY') {
      errors.push(...getEntityAdditionalSchemaErrors(this.formApi, this.state.isNew));
    }

    if (tabId === 'FILES') {
      const { fileErrors } = this.fileApi.read();
      errors.push(...fileErrors);
    } else if (tabId === 'EVENTS') {
      const eventErrors = flatten(this.eventsApi.errors(payload));
      errors.push(...eventErrors);
    } else {
      errors.push(...this._filterValidationsByTab(tabId, payload));
    }
    this.Alert.reset();

    if (errors.length) {
      if (showErrors) this.Alert.error(errors);
      this.onLock(false);
      return { payload, hasErrors: true };
    }

    this.onLock(false);
    return { payload, hasErrors: false };
  }

  onValidate(valid, errors) {
    this.state.valid = valid;
    this.state.dirty = true;
    this.errors = errors;
    this._onApply();
  }

  onError(...args) {
    return this._saveDraft()
      .catch(angular.noop)
      .then(() => this.Service.error(this.state, ...args));
  }

  onLock(value) {
    this.state.disabled = Boolean(value);
    this.formApi.lock(this.state.disabled);
  }

  onSave() {
    this.state.available = null;
    this.onLock(true);
    this._draftInterval();
    this.ProgressMessenger.reset().progress(20);
    return this._saveDraft(true)
      .then(
        () => this.ProgressMessenger.done(() => this.Alert.success('saveDraft')),
        () => this.Alert.error('saveDraft')
      )
      .then(() => this.onLock(false));
  }

  onSubmit(opts = {}) {
    this.state.submitted = true;
    const dataToSubmit = this._getDataToSubmit(opts);
    this.onLock(true);

    if (!dataToSubmit) {
      this.formApi.expand();
      this.onLock(false);
      return;
    }

    this.Alert.reset();
    this.ProgressMessenger.reset().progress(20);

    return this.Service.submit(this.state, ...dataToSubmit).then(
      res => {
        // submit has DefaultErrorHandler as catch
        // which may resolve undefined after redirect
        // see util.js
        if (res == undefined) return;

        const submissionId = res.data;

        return this._saveDraft().then(() => {
          this._draftInterval();

          return this.ProgressMessenger.done(() => {
            if (opts.addToCart) return this.Redirect.cart();

            this.Alert.success(this.state.action + 'Lei', null, { submissionId });

            // direct user to pay
            if (this.state.needPayment)
              this.Redirect.payment([
                submissionId,
                this._legalName,
                encodeURIComponent(
                  encodeURIComponent(
                    `/leis/${this.state.isTransfer ? 'transfer' : this.state.action}${
                      this.state.isNew ? '' : `/${this.state.id}`
                    }/0`
                  )
                )
              ]);
            else return this.Redirect.thankYou(Object.values(this._getLeiInfo(submissionId)));
          });
        });
      },
      () => {
        this.formApi.expand();
        this.state.warn = false;
        return this.onLock(false);
      }
    );
  }

  onLoad() {
    if (!(this.fileApi && this.formApi && this.eventsApi)) return;

    // reset message bar and clear up any messages
    this.Alert.reset();
    this.ProgressMessenger.reset().progress(50);

    return this.Service.init(this.state).then(store => {
      this.state.schema = store.root;

      this.formApi.init(store, {
        onValidate: this.onValidate.bind(this),
        onError: this.onError.bind(this),
        onNotify: this.onNotify.bind(this)
      });

      this.state.needAuthorization = ifAuthorization(this.state);
      this.fileApi.toggleAuth(this.state.needAuthorization);

      this.Service._payload(this.state).then(draftPayload => {
        this.state.goldenCopy = Object.assign({}, draftPayload);
        this.eventsApi.init(draftPayload);
      });

      return this.ProgressMessenger.done();
    }, angular.noop);
  }

  onNotify(keyChain, fieldValue) {
    onEntityCategoryChange(keyChain, fieldValue, this.state.isNew);
  }

  registerForm(formApi) {
    // Register
    this.formApi = formApi;
    return this.onLoad();
  }

  registerFile(fileApi) {
    // Register
    this.fileApi = fileApi;
    return this.onLoad();
  }

  registerEvents(eventsApi) {
    this.eventsApi = eventsApi;
    return this.onLoad();
  }
}

controller('FormController', FormController);
