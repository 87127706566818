import angular from 'angular';
import { directive, factory } from '../controllers/app';
import moment from '../../../common/lib/moment';
import leiProducts from '../../../common/const/lei-products';
import flashMessageData from '../../../common/const/flash-message-data';
import _ from 'lodash';

import views_templates_accordion from '@lei-website-client/views/templates/accordion.html';
import views_templates_accountPanel from '@lei-website-client/views/templates/accountPanel.html';
import views_templates_accountEmails from '@lei-website-client/views/templates/accountEmails.html';
import views_templates_userProfileAboutYou from '@lei-website-client/views/templates/userProfileAboutYou.html';
import views_templates_userProfileAddress from '@lei-website-client/views/templates/userProfileAddress.html';
import views_templates_userProfileCredential from '@lei-website-client/views/templates/userProfileCredential.html';
import views_templates_userProfileTermsPolicy from '@lei-website-client/views/templates/userProfileTermsPolicy.html';
import views_templates_userProfileThirdParty from '@lei-website-client/views/templates/userProfileThirdParty.html';
import views_templates_userProfileDeveloper from '@lei-website-client/views/templates/userProfileDeveloper.html';
import views_templates_resetAccountPassword from '@lei-website-client/views/templates/resetAccountPassword.html';
import views_templates_forgotAccountPassword from '@lei-website-client/views/templates/forgotAccountPassword.html';
import views_templates_forgotAccountUsername from '@lei-website-client/views/templates/forgotAccountUsername.html';
import views_templates_email from '@lei-website-client/views/templates/email.html';
import views_templates_usernameEmail from '@lei-website-client/views/templates/usernameEmail.html';
import views_templates_login from '@lei-website-client/views/templates/login.html';
import views_templates_twoFactorCodeValidation from '@lei-website-client/views/templates/twoFactorCodeValidation.html';
import views_templates_unlockAccount from '@lei-website-client/views/templates/unlockAccount.html';
import views_templates_feedback from '@lei-website-client/views/templates/feedback.html';
import views_templates_searchUsername from '@lei-website-client/views/templates/searchUsername.html';
import views_templates_searchEmail from '@lei-website-client/views/templates/searchEmail.html';
import views_templates_searchSubmissionId from '@lei-website-client/views/templates/searchSubmissionId.html';
import views_templates_searchAutoRenew from '@lei-website-client/views/templates/searchAutoRenew.html';
import views_templates_adminNote from '@lei-website-client/views/templates/adminNote.html';
import views_templates_adminMessage from '@lei-website-client/views/templates/adminMessage.html';
import views_templates_adminUserEmails from '@lei-website-client/views/templates/adminUserEmails.html';
import views_templates__cardForm from '@lei-website-client/views/templates/_cardForm.html';
import views_templates__autoRenewBatchForm from '@lei-website-client/views/templates/_autoRenewBatchForm.html';
import views_templates__addressForm from '@lei-website-client/views/templates/_addressForm.html';
import views_templates_labeledField from '@lei-website-client/views/templates/labeledField.html';
import views_templates_labeledSelect from '@lei-website-client/views/templates/labeledSelect.html';

directive('accordion', [
  '$location',
  '$timeout',
  '$anchorScroll',
  function($location, $timeout, $anchorScroll) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        header: '@',
        subheader: '@',
        uid: '@',
        id: '@',
        open: '='
      },
      template: views_templates_accordion,
      link: function($scope, $element) {
        var id = $scope.id;
        var $input = $element.find('input');

        if (id && id === $location.hash()) {
          $anchorScroll();
          if (!$input.prop('checked'))
            $timeout(function() {
              $input.trigger('click');
            });
        }
      }
    };
  }
]);

directive('accountPanel', [
  function() {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        title: '@',
        subTitle: '@',
        icon: '@',
        fluid: '='
      },
      template: views_templates_accountPanel
    };
  }
]);

directive('accountEmails', [
  function() {
    return {
      restrict: 'E',
      template: views_templates_accountEmails,
      controller: 'AccountEmailsDirectiveController',
      controllerAs: 'accountEmailsVM',
      scope: {
        accountEmails: '=',
        loginEmail: '=',
        onBehalfOfUserName: '='
      }
    };
  }
]);

factory('ProfilePartialFormDirective', [
  '$timeout',
  function($timeout) {
    return function(config) {
      if (!config.template && !config.templateUrl) {
        throw new Error('template or templateUrl required!');
      }

      var def = {
        restrict: 'E',
        scope: {
          schema: '=',
          vm: '=',
          validate: '=',
          ui: '=',
          onChange: '='
        },
        link: function(scope, element, attrs) {
          var deep = true;
          scope.$watch('vm', scope.onChange, deep);

          if (config.autocomplete === 'off') {
            angular
              .element(element)
              .find('input')
              .attr('autocomplete', 'off');

            $timeout(function() {
              angular
                .element(element)
                .find('input')
                .val('');
            }, 100);
          }
        }
      };

      return angular.merge({}, def, config);
    };
  }
]);

directive('userProfileAboutYou', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_userProfileAboutYou
    });
  }
]);

directive('userProfileAddress', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_userProfileAddress
    });
  }
]);

directive('userProfileCredential', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_userProfileCredential
    });
  }
]);

directive('userProfileTermsPolicy', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_userProfileTermsPolicy
    });
  }
]);

directive('userProfileThirdParty', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_userProfileThirdParty
    });
  }
]);

directive('userProfileDeveloper', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_userProfileDeveloper
    });
  }
]);

directive('resetAccountPassword', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_resetAccountPassword,
      autocomplete: 'off'
    });
  }
]);

directive('forgotAccountPassword', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_forgotAccountPassword,
      autocomplete: 'off'
    });
  }
]);

directive('forgotAccountUsername', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_forgotAccountUsername,
      autocomplete: 'off'
    });
  }
]);

directive('email', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_email,
      autocomplete: 'off'
    });
  }
]);

directive('usernameEmail', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_usernameEmail,
      autocomplete: 'off'
    });
  }
]);

directive('login', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_login,
      autocomplete: 'off'
    });
  }
]);

directive('twoFactorCodeValidation', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_twoFactorCodeValidation,
      autocomplete: 'off'
    });
  }
]);

directive('unlockAccount', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_unlockAccount,
      autocomplete: 'off'
    });
  }
]);

directive('feedback', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_feedback
    });
  }
]);

directive('searchUsername', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_searchUsername,
      autocomplete: 'off'
    });
  }
]);

directive('searchEmail', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_searchEmail,
      autocomplete: 'off'
    });
  }
]);

directive('searchSubmissionId', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_searchSubmissionId,
      autocomplete: 'off'
    });
  }
]);

directive('searchAutoRenew', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_searchAutoRenew,
      autocomplete: 'off'
    });
  }
]);

directive('adminNote', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_adminNote
    });
  }
]);

directive('adminMessage', [
  'ProfilePartialFormDirective',
  function(ProfilePartialFormDirective) {
    return ProfilePartialFormDirective({
      template: views_templates_adminMessage
    });
  }
]);

directive('adminUserEmails', [
  function() {
    return {
      restrict: 'E',
      template: views_templates_adminUserEmails,
      controller: 'AdminUserEmailsDirectiveController',
      controllerAs: 'adminUserEmailsVM',
      scope: {
        userEmails: '=',
        emailApiKeyPairs: '=',
        userLoginUsername: '=',
        userLoginEmail: '=',
        onBehalfOfUserName: '='
      }
    };
  }
]);

directive('cardForm', [
  'ProgressMessenger',
  'Alert',
  function(ProgressMessenger, Alert) {
    return {
      require: '^ngModel',
      restrict: 'AE',
      scope: {
        form: '<',
        action: '<',
        service: '<',
        editable: '<?',
        ngModel: '='
      },
      template: views_templates__cardForm,
      link: function(scope) {
        _loadStripeElements();
        function _loadStripeElements() {
          if (!scope.service.stripe) {
            return;
          } else if (scope.service.element) {
            try {
              scope.service.element.unmount();
            } catch (e) {
              // Nothing to see here
            }
            scope.service.element = undefined;
          }
          var elements = scope.service.stripe.elements();
          scope.service.element = elements.create('card', { hidePostalCode: true, iconStyle: 'solid' });
          scope.service.element.mount('#card-element');
          scope.service.element.addEventListener('change', function(event) {
            ProgressMessenger.reset().progress(20);
            if (event.error) {
              Alert.error(null, event.error.message);
              scope.action.onCardError(true);
            } else {
              Alert.reset();
              scope.action.onCardError(false);
            }
          });
        }

        scope.isDisabled = false;
        scope.months = moment.months;
        scope.years = moment.getYears();
        scope.parseDate = function(month, year) {
          month = ('0' + month).substr(-2);
          year = String(year).substr(-2);
          return month + ' / ' + year;
        };
        scope.$watch('service.stripe', _loadStripeElements);
      }
    };
  }
]);

directive('autoRenewBatchForm', [
  '$rootScope',
  '$location',
  'ProgressMessenger',
  'UserService',
  'CardService',
  'DefaultErrorHandler',
  function($rootScope, $location, ProgressMessenger, UserService, CardService, DefaultErrorHandler) {
    return {
      scope: { renewLeis: '<', keyword: '<?', available: '<?' },
      template: views_templates__autoRenewBatchForm,
      link: function(scope) {
        scope.months = moment.months;
        scope.paymentDef = leiProducts.PAYMENT_TYPES;
        scope.autoRenew = { validVAT: true, showVAT: false };
        scope.days = [];
        CardService.getCards()
          .then(function(res) {
            scope.cards = res.data.data;
            scope.autoRenew.stripeCustomer = res.data.stripeCustomer;
            return UserService.getSettings();
          }, DefaultErrorHandler)
          .then(function(res) {
            if (res && res.data) {
              scope.autoRenew.billingFrequency = res.data.billingFrequency;
              scope.autoRenew.releaseBeforePay = res.data.releaseBeforePay;
              scope.userPaymentTypes =
                res.data.paymentTypes &&
                // ENG4LEI-1052
                // paymentTypes is actually a string returned from the service, but just in case for
                // backward compatibility we keep the array behavior.
                // And it is *not* a serialized JSON string
                (Array.isArray(res.data.paymentTypes) ? res.data.paymentTypes : [res.data.paymentTypes]);
              // This behavior also implies that res.data.billingFrequency === 'MONTHLY'
              scope.paymentDef =
                (scope.userPaymentTypes && _.pick(scope.paymentDef, scope.userPaymentTypes)) || scope.paymentTypeMap;
              scope.autoRenew.paymentType =
                scope.userPaymentTypes && scope.userPaymentTypes.length === 1 && scope.userPaymentTypes[0];
            }
          })
          .catch(angular.noop);

        scope.$watch(
          'renewLeis',
          function(curr, old) {
            scope.autoRenew = scope.autoRenew || { validVAT: true, showVAT: false };
            scope.autoRenew.leis = Object.keys(curr).filter(function(key) {
              return curr[key];
            });
          },
          true
        );

        scope.$watch('autoRenew.paymentType', function(curr, old) {
          if (curr != old) delete scope.autoRenew.card;
        });

        scope.onCardChange = function onCardChange() {
          if (scope.autoRenew.card) {
            scope.vat = scope.autoRenew.card.metadata.vat;
          }
        };
        scope.validateVAT = function validateVat() {
          if (!scope.autoRenew.vat || scope.autoRenew.vat === 'N/A') {
            scope.autoRenew.validVAT = true;
            return;
          }

          return CardService.validateVAT(scope.autoRenew.vat)
            .then(function(res) {
              scope.autoRenew.validVAT = res.data;
            })
            .catch(angular.noop);
        };

        function reloadAutoRenewPage() {
          $location.url('users/submissions?.=6');
          window.location.reload();
        }

        function setupAutoRenew(serviceCall) {
          scope.isDisabled = true;
          ProgressMessenger.reset().progress(20);
          return serviceCall(scope.autoRenew)
            .then(reloadAutoRenewPage)
            .catch(function(ex) {
              var dateError = flashMessageData.invalidAutoRenewDate.error;
              if (ex.data[0].indexOf(dateError.substr(0, dateError.indexOf('%s'))) == 0) scope.showWarning = true;
              var vatError = flashMessageData.vatRequiredAutoRenew.error;
              if (ex.data[0].indexOf(vatError.substr(0, vatError.indexOf('%s'))) == 0) scope.showVAT = true;
              throw ex;
            })
            .catch(DefaultErrorHandler)
            .catch(angular.noop)
            .finally(function() {
              ProgressMessenger.done();
              scope.isDisabled = false;
            });
        }

        scope.onMonthChange = function getDates() {
          scope.days = moment.getDays(scope.autoRenew.month - 1);
        };

        scope.setupByKeyword = function setupAutoRenewByKeyword() {
          scope.autoRenew.keyword = scope.keyword;
          return setupAutoRenew(UserService.setupAutoRenewAll);
        };

        scope.confirmAutoRenewDialog = (message, action, type) => {
          const $ctrl = angular.element('#confirmDialog').scope().$ctrl;
          $ctrl.message = message;
          $ctrl.action = action;
          $ctrl.disclaimer = undefined;
          scope.autoRenew.leisToRemove = undefined;

          if (type === 'batchCancel') {
            scope.autoRenew.leisAffected = [...scope.autoRenew.leis];

            checkIfUnderMinimumInvoiceBatch()
              .then(showDisclaimer => {
                if (showDisclaimer) {
                  $ctrl.disclaimer =
                    "The Auto Renew 'Invoice Me' payment option requires that at least 10 LEIs be renewed on the same day. Consequently, the proposed edit will disable affected LEIs. You may still set up Auto Renew for these by selecting the credit card payment option. Do you wish to proceed?";
                  $ctrl.message = `Cancel ${scope.autoRenew.leisAffected.length} auto renews?`;
                }
              })
              .then(() => {
                $rootScope.$emit('showConfirmDialog');
              });
          } else if (type === 'batchAdd') {
            checkIfAnotherInvoiceBatchIsAffected()
              .then(showDisclaimer => {
                if (showDisclaimer) {
                  $ctrl.disclaimer =
                    "The Auto Renew 'Invoice Me' payment option requires that at least 10 LEIs be renewed on the same day. The proposed edit will disable existing Auto Renews of some LEIs as they will no longer qualify for the Invoice Me option. You may still set up Auto Renew for these by selecting the credit card payment option Do you wish to proceed?";
                  $ctrl.message = `Add/Edit ${scope.autoRenew.leis.length} auto renews?`;
                }
              })
              .then(() => {
                $rootScope.$emit('showConfirmDialog');
              });
          } else {
            $rootScope.$emit('showConfirmDialog');
          }
        };

        async function checkIfUnderMinimumInvoiceBatch() {
          const isReleaseBeforePay = await checkIfReleaseBeforePay();

          if (isReleaseBeforePay) return false;

          const res = await UserService.getActiveAutoRenewsByUsername();
          let otherLeis = [];

          if (angular.isArray(res.data)) {
            res.data.forEach(function(item) {
              if (!scope.autoRenew.leis.includes(item.lei) && item.paymentType === 'BILLME') {
                otherLeis.push(item);
              }
            });
          }
          const [underMin, affected] = hasUnderMinimumBatch(otherLeis);
          if (underMin) scope.autoRenew.leisAffected = [...scope.autoRenew.leis, ...affected];
          return underMin;
        }

        function hasUnderMinimumBatch(leis) {
          let frequencies = {};
          leis.forEach(({ lei, renewDate }) => {
            const date = renewDate.substr(5, 5); // get MM-DD
            if (!frequencies[date]) frequencies[date] = [];
            frequencies[date].push(lei);
          });

          let underMin = false;
          let affected = [];
          for (const date in frequencies) {
            if (frequencies[date].length < 10) {
              affected.push(...frequencies[date]);
              underMin = true;
            }
          }
          return [underMin, affected];
        }

        async function checkIfReleaseBeforePay() {
          const userSettingsResponse = await UserService.getSettings();
          const { endTime, billingFrequency, releaseBeforePay } = userSettingsResponse.data || {};
          console.log(
            'childForms.js> checkIfReleaseBeforePay() endTime:',
            endTime,
            ', billingFrequency:',
            billingFrequency,
            ', releaseBeforePay:',
            releaseBeforePay
          );

          if (userSettingsResponse.data && (!endTime || new Date(endTime) > new Date()) && releaseBeforePay === 1)
            return true;
          return false;
        }

        async function checkIfAnotherInvoiceBatchIsAffected() {
          const isReleaseBeforePay = await checkIfReleaseBeforePay();
          // when both month and year are null/undefined/NaN return true (credit card update)
          if (
            ((scope.autoRenew.month === null || isNaN(scope.autoRenew.month)) &&
              (scope.autoRenew.day === null || isNaN(scope.autoRenew.day))) ||
            isReleaseBeforePay
          )
            return false;

          const res = await UserService.getActiveAutoRenewsByUsername();
          let invoiceLeis = [];
          let hasAutoRenewDate = false; // check if autorenew date (month-day) is already set for other leis
          const monthStr = `0${scope.autoRenew.month.toString()}`.substr(-2);
          const dayStr = `0${scope.autoRenew.day.toString()}`.substr(-2);

          if (angular.isArray(res.data)) {
            res.data.forEach(({ lei, paymentType, renewDate }) => {
              if (!scope.autoRenew.leis.includes(lei) && paymentType === 'BILLME') {
                invoiceLeis.push({ lei, renewDate });
                hasAutoRenewDate = hasAutoRenewDate || renewDate.substr(5, 5) === `${monthStr}-${dayStr}`;
              }
            });
          }

          const renewDate = `2001-${monthStr}-${dayStr}`;
          if (scope.autoRenew.paymentType === 'BILLME' && hasAutoRenewDate) {
            scope.autoRenew.leis.forEach(lei => invoiceLeis.push({ lei, renewDate }));
          }
          const [underMin, affected] = hasUnderMinimumBatch(invoiceLeis);
          if (underMin) scope.autoRenew.leisToRemove = [...affected];
          return underMin;
        }

        scope.cancelAll = function cancelAutoRenewAll() {
          scope.isDisabled = true;
          return UserService.cancelAutoRenewAll(scope.keyword)
            .then(reloadAutoRenewPage, DefaultErrorHandler)
            .catch(angular.noop)
            .finally(() => {
              scope.isDisabled = false;
            });
        };

        scope.setup = setupAutoRenew.bind(null, UserService.setupAutoRenewBatch);

        scope.cancel = function deleteAutoRenewBatch() {
          scope.isDisabled = true;
          const { leis, leisAffected } = scope.autoRenew;
          const autoRenewsToCancel = leisAffected ? leisAffected : leis;
          return UserService.deleteAutoRenewBatch(autoRenewsToCancel)
            .then(reloadAutoRenewPage, DefaultErrorHandler)
            .catch(angular.noop)
            .finally(() => {
              scope.isDisabled = false;
            });
        };
      }
    };
  }
]);

directive('addressForm', [
  function() {
    return {
      require: '^ngModel',
      restrict: 'AE',
      scope: {
        prefix: '@',
        data: '<',
        form: '<',
        phone: '<?',
        action: '<',
        company: '<?',
        zipOptional: '<?',
        ngModel: '='
      },
      template: views_templates__addressForm,
      link: function(scope, element, attrs) {
        function _onUpdateCompany() {
          scope.$company = scope.company ? undefined : false;
        }

        _onUpdateCompany();
        scope.$watch('company', _onUpdateCompany);

        function _onUpdateZipOptional() {
          scope.$zipOptional = scope.zipOptional ? true : false;
        }

        _onUpdateZipOptional();
        scope.$watch('zipOptional', _onUpdateZipOptional);
      }
    };
  }
]);

directive('labeledField', [
  '$timeout',
  function($timeout) {
    return {
      require: '^ngModel',
      restrict: 'AE',
      scope: {
        name: '@',
        label: '@',
        pattern: '@',
        message: '@',
        placeholder: '@',
        autocomplete: '@?',
        form: '<',
        error: '<?',
        schema: '<?',
        srOnly: '<?',
        spacing: '<?',
        mandatory: '<?',
        maxlength: '<?',
        ngModel: '=',
        ngChange: '&'
      },
      template: views_templates_labeledField,
      link: function(scope) {
        function onUpdateMandatory() {
          scope.$mandatory =
            scope.mandatory == undefined || scope.mandatory == null ? scope.schema.$required : scope.mandatory;
        }

        scope.schema = scope.schema || {};
        onUpdateMandatory();

        scope.$watch('mandatory', onUpdateMandatory);
        scope.$watch('ngModel', function() {
          scope.ngChange();
        });
      }
    };
  }
]);

directive('labeledSelect', [
  '$timeout',
  function($timeout) {
    return {
      require: '^ngModel',
      restrict: 'AE',
      scope: {
        name: '@',
        label: '@',
        format: '@',
        message: '@',
        placeholder: '@',
        form: '<',
        items: '<',
        error: '<?',
        schema: '<?',
        srOnly: '<?',
        spacing: '<?',
        mandatory: '<?',
        ngModel: '=',
        ngChange: '&'
      },
      template: views_templates_labeledSelect,
      link: function(scope) {
        function onUpdateMandatory() {
          scope.$mandatory =
            scope.mandatory == undefined || scope.mandatory == null ? scope.schema.$required : scope.mandatory;
        }

        scope.schema = scope.schema || {};
        onUpdateMandatory();

        scope.$watch('mandatory', onUpdateMandatory);
        scope.$watch('ngModel', function() {
          scope.ngChange();
        });
      }
    };
  }
]);
