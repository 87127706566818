export const DS_NAME = 'com.bloomberg.ds.LEISubmissionv2';
export const CONSOLIDATED_PARENTS = ['directParent', 'ultimateParent'];
export const FUND_PARENTS = ['managingFundParent', 'umbrellaFundParent', 'masterFundParent'];
export const BRANCH_PARENT = ['branchParent'];
export const ENTITY_FIELDS_FOR_EVENT = [
  'legalName',
  'legalNameInEnglish',
  'otherEntityNames',
  'entityCategory',
  'entityLegalFormCode',
  'legalForm',
  'legalJurisdictionCountry',
  'legalJurisdictionRegion',
  'registrationAuthoritySource',
  'legalAddressInEnglish',
  'headquartersAddressInEnglish'
];
