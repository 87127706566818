'use strict';

// @TODO, start to consolidate all the text labels on the web pages to this file
// need string format functionality in controller or filter in html
module.exports.newLEI = {
  title: 'Create LEI Application',
  instruction:
    'To request a single new LEI, click the Checkout button on the right side of the page. On successful completion you will receive a tracking number. You can check the status of your submission on the My Requests tab. To save a draft of your submission, click on the Save Draft button.',
  actionButton: 'Checkout',
  saveButton: 'Save Draft',
  draftButton: 'Load unsubmitted draft'
};

module.exports.challengeLEI = {
  title: 'Challenge LEI Application',
  instruction:
    'To challenge a single LEI, click the Submit Challenge button at the bottom of the page. On successful completion you will receive a tracking number. You can check the status of your submission on the My Requests tab.<br><br>If you would like to challenge a non-Bloomberg managed LEI, please visit the Global LEI Foundation website by clicking <a href="https://www.gleif.org/en/lei/search" target="_gleif">here</a>.<br><br>If you would like to search a LEI, please click <a href="./search" target="_search">here</a> to be taken to the Search page.',
  saveButton: 'Save Draft',
  actionButton: 'Submit Challenge',
  draftButton: 'Load unsubmitted draft'
};

module.exports.renewLEI = {
  title: 'Renew LEI Application',
  instruction:
    'To renew a single LEI, click the Checkout button on the right side of the page. On successful completion you will receive a tracking number. You can check the status of your submission on the My Requests tab. To save a draft of your submission, click on the Save Draft button.',
  saveButton: 'Save Draft',
  actionButton: 'Checkout',
  draftButton: 'Load unsubmitted draft'
};

module.exports.updateLEI = {
  title: 'Update LEI Application',
  instruction:
    'To update a single LEI, click the Submit button on the right side of the page. On successful completion you will receive a tracking number. You can check the status of your submission on the My Requests tab. To save a draft of your submission, click on the Save Draft button.',
  saveButton: 'Save Draft',
  actionButton: 'Submit',
  renewLink: 'Renew this LEI',
  draftButton: 'Load unsubmitted draft'
};

module.exports.transferLEI = {
  title: 'Transfer LEI Application',
  step1: {
    title: 'Transfer LEI Application',
    instruction:
      'To transfer a single LEI to Bloomberg Finance LP, click Search at the right of the page. On successful completion you will review the LEI and make any necessary changes before submission, then you can check the status of your request on the My Requests tab. To locate your LEI, please click <a href="./search" target="_search">here</a> to be taken to the Search page that contains a transfer link which will return you to the transfer form for your selected LEI.',
    actionButton: 'Search'
  },
  step2: {
    title: 'Transfer LEI Application',
    instruction:
      'To transfer this LEI, fill in all required fields including "Entity Type", and click the Transfer button on the right side of the page. On successful completion you will receive a tracking number. You can check the status of your transfer on the My Requests tab. To save a draft of your submission, click on the Save Draft button.',
    transferButton: 'Transfer',
    transferRenewButton: 'Checkout',
    addCartButton: 'Add To Cart',
    saveButton: 'Save Draft',
    draftButton: 'Load unsubmitted draft'
  }
};

module.exports.transferRenewLEI = {
  step1: {
    title: 'Transfer LEI Application',
    instruction:
      'To transfer a single LEI to Bloomberg Finance LP, click Search at the bottom of the page. On successful completion you will review the LEI and make any necessary changes before submission, then you can check the status of your request on the My Requests tab. To locate your LEI, please click <a href="./search" target="_search">here</a> to be taken to the Search page that contains a transfer link which will return you to the transfer form for your selected LEI.',
    actionButton: 'Search'
  },
  step2: {
    title: 'Transfer LEI Application',
    instruction:
      'Attention: The current registration status of the selected LEI is Lapsed. In order for this LEI record to be successfully transferred, you will also be asked to submit payment details for renewal after completion of this form. To save a draft of your submission, click on the Save Draft button.',
    transferRenewButton: 'Checkout',
    saveButton: 'Save Draft',
    addCartButton: 'Add To Cart',
    draftButton: 'Load unsubmitted draft'
  }
};

module.exports.viewLEI = {
  challengeLink: 'Click here to challenge this LEI'
};

module.exports.payment = {
  title: 'Payment Form',
  instruction: {
    init:
      'Please finish the payment before you submit another LEI request, otherwise your current submission data will be cleared.',
    paid:
      'Your LEI request has been successfully submitted. The submission will be reviewed by our analysts and we will get back to you as soon as we process the request. Please note that an invoice will be sent after we complete the request. Thank you for using Bloomberg LEI.'
  },
  stripe_info:
    'We\'ve partnered with Stripe to manage payment processing. Stripe will collect and process your payment processing data. <a href="https://stripe.com/us/terms">Stripe\'s Terms of Service</a> and <a href="https://stripe.com/us/privacy">Privacy Policy</a> govern your rights and liabilities in relation to the payment processing data you provide to them. Bloomberg does not receive or have access to your payment processing data.',
  tax_info:
    "The tax charged (if applicable) is determined based on the domicile country provided by the user on the payment details form. Bloomberg assumes the domicile country is the country most closely connected to the user and therefore considered to be the place of supply for tax purposes. The tax amount shown above is provisional and the tax amount charged/billed will be subject to Bloomberg's validation of the Domestic Tax Number (VAT/GST), where provided.",
  total_price:
    'The total price listed above is an estimate based on approval of all requests. Should any requests be rejected, your final costs may be lower. Users will not be charged/billed any amount above the estimate.'
};
