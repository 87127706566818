import angular from 'angular';
import _ from 'lodash';
import { factory } from './app';
import { NO_CACHE } from '../utils/constants';
import passwordHash from '../../../common/lib/password-hash';
import { schemaStore } from '../../../common/validate';

factory('UserService', [
  '$rootScope',
  '$http',
  '$httpParamSerializer',
  function($rootScope, $http, httpParamSerializer) {
    var assign = angular.extend;

    return {
      getSubmissions: getSubmissions,
      getLeis: getLeis,
      getLeiSubmission: getLeiSubmission,
      signUp: signUp,
      logout: logout,

      getUserProfile: getUserProfile,
      getAccountEmails: getAccountEmails,
      getUserInfo,
      addAccountEmail: addAccountEmail,
      removeAccountEmail: removeAccountEmail,
      updateUserProfile: updateUserProfile,
      validateUser: validateUser,
      validateTwoFactorCode: validateTwoFactorCode,
      sendUnlockAccountCode: sendUnlockAccountCode,
      validateUnlockCode: validateUnlockCode,
      generateResetPasswordKey: generateResetPasswordKey,
      resetPassword: resetPassword,
      handleForgotUsername: handleForgotUsername,
      getApiKeyByUsermail: getApiKeyByUsermail,
      ownershipTransfer: ownershipTransfer,
      setupAutoRenewBatch: setupAutoRenewBatch,
      setupAutoRenewAll: setupAutoRenewAll,
      getAutoRenewalByLei: getAutoRenewalByLei,
      getAutoRenew: getAutoRenew,
      getAutoRenewalByLeis: getAutoRenewalByLeis,
      getActiveAutoRenewsByUsername: getActiveAutoRenewsByUsername,
      getSettings: getSettings,
      getBillingClass: getBillingClass,
      deleteAutoRenew: deleteAutoRenew,
      deleteAutoRenewBatch: deleteAutoRenewBatch,
      cancelAutoRenewAll: cancelAutoRenewAll
    };

    function signUp(profile, captchaResponse) {
      var profileWithPasswordHashed = assign({}, profile);

      profileWithPasswordHashed.password = passwordHash(profileWithPasswordHashed.password);
      profileWithPasswordHashed.repeatPassword = passwordHash(profileWithPasswordHashed.repeatPassword);

      var options = {
        method: 'POST',
        url: '/user/signUp',
        data: {
          profile: profileWithPasswordHashed,
          captchaResponse: captchaResponse
        }
      };

      return $http(options);
    }

    function logout() {
      var options = {
        method: 'POST',
        url: '/user/logout'
      };

      return $http(options);
    }

    function getLeiSubmission(leiSubmissionId, raw) {
      var options = {
        method: 'GET',
        url: '/user/submissions/' + leiSubmissionId,
        params: {
          raw: raw ? 1 : null
        }
      };

      return $http(options);
    }

    function ownershipTransfer(data) {
      var options = {
        method: 'POST',
        url: '/lei/ownershipTransfer',
        data: data
      };
      return $http(options);
    }

    function setupAutoRenewBatch(autoRenew) {
      var options = {
        method: 'POST',
        url: '/lei/autoRenewBatch',
        data: autoRenew
      };
      return $http(options);
    }

    function setupAutoRenewAll(autoRenew) {
      var options = {
        method: 'POST',
        url: '/lei/autoRenewAll',
        data: autoRenew
      };
      return $http(options);
    }

    function getAutoRenew(offset, limit, sortDef, filter) {
      var options = {
        method: 'GET',
        url: '/user/autoRenewals',
        params: {
          _sort: sortDef,
          _offset: offset,
          _limit: limit,
          filter: filter
        },
        headers: NO_CACHE
      };
      return $http(options);
    }

    function getAutoRenewalByLei(lei) {
      var options = {
        method: 'GET',
        url: '/user/autoRenewalByLei/' + lei,
        headers: NO_CACHE
      };
      return $http(options);
    }

    function getAutoRenewalByLeis(leis) {
      var options = {
        method: 'GET',
        url: '/user/autoRenewalByLeis?' + httpParamSerializer({ leis: leis }),
        headers: NO_CACHE
      };
      return $http(options);
    }

    function getActiveAutoRenewsByUsername() {
      var options = {
        method: 'GET',
        url: '/user/activeAutoRenewsByUsername',
        headers: NO_CACHE
      };
      return $http(options);
    }

    function deleteAutoRenew(lei) {
      var options = {
        method: 'DELETE',
        url: '/user/autoRenew/' + lei
      };
      return $http(options);
    }

    function cancelAutoRenewAll(keyword) {
      var options = {
        method: 'DELETE',
        url: '/user/autoRenewAll',
        params: {
          keyword: keyword
        }
      };
      return $http(options);
    }

    function deleteAutoRenewBatch(leis) {
      var options = {
        method: 'DELETE',
        url: '/user/autoRenewBatch/',
        params: {
          leis: leis
        }
      };
      return $http(options);
    }

    function getSettings() {
      var options = {
        method: 'GET',
        url: '/user/settings'
      };
      return $http(options);
    }

    function getBillingClass(defaultBillingClass) {
      var options = {
        method: 'GET',
        url: '/user/billingClass',
        params: {
          defaultBillingClass
        }
      };
      return $http(options);
    }

    function getSubmissions(
      _offset,
      _limit,
      _sort,
      _inclusive,
      _filter,
      likeHumanReadableWorkId,
      likeEmail,
      likeFinalId,
      likeSubmissionTime,
      likePaymentAmount,
      likeLegalName,
      exclusiveFilterNamedParams
    ) {
      if (exclusiveFilterNamedParams) {
        var f,
          params = exclusiveFilterNamedParams.slice();
        exclusiveFilterNamedParams = {};
        while ((f = params.pop())) {
          if (f && f.field && f.value) {
            exclusiveFilterNamedParams['like' + f.field.slice(0, 1).toUpperCase() + f.field.slice(1) + '2'] = f.value;
          }
        }
      }

      var options = {
        method: 'GET',
        url: '/user/submissions',
        headers: NO_CACHE,
        params: angular.extend(exclusiveFilterNamedParams || {}, {
          _offset: _offset,
          _limit: _limit,
          _sort: _sort,
          _inclusive: _inclusive,
          _filter: _filter,
          likeHumanReadableWorkId: likeHumanReadableWorkId,
          likeEmail: likeEmail,
          likeFinalId: likeFinalId,
          likeSubmissionTime: likeSubmissionTime,
          likePaymentAmount: likePaymentAmount,
          likeLegalName: likeLegalName
        })
      };

      return $http(options);
    }

    function getLeis(_offset, _limit, _sort, _fields, filter, numFilter, namedFilter) {
      var params = {
        _offset: _offset,
        _limit: _limit,
        _sort: _sort,
        _fields: _fields,
        filter: filter,
        namedFilter: namedFilter,
        enrich: true
      };

      $rootScope.$emit('page-fetch', Object.assign({}, params));

      var options = {
        method: 'GET',
        url: '/lei',
        headers: NO_CACHE,
        params: params
      };

      return $http(options);
    }

    function getUserProfile() {
      var options = {
        method: 'GET',
        url: '/user/profile',
        headers: NO_CACHE
      };

      return $http(options);
    }

    function getUserInfo() {
      var options = {
        method: 'GET',
        url: '/user'
      };

      return $http(options);
    }

    function getAccountEmails() {
      var options = {
        method: 'GET',
        url: '/user/account/emails',
        headers: NO_CACHE
      };

      return $http(options);
    }

    function addAccountEmail(email) {
      var options = {
        method: 'PUT',
        url: '/user/account/emails',
        data: {
          email: email
        }
      };

      return $http(options);
    }

    function removeAccountEmail(email) {
      var options = {
        method: 'POST',
        url: '/user/account/emails/remove',
        data: {
          email: email
        }
      };

      return $http(options);
    }

    function updateUserProfile(profile) {
      var options = {
        method: 'PUT',
        url: '/user/profile',
        data: profile
      };

      return $http(options);
    }

    function validateUser(username, password, email) {
      var userWithPasswordHashed = {
        username: username,
        password: passwordHash(password),
        email: email
      };

      var options = {
        method: 'POST',
        url: '/user',
        data: userWithPasswordHashed
      };

      return $http(options);
    }

    function validateTwoFactorCode(code) {
      var options = {
        method: 'POST',
        url: '/user/two-factor-code',
        data: {
          twoFactorCode: code
        }
      };

      return $http(options);
    }

    function validateUnlockCode(username, code) {
      var options = {
        method: 'POST',
        url: '/user/unlock-code',
        data: {
          username: username,
          unlockAccountCode: code
        }
      };

      return $http(options);
    }

    function sendUnlockAccountCode(username, email) {
      var options = {
        method: 'PUT',
        url: '/user/unlock-code',
        data: {
          username: username,
          email: email
        }
      };

      return $http(options);
    }

    function generateResetPasswordKey(username, email) {
      var options = {
        method: 'POST',
        url: '/user/generateResetPasswordKey',
        data: {
          username: username,
          email: email
        }
      };

      return $http(options);
    }

    function resetPassword(username, email, resetPasswordKey, newPassword) {
      var hashedPassword = passwordHash(newPassword);

      var options = {
        method: 'POST',
        url: '/user/resetPassword',
        data: {
          username: username,
          email: email,
          resetPasswordKey: resetPasswordKey,
          newPassword: hashedPassword
        }
      };

      return $http(options);
    }

    function handleForgotUsername(email) {
      var options = {
        method: 'POST',
        url: '/user/handleForgotUsername',
        data: {
          email: email
        }
      };
      return $http(options);
    }

    function getApiKeyByUsermail(username, email) {
      var options = {
        method: 'GET',
        url: '/user/account/api-key',
        data: {
          username: username,
          email: email
        }
      };
      return $http(options);
    }
  }
]);

factory('phoneBackfill', [
  'UserService',
  function(UserService) {
    var updateProfileSchema = schemaStore.get('updateProfile');
    // pre-process before sending to UI
    return function(response) {
      // If country code has been processed, don't process again
      if (!response.data || !response.data.phoneCountryCode || response.data.phoneCountryCode.charAt(0) !== '+')
        return response;

      var profile = response.data;
      var options = _.filter(updateProfileSchema.phoneCountryCode.$options, function(phone) {
        return profile.phoneCountryCode === phone.dial_code;
      });
      var countries = _.filter(updateProfileSchema.country.$options, function(country) {
        return profile.country === country.code;
      });

      // if more than one country match that dial_code
      if (options.length > 1)
        // filter by country name for a match
        options = _.filter(options, function(phone) {
          return countries.length > 0 && countries[0].name === phone.name;
        });

      // if no match found, UI will display its placeholder
      // but value will be as follows:
      profile.phoneCountryCode =
        options.length === 0 ? '__' + profile.phoneCountryCode : options[0].code + profile.phoneCountryCode;

      // will call user update profile without user
      // submitting a change
      return UserService.updateUserProfile(profile)
        .then(function() {
          return response;
        })
        .catch(function() {
          return response;
        });
    };
  }
]);
