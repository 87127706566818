import { controller } from './app';
import _ from 'lodash';
import angular from 'angular';
import FileSaver from 'file-saver';
import { BULK_STAGES, BULK_STEPS, BULK_UPDATE_STAGES, BULK_UPDATE_STEPS } from '../utils/constants';
import fileUpload from '../../../common/const/file-upload';
import flashMessageData from '../../../common/const/flash-message-data';
import { mustBeRenewedLei } from '../../../common/lib/transfer-renew-check';
import attachmentChecker from '../../../common/lib/attachment-check';
import { addDerivedEventFieldsToPayloads } from '../../../common/lib/common-events-util';

// Form Generation Controller (Uses BBDSCATALOGSVC schema to create a form generation view)

// Controller for creating new LEIs or renew LEIs in bulk
controller(
  'BulkLeiController',
  /* @ngInject */
  function BulkLeiController(
    $scope,
    $routeParams,
    $q,
    LeiService,
    DefaultErrorHandler,
    ProgressMessenger,
    AlertMessenger,
    Alert,
    StateManager,
    Redirect,
    UserService
  ) {
    // variables
    // action can be new, update, renew
    $scope.action = $routeParams.action || 'new';
    var PAID_ACTIONS = ['new', 'renew', 'transfer_renew'];
    var AUTH_ACTIONS = ['new', 'transfer', 'transfer_renew'];
    $scope.secLabel =
      {
        update: 'Update',
        new: 'Create',
        renew: 'Renew',
        transfer: 'Transfer'
      }[$scope.action] + ' LEI Application';

    $scope.fileUpload = fileUpload;
    var flashMessages = flashMessageData;
    // var MAXIMUM_TRACKING_NUM = 10;
    var FORMAT = { EXCEL: 'EXCEL', JSON: 'JSON', NONE: 'NONE' };
    var STAGE = $scope.action === 'update' || $scope.action === 'transfer' ? BULK_UPDATE_STAGES : BULK_STAGES;
    $scope.STAGE = STAGE;
    $scope.FORMAT = FORMAT;
    $scope.currentStage = STAGE.SUBMIT;
    $scope.currentFormat = FORMAT.EXCEL;
    $scope.excel = { file: {} };
    $scope.bulkAttachments = { models: { files: [], authForms: [], refFiles: [] } };

    $scope.activeFragment = 0;

    // actions
    $scope.publishBulkPayload = publishBulkPayload;
    $scope.clearFormAndPayloads = clearFormAndPayloads;
    $scope.updateSelectedPayload = updateSelectedPayload;

    $scope.wizardSteps = $scope.action === 'update' || $scope.action === 'transfer' ? BULK_UPDATE_STEPS : BULK_STEPS;
    $scope.isExportingBulkLEI = false;

    $scope.$watch('currentStage', function(stage) {
      $scope.wizardSteps = $scope.wizardSteps.map(function(w) {
        w.active = stage >= w.stage;
        return w;
      });
    });

    $scope.bulkLeiIdsToExport;

    $scope.updateBulkLeiIdsToExport = function(input) {
      var lines = input.match(/[^\r\n]+/g);

      if (!Array.isArray(lines)) {
        $scope.bulkLeiIdsToExport = null;
        return;
      }

      $scope.bulkLeiIdsToExport = lines.map(function(l) {
        return l.trim();
      });
    };

    $scope.handleExportForTransfer = function() {
      $scope.isExportingBulkLEI = true;
      LeiService.handleExportForTransfer($scope.bulkLeiIdsToExport)
        .then(res => {
          //Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
          //Content-Disposition: attachment; filename="LEI Bulk - 1 items.xlsx"
          $scope.isExportingBulkLEI = false;
          const { data, status } = res;
          if (status < 400) {
            var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'LEI Bulk.xlsx');
          }
        })
        .catch(err => {
          $scope.isExportingBulkLEI = false;
          const arr = new Uint8Array(err.data);
          const str = String.fromCharCode.apply(String, arr);
          try {
            err.data = JSON.parse(str);
          } catch {
            err.data = str;
          }
          throw err;
        })
        .catch(DefaultErrorHandler);
    };

    load();

    // function definitions
    /*
     * load initial data for view
     */
    function load() {
      // initialize view
      ProgressMessenger.reset().progress(50);
      AlertMessenger.reset();

      LeiService.getBulkExcelTemplate()
        .then(function(res) {
          var data = res.data;
          $scope.excelTemplateUrl = data[$scope.action];
          $scope.sampleResources = data.samples.map(function freshlink(sa) {
            sa.url = sa.url + '?fresh=' + Date.now();
            return sa;
          });
        })
        .catch(DefaultErrorHandler);

      $scope.cdnPrefix = __CDN__;
    }

    // the dropdown change event is not propagated to model somehow
    function updateSelectedPayload(index) {
      $scope.activeFragment = index;
    }

    $scope.setSubmissionFormat = function(format) {
      clearFormAndPayloads();
      $scope.currentFormat = format;
      $scope.currentStage = STAGE.SUBMIT;
      AlertMessenger.reset();
    };

    $scope.beforeModelChanged = function() {
      console.log('beforeModelChanged');
      ProgressMessenger.reset().progress(25);
      AlertMessenger.info('Processing files... this may take several seconds...');
    };

    $scope.deleteAttachment = function(type, file) {
      $scope.bulkAttachments.models[type] = $scope.bulkAttachments.models[type].filter(function(f) {
        return f.name != file.name;
      });
    };

    $scope.dragAttachments = function(x, xx, newFiles) {
      console.log('dragAttachments');
      AlertMessenger.reset();
    };

    $scope.handleExcelWorkbook = function(excelWorkbook) {
      if (!(excelWorkbook || {}).name) {
        return AlertMessenger.alert('Please select an Excel workbook to upload.');
      }

      StateManager.lockUI();
      $scope.uploading = StateManager.uiIsLocked();
      ProgressMessenger.reset().progress(25);
      AlertMessenger.info('This may take several seconds...');

      var action2validationService = {
        new: LeiService.uploadAndValidateExcel,
        update: LeiService.validateExcelForUpdateRenew,
        renew: LeiService.validateExcelForUpdateRenew,
        transfer: LeiService.validateExcelForTransfer
      };

      var validationService = action2validationService[$scope.action];

      validationService(excelWorkbook)
        .then(async function(res) {
          var data = res.data;
          console.log(data);
          /**
           * without error data is like [ { payload: { version: '5.0', data: [Object] },
           * metadata: { attachments: 'a.doc | b.doc' }  } ]
           */
          if (data.errorMessage) {
            AlertMessenger.alert(data.errorMessage);
            clearFormAndPayloads();
          } else if (data) {
            $scope.excelToken = data.token;
            var registrationStatus = data[0].metadata.registrationStatus;
            var renewDate = data[0].metadata.renewDate;
            const dataWithEventGroupFields = await addDerivedEventFieldsToPayloads(data, LeiService.getEventIds);
            $scope.bulkPayload = mergeExcelPayloadMetadata(dataWithEventGroupFields);
            console.log($scope.bulkPayload);

            // for transfer_renew case, set action type based on registrationStatus
            if (
              $scope.action == 'transfer' &&
              (registrationStatus === 'LAPSED' || (renewDate && mustBeRenewedLei(registrationStatus, renewDate)))
            ) {
              $scope.action = 'transfer_renew';
            }

            $scope.currentStage = STAGE.PUBLISH;
            $scope.activeFragment = 0;

            AlertMessenger.reset();
          } else {
            return $q.reject();
          }
        })
        .then(function() {
          if ($scope.action === 'renew') {
            var leis = $scope.bulkPayload.reduce(function(ids, payload) {
              ids.push(payload.__lei);
              return ids;
            }, []);
            return UserService.getAutoRenewalByLeis(leis) // get auto renews by leis so we can warn users
              .then(function(res) {
                $scope.hasAutorenew = !!res.data.length;
                $scope.leiHasAutorenew = (res.data[0] || {}).lei;
              })
              .catch(function(ex) {
                // catch non-critical step error here without affecting user submitting renew
                console.error(ex);
              });
          }
        })
        .catch(function(err) {
          console.log(err);

          if (err.status >= 400 && err.status < 500) {
            AlertMessenger.alert(err.data);
          } else {
            AlertMessenger.alert(
              'There was a problem with your Excel submission.  Please try again later or contact an administrator.'
            );
          }

          $scope.bulkPayload = null;
        })
        .finally(function() {
          StateManager.unlockUI();
          ProgressMessenger.done();
          $scope.uploading = StateManager.uiIsLocked();
        });
    };

    function mergeExcelPayloadMetadata(payloads) {
      return payloads.map(function(p) {
        if (_.has(p, 'metadata.references'))
          _.set(p.payload.data, '__attachments', p.metadata.references.split('|').map(_.trim));

        if (_.has(p, 'metadata.authorizationForm'))
          p.payload.data.__attachments = (p.payload.data.__attachments || []).concat(p.metadata.authorizationForm);

        if (_.has(p, 'metadata.lei')) _.set(p.payload.data, '__lei', p.metadata.lei);
        if (_.has(p, 'metadata.referenceId')) _.set(p.payload.data, '__referenceId', p.metadata.referenceId);

        return p.payload.data;
      });
    }

    function clearFormAndPayloads() {
      $scope.currentStage = STAGE.FORMAT_SELECT;
      $scope.currentFormat = FORMAT.NONE;
      $scope.bulkPayload = null;
      $scope.successSubmissionIds = null;
      $scope.excel = { file: {} };
      $scope.bulkAttachments = { models: { files: [], authForms: [], refFiles: [] } };
      $scope.bulkFileGroups = {};
      $scope.excel.form.$setPristine();
      $scope.publishOptions = {};
      $scope.attachmentTracker = undefined;
    }

    /**
     * create/renew lei in bulk
     * @return {Promise}        Promise by of the creation
     */
    function publishBulkPayload(bulkPayload, renewOptIn) {
      console.log(renewOptIn, bulkPayload, $scope.bulkAttachments.models);

      AlertMessenger.reset();
      ProgressMessenger.reset().progress(20);
      var customerActions = {
        acceptedCustomerAgreement: $scope.serviceCustomerAgreement || false,
        renewOptIn: renewOptIn === true,
        acceptedTransferWaiver: $scope.transferWaiver
      };

      if (renewOptIn === true) {
        $scope.action = 'transfer_renew';
      }

      var bulkSubmissionFn = {
        new: LeiService.bulkNew,
        renew: LeiService.bulkRenew,
        update: LeiService.bulkUpdate,
        transfer: LeiService.bulkTransfer,
        transfer_renew: LeiService.bulkTransferRenew
      }[$scope.action];

      if (!areAttachmentsOk(bulkPayload)) {
        return;
      }

      var files = $scope.bulkAttachments.models.refFiles.concat($scope.bulkAttachments.models.authForms);
      return bulkSubmissionFn(JSON.stringify(bulkPayload), JSON.stringify(customerActions), files)
        .then(function(res) {
          let submissionIds, humanReadableWorkIds;
          if (Array.isArray(res.data)) {
            submissionIds = res.data;
            humanReadableWorkIds = res.data;
          } else {
            // bulkUpdate and bulkTransfer
            submissionIds = res.data.submissionIds || [];
            humanReadableWorkIds = res.data.humanReadableWorkIds || [];
          }
          $scope.transactionNumber = submissionIds;

          if (PAID_ACTIONS.includes($scope.action)) {
            Alert.success('bulkSubmission');
            // direct user to pay
            // Check workitems in excel
            let legalName;
            legalName = 'See Excel WorkBook for more details';
            if (bulkPayload.length === 1) {
              // Try to get specific legal name if there is only one item on worksheet
              legalName = _.get(bulkPayload, '0.entity.legalName', legalName);
            }
            Redirect.payment([submissionIds[0], legalName]);
          } else {
            Redirect.thankYou([
              bulkPayload.length === 1 ? $scope.action.toLowerCase().split('_')[0] || 'NA' : 'NA',
              // only show trackId (parent id) for bulk submissions
              bulkPayload.length > 1 ? submissionIds?.[0] : humanReadableWorkIds?.[0],
              _getValueFromBulkPayload(bulkPayload, '0.entity.legalName'),
              _getValueFromBulkPayload(bulkPayload, '0.__lei')
            ]);
          }
        })
        .catch(DefaultErrorHandler)
        .catch(angular.noop);
    }

    function areAttachmentsOk(bulkPayload) {
      var attachedFiles = $scope.bulkAttachments.models.refFiles.concat($scope.bulkAttachments.models.authForms);
      var attachedFilesHash = {},
        attachmentHash = {},
        file2size = {};
      attachedFiles.forEach(function(file) {
        attachedFilesHash[file.name] = true;
        file2size[file.name] = file.size;
      });

      // check auth files
      if (AUTH_ACTIONS.includes($scope.action)) {
        if ($scope.bulkAttachments.models.authForms.length > bulkPayload.length) {
          return AlertMessenger.alert(flashMessages.moreAuthorizationForm.error) && false;
        }

        if ($scope.bulkAttachments.models.authForms.length < 1) {
          return AlertMessenger.alert(flashMessages.lessAuthorizationForm.error) && false;
        }
      }

      // check if any attachment is empty
      var emptyFlag = bulkPayload.some(function(payload) {
        var attachments = payload.__attachments;
        if (attachments) {
          if (
            attachments.some(function(attachment) {
              return file2size[attachment] === 0;
            })
          ) {
            return true;
          }
        }
      });

      if (emptyFlag) {
        return AlertMessenger.alert(flashMessages.zeroAttachmentsSize.error) && false;
      }

      // check total attachment size per payload
      var flag = bulkPayload.some(function(payload) {
        var attachments = payload.__attachments;
        if (attachments) {
          var sum = attachments.reduce(function(sum, attachment) {
            return sum + file2size[attachment];
          }, 0);
          return sum > $scope.fileUpload.max_file_size_per_submission;
        }
      });

      if (flag) {
        return AlertMessenger.alert(flashMessages.maxFileUploadSize.error) && false;
      }

      var attachmentsList = bulkPayload.map(function(payload) {
        var attachments = payload.__attachments;
        if (attachments) {
          attachments.forEach(function(attachment) {
            attachmentHash[attachment] = true;
          });
        }
        return attachments;
      });
      var missingFiles = attachmentChecker.check(attachedFilesHash, attachmentsList);
      if (missingFiles.length > 0) {
        Alert.error('attachmentsNeeded', null, {
          attachments: missingFiles.join(', ')
        });
        return false;
      }

      var attachedFileNames = attachedFiles.map(function(f) {
        return f.name;
      });

      var extraFiles = attachedFileNames.reduce(function(res, fileName) {
        if (!attachmentHash[fileName]) {
          res.push(fileName);
        }
        return res;
      }, []);
      if (extraFiles.length > 0) {
        Alert.error('attachmentsNotNeeded', null, {
          attachments: extraFiles.join(', ')
        });
        return false;
      }

      return true;
    }

    function _getValueFromBulkPayload(payload, path, defaultValue = 'NA') {
      return _.get(payload, path, defaultValue);
    }
  }
);
