'use strict';

var MAX_MESSAGE_CHARACTERS = 2000;
var requiredString = require('./required-string');

module.exports = function makeAdminMessageSchema() {
  return {
    message: {
      $label: 'Admin Message',
      $placeholder: 'Admin message (max. ' + MAX_MESSAGE_CHARACTERS + ')',
      checkFn: requiredString,
      $message: 'Please provide admin message.'
    },
    _messageMaxLength: {
      checkFn: function(_val, body, report) {
        if (!report.message.$valid) {
          return true;
        }

        return body.message.length < MAX_MESSAGE_CHARACTERS;
      },
      $message: 'Admin message must be within ' + MAX_MESSAGE_CHARACTERS + ' characters.'
    }
  };
};
