import { component } from '../app';
import _ from 'lodash';
import template from '@lei-website-client/views/form/tabs.html';
import { getAction } from '../utilities';
import { WORKFLOW_TAB_STEPS, VALIDATION_STATUS } from '../../../utils/constants';
import { DS_NAME } from '../utilities/shared';

export default class FormTabsController {
  /* @ngInject */
  constructor($rootScope, $routeParams, LeiService) {
    console.log('lei-form-tab.js> constructor() Starting');
    this.$rootScope = $rootScope;
    this.leiId = $routeParams.id;
    this.Service = LeiService;

    this.actions = {
      switchTabs: (id, isInitial) => {
        // New
        console.log('lei-form-tab.js> switchTabs() Starting\n', this.steps);

        const prevActiveTabId = this._getPreviousActiveTabId();
        if (prevActiveTabId >= 0 && !isInitial) {
          this.runSectionValidations(prevActiveTabId);
        }

        this.steps.map((step, index) => {
          step.active = step.id === id;
          if (step.active) {
            this.steps[index].active = true;
            this.$rootScope.$emit('onChangeTab', step);
          }
        });
      }
    };

    this.runSectionValidations = async tabIndex => {
      const tabId = this.steps[tabIndex].id;
      // schema validations
      const { payload, hasErrors } = this.formData(tabId, { showErrors: false });
      const formPayload = _.get(payload, DS_NAME);

      if (!formPayload || hasErrors) {
        this.steps[tabIndex].validationStatus = VALIDATION_STATUS.FAILED;
        return;
      }

      if (['FILES'].includes(tabId)) {
        this.steps[tabIndex].validationStatus = VALIDATION_STATUS.PASSED;
        return; // no bre validations for files section
      }

      // bre validations
      await this.Service.validateFormSection(getAction(this.state), this.leiId, {}, formPayload, tabId, true) // skipWarnings = true
        .then(() => (this.steps[tabIndex].validationStatus = VALIDATION_STATUS.PASSED))
        .catch(err => {
          if (err.status >= 400 && err.status < 500 && err.data.length) {
            this.steps[tabIndex].validationStatus = VALIDATION_STATUS.FAILED;
          }
        });
    };

    this.validationStatusToColor = status => {
      return {
        UNTOUCHED: 'text-muted',
        PASSED: 'text-success',
        FAILED: 'text-danger'
      }[status];
    };

    this.$rootScope.$on('requestChangeTab', (event, { id }) => {
      this.actions.switchTabs(id);
    });
  }

  _getDefaultSteps(action) {
    return WORKFLOW_TAB_STEPS.map(step => ({
      ...step,
      validationStatus: VALIDATION_STATUS.UNTOUCHED
    })).filter(({ id }) => action !== 'NEW' || id !== 'EVENTS');
  }

  _getPreviousActiveTabId() {
    return this.steps.findIndex(step => step.active);
  }

  _setPropsFromState() {
    console.log('lei-form-tab.js> _setPropsFromState() Starting ');

    const action = getAction(this.state);
    console.log('lei-form-tab.js> _setPropsFromState() action:' + action);

    this.steps = this._getDefaultSteps(action);

    this.actions.switchTabs(this.steps[0].id, true); // true = isInitial load
  }

  $onInit() {
    console.log('lei-form-tab.js> $onInit() Starting ');
    this._setPropsFromState();
  }
}

component('leiFormTabs', {
  controller: FormTabsController,
  template,
  bindings: {
    onLoad: '<onload',
    state: '<',
    formData: '<formdata'
  }
});
