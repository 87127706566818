import { controller } from './app';
import { CHALLENGE_CATEGORIES } from '../utils/constants';

// Controller for View transfer LEI request
controller('ViewLeiChallengeController', [
  '$scope',
  '$filter',
  '$location',
  'LeiService',
  'ProgressMessenger',
  'AlertMessenger',
  'DefaultErrorHandler',
  function($scope, $filter, $location, LeiService, ProgressMessenger, AlertMessenger, DefaultErrorHandler) {
    var vm = this;
    vm.props = { secLabel: 'LEI Challenge Request', viewOnly: true };

    var duplicateAction = CHALLENGE_CATEGORIES.duplicateAction;
    vm.props.dupChallenge = CHALLENGE_CATEGORIES.dupChallenge;
    vm.props.otherChallenge = CHALLENGE_CATEGORIES.otherChallenge;
    vm.props.missingChallenge = CHALLENGE_CATEGORIES.missingChallenge;

    load();
    /*
     * load initial data for the view if needed
     */
    function load() {
      // reset alert message
      AlertMessenger.reset();
      // path e.g. /leis/transfer/:id/:humanId
      var path = $location.path().split('/');
      vm.props.submissionId = path.length > 2 ? path[3] : null;
      vm.props.humanId = path.length > 3 ? path[4] : null;
      vm.props.secLabel += ' #' + vm.props.humanId;

      LeiService.viewLeiChallenge(vm.props.submissionId)
        .then(function(res) {
          var data = res.data;
          vm.challengeInfo = data.data.action[duplicateAction];

          if (vm.challengeInfo.challengeCategory[CHALLENGE_CATEGORIES.dupChallengeCategory]) {
            vm.challengeInfo.duplicateLEI =
              vm.challengeInfo.challengeCategory[CHALLENGE_CATEGORIES.dupChallengeCategory].duplicateLEI;
            vm.challengeInfo.challengeCategory = CHALLENGE_CATEGORIES.dupChallenge;
          } else {
            vm.challengeInfo.challengeCategory = JSON.stringify(vm.challengeInfo.challengeCategory);
          }
        })
        .catch(DefaultErrorHandler);
    }
  }
]);
