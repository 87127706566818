'use strict';

var get = require('lodash.get');
var set = require('lodash.set');

function createPropsChecker (checkers, options) {
    return function actualPropsCheck (body, onInvalid) {
        options = options || {};

        var allValid = true;

        var invalidReport = [];

        var ret = checkers.reduce(function arrayToMap (report, checker) {

            var maxlength;
            var val = get(body, checker.path);

            set(report, joinPath(checker.path, '$valid'), true);

            // if there is checkFn, then only run checkFn
            if (checker.checkFn && !checker.checkFn(val, body, report)) {

                if (checker.required || checker.maxlength ||
                    checker.pattern) {
                    throw new Error('checkFn is defined, do not define ' +
                        'required, type, pattern or maxlength for ' +
                        checker.path);
                }

                allValid = false;
                set(report, joinPath(checker.path, '$valid'), false);
                set(report, joinPath(checker.path, ['$error', 'checkFn']),
                    true);
                onInvalid && onInvalid('checkFn', val, checker);
                invalidReport.push(
                    { type: 'checkFn', val: val, checker: checker });

            } else {

                if (checker.required && typeof val === 'undefined') {
                    allValid = false;
                    set(report, joinPath(checker.path, '$valid'), false);
                    set(report, joinPath(checker.path, ['$error', 'required']),
                        true);
                    onInvalid && onInvalid('required', val, checker);
                    invalidReport.push(
                        { type: 'required', val: val, checker: checker });
                }

                if (checker.type && !inType(val, checker.type)) {
                    allValid = false;
                    set(report, joinPath(checker.path, '$valid'), false);
                    set(report, joinPath(checker.path, ['$error', 'type']),
                        typeof val);
                    onInvalid && onInvalid('type', val, checker);
                    invalidReport.push(
                        { type: 'type', val: val, checker: checker });
                }

                if (checker.pattern && !checker.pattern.test(val)) {
                    allValid = false;
                    set(report, joinPath(checker.path, '$valid'), false);
                    set(report, joinPath(checker.path, ['$error', 'pattern']),
                        true);
                    onInvalid && onInvalid('pattern', val, checker);
                    invalidReport.push(
                        { type: 'pattern', val: val, checker: checker });
                }

                maxlength = checker.maxlength || options.maxlength;

                if (maxlength && ('' + val).length > maxlength) {
                    allValid = false;
                    set(report, joinPath(checker.path, '$valid'), false);
                    set(report, joinPath(checker.path, ['$error', 'maxlength']),
                        maxlength);
                    onInvalid && onInvalid('maxlength', val, checker);
                    invalidReport.push(
                        { type: 'maxlength', val: val, checker: checker });
                }

            }

            return report;
        }, {});

        ret.$valid = !!allValid;
        ret.$invalidReport = invalidReport;

        return ret;

    };
}

function inType (val, targetType) {
    var t = typeof val;

    return [].concat(targetType).indexOf(t) !== -1;
}

function joinPath (checkerPath, path) {
    if (Array.isArray(checkerPath)) {
        return checkerPath.concat(path);
    }

    var cpArr = checkerPath.split('.');
    return cpArr.concat(path);
}

module.exports = createPropsChecker;
