import angular from 'angular';
import { directive, factory, filter } from '../controllers/app';

directive('leiNav', [
  'AccessLevel',
  '$rootScope',
  '$window',
  'UserService',
  'CardService',
  function(AL, $rootScope, $window, UserService, CardService) {
    return {
      restrict: 'A',
      link: function(scope) {
        scope.accessLevel = AL.get() || AL.OPEN;
        scope.cartNum = 0;

        function updateSchema(schema) {
          return schema.map(function(m) {
            m = inflateMenuItem(m);

            if (m.items) {
              m.items = m.items.map(inflateMenuItem);
            }

            return m;
          });
        }

        function inflateMenuItem(m) {
          // reset
          m.none = false;
          m.disabled = false;

          if (scope.accessLevel < m.from) {
            m.disabled = true;

            if (m.exclusive) {
              m.none = true;
            }
          }

          if (Object.prototype.hasOwnProperty.call(m, 'till') && scope.accessLevel >= m.till) {
            m.none = true;
          }

          return m;
        }

        scope.mainSchema = [
          { label: 'Create an Account', link: '/users/signup', till: AL.TWO_FACTOR, classNames: 'highlight' },

          { label: 'Search', link: '/search', from: AL.OPEN },

          {
            label: 'Blog',
            link: '/blog/',
            from: AL.OPEN,
            external: () => {
              $window.location.href = '/blog/';
            }
          },

          { label: 'FAQ', link: '/docs/faq', from: AL.OPEN },

          { label: 'Contact', link: '/contact', from: AL.OPEN },

          { label: 'Admin', link: '/adm1ns/account', from: AL.ADMIN, exclusive: true },

          { label: 'Log In', link: '/users/login', from: AL.OPEN, till: AL.TWO_FACTOR },

          {
            label: 'Account',
            from: AL.TWO_FACTOR,
            exclusive: true,
            items: [
              { label: 'My Requests', link: '/users/submissions', from: AL.TWO_FACTOR },

              { label: 'My Profile', link: '/users/account/profile', from: AL.TWO_FACTOR },

              { label: null },

              { label: 'Log Out', link: '/users/logout', from: AL.TWO_FACTOR }
            ],
            username: null
          }
        ];

        scope.secondarySchemaPublic = [
          { label: 'Create', link: '/leis/entry/new', from: AL.OPEN },

          { label: 'Update', link: '/leis/entry/update', from: AL.OPEN },

          { label: 'Renew', link: '/leis/entry/renew', from: AL.OPEN },

          { label: 'Transfer', link: '/leis/entry/transfer', from: AL.OPEN }
        ];

        scope.secondarySchemaLoggedIn = [
          { label: 'Create', link: '/leis/entry/new', from: AL.TWO_FACTOR },

          { label: 'Update', link: '/leis/entry/update', from: AL.TWO_FACTOR },

          { label: 'Renew', link: '/leis/entry/renew', from: AL.TWO_FACTOR },

          { label: 'Transfer', link: '/leis/entry/transfer', from: AL.TWO_FACTOR },

          { label: 'Cart', link: '/users/cart', from: AL.TWO_FACTOR }
        ];

        function apply(callback) {
          // Safe apply in case mid render cycle
          var phase = scope.$root && scope.$root.$$phase;
          if (phase === '$apply' || phase === '$digest') {
            if (callback && typeof callback === 'function') callback();
          } else scope.$apply(callback);
        }

        function refresh() {
          scope.secondarySchemaLoggedIn.some(item => {
            if (!item.label.startsWith('Cart')) return false;

            return (item.label = scope.cartNum ? 'Cart (' + scope.cartNum + ')' : 'Cart');
          });

          scope.mainSchema = updateSchema(scope.mainSchema);
          scope.secondarySchemaLoggedIn = updateSchema(scope.secondarySchemaLoggedIn);
          scope.secondarySchemaPublic = updateSchema(scope.secondarySchemaPublic);
          scope.hideSecondaryBar = scope.accessLevel < AL.TWO_FACTOR;
        }

        $rootScope.$on(AL.CHANGE_EVENT, function(event, al) {
          // Debounce this event, which is called often.
          if (al === scope.accessLevel) {
            return;
          }

          scope.accessLevel = al;
          apply(refresh);

          if (al < AL.TWO_FACTOR) {
            return;
          }

          CardService.getCart();

          return UserService.getUserInfo()
            .then(
              res => res.data.username,
              () => null
            )
            .then(username =>
              scope.mainSchema.some(item => {
                if (item.label !== 'Account') return false;
                item.username = username;
                return true;
              })
            );
        });

        $rootScope.$on(CardService.CART_EVENT, function(event, res) {
          var cartNum = (res.data.cartItems || []).length;
          if (cartNum === scope.cartNum) {
            return;
          }

          scope.cartNum = cartNum;
          apply(refresh);
        });

        $rootScope.$on(CardService.EMPTY_CART, function() {
          scope.cartNum = 0;
          apply(refresh);
        });

        refresh();
      }
    };
  }
]);

filter('labelToId', function() {
  return function(value, prefix) {
    return (prefix || '') + value.toLowerCase().replace(/\s/g, '-');
  };
});

import navItemsTemplate from '../../views/templates/navItems.html';
directive('navItems', function() {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      schema: '=',
      classNames: '@'
    },
    template: navItemsTemplate
  };
});

import accessLevel from '../../../common/const/access-level';
factory('AccessLevel', [
  '$rootScope',
  function($rootScope) {
    var CHANGE_EVENT = 'CHANGE_EVENT';

    var OPEN = accessLevel.OPEN;
    var LOGIN = accessLevel.LOGIN;
    var TWO_FACTOR = accessLevel.TWO_FACTOR;
    var ADMIN = accessLevel.ADMIN;

    var currentLevel;

    return {
      OPEN: OPEN,
      LOGIN: LOGIN,
      TWO_FACTOR: TWO_FACTOR,
      ADMIN: ADMIN,

      CHANGE_EVENT: CHANGE_EVENT,

      get: function() {
        return currentLevel;
      },

      change: function(level) {
        currentLevel = level;
        setTimeout(function() {
          $rootScope.$emit(CHANGE_EVENT, level);
        }, 500);
      }
    };
  }
]);

directive('verticalHide', [
  '$document',
  '$window',
  '$interval',
  function($document, $window, $interval) {
    var delta = 10;
    var minTime = 250;
    var minDisplacement = 300;

    return {
      restrict: 'A',
      link: function($scope, $element) {
        const $ = angular.element;
        $document = $($document);
        $window = $($window);
        $element = $($element);

        var $top = $('.vertical-hide-top');
        var debounce = 0,
          didScroll = false;
        $element.addClass('vertical-hide');

        var stop = $interval(function() {
          if (!didScroll) return;

          var position = $window.scrollTop();
          didScroll = false;

          // Make sure they scroll more than delta
          if (Math.abs(debounce - position) <= delta) return;

          // Some stuff only shows at the very top.
          if (position > minDisplacement) $top.css('margin-top', -$top.height());
          else $top.css('margin-top', 0);

          // If they scrolled down and are past the navbar, add class .nav-up.
          // This is necessary so you never see what is "behind" the navbar.
          if (position > debounce && position > minDisplacement)
            // Scroll Down
            $element.css('margin-top', -$element.height());
          else if (position + $window.height() < $document.height())
            // Scroll Up
            $element.css('margin-top', 0);

          debounce = position;
        }, minTime);

        // Listeners
        $window.scroll(function() {
          didScroll = true;
        });
        $scope.$on('$destroy', $interval.cancel.bind($interval, stop));
      }
    };
  }
]);

directive('slabUi', [
  '$rootScope',
  function($rootScope) {
    return {
      restrict: 'A',
      scope: false,
      link: function(scope, element) {
        var HOME_FLAG = 'zuhause',
          SRCH_FLAG = 'suchbar',
          SRCH_ROUTES = [
            'Search',
            'Request',
            'Bulk Request',
            'My Requests',
            'Challenge',
            'Renew',
            'Transfer',
            'Update',
            'Payment'
          ];

        // Disables search bar when in Search view
        $rootScope.$on('$routeChangeSuccess', function(event, current) {
          if (!current.$$route) return;

          'Home' === current.$$route.title
            ? !element.hasClass(HOME_FLAG) && element.addClass(HOME_FLAG)
            : element.hasClass(HOME_FLAG) && element.removeClass(HOME_FLAG);

          !SRCH_ROUTES.some(function(x) {
            return x === current.$$route.title;
          })
            ? !element.hasClass(SRCH_FLAG) && element.addClass(SRCH_FLAG)
            : element.hasClass(SRCH_FLAG) && element.removeClass(SRCH_FLAG);
        });
      }
    };
  }
]);

directive('quickSearch', [
  '$rootScope',
  '$location',
  'AccessLevel',
  function($rootScope, $location, AccessLevel) {
    return {
      restrict: 'A',
      replace: false,
      scope: {},
      template:
        '' +
        '<form name="quickSearchForm" class="row justify-content-center" ng-submit="quickSearch(quickSearchString)">' +
        '<div class="input-group input-group-lg input-group-rounded col-md-22 col-lg-18">' +
        '<input type="text" class="form-control" placeholder="Search &quot;{{options[index].text}}&quot; by Entity Name, LEI..." ng-model="quickSearchString">' +
        '<div class="input-group-append">' +
        '<button type="button" class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ng-bind="options[index].text"></button>' +
        '<button class="btn btn-secondary" type="submit"><span class="fa fa-search" aria-hidden="true"></span></button>' +
        '<div class="dropdown-menu dropdown-menu-right">' +
        '<a href="#" class="dropdown-item" ng-repeat="opt in options track by $index" ng-click="changeIndex($index)" ng-if="!opt.auth || auth">{{opt.text}}</a>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</form>',
      link: function(scope, element) {
        scope.options = [
          { text: 'Bloomberg LEIs', path: '/search' },
          { text: 'Additional LEIs', path: '/search' },
          { text: 'My Submissions', path: '/users/submissions', auth: true },
          { text: 'My LEIs', path: '/users/submissions', auth: true }
        ];
        scope.index = 0;
        scope.auth = false;

        // Redirects query to Search view
        scope.quickSearch = function(quickSearchString) {
          var searchPath = scope.options[scope.index].path;
          var searchTab = scope.index + 1;
          $location.path(searchPath).search({
            '.': [searchTab, '', quickSearchString, '', '[]']
          });
          $location.replace();
        };

        scope.changeIndex = function(index) {
          scope.index = index;
        };

        scope.accessLevel = AccessLevel.OPEN;
        scope.$on(
          '$destroy',
          $rootScope.$on(AccessLevel.CHANGE_EVENT, function(event, al) {
            // Debounce this event, which is called often.
            if (al === scope.accessLevel) {
              return;
            }

            scope.accessLevel = al;
            scope.auth = al >= AccessLevel.TWO_FACTOR;
          })
        );
      }
    };
  }
]);
