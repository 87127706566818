var checkArguments = {
    TYPEOF: {
        STRING   : "string",
        OBJECT   : "object",
        BOOLEAN  : "boolean",
        UNDEFINED: "undefined",
        FUNCTION : "function",
        NUMBER   : "number"
    },
};

module.exports =  checkArguments;
