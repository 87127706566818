import { capitalize, clone, debounce, get, isEqual, isObject, merge, set, transform } from 'lodash';
import angular from 'angular';
import {
  AFFECTED_FIELD_WITH_NUMBER_VALUE,
  AFFECTED_FIELD_XPATH_TERM_OVERRIDES,
  COMPLEX_EVENT_GROUP_TYPE_MAP,
  COMPLEX_EVENT_TYPE_MAP,
  DS_NAME,
  EVENT_CATEGORIES,
  EVENT_CRUMB_TO_EVENT_TYPE_MAP,
  EVENT_LABEL_OVERRIDES,
  EVENT_RELATIONSHIP_PARENTS,
  EXCLUDE_CRUMB_LIST,
  INCLUDE_CRUMB_LIST,
  LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP,
  LEGAL_ENTITY_EVENT_REQUIRED_FIELDS,
  LEGAL_ENTITY_EVENT_SKIP_TYPE,
  LEGAL_ENTITY_EVENT_STATUS,
  UNREALIZED_EVENT_TYPE
} from '../../../../../common/const/entity-actions';

/**
 * Allow combinations of empty string, null, and undefined values for both valueA and valueB to match.
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {boolean}       Return a new object who represent the diff
 */
function _isAllowedFalsey(valueA, valueB) {
  return (
    !valueA &&
    !valueB &&
    (valueA === '' || valueA === null || valueA === undefined) &&
    (valueB === '' || valueB === null || valueB === undefined)
  );
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function leiDiffAFromB(objectA, objectB, isObjectAValueFirst = true) {
  return transform(objectA, (resultA, valueA, keyA) => {
    // Add missing objects or null for missing primitive values this provides a deeper nested comparison
    try {
      if (objectB[keyA] == null) objectB[keyA] = valueA !== Object(valueA) ? undefined : {};
      if (!isEqual(valueA, objectB[keyA]) && valueA != objectB[keyA] && !_isAllowedFalsey(valueA, objectB[keyA])) {
        resultA[keyA] =
          isObject(valueA) && isObject(objectB[keyA])
            ? leiDiffAFromB(valueA, objectB[keyA], isObjectAValueFirst)
            : isObjectAValueFirst
            ? { leiDiff: [valueA, objectB[keyA]] }
            : { leiDiff: [objectB[keyA], valueA] };
      }
    } catch (e) {
      console.error(
        'Error: lei-events-util.js> leiDiffAFromB() objectA:',
        objectA,
        ' objectB:',
        objectB,
        ' resultA:',
        resultA,
        ' valueA:',
        valueA,
        ' keyA:',
        keyA,
        e.message
      );
    }
  });
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} objectA First object to compare
 * @param  {Object} objectB Second object to compare with
 * @return {Object} Return a new object with, [objectA_value, objectB_value], with diff between the 2
 */
export function leiDiff(objectA, objectB) {
  const diffAFromB = leiDiffAFromB(objectA, objectB);
  const diffBFromA = leiDiffAFromB(objectB, objectA, false);
  return merge(diffBFromA, diffAFromB);
}

export function isArrayOfPrimitives(array) {
  return array.every(i => i !== Object(i));
}

export function collectCrumbs(crumbs, crumb) {
  if (!crumbs && !crumb) return [];
  if (!crumb) return crumbs;
  if (!crumbs) return [crumb];

  // Don't add leiDiff to crumbs
  if (crumb === 'leiDiff') return crumbs;

  return [...crumbs, crumb];
}

export function convertSchemaPropertyNameToLabel(schemaPropertyName) {
  if (schemaPropertyName == null) return;

  let label = isNaN(schemaPropertyName) ? schemaPropertyName : +schemaPropertyName + 1 + '';

  // only take final segment of crumb
  // from: lei.somthing.important to: important
  label = label.includes('.') ? label.substring(label.lastIndexOf('.') + 1, label.length) : label;

  // convert to use for UI as context label
  label = label.replace(/([A-Z]+)/g, ' $1');
  label = label.replace(/\w+/g, capitalize);
  return label.trim();
}

export function getCrumbString(crumbArray, crumbSeparator = ' > ') {
  if (!crumbArray || !crumbArray.length) return;

  const cleanCrumbs = crumbArray.map(crumb => {
    return convertSchemaPropertyNameToLabel(crumb);
  });

  const crumbString = cleanCrumbs.join(crumbSeparator);

  return crumbString;
}

export function getXpathFromCrumbs(crumbArray, prefix = '//', separator = '/') {
  if (!crumbArray || !crumbArray.length) return;

  const cleanXpathTerms = crumbArray.map(schemaPropertyName => {
    const cleanXpathTerm = isNaN(schemaPropertyName) ? schemaPropertyName : +schemaPropertyName + '';

    if (cleanXpathTerm.includes('.')) {
      const matchedKey = Object.keys(AFFECTED_FIELD_XPATH_TERM_OVERRIDES).find(overide =>
        cleanXpathTerm.startsWith(overide)
      );

      const xpathTermGenericSchema = matchedKey
        ? cleanXpathTerm.replace(matchedKey, AFFECTED_FIELD_XPATH_TERM_OVERRIDES[matchedKey])
        : cleanXpathTerm;
      return xpathTermGenericSchema;
    } else {
      return cleanXpathTerm;
    }
  });

  return prefix + cleanXpathTerms.join(separator);
}

export function traverseObjectProps(targetItem, crumbs, results, fnProcess) {
  if (!targetItem) {
    return;
  }
  const keys = Object.keys(targetItem);
  keys.forEach(key => {
    const nestedItem = targetItem[key];
    const newCrumbs = collectCrumbs(crumbs, key);
    fnProcess(nestedItem, newCrumbs, results);
  });
}

export function generateLeiDiffReport(item, crumbs = [], results) {
  const emptyObject = {};
  /* stop processing - begin */
  // stop processing when item is empty
  if (item instanceof Function) {
    console.debug(`lei-events-util.js> generateLeiDiffReport() stop processing found function crumbs: ${crumbs} `);
    return;
  }
  if (!item || angular.equals(item, emptyObject)) {
    console.debug(`lei-events-util.js> generateLeiDiffReport() stop processing found empty: ${crumbs}, item:${item}`);
    return;
  }
  // stop processing when child with prop name leiDiff is found
  if (item['leiDiff']) {
    console.debug(`lei-events-util.js> generateLeiDiffReport() store, diff found: ${crumbs}, targetItem:${item}`);
    const newValue = item['leiDiff'][0];
    const origValue = item['leiDiff'][1];
    const key = getCrumbString(crumbs);
    results[key] = [{ crumbs: crumbs, new: newValue, orig: origValue }];
    return;
  }
  /* stop processing - end */

  console.log(`lei-events-util.js> generateLeiDiffReport() called with item:${item}, breadcrumb: ${crumbs}`);
  traverseObjectProps(item, crumbs, results, generateLeiDiffReport);
}

export function generateGroupedLeiDiffReport(
  leiDiffReport,
  includeCrumbList = INCLUDE_CRUMB_LIST,
  crumbSeparatorRegex = /^\s>\s/
) {
  console.log(
    `lei-events-util.js> generateGroupedLeiDiffReport() called with leiDiffReport: ${leiDiffReport}, includeCrumbList:${includeCrumbList}`
  );
  if (!includeCrumbList || includeCrumbList.length == 0) return leiDiffReport;
  if (!leiDiffReport) return;

  const newLeiDiffReport = Object.entries(leiDiffReport).reduce((accum, [key, [value]]) => {
    const matchedCrumb = includeCrumbList.find(crumb => key.match(crumb));

    // if group match is found add values to array
    if (matchedCrumb) {
      const regexGroup = key.match(matchedCrumb);
      const newCrumb = regexGroup['0'];
      const name = key
        .substring(newCrumb.length, key.length)
        .replace(crumbSeparatorRegex /*/^\s>\s/*/, '')
        .trimStart();
      accum[newCrumb]
        ? accum[newCrumb].push({
            groupCrumbs: newCrumb,
            crumbs: key,
            crumbsArray: value.crumbs,
            xpath: getXpathFromCrumbs(value.crumbs),
            name,
            new: value.new,
            orig: value.orig
          })
        : (accum[newCrumb] = [
            {
              groupCrumbs: newCrumb,
              crumbs: key,
              crumbsArray: value.crumbs,
              xpath: getXpathFromCrumbs(value.crumbs),
              name,
              new: value.new,
              orig: value.orig
            }
          ]);
      return accum;
    }
    // when no match found keep original
    (value.xpath = getXpathFromCrumbs(value.crumbs)), (value.groupCrumbs = key);
    value.crumbsArray = value.crumbs;
    value.crumbs = key;
    accum[key] = [value];
    return accum;
  }, {});

  return newLeiDiffReport;
}

export function filterLeiDiffReport(
  leiDiffReport,
  excludeCrumbList = EXCLUDE_CRUMB_LIST,
  includeCrumbList = INCLUDE_CRUMB_LIST
) {
  console.debug(
    `lei-events-util.js> filterLeiDiffReport() called with leiDiffReport: ${leiDiffReport}, excludeCrumbList:${excludeCrumbList}, includeCrumbList:${includeCrumbList}`
  );
  let newLeiDiffReport = clone(leiDiffReport);

  newLeiDiffReport = Object.fromEntries(
    Object.entries(newLeiDiffReport).filter(([key]) => {
      return includeCrumbList.some(crumb => {
        return key.match(crumb);
      });
    })
  );
  newLeiDiffReport = Object.fromEntries(
    Object.entries(newLeiDiffReport).filter(([key]) => !excludeCrumbList.some(excludeCrumb => excludeCrumb.test(key)))
  );

  return newLeiDiffReport;
}

export function sortLeiDiffList(leiDiffReport) {
  const sortedLeiDiffReport = leiDiffReport.sort((leiDiffReportA, leiDiffReportB) => {
    return leiDiffReportA[0].groupCrumbs >= leiDiffReportB[0].groupCrumbs ? 1 : -1;
  });

  return sortedLeiDiffReport;
}

export function getPath(object, path, defaultValue = null) {
  return get(object, path, defaultValue);
}

export function setPath(object, path, value) {
  return set(object, path, value);
}

export function convertToLabel(fieldName, overrides = { overrides: EVENT_LABEL_OVERRIDES }) {
  if (!fieldName == null) return fieldName;
  const label = fieldName
    ? String(fieldName)
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\w+/g, capitalize)
    : fieldName;

  return EVENT_LABEL_OVERRIDES[label] ? EVENT_LABEL_OVERRIDES[label] : label;
}

/**
 * Add event to groupedLeiDiffReport.
 * @param groupedLeiDiffReport ex: {"Entity > Legal Name": [ { "groupCrumbs": "Entity > Legal Name", ... "new": "Frank Test Form And Name", "orig": "Frank Test Create - Local - 3/7" } ] }
 * @param effectiveDate
 * @returns {any} ex: {"Entity > Legal Name": [ { "groupCrumbs": "Entity > Legal Name", ... } ], event: {...}, getEvent() }
 */
export function addEventModelToGroupedLeiDiffReport(
  groupedLeiDiffReport,
  { effectiveDate = undefined } = { effectiveDate: undefined }
) {
  return Object.keys(groupedLeiDiffReport).reduce((tempLeiDiffReport, groupCrumb) => {
    const event = tempLeiDiffReport[groupCrumb].reduce((accum, leiDiffInfo) => {
      accum.affectedFieldsHelper.push({
        // TODO: crumb to xpath conversion - needs more work to properly represent array - handled by exporter
        affectedFieldHelperJsonPath: leiDiffInfo.xpath,
        affectedFieldHelperValue: leiDiffInfo.new
      });
      return accum;
    }, generateEvent(effectiveDate, { groupCrumb }));

    tempLeiDiffReport[groupCrumb].event = event;
    tempLeiDiffReport[groupCrumb].getEvent = () => tempLeiDiffReport[groupCrumb].event;

    return tempLeiDiffReport;
  }, groupedLeiDiffReport);
}

export function toDateString(date) {
  if (!date == null || !(date instanceof Date)) return undefined;
  return date.toISOString().substring(0, date.toISOString().indexOf('T'));
}

// returns an array of diffs with getEvent instance method to retrieve event model
// TODO: May want to use prepareEventForFormFields inside
export function generateEvent(effectiveDate, { groupCrumb }) {
  console.info(`lei-events-util.js> generateEvent() with effectiveDate: ${effectiveDate}, groupCrumb: ${groupCrumb}`);
  const effectiveDateNoTime =
    effectiveDate && new Date(effectiveDate.getFullYear(), effectiveDate.getMonth(), effectiveDate.getDate());
  const crumbToEventType = Object.entries(EVENT_CRUMB_TO_EVENT_TYPE_MAP).find(([, crumbRegex]) =>
    groupCrumb.match(crumbRegex)
  );
  const derivedLegalEntityEventType = crumbToEventType && {
    id: crumbToEventType[0],
    label: convertToLabel(crumbToEventType[0])
  };
  const legalEntityEventType = derivedLegalEntityEventType || {
    id: UNREALIZED_EVENT_TYPE,
    label: convertToLabel(UNREALIZED_EVENT_TYPE)
  };
  console.debug(
    `lei-events-util.js> generateEvent() groupCrumb: ${groupCrumb}, legalEntityEventType: ${legalEntityEventType}`
  );
  return {
    category: EVENT_CATEGORIES.EVENT,
    legalEntityEventType: legalEntityEventType,
    derivedLegalEntityEventType: legalEntityEventType,
    legalEntityEventEffectiveDate: { dateString: toDateString(effectiveDateNoTime) || '', date: effectiveDate },
    legalEntityEventRecordedDate: undefined,
    legalEntityEventStatus: effectiveDateNoTime
      ? getStatusFromEffectiveDate(effectiveDateNoTime)
      : { id: '', label: '' },
    validationDocuments: { id: '', label: '[Please Select]' },
    validationReference: undefined,
    legalEntityEventGroupType: { id: 'STANDALONE', label: 'Standalone' },
    legalEntityEventGroupId: undefined,
    legalEntityEventGroupSequenceNo: undefined,
    affectedFieldsHelper: [] // [ex: { affectedFieldHelperJsonPath:"", affectedFieldHelperValue:"" }]
  };
}

export function getExtraFieldValuesForForm(event, extraFieldsMap = LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP) {
  if (!event.affectedFieldsHelper) return;

  // extraFieldsForTypeMap = [{ required: true, formKey: 'successorEntityType', baseXpath: ['entity', 'successorEntity'] }],
  const extraFieldsForTypeMap = extraFieldsMap[event.legalEntityEventType];
  if (!extraFieldsForTypeMap) return;

  const baseXpathForExtraFields = extraFieldsForTypeMap.map(({ baseXpath }) => getXpathFromCrumbs(baseXpath));

  // ex: event.affectedFieldsHelper = [ex: { affectedFieldHelperJsonPath:"", affectedFieldHelperValue:"" }]
  const affectedFieldsHelperFiltered = event.affectedFieldsHelper.filter(({ affectedFieldHelperJsonPath }) =>
    baseXpathForExtraFields.some(baseXpath => affectedFieldHelperJsonPath.startsWith(baseXpath))
  );

  if (!affectedFieldsHelperFiltered) return;

  const extraFieldValuesForForm = affectedFieldsHelperFiltered.reduce(
    (accum, { affectedFieldHelperJsonPath, affectedFieldHelperValue }) => {
      const crumbs = getCrumbsFromXpath(affectedFieldHelperJsonPath);
      const formKey = crumbs[crumbs.length - 1];
      accum[formKey] = affectedFieldHelperValue;
      return accum;
    },
    {}
  );

  return extraFieldValuesForForm;
}

// TODO: add unit tests
export function stringToDate(dateString) {
  const [year, month, day] = dateString.split('T')[0].split('-');
  return new Date(year, +month - 1, day);
}

export function prepareEventForFormFields(event, category) {
  // when already processed skip
  if (!event.legalEntityEventType.id) {
    const legalEntityEventType = {
      id: event.legalEntityEventType,
      label: convertToLabel(event.legalEntityEventType)
    };
    const legalEntityEventRecordedDate = event.legalEntityEventRecordedDate
      ? event.legalEntityEventRecordedDate
      : undefined;
    const legalEntityEventStatus = event.legalEntityEventStatus
      ? {
          id: event.legalEntityEventStatus,
          label: convertToLabel(event.legalEntityEventStatus)
        }
      : undefined;
    const legalEntityEventGroupType = event.legalEntityEventGroupType
      ? {
          id: event.legalEntityEventGroupType,
          label: convertToLabel(event.legalEntityEventGroupType)
        }
      : undefined;
    const legalEntityEventEffectiveDate = event.legalEntityEventEffectiveDate
      ? (() => {
          const effectiveDateNoTime = stringToDate(event.legalEntityEventEffectiveDate);
          return { dateString: event.legalEntityEventEffectiveDate, date: effectiveDateNoTime };
        })()
      : undefined;
    const validationDocuments = event.validationDocuments
      ? {
          id: event.validationDocuments,
          label: convertToLabel(event.validationDocuments)
        }
      : undefined;

    // Add extraFields values when available
    const extraFields = getExtraFieldValuesForForm(event);

    const newEvent = {
      ...event,
      ...{
        category: category,
        legalEntityEventType: legalEntityEventType,
        legalEntityEventEffectiveDate: legalEntityEventEffectiveDate,
        legalEntityEventRecordedDate: legalEntityEventRecordedDate,
        legalEntityEventStatus: legalEntityEventStatus,
        validationDocuments: validationDocuments,
        validationReference: event.validationReference,
        legalEntityEventGroupType: legalEntityEventGroupType,
        legalEntityEventGroupId: event.legalEntityEventGroupId,
        legalEntityEventGroupSequenceNo: event.legalEntityEventGroupSequenceNo,
        affectedFieldsHelper: event.affectedFieldsHelper // [ex: { affectedFieldHelperJsonPath:"", affectedFieldHelperValue:"" }]
      },
      ...extraFields
    };

    console.debug('lei-events-util.js> prepareEventForFormFields() newEvent: ', newEvent);

    return newEvent;
  }
  return event;
}

// returns an array of events
export function generateExistingEventsReport(existingEvents) {
  if (!existingEvents) return [];
  if (!Array.isArray(existingEvents)) {
    existingEvents.length = Object.keys(existingEvents).length;
    existingEvents = Array.from(existingEvents);
  }

  const publishedExistingEvents = existingEvents.filter(
    ({ legalEntityEventRecordedDate }) => !!legalEntityEventRecordedDate
  );
  const preparedEvents = publishedExistingEvents.map(existingEvent => {
    const event = prepareEventForFormFields(existingEvent, EVENT_CATEGORIES.EXISTING_EVENT);
    event.getEvent = () => event;
    return event;
  });
  console.debug('lei-events-util.js> generateExistingEventsReport() preparedEvents: ', preparedEvents);
  return preparedEvents;
}

// Generate temp action
// TODO: Add unit tests
// TODO: May want to use prepareEventForFormFields inside
export async function generateTempAction({ effectiveDate = null, eventCallBackFn, generateLineageId }) {
  const effectiveDateNoTime =
    effectiveDate && new Date(effectiveDate.getFullYear(), effectiveDate.getMonth(), effectiveDate.getDate());
  const lineageIdReq = await generateLineageId(1);
  const lineageId = lineageIdReq.data.pop().humanReadableId;

  const event = {
    category: EVENT_CATEGORIES.ACTION,
    legalEntityEventType: { id: '', label: '[New Event]' },
    legalEntityEventEffectiveDate: { dateString: toDateString(effectiveDateNoTime) || '', date: effectiveDate },
    legalEntityEventRecordedDate: undefined,
    legalEntityEventStatus: effectiveDateNoTime
      ? getStatusFromEffectiveDate(effectiveDateNoTime)
      : { id: '', label: '' },
    validationDocuments: { id: '', label: '[Please Select]' },
    validationReference: undefined,
    legalEntityEventGroupType: { id: 'STANDALONE', label: 'Standalone' },
    legalEntityEventGroupId: undefined,
    legalEntityEventGroupSequenceNo: undefined,
    lineageId,
    affectedFieldsHelper: [], // [ex: { affectedFieldHelperJsonPath:"", affectedFieldHelperValue:"" }]
    successorEntityConditional: undefined,
    successorEntityType: undefined,
    successorEntityName: undefined,
    successorLEI: undefined
  };
  event.getEvent = () => event;
  eventCallBackFn(event);

  return event;
}

/**
 * Determine event type using complexEventTypeMap.
 * @param groupedLeiDiffReport Ex: { "Entity > Legal Name": [...].event: { "legalEntityEventType": { "id": "UNREALIZED_EVENT_TYPE", "label": "Unrealized Event Type" }, "derivedLegalEntityEventType": { "id": "UNREALIZED_EVENT_TYPE", "label": "Unrealized Event Type" }, ... }}
 * @param complexEventTypeMap
 * @param applyToEventFields
 * @returns {any} Ex: { "Entity > Legal Name": [...].event: { "legalEntityEventType": { "id": "CHANGE_LEGAL_NAME", "label": "Change Legal Name" }, "derivedLegalEntityEventType": { "id": "CHANGE_LEGAL_NAME", "label": "Change Legal Name" }, ... }}
 */
export function addComplexEventTypes(
  groupedLeiDiffReport,
  { complexEventTypeMap, applyToEventFields } = {
    complexEventTypeMap: COMPLEX_EVENT_TYPE_MAP,
    applyToEventFields: ['legalEntityEventType', 'derivedLegalEntityEventType']
  }
) {
  const eventsWithComplexTypes = {};
  // Ex: Object.entries(COMPLEX_EVENT_TYPE_MAP) = [
  //   key: TRANSFORMATION_BRANCH_TO_SUBSIDIARY,
  //   value: { orGroup: [{ andGroup: [{}, {}, ...]}, { andGroup: [{}, {}, ...]}, ...]}
  // ]
  const complexTypes = Object.entries(complexEventTypeMap).filter(([eventType, rules]) => {
    const successfullOr = rules.orGroup.some(({ andGroup }) => {
      const successfullAnd = andGroup.every(({ field, new: newValueRegex, orig: origValueRegex, withSiblings }) => {
        // Ex: Object.values(groupedLeiDiffReport) = [[{crumbs:'some > crumb', new:'New', orig:'Old'},{...}, ...], [{},{}, ...], ...]
        console.debug(
          `lei-events-util.js> addComplexEventTypes() checking for ${eventType} with RULE>> field: ${field}, newVal: ${newValueRegex}, origValueRegex: ${origValueRegex}`
        );
        const sucessfullCount = Object.values(groupedLeiDiffReport).filter(groupedLeiDiff => {
          // Ex: groupedLeiDiff = [{crumbs:'some > crumb', new:'New', orig:'Old'},{...}, ...]
          const testResult = groupedLeiDiff.some(leiDiff => {
            // Ex: leiDiff = {crumbs:'some > crumb', new:'New', orig:'Old'}
            const newValue = leiDiff.new && leiDiff.new + '';
            const origValue = leiDiff.orig && leiDiff.orig + '';
            const success =
              field.test(leiDiff.crumbs) &&
              // bypass regex validation - match any value when true
              (newValueRegex === true ||
                (leiDiff.new !== undefined ? !!newValue.match(newValueRegex) : leiDiff.new == newValueRegex)) &&
              // bypass regex validation - match any value when true
              (origValueRegex === true ||
                (leiDiff.orig !== undefined ? !!origValue.match(origValueRegex) : leiDiff.orig == origValueRegex));

            success &&
              console.log(
                `lei-events-util.js> addComplexEventTypes() SUCCESS: ${success}, leiDiff.crumbs: ${leiDiff.crumbs}, leiDiff.new: ${leiDiff.new}, leiDiff.orig: ${leiDiff.orig}`
              );

            return success;
          });

          // Keep track of matched regex by complex type
          if (testResult && groupedLeiDiff.event)
            eventsWithComplexTypes[eventType]
              ? eventsWithComplexTypes[eventType].push(groupedLeiDiff.event)
              : (eventsWithComplexTypes[eventType] = [groupedLeiDiff.event]);

          return testResult;
        }).length;
        console.debug(`lei-events-util.js> addComplexEventTypes() sucessfullCount: ${sucessfullCount}`);
        return !!sucessfullCount;
      });
      // When every check is not successful clear results
      if (!successfullAnd) eventsWithComplexTypes[eventType] = undefined;
      return successfullAnd;
    });
    return successfullOr;
  });

  console.log(`lei-events-util.js> addComplexEventTypes() complexTypes: ${complexTypes}`);

  complexTypes.forEach(complexType => {
    // If every regex is satisfied for a complex type then add that type to the associated events
    Object.keys(complexEventTypeMap).forEach(complexEventType => {
      if (complexType && complexEventType === complexType[0] && eventsWithComplexTypes[complexEventType]) {
        eventsWithComplexTypes[complexType[0]].forEach(event => {
          const legalEntityEventType = { id: complexType[0], label: convertToLabel(complexType[0]) };
          applyToEventFields.forEach(eventField => {
            event[eventField] = legalEntityEventType;
          });
        });
      }
    });
  });

  // return value, used by test cases
  return complexTypes ? complexTypes.map(complexType => complexType[0]) : undefined;
}

export function groupComplexEventsByType(
  groupedLeiDiffReport,
  { complexEventTypeMap = COMPLEX_EVENT_TYPE_MAP } = { complexEventTypeMap: COMPLEX_EVENT_TYPE_MAP }
) {
  // Ex: groupedLeiDiffReport = { "Entity > Entity Category": [{"crumbs": "Entity > Entity Category", ...}], "Another > Crumb": [{..}], ... }
  const newGroupedLeiDiffReport = Object.entries(groupedLeiDiffReport)
    // Ex:
    //   key = "Entity > Entity Category"
    //   groupedLeiDiff = [{ "crumbs": "Entity > Entity Category", "new": "BRANCH", "orig": "GENERAL", }, ...],
    .reduce((accum, [key, groupedLeiDiff]) => {
      // Only taking first match since currently each item for complex rules belong to different groups
      // We may need to modify if rules looks at multiple items in the same group
      //const complexLeiDiffs = groupedLeiDiff.find( leiDiff => complexEventTypeMap[leiDiff.event.legalEntityEventType]);
      const currentLegalEntityEventType = groupedLeiDiff.event.legalEntityEventType;

      // skip for event types that did not match every regex from andGroup
      // For reference:
      // Look at: addComplexEventTypes() and generateEvents()
      // And: entity-actions.js> COMPLEX_EVENT_TYPE_MAP > TRANSFORMATION_SUBSIDIARY_TO_BRANCH > orGroup > andGroup
      if (!currentLegalEntityEventType || currentLegalEntityEventType.id == UNREALIZED_EVENT_TYPE) {
        return accum;
      }

      const matchedRule = complexEventTypeMap[currentLegalEntityEventType.id];
      const complexLeiDiffs = currentLegalEntityEventType && matchedRule && groupedLeiDiff;
      //const complexLeiDiffs = matchedRule && groupedLeiDiff;

      // for simple event types
      if (!complexLeiDiffs) {
        accum[key] = groupedLeiDiff;
        return accum;
      }

      // for complex event type
      console.log(
        `lei-events-util.js> groupComplexEventsByType() complexLeiDiff: ${JSON.stringify(
          complexLeiDiffs
        )}, matchedRule: ${JSON.stringify(matchedRule)}`
      );
      const complexLeiDiffGroup = accum[matchedRule.groupCrumb];
      // when a complex type is already available for this group
      if (complexLeiDiffGroup) {
        // When current complexLeiDiffs is the groupCrumb and should be shown on ui
        if (key == matchedRule.groupCrumb) {
          // keep ComplexLeiDiff details
          // TODO: Unneeded props event, getEvent are included
          complexLeiDiffs.push(...complexLeiDiffGroup);
          complexLeiDiffs.event.affectedFieldsHelper = complexLeiDiffs.event.affectedFieldsHelper.concat(
            complexLeiDiffGroup.event.affectedFieldsHelper
          );
          // Make the complexLeiDiffs with groupCrumb the main record
          accum[matchedRule.groupCrumb] = complexLeiDiffs;
        }
        // When current complexLeiDiffs should not be shown on ui
        else {
          // keep complexLeiDiffs details
          // TODO: Unneeded props event, getEvent go with it
          complexLeiDiffs.forEach(complexLeiDiff => (complexLeiDiff.isHiddenField = true));
          complexLeiDiffGroup.push(...complexLeiDiffs);
          complexLeiDiffGroup.event.affectedFieldsHelper = complexLeiDiffGroup.event.affectedFieldsHelper.concat(
            complexLeiDiffs.event.affectedFieldsHelper
          );
        }
        return accum;
      }
      // when this is the first complex type for this group
      if (!key.startsWith(matchedRule.groupCrumb)) {
        complexLeiDiffs.forEach(complexLeiDiff => (complexLeiDiff.isHiddenField = true));
      }
      accum[matchedRule.groupCrumb] = complexLeiDiffs;
      return accum;
    }, {});

  return newGroupedLeiDiffReport;
}

/**
 * Determine event group type using complexEventTypeMap.
 * @param groupedLeiDiffReport Ex: { "Entity > Legal Name": [...].event: { "legalEntityEventGroupType": STANDALONE, ... }}
 * @param complexEventTypeMap
 * @param applyToEventFields
 * @returns {any} Ex: { "Entity > Legal Name": [...].event: { "legalEntityEventGroupType": "CHANGE_LEGAL_FORM_AND_NAME", ... }}
 */
export function addComplexEventGroupTypes(groupedLeiDiffReport) {
  // Reset values to standalone
  Object.values(groupedLeiDiffReport).forEach(
    ({ event }) => (event.legalEntityEventGroupType = { id: 'STANDALONE', label: 'Standalone' })
  );
  // Determine event group types
  const complexType = addComplexEventTypes(groupedLeiDiffReport, {
    complexEventTypeMap: COMPLEX_EVENT_GROUP_TYPE_MAP,
    applyToEventFields: ['legalEntityEventGroupType']
  });

  // return value used by test cases
  return complexType;
}

async function _addEventGroupIdsFn(complexEventGroupTypeMap, generateIds) {
  const complexEventGroupTypeMapClone = deepClone(complexEventGroupTypeMap);
  const rules = Object.values(complexEventGroupTypeMapClone);
  const res = await generateIds(rules.length);
  const eventIds = res.data;
  rules.forEach(rule => {
    rule.legalEntityEventGroupId = eventIds.pop().humanReadableId;
  });

  return event => {
    const isGroupFieldInfo =
      complexEventGroupTypeMapClone[event.legalEntityEventGroupType.id || event.legalEntityEventGroupType];
    return isGroupFieldInfo
      ? Object.assign({ legalEntityEventGroupId: isGroupFieldInfo.legalEntityEventGroupId }, event)
      : event;
  };
}

/**
 * Add group id for events having group types not equal to STANDALONE
 * @param groupedLeiDiffReport Ex: { "Entity > Legal Name": [...].event: { "legalEntityEventGroupType": CHANGE_LEGAL_FORM_AND_NAME, ... }}
 * @param generateIds
 * @param complexEventGroupTypeMap
 * @returns {Promise<{}>} Ex: { "Entity > Legal Name": [...].event: { "legalEntityEventGroupType": CHANGE_LEGAL_FORM_AND_NAME, legalEntityEventGroupId: "DMY6PY83A050", ... }}
 */
export async function addEventGroupIds(
  groupedLeiDiffReport,
  generateIds,
  { complexEventGroupTypeMap } = { complexEventGroupTypeMap: COMPLEX_EVENT_GROUP_TYPE_MAP }
) {
  console.debug('lei-events-util.js> addEventGroupIds() groupedLeiDiffReport:', groupedLeiDiffReport);
  // Reset values to standalone
  Object.values(groupedLeiDiffReport).forEach(({ event }) => (event.legalEntityEventGroupId = undefined));
  const processedGroupedLeiDiffReport = _traverseGroupedLeiDiffReportWithEvents(groupedLeiDiffReport, [
    await _addEventGroupIdsFn(complexEventGroupTypeMap, generateIds)
  ]);
  return processedGroupedLeiDiffReport;
}

export function getStatusFromEffectiveDate(date) {
  return date
    ? date <= new Date()
      ? { id: LEGAL_ENTITY_EVENT_STATUS.COMPLETED, label: convertToLabel(LEGAL_ENTITY_EVENT_STATUS.COMPLETED) }
      : { id: LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS, label: convertToLabel(LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS) }
    : { id: '', label: '' };
}

// Adapter to convert event to Bbds events.
export function prepareForBbds(bbdsData, groupedComplexLeiDiffs, { recordedDate } = { recordedDate: new Date() }) {
  const legalEntityEventRecordedDate =
    recordedDate && recordedDate instanceof Date ? recordedDate.toISOString() : undefined;
  return groupedComplexLeiDiffs.map(groupedComplexLeiDiff => {
    const eventType = groupedComplexLeiDiff.event.legalEntityEventType.id;
    const eventStatus = groupedComplexLeiDiff.event.legalEntityEventStatus.id;

    const keepAffectedFieldsHelper = eventStatus == LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS;

    const affectedFieldsHelperWithStringifiedValues = [];
    if (keepAffectedFieldsHelper) {
      const affectedFieldsHelper = groupedComplexLeiDiff.event.affectedFieldsHelper.map(affectedFieldHelper => {
        const affectedFieldsHelperWithStringifiedValue = affectedFieldHelper.affectedFieldHelperValue
          ? affectedFieldHelper.affectedFieldHelperValue + ''
          : affectedFieldHelper.affectedFieldHelperValue;
        return Object.assign({}, affectedFieldHelper, {
          affectedFieldHelperValue: affectedFieldsHelperWithStringifiedValue
        });
      });

      affectedFieldsHelperWithStringifiedValues.push(...affectedFieldsHelper);
    }

    const getExtraFields = eventHasExtraFields(eventType);
    const extraFields = getExtraFields() || [];
    extraFields.forEach(extraField => {
      if (!extraField.internal) {
        // extra-field update is based on user's input
        const extraFieldKey = getPath(groupedComplexLeiDiff, ['event', extraField.formKey]);
        // Update payload for events with completed status having extra fields
        if (eventStatus === LEGAL_ENTITY_EVENT_STATUS.COMPLETED)
          setPath(
            bbdsData,
            [DS_NAME, 'data', ...extraField.baseXpath, extraFieldKey],
            groupedComplexLeiDiff.event[extraFieldKey]
          );

        affectedFieldsHelperWithStringifiedValues.push({
          affectedFieldHelperJsonPath: getXpathFromCrumbs([...extraField.baseXpath, extraFieldKey]),
          affectedFieldHelperValue: groupedComplexLeiDiff.event[extraFieldKey]
        });
      }
    });

    // if successorLei or successorEntityName is set => we are INACTIVATING the current entity
    const successorLei = getPath(bbdsData, [DS_NAME, 'data', 'entity', 'successorEntity', 'successorLEI']);
    const successorName = getPath(bbdsData, [DS_NAME, 'data', 'entity', 'successorEntity', 'successorEntityName']);
    const requiresDeactivatingEntity = ['DISSOLUTION'];

    if (successorLei || successorName || requiresDeactivatingEntity.includes(eventType)) {
      // internal fields (entityStatus, etc) updates based on action type
      extraFields
        .filter(({ internal }) => internal)
        .map(extraField => {
          if (eventStatus === LEGAL_ENTITY_EVENT_STATUS.COMPLETED)
            setPath(bbdsData, [DS_NAME, 'data', ...extraField.baseXpath], extraField.newValue);
        });
    }

    // If event status is not in progress, clear affected fields
    groupedComplexLeiDiff.bbdsEvent = convertEventToBbdsEvent(groupedComplexLeiDiff.event, {
      recordedDate: legalEntityEventRecordedDate,
      affectedFieldsHelper:
        affectedFieldsHelperWithStringifiedValues.length > 0 ? affectedFieldsHelperWithStringifiedValues : undefined
    });

    return groupedComplexLeiDiff;
  });
}

// Check for required event fields LEGAL_ENTITY_EVENT_REQUIRED_FIELDS.
// Field is required when no requiredWhenFieldNameExists conditional is defined.
// When requiredWhenFieldNameExists is defined, the field is required if the specified dependent field and value match.
export function validateRequiredFields(groupedComplexLeiDiffs, extraFieldsMap = LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP) {
  // Loop through all events
  const allErrors = groupedComplexLeiDiffs.map(({ validationEvent }) => {
    // Skip validation for data correction
    if (validationEvent.legalEntityEventType == 'DATA_CORRECTION') return [];

    // For each validationEvent check that the required fields are present
    const errors = Object.entries(LEGAL_ENTITY_EVENT_REQUIRED_FIELDS[validationEvent.category])
      .map(
        ([
          // Ex: requiredEventFieldName = successorEntityType
          requiredFieldName,
          { requiredWhenFieldNameExists = null, requiredWhenFieldValue = null, message }
        ]) => {
          // Field is always required when requiredWhenFieldNameExists is not defined. Ex: legalEntityEventType
          if (!requiredWhenFieldNameExists && !validationEvent[requiredFieldName]) {
            return { severity: 'error', message: `[EVENTS]: ${message}` };
          }
          // When requiredWhenFieldNameExists is defined then field is only required when dependent field and value are present
          else if (
            requiredWhenFieldNameExists &&
            getPath(extraFieldsMap, [validationEvent.legalEntityEventType], []).some(
              ({ conditionalQuestion }) => conditionalQuestion
            ) &&
            validationEvent[requiredWhenFieldNameExists] == requiredWhenFieldValue &&
            (validationEvent[requiredFieldName] === null || validationEvent[requiredFieldName] === undefined)
          ) {
            return { severity: 'error', message: `[EVENTS]: ${message}` };
          }
          // all good
          return null;
        }
      )
      .filter(error => !!error);
    return errors;
  });
  console.log(`allErrors: ${JSON.stringify(allErrors)}`);

  return allErrors;
}

export function convertEventToBbdsEvent(event, { recordedDate, affectedFieldsHelper }) {
  return {
    legalEntityEventType: event.legalEntityEventType.id,
    legalEntityEventEffectiveDate: event.legalEntityEventEffectiveDate
      ? event.legalEntityEventEffectiveDate.dateString
      : undefined,
    legalEntityEventRecordedDate: recordedDate,
    legalEntityEventStatus: event.legalEntityEventStatus ? event.legalEntityEventStatus.id : undefined,
    validationDocuments: event.validationDocuments.id,
    legalEntityEventGroupType: event.legalEntityEventGroupType.id,
    legalEntityEventGroupId: event.legalEntityEventGroupId,
    legalEntityEventGroupSequenceNo: event.legalEntityEventGroupSequenceNo,
    lineageId: event.lineageId,
    affectedFieldsHelper: affectedFieldsHelper
  };
}

// Prune empty lists
// Example:
// From: "entity": { "otherEntityNames": { "otherEntityName": [ { "type": "PREVIOUS_LEGAL_NAME", "new": undefined }]},
// To: "entity": { "otherEntityNames": undefined },
export function pruneCrumbsWithEmptyList(crumbsArray, value) {
  let foundNumber = false;
  const crumbsArrayNoEmptyList = value
    ? crumbsArray
    : crumbsArray.filter(crumb => {
        foundNumber = foundNumber || !isNaN(crumb);
        return !foundNumber;
      });
  foundNumber && crumbsArrayNoEmptyList.pop();

  return crumbsArrayNoEmptyList;
}

export function pruneEmptyRelationships(crumbsArray, value) {
  let foundParent = false,
    lastCrumb;
  const crumbsArrayNoEmptyRelationships = value
    ? crumbsArray
    : crumbsArray.filter(crumb => {
        if (!foundParent) {
          lastCrumb = crumb;
        }
        foundParent = foundParent || EVENT_RELATIONSHIP_PARENTS.includes(crumb);
        return !foundParent;
      });
  foundParent && crumbsArrayNoEmptyRelationships.push(lastCrumb);

  return crumbsArrayNoEmptyRelationships;
}

export function pruneUltimateWhenNoDirectParent(bbdsData) {
  const directParent = getPath(bbdsData, [DS_NAME, 'data', 'relationships', 'directParent']);
  if (!directParent) {
    return setPath(bbdsData, [DS_NAME, 'data', 'relationships', 'ultimateParent'], undefined);
  }

  if (directParent) {
    const ultimateParent = getPath(bbdsData, [DS_NAME, 'data', 'relationships', 'ultimateParent']);
    const ultimateParentEntity = getPath(ultimateParent, [`${DS_NAME}.ParentInformation`, 'parentEntity']);

    if (!ultimateParentEntity) {
      setPath(bbdsData, [DS_NAME, 'data', 'relationships', 'ultimateParent'], {
        [`${DS_NAME}.UltimateParentSameAsDirectParent`]: {}
      });
    }
  }
  return bbdsData;
}

// Add unit tests
// Required to reverse lei-preprocess.js>  addRelationshipPeriods()
export function pruneEmptyRelationshipsBeforeDiff(relationships) {
  Object.keys(relationships).forEach(relationshipType => {
    // remove relationship when start date doesn't exists
    if (relationshipType === 'branchParent') {
      const relationshipPeriod = getPath(relationships[relationshipType], [
        'relationshipPeriods',
        'relationshipPeriod'
      ]);
      if (relationshipPeriod) {
        const newRelationshipPeriod = relationshipPeriod.filter(({ startDate }) => !!startDate);
        setPath(
          relationships[relationshipType],
          ['relationshipPeriods', 'relationshipPeriod'],
          newRelationshipPeriod.length ? newRelationshipPeriod : undefined
        );
      }
    } else if (['directParent', 'ultimateParent'].includes(relationshipType)) {
      const relationshipPeriod = getPath(relationships[relationshipType], [
        `${DS_NAME}.ParentInformation`,
        'relationshipPeriods',
        'relationshipPeriod'
      ]);
      if (relationshipPeriod) {
        const newRelationshipPeriod = relationshipPeriod.filter(({ startDate }) => !!startDate);
        setPath(
          relationships[relationshipType],
          [`${DS_NAME}.ParentInformation`, 'relationshipPeriods', 'relationshipPeriod'],
          newRelationshipPeriod.length ? newRelationshipPeriod : undefined
        );
      }
    }
  });
}

// Revert bbds payload values for in progress fields
export function revertInProgressFieldsForBbds(bbdsData, groupedComplexLeiDiffs) {
  groupedComplexLeiDiffs
    .filter(({ bbdsEvent: { legalEntityEventStatus, legalEntityEventType } }) => {
      // for event marked as data-correction event_type, treat them as completed status since
      // their change should be directly applied to the payload
      return (
        legalEntityEventStatus === LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS &&
        legalEntityEventType !== LEGAL_ENTITY_EVENT_SKIP_TYPE
      );
    })
    .forEach(groupedComplexLeiDiff => {
      console.debug(
        `lei-events-util.js> revertInProgressFieldsForBbds() groupedComplexLeiDiff: ${groupedComplexLeiDiff}`
      );
      groupedComplexLeiDiff.forEach(({ crumbsArray, orig }) => {
        console.debug(`lei-events-util.js> revertInProgressFieldsForBbds() crumbsArray:${crumbsArray}, orig: ${orig}`);

        const crumbsArrayNoEmptyRelationships = pruneEmptyRelationships(crumbsArray, orig);
        const crumbsArrayNoEmptyList = pruneCrumbsWithEmptyList(crumbsArrayNoEmptyRelationships, orig);

        setPath(bbdsData[DS_NAME]['data'], crumbsArrayNoEmptyList, orig);
      });
    });

  return bbdsData;
}

// TODO: Add unit tests
export function pruneSkipEventTypes(groupedLeiDiffsWithBbdsEvents, skippedEventTypes = LEGAL_ENTITY_EVENT_SKIP_TYPE) {
  return (
    groupedLeiDiffsWithBbdsEvents
      // Filter out groupedLeiDiffsWithBbdsEvents having entity types that should be skipped, ex: DATA_CORRECTION
      .filter(({ bbdsEvent: { legalEntityEventType } }) => !skippedEventTypes.includes(legalEntityEventType))
  );
}

export function eventHasExtraFields(legalEntityEventType) {
  const extraFields = LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP[legalEntityEventType];
  return key =>
    key ? (extraFields || []).find(({ formKey, required }) => formKey === key && required) : extraFields || [];
}

// TODO: Add unit tests
export function addCallbackToEvents(events, { eventCallBackFn }) {
  return events.map(event => eventCallBackFn(event));
}

//TODO: add unit tests
export function parseEventStringDate(event) {
  const date = getPath(event, ['legalEntityEventEffectiveDate', 'date']);
  const dateString = getPath(event, ['legalEntityEventEffectiveDate', 'dateString']);
  const effectiveDate = date ? { dateString, date: stringToDate(date) } : event.legalEntityEventEffectiveDate;
  event.legalEntityEventEffectiveDate = effectiveDate;
  return event;
}

// TODO: Consider using for groupComplexEventsByType & addComplexEventTypes
function _traverseGroupedLeiDiffReportWithEvents(groupedLeiDiffReport, leiEventFns = [], leiDiffFns = []) {
  const processedGroupedLeiDiffReport = Object.entries(groupedLeiDiffReport)
    // Ex:
    //   key = "Entity > Entity Category"
    //   groupedLeiDiff = [{ "crumbs": "Entity > Entity Category", "new": "BRANCH", "orig": "GENERAL", }, ...],
    .reduce((accum, [key, groupedLeiDiff]) => {
      const newGroupedLeiDiff = leiDiffFns.length
        ? leiDiffFns.reduce((accum, leiDiffFn) => leiDiffFn(deepClone(accum)), groupedLeiDiff)
        : groupedLeiDiff;
      newGroupedLeiDiff.event = leiEventFns.length
        ? [...leiEventFns, parseEventStringDate].reduce(
            (accum, leiEventFn) => leiEventFn(deepClone(accum)),
            groupedLeiDiff.event
          )
        : groupedLeiDiff.event;
      accum[key] = newGroupedLeiDiff;
      return accum;
    }, {});

  return processedGroupedLeiDiffReport;
}

function _addExtraEventFields(event) {
  const extraFieldsInfo = LEGAL_ENTITY_EVENT_EXTRA_FIELDS_MAP[event.legalEntityEventType.id];
  return extraFieldsInfo
    ? Object.assign({ extraFields: extraFieldsInfo.map(({ required, formKey }) => ({ required, formKey })) }, event)
    : event;
}

export function addExtraEventFieldsToGroupedLeiDiffReport(groupedLeiDiffReport) {
  console.log(
    'lei-events-util.js> addExtraEventFieldsToGroupedLeiDiffReport() groupedLeiDiffReport:',
    groupedLeiDiffReport
  );
  const processedGroupedLeiDiffReport = _traverseGroupedLeiDiffReportWithEvents(groupedLeiDiffReport, [
    _addExtraEventFields
  ]);
  return processedGroupedLeiDiffReport;
}

/**
 * Merge events previously generated and potentially updated by user with those that are newly generated
 * @param inFlightGroupedLeiDiffReport - report from previous load of events tab
 * @param newGroupedLeiDiffReport - report from current load of events tab (new diff results)
 * @returns {*}
 */
export function mergeInflightGroupedLeiDiffReport(inFlightGroupedLeiDiffReport, newGroupedLeiDiffReport) {
  console.log(
    'lei-events-util.js> mergeInflightGroupedLeiDiff() inFlightGroupedLeiDiffReport:',
    inFlightGroupedLeiDiffReport,
    ' newGroupedLeiDiffReport:',
    newGroupedLeiDiffReport
  );
  const emptyObject = {};

  // Early returns
  if (!inFlightGroupedLeiDiffReport || angular.equals(inFlightGroupedLeiDiffReport, emptyObject)) {
    return newGroupedLeiDiffReport;
  }
  if (!newGroupedLeiDiffReport || angular.equals(newGroupedLeiDiffReport, emptyObject)) {
    return;
  }

  // Ex:
  //   key = "Entity > Entity Category"
  //   groupedLeiDiff = [{ "crumbs": "Entity > Entity Category", "new": "BRANCH", "orig": "GENERAL", }, ...],
  const mergedInflightGroupedLeiDiffReport = Object.entries(newGroupedLeiDiffReport).reduce(
    (accum, [key, newGroupedLeiDiff]) => {
      // Pick inflight diff when it is available otherwise return new diff
      if (inFlightGroupedLeiDiffReport[key] && angular.equals(inFlightGroupedLeiDiffReport[key], newGroupedLeiDiff)) {
        console.log(
          'lei-events-util.js> mergeInflightGroupedLeiDiff() inFlightGroupedLeiDiffReport[key]:',
          inFlightGroupedLeiDiffReport[key],
          ' newGroupedLeiDiff:',
          newGroupedLeiDiff
        );
        const clonedInFlightGroupedLeiDiffReport = deepClone(inFlightGroupedLeiDiffReport[key]);
        clonedInFlightGroupedLeiDiffReport.event = { ...inFlightGroupedLeiDiffReport[key].event };
        clonedInFlightGroupedLeiDiffReport.getEvent = () => clonedInFlightGroupedLeiDiffReport.event;
        accum[key] = clonedInFlightGroupedLeiDiffReport;
      } else {
        accum[key] = newGroupedLeiDiff;
      }

      return accum;
    },
    {}
  );

  return mergedInflightGroupedLeiDiffReport;
}

function _mergeDraftBbdsWithGroupedLeiDiffEvents(draftBbdsLegalEntityEvents) {
  return groupedLeiDiffEvent => {
    const draftBbdsLegalEntityEvent = draftBbdsLegalEntityEvents.find(
      ({ legalEntityEventType }) => legalEntityEventType === groupedLeiDiffEvent.legalEntityEventType.id
    );
    if (!draftBbdsLegalEntityEvent) {
      return groupedLeiDiffEvent;
    }
    const preparedDraftBbdsLegalEntityEvent = prepareEventForFormFields(
      draftBbdsLegalEntityEvent,
      EVENT_CATEGORIES.EVENT
    );
    return { ...groupedLeiDiffEvent, ...preparedDraftBbdsLegalEntityEvent };
  };
}

export function mergeDraftBbdsDataWithGroupedLeiDiffReportEvents(draftBbdsData, groupedLeiDiffReport) {
  const emptyObject = {};
  // early returns
  if (!draftBbdsData || angular.equals(draftBbdsData, emptyObject)) {
    return groupedLeiDiffReport;
  }

  // Get unpublished draft events and retrieve merge function
  const draftEvents = getPath(draftBbdsData, ['entity', 'legalEntityEvents', 'legalEntityEvent']) || []; // protect against null
  const unpublishedLegalEntityEvents = deepClone(
    draftEvents.filter(draftEvent => {
      console.debug(
        '>> lei-event-util> mergeDraftBbdsDataWithGroupedLeiDiffReportEvents() draftEvent:',
        draftEvent,
        'draftEvent.legalEntityEventRecordedDate:',
        draftEvent.legalEntityEventRecordedDate
      );
      return !draftEvent.legalEntityEventRecordedDate;
    })
  );

  console.debug(
    'lei-events-util.js> mergeDraftBbdsDataWithGroupedLeiDiffReportEvents() unpublishedLegalEntityEvents:',
    unpublishedLegalEntityEvents
  );
  const mergeFn = _mergeDraftBbdsWithGroupedLeiDiffEvents(unpublishedLegalEntityEvents);

  // Go through groupedLeiDiffReport events and merge in unpublished draft events
  const newGroupedLeiDiffReport = _traverseGroupedLeiDiffReportWithEvents(groupedLeiDiffReport, [mergeFn]);

  return newGroupedLeiDiffReport;
}

export function getCrumbsFromXpath(affectedFieldHelperJsonPath, prefix = '//', separator = '/') {
  const cleanXpathTerms = affectedFieldHelperJsonPath.replace(prefix, '').split(separator); // @ always 1-to-1 mapping?
  const xpathTerms = cleanXpathTerms.map(cleanXpathTerm => {
    const xpathTerm = isNaN(cleanXpathTerm) ? cleanXpathTerm : +cleanXpathTerm + '';
    if (xpathTerm.includes('.')) {
      const matchedKey = Object.keys(AFFECTED_FIELD_XPATH_TERM_OVERRIDES).find(overide =>
        xpathTerm.startsWith(overide)
      );
      const xpathTermConcreteSchema = matchedKey
        ? xpathTerm.replace(matchedKey, AFFECTED_FIELD_XPATH_TERM_OVERRIDES[matchedKey])
        : xpathTerm;
      return xpathTermConcreteSchema;
    } else {
      return xpathTerm;
    }
  });
  return xpathTerms;
}

// TODO: Add unit tests
export function updateEntityDetailsOnEventCompleted(processedPayload, { affectedFieldsHelper }) {
  affectedFieldsHelper.forEach(({ affectedFieldHelperJsonPath, affectedFieldHelperValue }) => {
    const xpathTerms = getCrumbsFromXpath(affectedFieldHelperJsonPath); // @ always 1-to-1 mapping?
    const prunedXpathTerms = pruneEmptyRelationships(xpathTerms, affectedFieldHelperValue);
    const lastXpathTerm = prunedXpathTerms[prunedXpathTerms.length - 1];
    const preparedValue =
      affectedFieldHelperValue && AFFECTED_FIELD_WITH_NUMBER_VALUE.includes(lastXpathTerm)
        ? +affectedFieldHelperValue
        : affectedFieldHelperValue;
    setPath(processedPayload, [DS_NAME, 'data', ...prunedXpathTerms], preparedValue);
  });
}

// TODO: Add unit tests
export function generateNewEventsByStatusChange(initialBbdsEvents, modifiedFormEvents, processedPayload, options) {
  let newEvents = [];
  const inProgress = LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS;
  const withdrawn = LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED;
  const completed = LEGAL_ENTITY_EVENT_STATUS.COMPLETED;

  modifiedFormEvents.forEach(formEvent => {
    const preparedModifiedEvent = convertEventToBbdsEvent(formEvent, {
      recordedDate: new Date().toISOString(),
      affectedFieldsHelper: formEvent.affectedFieldsHelper
    });

    if (preparedModifiedEvent.legalEntityEventStatus !== inProgress) {
      let newEvent = initialBbdsEvents.find(({ lineageId }) => lineageId === preparedModifiedEvent.lineageId);
      newEvent = {
        ...newEvent,
        ...preparedModifiedEvent
      };

      const getExtraFields = eventHasExtraFields(preparedModifiedEvent.legalEntityEventType);
      const extraFieldJsonPaths = getExtraFields().map(({ baseXpath }) => getXpathFromCrumbs(baseXpath));

      if (preparedModifiedEvent.legalEntityEventStatus === completed) {
        if (options && options.applyCompleted) updateEntityDetailsOnEventCompleted(processedPayload, newEvent);
        // filter affectedFieldsHelper for COMPLETED events and only keep extra field
        newEvent = {
          ...newEvent,
          affectedFieldsHelper: newEvent.affectedFieldsHelper.filter(({ affectedFieldHelperJsonPath }) =>
            extraFieldJsonPaths.some(jsonPath => affectedFieldHelperJsonPath.startsWith(jsonPath))
          )
        };
      }

      // remove affectedFieldsHelper for WITHDRAWN events or when affected fields is empty
      const affectedFieldsHelper =
        preparedModifiedEvent.legalEntityEventStatus === withdrawn ||
        (newEvent.affectedFieldsHelper && newEvent.affectedFieldsHelper.length === 0)
          ? undefined
          : newEvent.affectedFieldsHelper;
      newEvent = {
        ...newEvent,
        affectedFieldsHelper
      };

      newEvents.push(newEvent);
    }
  });
  pruneUltimateWhenNoDirectParent(processedPayload);
  return newEvents;
}

// TODO: Add unit tests
export function updateRunningEvents(initialEvents, modifiedEvents) {
  return initialEvents.map(event => {
    const matchedEvent = modifiedEvents.find(({ legalEntityEventType, lineageId, legalEntityEventStatus }) => {
      const isMatch =
        legalEntityEventType.id === event.legalEntityEventType &&
        lineageId === event.lineageId &&
        legalEntityEventStatus.id === event.legalEntityEventStatus;
      return isMatch;
    });

    if (matchedEvent) {
      const preparedMatchedEvent = convertEventToBbdsEvent(matchedEvent, {
        recordedDate: new Date().toISOString(),
        affectedFieldsHelper: event.affectedFieldsHelper
      });
      return { ...event, ...preparedMatchedEvent };
    }

    return event;
  });
}

/*
 * Iterate over all the events in reverse order. Separate events in 2 groups: already
 * done (all completed/withdrawn events + their corresponding in-progress), and running ones (in-progress with no matching completed/withdrawn)
 * Filter afterwards to keep only the in-progress events
 */
// TODO: Add unit tests
export function getInProgressAndDoneFromExistingEvents(allEvents) {
  const doneLineageIds = new Set();
  const doneStatuses = [LEGAL_ENTITY_EVENT_STATUS.COMPLETED, LEGAL_ENTITY_EVENT_STATUS.WITHDRAWN_CANCELLED];
  const inProgress = LEGAL_ENTITY_EVENT_STATUS.IN_PROGRESS;

  // Filter for Existing events having recorded/submission date.
  // Skip draft (in flight) events, which don't have a recorded date.
  const publishedExistingEvents = allEvents.filter(
    ({ legalEntityEventRecordedDate }) => !!legalEntityEventRecordedDate
  );

  publishedExistingEvents.forEach(event => {
    if (doneStatuses.includes(event.legalEntityEventStatus) && event.lineageId) doneLineageIds.add(event.lineageId);
  });

  const doneEvents = publishedExistingEvents.filter(({ lineageId }) => doneLineageIds.has(lineageId));
  const runningEvents = publishedExistingEvents.filter(
    ({ legalEntityEventStatus, lineageId }) => legalEntityEventStatus === inProgress && !doneLineageIds.has(lineageId)
  );

  return { runningEvents, doneEvents };
}

// TODO: Add unit tests
export async function addLineageIdsToGroupedLeiDiffReportEvents(groupedLeiDiffReport, getEventIds) {
  console.log(
    'lei-events-util.js> addLineageIdsToGroupedLeiDiffReportEvents() groupedLeiDiffReport:',
    groupedLeiDiffReport
  );

  const groupedLeiDiffs = Object.values(groupedLeiDiffReport || {});
  if (!groupedLeiDiffs || !groupedLeiDiffs.length) return;

  const res = await getEventIds(groupedLeiDiffs.length);
  const eventIds = res.data;

  groupedLeiDiffs.forEach(groupedLeiDiff => {
    if (!getPath(groupedLeiDiff, ['event', 'lineageId'])) {
      setPath(groupedLeiDiff, ['event', 'lineageId'], eventIds.pop().humanReadableId);
    }
  });
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// TODO: Detected multiple triggers for onChangeTab. Investigate cause.
export function debounceCall(fn, wait) {
  return debounce(fn, wait);
}
