'use strict';

// Japan: 47 L1 subdivisions
module.exports = [
  { abbr: '28', name: 'Hyogo' },
  { abbr: '04', name: 'Miyagi' },
  { abbr: '05', name: 'Akita' },
  { abbr: '14', name: 'Kanagawa' },
  { abbr: '09', name: 'Tochigi' },
  { abbr: '41', name: 'Saga' },
  { abbr: '15', name: 'Niigata' },
  { abbr: '33', name: 'Okayama' },
  { abbr: '38', name: 'Ehime' },
  { abbr: '34', name: 'Hiroshima' },
  { abbr: '31', name: 'Tottori' },
  { abbr: '45', name: 'Miyazaki' },
  { abbr: '44', name: 'Oita' },
  { abbr: '30', name: 'Wakayama' },
  { abbr: '37', name: 'Kagawa' },
  { abbr: '22', name: 'Shizuoka' },
  { abbr: '10', name: 'Gunma' },
  { abbr: '26', name: 'Kyoto' },
  { abbr: '24', name: 'Mie' },
  { abbr: '27', name: 'Osaka' },
  { abbr: '13', name: 'Tokyo' },
  { abbr: '25', name: 'Shiga' },
  { abbr: '32', name: 'Shimane' },
  { abbr: '40', name: 'Fukuoka' },
  { abbr: '20', name: 'Nagano' },
  { abbr: '06', name: 'Yamagata' },
  { abbr: '08', name: 'Ibaraki' },
  { abbr: '36', name: 'Tokushima' },
  { abbr: '01', name: 'Hokkaido' },
  { abbr: '42', name: 'Nagasaki' },
  { abbr: '07', name: 'Fukushima' },
  { abbr: '12', name: 'Chiba' },
  { abbr: '43', name: 'Kumamoto' },
  { abbr: '16', name: 'Toyama' },
  { abbr: '46', name: 'Kagoshima' },
  { abbr: '29', name: 'Nara' },
  { abbr: '39', name: 'Kochi' },
  { abbr: '03', name: 'Iwate' },
  { abbr: '23', name: 'Aichi' },
  { abbr: '19', name: 'Yamanashi' },
  { abbr: '35', name: 'Yamaguchi' },
  { abbr: '21', name: 'Gifu' },
  { abbr: '02', name: 'Aomori' },
  { abbr: '11', name: 'Saitama' },
  { abbr: '17', name: 'Ishikawa' },
  { abbr: '47', name: 'Okinawa' },
  { abbr: '18', name: 'Fukui' }
];
