import _ from 'lodash';
import { DS_NAME, CONSOLIDATED_PARENTS, BRANCH_PARENT, FUND_PARENTS } from './shared';
import { LOCAL_LANGUAGE_COUNTRIES_MAP } from '../../../../../common/const/countries-local_language';

export const RELATIONSHIP_PERIODS = ['RELATIONSHIP_PERIOD', 'ACCOUNTING_PERIOD'];

export function mutateAccountingStandards(data, parent) {
  const info = _.get(
    data,
    ['data', 'relationships', parent, CONSOLIDATED_PARENTS.includes(parent) && `${DS_NAME}.ParentInformation`].filter(
      Boolean
    )
  );
  /* istanbul ignore if */
  if (!info) return;

  const standards = _.get(info, ['accountingStandards', 'accountingStandard'], []);
  for (let i = standards.length - 1; i >= 0; i--) {
    const { standard } = standards[i];
    /* istanbul ignore else */
    if (standard) {
      info['accountingStandards'] = standard;
      return;
    }
  }
  /* istanbul ignore next */
  delete info['accountingStandards'];
}

export function getRelationshipPeriods(data, parent) {
  const relationshipKeys = ['data', 'relationships', parent];
  const relationship = _.get(data, relationshipKeys);
  if (!relationship) {
    _.set(data, relationshipKeys, {});
    return getRelationshipPeriods(data, parent);
  }
  if (!Object.keys(relationship).length) relationship._resolves = null;

  const relationshipPeriodKeys = [
    CONSOLIDATED_PARENTS.includes(parent) && `${DS_NAME}.ParentInformation`,
    'relationshipPeriods',
    'relationshipPeriod'
  ].filter(Boolean);
  const relationshipPeriods = _.get(relationship, relationshipPeriodKeys);
  if (!relationshipPeriods) {
    _.set(relationship, relationshipPeriodKeys, []);
    return getRelationshipPeriods(data, parent);
  }

  return relationshipPeriods;
}

export function addRelationshipPeriods(data, parent) {
  const relationshipPeriods = getRelationshipPeriods(data, parent);

  const usedRelationshipPeriods = relationshipPeriods.reduce((acc, { periodType }) => {
    /* istanbul ignore else */
    if (periodType) acc[periodType] = true;
    return acc;
  }, {});

  for (const periodType of RELATIONSHIP_PERIODS) {
    // don't add accounting-period to fund-parents relationships
    if (FUND_PARENTS.includes(parent) && periodType === 'ACCOUNTING_PERIOD') {
      continue;
    }
    if (!usedRelationshipPeriods[periodType]) relationshipPeriods.push({ periodType });
  }
}

export function ensureBranchLocation(entity) {
  // load location of branch from legal address
  const legalAddress = _.get(entity, ['data', 'entity', 'legalAddressInEnglish']);
  if (legalAddress) {
    _.set(entity, ['data', 'entity', 'branchCountry'], legalAddress.country ? legalAddress.country : '');
    _.set(entity, ['data', 'entity', 'branchRegion'], legalAddress.region ? legalAddress.region : '');
  }
}

export function addOtherAddresses(payload) {
  console.info('lei-preprocess> getOtherAdresses() payload:', payload);
  const legalAddressCountry = _.get(payload, ['data', 'entity', 'legalAddressInEnglish', 'country']);
  const headquarterCountry = _.get(payload, ['data', 'entity', 'headquartersAddressInEnglish', 'country']);
  const requiredLanguage = LOCAL_LANGUAGE_COUNTRIES_MAP[legalAddressCountry];
  const requiredHQLanguage = LOCAL_LANGUAGE_COUNTRIES_MAP[headquarterCountry];
  const otherAddressInfos = [
    { type: 'ALTERNATIVE_LANGUAGE_LEGAL_ADDRESS', languageCode: requiredLanguage },
    { type: 'ALTERNATIVE_LANGUAGE_HEADQUARTERS_ADDRESS', languageCode: requiredHQLanguage }
  ];

  const otherAddresses = _.get(payload, ['data', 'entity', 'otherAddressesInLocalLanguage', 'otherAddress'], []);

  if (otherAddresses.length == 0)
    _.set(payload, ['data', 'entity', 'otherAddressesInLocalLanguage', 'otherAddress'], otherAddresses);

  // existing required other address types
  const existingOtherAddressesInLocalLanguageTypes = new Set(
    otherAddresses.filter(({ languageCode }) => languageCode == requiredLanguage).map(({ type }) => type)
  );

  // missing required other address types
  const missingLocalLanguageOtherAddressTypes = otherAddressInfos.filter(
    ({ type: otherAddressType }) => !existingOtherAddressesInLocalLanguageTypes.has(otherAddressType)
  );

  // Always add otherAddressesInLocalLanguage.  Clean up in post process.
  missingLocalLanguageOtherAddressTypes.forEach(({ type: missingLocalLanguageOtherAddressType }) => {
    if (missingLocalLanguageOtherAddressType == otherAddressInfos[0].type) {
      otherAddresses.push(otherAddressInfos[0]);
    } else if (missingLocalLanguageOtherAddressType == otherAddressInfos[1].type) {
      otherAddresses.push(otherAddressInfos[1]);
    }
  });
  console.info('lei-preprocess> getOtherAdresses() otherAddresses:', otherAddresses);
}

export function moveOtherAddressesToDummyLanguageAddresses(payload) {
  const legalAddressCountry = _.get(payload, ['data', 'entity', 'legalAddressInEnglish', 'country']);
  const headquarterCountry = _.get(payload, ['data', 'entity', 'headquartersAddressInEnglish', 'country']);
  const requiredLanguage = LOCAL_LANGUAGE_COUNTRIES_MAP[legalAddressCountry];
  const requiredHQLanguage = LOCAL_LANGUAGE_COUNTRIES_MAP[headquarterCountry];
  const otherAddressInfos = [
    { type: 'ALTERNATIVE_LANGUAGE_LEGAL_ADDRESS', languageCode: requiredLanguage },
    { type: 'ALTERNATIVE_LANGUAGE_HEADQUARTERS_ADDRESS', languageCode: requiredHQLanguage }
  ];
  const otherAddressTypes = otherAddressInfos.map(({ type }) => type);

  const otherAddresses = _.get(payload, ['data', 'entity', 'otherAddressesInLocalLanguage', 'otherAddress'], []);

  if (otherAddresses.length == 0) return;

  // Retrieve existing required local language addresses
  const existingLegalAddressInLocalLanguage = otherAddresses.find(
    ({ type, languageCode }) => type == otherAddressInfos[0].type && languageCode == otherAddressInfos[0].languageCode
  );
  const existingHeadquartersAddressInLocalLanguage = otherAddresses.find(
    ({ type, languageCode }) => type == otherAddressInfos[1].type && languageCode == otherAddressInfos[1].languageCode
  );

  // Always move otherAddressesInLocalLanguage to dummy addresses.  Clean up in post process.
  _.set(payload, ['data', 'entity', 'legalAddressInLocalLanguage'], existingLegalAddressInLocalLanguage);
  _.set(payload, ['data', 'entity', 'headquartersAddressInLocalLanguage'], existingHeadquartersAddressInLocalLanguage);

  // Remove existing required local language addresses from other addresses. Revert in lei-postprocess.
  const remainingOtherAddresses = otherAddresses.filter(({ type, languageCode }) => {
    otherAddressTypes.includes(type) && languageCode != requiredLanguage;
    if (type == otherAddressTypes[0]) {
      return languageCode != otherAddressInfos[0].languageCode;
    } else if (type == otherAddressTypes[1]) {
      return languageCode != otherAddressInfos[1].languageCode;
    }
  });

  if (remainingOtherAddresses.length > 0)
    _.set(payload, ['data', 'entity', 'otherAddressesInLocalLanguage', 'otherAddress'], remainingOtherAddresses);
  else _.set(payload, ['data', 'entity', 'otherAddressesInLocalLanguage'], undefined);
}

export function getStandardKeysFromChain(standards, keyChain) {
  return standards
    .map(standard => {
      const union = _.get(standard, keyChain);
      return union && typeof union === 'object' ? Object.keys(union)[0] : null;
    })
    .filter(Boolean);
}

export function ifUnion(data, callback) {
  let isUnion = 0;
  const entries = Object.entries(data);
  for (const [k, v] of entries) {
    if (k[0] === '_') continue;

    // only need first key to prove it's union.
    if (!isUnion) {
      if (entries.length != 1 && k.startsWith(DS_NAME)) isUnion = 1;
      else isUnion = -1;
    }

    callback(k, v);
  }
  return isUnion;
}

export function recursiveResolveUnions(data, standards, keyChain) {
  if (!data || Array.isArray(data) || typeof data !== 'object' || !Object.keys(data).length) return;

  const next = (key, nextData) => recursiveResolveUnions(nextData, standards, [...keyChain, key]);
  if (ifUnion(data, next) <= 0) return;

  // pick highest precedence key as resolve
  for (const standardKey of getStandardKeysFromChain(standards, keyChain)) {
    /* istanbul ignore else */
    if (Object.prototype.hasOwnProperty.call(data, standardKey)) {
      data._resolves = standardKey;
      return;
    }
  }
  // resolve to nothing, but keep data
  data._resolves = null;
}

export function cleanPNI(payload, parent) {
  const parentEntityPath = ['data', 'relationships', parent, `${DS_NAME}.ParentInformation`, 'parentEntity'];

  const parentLei = _.get(payload, [...parentEntityPath, `${DS_NAME}.ParentLEI`]);

  if (parentLei && Object.keys(parentLei).length > 0) {
    _.set(payload, parentEntityPath, parentLei);
  }
}

function setNoCreationDateIsAvailableIfApplicable(payload) {
  const isFund = () => _.get(payload, ['data', 'entity', 'entityCategory']) === 'FUND';

  const creationDateIsEmpty = () => !_.get(payload, ['data', 'entity', 'entityCreationDate'], null);

  const setNoCreationDateIsAvailable = val => _.set(payload, 'data.entity.noCreationDateIsAvailable', val);

  if (isFund() && creationDateIsEmpty()) setNoCreationDateIsAvailable(true);
  else setNoCreationDateIsAvailable(false);
}

/* istanbul ignore next */
export function preprocess(goldenData, clonedData) {
  _.merge(clonedData, { data: { registration: undefined } });
  const payload = _.merge({}, goldenData, clonedData);

  // all parents
  const parents = [...CONSOLIDATED_PARENTS, ...FUND_PARENTS, ...BRANCH_PARENT];
  parents.forEach(parent => {
    addRelationshipPeriods(payload, parent);
  });

  // handle location of branch
  const entityCategory = _.get(payload, ['data', 'entity', 'entityCategory']);
  if (entityCategory === 'BRANCH') {
    ensureBranchLocation(payload);
  }

  // handle local language addresses
  addOtherAddresses(payload);
  moveOtherAddressesToDummyLanguageAddresses(payload);

  recursiveResolveUnions(
    payload,
    // Precedence (first is higher)
    [clonedData, goldenData].filter(Boolean),
    []
  );

  parents.forEach(parent => {
    mutateAccountingStandards(payload, parent);
  });

  // depracated PNIs
  CONSOLIDATED_PARENTS.map(parent => {
    cleanPNI(payload, parent);
  });

  setNoCreationDateIsAvailableIfApplicable(payload);

  return payload;
}

const PROFILE_TO_PAYLOAD = {
  firstName: 'data.contact.contactFirstName',
  lastName: 'data.contact.contactLastName',
  organizationName: 'data.contact.contactCompany',
  jobTitle: 'data.contact.contactPosition',
  phone: 'data.contact.contactPhone'
};

/* istanbul ignore next */
export function addContactFromProfile(profile, payload) {
  for (const [profilePath, payloadPath] of Object.entries(PROFILE_TO_PAYLOAD))
    _.set(payload, payloadPath, _.get(profile, profilePath));
}
