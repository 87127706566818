import { controller } from './app';

controller('ErrorController', [
  '$scope',
  '$location',
  'message',
  'prev',
  function($scope, $location, message, prev) {
    $scope.message = message;
    $scope.prev = prev;

    if (prev) {
      $scope.absUrl = $location.host() + prev;
    }
  }
]);
