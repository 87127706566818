'use strict';

function MapStore(getCheck, saveCheck) {
  this.getCheck = getCheck;
  this.saveCheck = saveCheck;
  this.map = {};
}

MapStore.prototype.get = function(key) {
  this.getCheck(key, this.map);

  return this.map[key];
};

MapStore.prototype.save = function(key, val) {
  this.saveCheck(key, val, this.map);

  this.map[key] = val;
};

module.exports = MapStore;
