'use strict';

// Panama: 13 L1 subdivisions
module.exports = [
  { abbr: '8', name: 'Panama' },
  { abbr: '3', name: 'Colon' },
  { abbr: '5', name: 'Darien' },
  { abbr: '6', name: 'Herrera' },
  { abbr: '7', name: 'Los Santos' },
  { abbr: 'NB', name: 'Ngabe-Bugle' },
  { abbr: '9', name: 'Veraguas' },
  { abbr: '2', name: 'Cocle' },
  { abbr: 'EM', name: 'Embera' },
  { abbr: '4', name: 'Chiriqui' },
  { abbr: '1', name: 'Bocas del Toro' },
  { abbr: 'KY', name: 'Kuna Yala' },
  { abbr: '10', name: 'Panama Oeste' }
];
