import angular from 'angular';
import { filter, controller } from '../app';

filter('accountFullName', [
  function() {
    return function(lastFirstArr) {
      var last = lastFirstArr[0] || '<No Last Name>';
      var first = lastFirstArr[1] || '<No First Name>';

      return last + ',' + first;
    };
  }
]);

filter('accountFullPhone', [
  function() {
    return function(codePhoneArr) {
      var code = 'No Country Code';
      var phone = codePhoneArr[1];

      if (codePhoneArr[0]) code = codePhoneArr[0].charAt(0) === '+' ? codePhoneArr[0] : codePhoneArr[0].substring(2);

      return code + ' - ' + phone;
    };
  }
]);

filter('accountFullAddress', [
  function() {
    return function(addr1Addr2CityStateCountryPostalArr) {
      var input = addr1Addr2CityStateCountryPostalArr;

      var addr1 = input[0];
      var addr2 = input[1];
      var city = input[2];
      var state = input[3];
      var country = input[4].name;
      var postal = input[5];

      return [addr1, addr2, city, state, country, postal]
        .filter(function(part) {
          return !!part;
        })
        .join(', ');
    };
  }
]);

filter('accountAllEmails', [
  function() {
    return function(emailArr) {
      return emailArr.join(',');
    };
  }
]);

import { schemaStore, checkStore } from '../../../../common/validate';
controller('AdminAccountController', [
  'AdminService',
  'ServiceErrorHandler',
  'Alert',
  'loginEmail',
  function(AdminService, ServiceErrorHandler, Alert, loginEmail) {
    var checkSearchUsername = checkStore.get('searchUsername');
    var checkSearchEmail = checkStore.get('searchEmail');
    var checkAdminNote = checkStore.get('adminNote');
    var checkAdminMessage = checkStore.get('adminMessage');

    var self = this;
    self.props = {};
    self.props.searchUsernameSchema = schemaStore.get('searchUsername');
    self.props.searchEmailSchema = schemaStore.get('searchEmail');
    self.props.adminNoteSchema = schemaStore.get('adminNote');
    self.props.adminMessageSchema = schemaStore.get('adminMessage');
    self.props.adminLoginEmail = loginEmail;
    self.state = {
      model: {},
      vm: {
        searchUsername: {},
        searchEmail: {},
        adminNote: {},
        adminMessage: {}
      },
      validate: {
        searchUsername: {},
        searchEmail: {},
        adminNote: {},
        adminMessage: {}
      }
    };

    self.props.handleSearchUsernameChange = function handleSearchUsernameChange() {
      var input = {
        username: self.state.vm.searchUsername.username
      };

      var check = checkSearchUsername(input);
      self.state.validate.searchUsername = check;

      if (!check.$valid) {
        return;
      }

      self.state.model.searchUsername = input;
    };

    self.props.handleSearchEmailChange = function handleSearchEmailChange() {
      var input = {
        email: self.state.vm.searchEmail.email
      };

      var check = checkSearchEmail(input);
      self.state.validate.searchEmail = check;

      if (!check.$valid) {
        return;
      }

      self.state.model.searchEmail = input;
    };

    self.props.handleAdminNoteChange = function handleAdminNoteChange() {
      var input = {
        note: self.state.vm.adminNote.note,
        sdsk: self.state.vm.adminNote.sdsk
      };

      var check = checkAdminNote(input);
      self.state.validate.adminNote = check;

      if (!check.$valid) {
        return;
      }

      self.state.model.adminNote = input;
    };

    self.props.handleAdminMessageChange = function handleAdminMessageChange() {
      var input = {
        message: self.state.vm.adminMessage.message
      };

      var check = checkAdminMessage(input);
      self.state.validate.adminMessage = check;

      if (!check.$valid) {
        return;
      }

      self.state.model.adminMessage = input;
    };

    self.props.handleSearchUsernameSubmit = function handleSearchUsernameSubmit() {
      self.state.currentUser = null;

      var username = self.state.model.searchUsername.username;

      return AdminService.getUser(username)
        .then(function(res) {
          self.state.currentUser = res.data;
          self.state.currentUsername = username;

          // populate admin note
          window.setTimeout(function() {
            self.state.vm.adminNote = {
              note: res.data.adminReview.note,
              sdsk: res.data.adminReview.sdsk
            };
          }, 0);
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };

    self.props.handleSearchEmailSubmit = function handleSearchEmailSubmit() {
      self.state.currentEmail = null;

      var email = self.state.model.searchEmail.email;

      return AdminService.getUsersByEmail(email)
        .then(function(res) {
          self.state.currentUsers = res.data;
          self.state.currentEmail = email;
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };

    self.props.handleSearchEmailView = function handleSearchEmailView(username) {
      self.state.vm.searchUsername.username = username;
      self.state.model.searchUsername = {
        username: username
      };

      return self.props.handleSearchUsernameSubmit();
    };

    self.props.handleAdminNoteSubmit = function handleAdminNoteSubmit() {
      var username = self.state.currentUsername;
      var note = self.state.model.adminNote.note;
      var sdsk = self.state.model.adminNote.sdsk;

      return AdminService.saveNote(username, note, sdsk)
        .then(function() {
          Alert.success('saveNote');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };

    self.props.handleAdminMessageSubmit = function handleAdminMessageSubmit() {
      var username = self.state.currentUsername;
      var message = self.state.model.adminMessage.message;

      return AdminService.sendMessage(username, message)
        .then(function() {
          Alert.success('sendAdminMessage');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };

    self.props.handleActivateAccount = function handleActivateAccount() {
      var username = self.state.currentUsername;

      return AdminService.activateAccount(username)
        .then(function() {
          Alert.success('activate');
          self.props.handleSearchUsernameSubmit();
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };

    self.props.handleDeactivateAccount = function handleDeactivateAccount() {
      var username = self.state.currentUsername;

      return AdminService.deactivateAccount(username)
        .then(function() {
          Alert.success('deactivate');
          self.props.handleSearchUsernameSubmit();
        })
        .catch(ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };
  }
]);
