'use strict';

function getCheckMessages(checkPropsFn, input) {
  var noDup = {};

  return checkPropsFn(input)
    .$invalidReport.map(function(r) {
      var message = r.checker.$message;

      if (noDup[message]) {
        return null;
      }

      noDup[message] = true;

      return message;
    })
    .filter(function(m) {
      return m !== null;
    });
}

module.exports = getCheckMessages;
