import { factory } from './app';

factory('FriendlyIsoTime', [
  function() {
    var isoDateTimeReg = /^\d\d\d\d-\d\d-\d\d.\d\d:\d\d:\d\d.*/;
    return function(isoTime) {
      if (!isoDateTimeReg.test(isoTime)) {
        return isoTime;
      }
      return isoTime.substr(0, 10) + ' ' + isoTime.substr(11, 8);
    };
  }
]);
