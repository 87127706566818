import angular from 'angular';
import { factory } from '../app';

factory('AdminUserEmailsStore', [
  'ReduxStoreFactory',
  'AdminUserEmailsReducer',
  function(ReduxStoreFactory, AdminUserEmailsReducer) {
    var initialState = {
      model: {
        emailApiKeyPairs: [],
        newEmailApiKeyPair: null
      },
      validate: {
        newEmailApiKeyPair: false
      },
      ui: {
        showAllErrors: false
      }
    };

    return ReduxStoreFactory('AdminUserEmailsStore', AdminUserEmailsReducer, initialState);
  }
]);

factory('AdminUserEmailsActionType', [
  'ReduxActionTypeFactory',
  function(ReduxActionTypeFactory) {
    return ReduxActionTypeFactory([
      'NEW_EMAIL',
      'SET_EMAIL_API_KEY_PAIRS',
      'ADD_EMAIL',
      'REMOVE_EMAIL',
      'GENERATE_API_KEY',
      'REMOVE_API_KEY',
      'SET_API_KEY',
      'VALIDATE_NEW_EMAIL',
      'UI_SHOW_ALL_ERRORS'
    ]);
  }
]);

factory('AdminUserEmailsAction', [
  'AdminUserEmailsActionType',
  function(AdminUserEmailsActionType) {
    return {
      addEmail: function(newEmail) {
        return {
          type: AdminUserEmailsActionType.ADD_EMAIL,
          data: newEmail
        };
      },
      setEmailApiKeyPairs: function(emailApiKeyPairs) {
        return {
          type: AdminUserEmailsActionType.SET_EMAIL_API_KEY_PAIRS,
          data: emailApiKeyPairs
        };
      },
      newEmail: function(newEmail) {
        return {
          type: AdminUserEmailsActionType.NEW_EMAIL,
          data: newEmail
        };
      },
      removeEmail: function(emailToBeRemoved) {
        return {
          type: AdminUserEmailsActionType.REMOVE_EMAIL,
          data: emailToBeRemoved
        };
      },
      setApiKey: function(email, apiKey) {
        return {
          type: AdminUserEmailsActionType.SET_API_KEY,
          data: { apiKey, email }
        };
      },
      validate: function(validateObj) {
        return {
          type: AdminUserEmailsActionType.VALIDATE_NEW_EMAIL,
          data: validateObj
        };
      },
      showAllErrors: function(showOrNot) {
        return {
          type: AdminUserEmailsActionType.UI_SHOW_ALL_ERRORS,
          data: !!showOrNot
        };
      }
    };
  }
]);

factory('AdminUserEmailsReducer', [
  'AdminUserEmailsActionType',
  function(AdminUserEmailsActionType) {
    var assign = angular.extend;

    function emailApiKeyPairsReducer(state, action) {
      if (action.type === AdminUserEmailsActionType.ADD_EMAIL) {
        return [].concat(state).concat({ email: action.data, apiKey: null });
      }

      if (action.type === AdminUserEmailsActionType.REMOVE_EMAIL) {
        return [].concat(state).filter(function(em) {
          return em.email !== action.data;
        });
      }

      if (action.type === AdminUserEmailsActionType.SET_API_KEY) {
        return [].concat(state).map(function(em) {
          if (em.email !== action.data.email) {
            return em;
          }
          return {
            email: action.data.email,
            apiKey: action.data.apiKey
          };
        });
      }

      if (action.type === AdminUserEmailsActionType.SET_EMAIL_API_KEY_PAIRS) {
        return [].concat(action.data);
      }

      return state;
    }

    function newEmailReducer(state, action) {
      if (action.type === AdminUserEmailsActionType.NEW_EMAIL) {
        return action.data;
      }

      return state;
    }

    function validateReducer(state, action) {
      if (action.type === AdminUserEmailsActionType.VALIDATE_NEW_EMAIL) {
        return assign({}, state, action.data);
      }

      return state;
    }

    function showAllErrorsReducer(state, action) {
      if (action.type === AdminUserEmailsActionType.UI_SHOW_ALL_ERRORS) {
        return action.data;
      }

      return state;
    }

    return function r(state, action) {
      return {
        model: {
          emailApiKeyPairs: emailApiKeyPairsReducer(state.model.emailApiKeyPairs, action),
          newEmail: newEmailReducer(state.model.newEmail, action)
        },
        validate: validateReducer(state.validate, action),
        ui: {
          showAllErrors: showAllErrorsReducer(state.ui.showAllErrors, action)
        }
      };
    };
  }
]);
