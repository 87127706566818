import { controller } from './app';
import currencies from '../../../common/const/currencies';
import leiProducts from '../../../common/const/lei-products';
import pageLabels from '../../../common/const/page-labels';

controller(
  'faqController',
  /* @ngInject */
  function($scope, $anchorScroll, AlertMessenger) {
    this.props = {};
    this.props.leiProducts = leiProducts;
    this.props.pageLabels = pageLabels;
    this.props.currencies = currencies;

    var that = this;
    that.props.cdnPrefix = __CDN__;

    $scope.scroll = function() {
      $anchorScroll();
    };

    AlertMessenger.info(
      'For any users whose LEIs are being transferred from GMEI/DTCC during the August 2023 transition, please visit this FAQ '
    );
  }
);
