'use strict';

var countries = require('@dtwebservices/node-bconnect/lib/helper/country-code');
var phoneCountryCodes = require('@dtwebservices/node-bconnect/lib/helper/phone-area-code');
var usStates = require('@dtwebservices/node-bconnect/lib/helper/us-states');
var BC_FIELD_MAP = require('@dtwebservices/node-bconnect/lib/workflow/workflow-helper-profile').FIELD_MAP;
var _ = require('lodash');

function getCode(country) {
  return country.code;
}

var usStatesAbbrMap = usStates
  .map(function getAbbr(s) {
    return s.abbr;
  })
  .reduce(function toMap(prev, cur) {
    prev[cur] = true;
    return prev;
  }, {});

var ORGANIZATION_NAME_MAX_LENGTH = BC_FIELD_MAP['organizationName'].maxlength;

var nonEmptyString = require('./required-string');

// mode
// 'new' (default) - require everything, including TOS and PP
// 'update' - do not require TOS or PP
function makeUserProfileSchema(mode) {
  var updateSchema = {
    firstName: {
      $label: 'First Name',
      $message: 'Please provide valid first name ' + ' (max. ' + BC_FIELD_MAP['firstName'].maxlength + ' characters).',
      $required: true,
      $placeholder: 'First Name',
      checkFn: function(val) {
        return nonEmptyString(val) && Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['firstName'].maxlength;
      }
    },
    lastName: {
      $label: 'Last Name',
      $message: 'Please provide valid last name ' + ' (max. ' + BC_FIELD_MAP['lastName'].maxlength + ' characters).',
      $required: true,
      $placeholder: 'Last Name',
      checkFn: function(val) {
        return nonEmptyString(val) && Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['lastName'].maxlength;
      }
    },
    middleName: {
      $label: 'Middle Name',
      $placeholder: 'M',
      $message:
        'Please provide valid middle name ' + ' (max. ' + BC_FIELD_MAP['middleName'].maxlength + ' characters).',
      checkFn: function(val) {
        return !val || Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['middleName'].maxlength;
      }
    },
    suffix: {
      $label: 'Suffix',
      $placeholder: 'Suffix',
      $message: 'Please provide valid Suffix ' + ' (max. ' + BC_FIELD_MAP['suffix'].maxlength + ' characters).',
      checkFn: function(val) {
        return !val || Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['suffix'].maxlength;
      }
    },
    organizationName: {
      $label: 'Company Name',
      $message: 'Please provide valid company name' + ' (max. ' + ORGANIZATION_NAME_MAX_LENGTH + ' characters).',
      $required: true,
      $placeholder: 'Company Name',
      checkFn: function(val) {
        return nonEmptyString(val) && Buffer.byteLength(val, 'utf8') < ORGANIZATION_NAME_MAX_LENGTH;
      }
    },
    phoneCountryCode: {
      $label: 'Phone Country Code',
      $options: _.sortBy(phoneCountryCodes, 'name'),
      $placeholder: 'Country Code'
    },
    phone: {
      $label: 'Phone',
      $message: 'Please provide valid phone number ' + ' (max. ' + BC_FIELD_MAP['phone'].maxlength + ' characters).',
      $required: true,
      $placeholder: 'Phone',
      checkFn: function(val) {
        return nonEmptyString(val) && Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['phone'].maxlength;
      }
    },
    address1: {
      $label: 'Address Line 1',
      $message:
        'Please provide valid address line 1 ' + ' (max. ' + BC_FIELD_MAP['address1'].maxlength + ' characters).',
      $required: true,
      $placeholder: 'Address Line 1',
      checkFn: function(val) {
        return nonEmptyString(val) && Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['address1'].maxlength;
      }
    },
    address2: {
      $label: 'Address Line 2',
      $message:
        'Please provide valid address line 2 ' + ' (max. ' + BC_FIELD_MAP['address2'].maxlength + ' characters).',
      $placeholder: 'Address Line 2',
      checkFn: function(val) {
        return !val || Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['address2'].maxlength;
      }
    },
    country: {
      $label: 'Country/Region',
      $placeholder: 'Country/Region',
      $message: 'Please provide your country.',
      $required: true,
      $options: _.sortBy(countries, 'name'),
      checkFn: function isDefinedCountryCode(val) {
        return typeof val === 'number' && countries.map(getCode).indexOf(val) !== -1;
      }
    },
    state: {
      $label: 'State',
      $message: 'Please provide valid state ' + ' (max. ' + BC_FIELD_MAP['state'].maxlength + ' characters).',
      $placeholder: 'State',
      checkFn: function(val) {
        return !val || Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['state'].maxlength;
      }
    },
    city: {
      $label: 'City',
      $message: 'Please provide valid city ' + ' (max. ' + BC_FIELD_MAP['city'].maxlength + ' characters).',
      $required: true,
      $placeholder: 'City',
      checkFn: function(val) {
        return !val || Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['city'].maxlength;
      }
    },
    postalCode: {
      $label: 'Postal Code',
      $message:
        'Please provide valid postal code ' + ' (max. ' + BC_FIELD_MAP['postalCode'].maxlength + ' characters).',
      $required: true,
      $placeholder: 'Postal Code',
      checkFn: function(val) {
        return !val || Buffer.byteLength(val, 'utf8') < BC_FIELD_MAP['postalCode'].maxlength;
      }
    },
    _usState: {
      checkFn: function isOneOfUSState(_val, body, report) {
        if (!body) {
          return true;
        }

        if (body.country !== 1) {
          return true;
        }

        return !!usStatesAbbrMap[body.state];
      },
      $message: 'Please provide one of the US state abbreviations (e.g. NY).'
    },
    _name: {
      checkFn: function comprehensiveAddressValidate(_val, body, currentReport) {
        return currentReport.firstName.$valid && currentReport.lastName.$valid;
      },
      $message: 'Please provide your first name and last name.'
    },
    _address: {
      checkFn: function comprehensiveAddressValidate(_val, body, currentReport) {
        return (
          currentReport.address1.$valid &&
          currentReport.address2.$valid &&
          currentReport.country.$valid &&
          currentReport.state.$valid &&
          currentReport.city.$valid &&
          currentReport.postalCode.$valid
        );
      },
      $message: 'Please provide valid values in your address fields.'
    }
  };

  var newProfileExt = {
    termsOfService: {
      $label: 'Terms of Service',
      $message: 'You must agree to the Terms of Service to continue.',
      $link: '/docs/terms-of-service',
      checkFn: function(val) {
        return val === true;
      }
    },
    privacyPolicy: {
      $label: 'Privacy Policy',
      $message: 'You must agree to the Privacy Policy to continue.',
      $link: '/docs/privacy-policy',
      checkFn: function(val) {
        return val === true;
      }
    }
  };

  if (typeof mode === 'undefined' || mode === 'new') {
    return _.assign({}, updateSchema, newProfileExt);
  }

  return updateSchema;
}

module.exports = makeUserProfileSchema;
