import { directive, filter } from './app';

directive(
  'fixedSizeExport',
  /* @ngInject */
  function($httpParamSerializer, $rootScope) {
    return {
      restrict: 'A',
      scope: {
        available: '<?',
        max: '<?'
      },
      template:
        '<a role="button" target="_blank" class="btn btn-success ml-1" ng-href="{{url}}" ng-class="{disabled: disabled}">{{label}}</a>',
      link: function(scope) {
        var params = {};
        function refresh() {
          var limit = Math.min(+scope.max || 0, +scope.available || 0);
          scope.disabled = !limit;
          scope.label = 'Export ' + (limit < +scope.available ? 'First ' + limit : 'All') + ' Items';
          scope.url =
            '/lei/exportFixedExcelBulk?' +
            $httpParamSerializer(
              Object.assign(params, {
                leiId: null,
                _limit: limit,
                _offset: 0,
                ts: Date.now()
              })
            );
        }

        scope.$watch('available', refresh);
        scope.$watch('max', refresh);

        scope.$on(
          '$destroy',
          $rootScope.$on('page-fetch', function(event, data) {
            params = data;
            refresh();
          })
        );
      }
    };
  }
);

directive(
  'autoRenewExport',
  /* @ngInject */
  function($httpParamSerializer, $rootScope) {
    return {
      restrict: 'A',
      scope: {
        available: '<?'
      },
      template:
        '<a role="button" target="_blank" class="btn btn-success ml-1" ng-href="{{url}}" ng-class="{disabled: disabled}">Export All Items</a>',
      link(scope) {
        let params = {};
        function refresh() {
          scope.disabled = !+scope.available;
          scope.url =
            '/user/export/auto-renew/all?' +
            $httpParamSerializer(
              Object.assign(params, {
                ts: Date.now()
              })
            );
        }

        scope.$watch('available', refresh);

        scope.$on(
          '$destroy',
          $rootScope.$on('page-fetch', function(event, data) {
            params = data;
            refresh();
          })
        );
      }
    };
  }
);

filter('trueVals', function() {
  return function(obj) {
    return Object.keys(obj).filter(function(key) {
      return obj[key];
    });
  };
});

filter('exportGleifExcelBulkLink', [
  '$httpParamSerializer',
  function($httpParamSerializer) {
    return function(idArray) {
      if (!(Array.isArray(idArray) && idArray.length)) {
        return '';
      }
      return '/lei/exportGleifExcelBulk?' + $httpParamSerializer({ ids: idArray });
    };
  }
]);

filter('exportExcelBulkLink', [
  '$httpParamSerializer',
  function($httpParamSerializer) {
    return function(idArray) {
      if (!(Array.isArray(idArray) && idArray.length)) {
        return '';
      }
      return '/lei/exportExcelBulk?' + $httpParamSerializer({ ids: idArray });
    };
  }
]);
