import angular from 'angular';
import _ from 'lodash';
import { factory, directive, filter, service, component } from './app';

filter('mB', [
  function() {
    return function(bytes) {
      return Math.ceil(bytes / 1024 / 1024) + ' MB';
    };
  }
]);

filter('kB', [
  function() {
    return function(bytes) {
      return Math.ceil(bytes / 1024) + ' KB';
    };
  }
]);

factory('$exceptionHandler', [
  '$injector',
  '$window',
  '$log',
  function($injector, $window, $log) {
    var throttler = $window.throttlerFn && $window.throttlerFn();

    return function(exception, cause) {
      // if this is http response message, skip it
      if (exception.status) return false;

      $log.error(exception, cause);

      throttler && throttler(sendReport.bind(null, exception, cause));

      function sendReport(exception, cause) {
        var $http = $injector.get('$http');
        $http
          .post('/public-store/report-error', {
            page: window.location.href,
            cause: cause,
            error: exception,
            type: exception.constructor.name,
            stack: exception.stack
          })
          .catch(function(ex) {
            $log.error(ex);
          });
      }

      return false;
    };
  }
]);

/*
 * Error handler which does
 * 1) alert error message
 * 2) redirect if there is 401 or 403
 */
factory('DefaultErrorHandler', [
  'Redirect',
  'ServiceErrorHandler',
  'StateManager',
  function(Redirect, ServiceErrorHandler, StateManager) {
    var log = console.log.bind(console, '[DefaultErrorHandler]');

    function defaultErrorHandler(res) {
      var data = res.data,
        status = res.status,
        headers = res.headers && res.headers(),
        config = res.config;

      // once the backend error are standardized,
      // we should get error in json
      // expect $http call use success/error
      if (status === 401) {
        log(data, status, headers, config);
        Redirect.loginAfter401();
      } else if (status === 403 || status === 404) {
        log(data, status, headers, config);
        Redirect.status(status);
      } else {
        StateManager.unlockUI();
        return ServiceErrorHandler({ alert: 'data' })(res);
      }
    }

    return defaultErrorHandler;
  }
]);

/**
 *  Usage:
 *
 * .catch(ServiceErrorHandler({alert: 'key'}))
 *  --> flash key
 *
 * .catch(ServiceErrorHandler({alert: 'data'}))
 *   --> display data as string, or data.message as string
 *
 * .catch(ServiceErrorHandler('login'))
 *   --> fetch flashMessageData['login']['error'] and display
 *
 * * All cases re-reject the http rejection to
 *   upper level controller to do (optional) more handling
 */
factory('ServiceErrorHandler', [
  'Alert',
  '$q',
  function(Alert, $q) {
    var log = console.log.bind(console, '[ServiceErrorHandler]');

    function makeServiceErrorHandler(options) {
      var opts = options || {};

      return function serviceErrorHandler(httpRejection) {
        log(httpRejection, '[ options=', options, ']');

        // expected the $http call use then/catch
        var data = httpRejection.data;

        if (opts.alert === 'data') {
          if (Array.isArray(data)) {
            // a (likely) Error obj with .messages<array> json'ed back,
            // but it's NOT instanceof Error
            Alert.error(data);
          } else if (data && typeof data === 'object' && typeof data.message === 'string') {
            // a (likely) Error obj with .message json'ed back,
            // but it's NOT instanceof Error
            Alert.error(data);
          } else if (typeof data === 'string') {
            // a string sent/json'ed back
            Alert.error(null, data);
          }
        } else {
          // defined flash key
          Alert.error(opts.alert);
        }

        return $q.reject(httpRejection);
      };
    }

    return makeServiceErrorHandler;
  }
]);

directive('titleTooltip', [
  function() {
    return {
      restrict: 'A',
      link: function(scope, element) {
        var $ = window.jQuery;
        var $element = $(element);
        // requires bootstrap.js
        $element.tooltip({
          selector: '[title]',
          container: 'body',
          placement: function(ele, node) {
            var $node = $(node);

            if ($node.is('input') || $node.is('select')) {
              return 'top';
            } else if ($node.is('label') || $node.is('span')) {
              return 'right';
            }

            return 'auto';
          }
        });
      }
    };
  }
]);

factory('keymirror', function() {
  return function keyMirror(obj) {
    var ret = {};
    var key;
    if (!(obj instanceof Object && !Array.isArray(obj))) {
      throw new Error('keyMirror(...): Argument must be an object.');
    }
    for (key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        ret[key] = key;
      }
    }
    return ret;
  };
});

directive('resetAlertOnSubmit', [
  'AlertMessenger',
  function(AlertMessenger) {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.bind('submit', function() {
          AlertMessenger.reset();
        });
      }
    };
  }
]);

directive('resetAlertOnClick', [
  'AlertMessenger',
  function(AlertMessenger) {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.bind('click', function() {
          AlertMessenger.reset();
        });
      }
    };
  }
]);

directive('collapseNavbarOnClick', [
  function() {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.bind('click', function(event) {
          var navbar = angular.element(event.target).closest('.navbar-collapse');
          navbar.hasClass('show') && navbar.collapse('hide');
        });
      }
    };
  }
]);

import flashMessageData from '../../../common/const/flash-message-data';
factory('Alert', [
  '$filter',
  'AlertMessenger',
  function($filter, AlertMessenger) {
    var vsprintf = $filter('vsprintf');

    function getErrorText(input, type) {
      if (input instanceof Error) {
        return input.message;
      } else if (typeof input === 'string') {
        return flashMessageData[input][type];
      }
    }

    return {
      reset: AlertMessenger.reset.bind(AlertMessenger),
      error: function errorAlert(errORkeyORArr, rawString, params) {
        params = params || {};

        if (Array.isArray(errORkeyORArr)) AlertMessenger.reset().alert(errORkeyORArr);
        else if (!errORkeyORArr) AlertMessenger.reset().alert(vsprintf(rawString, params));
        else AlertMessenger.reset().alert(vsprintf(getErrorText(errORkeyORArr, 'error'), params));
      },
      success: function successAlert(key, rawString, params) {
        params = params || {};
        if (!key) AlertMessenger.reset().success(vsprintf(rawString, params));
        else AlertMessenger.reset().success(vsprintf(flashMessageData[key].success, params));
      }
    };
  }
]);

service('AlertMessenger', [
  'ProgressMessenger',
  '$rootScope',
  function(ProgressMessenger, $rootScope) {
    function send(type, message, options) {
      ProgressMessenger.hide();

      options = options || {};

      $rootScope.$emit('alert-flash', {
        alertType: type == 'error' ? 'danger' : type,
        alertMessage: message,
        alertAppend: !!options.append
      });

      return this;
    }

    function alert(message, options) {
      Array.isArray(message) &&
      message.some(function(x) {
        return typeof x == 'object';
      })
        ? message.reverse().forEach(function(item) {
            item &&
              item.message &&
              send.call(this, item.severity || 'danger', item.message, Object.assign({ append: true }, options || {}));
          })
        : this.error(message, options);
    }

    (this.alert = alert.bind(this)),
      (this.error = send.bind(this, 'danger')),
      (this.warn = send.bind(this, 'warning')),
      (this.info = send.bind(this, 'info')),
      (this.success = send.bind(this, 'success')),
      (this.reset = send.bind(this, null));
  }
]);

factory('ProgressMessenger', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    function send(show, percent) {
      $rootScope.$emit('progress-bar', {
        show: show,
        percent: percent
      });
    }

    function hide() {
      return send(false, 0);
    }

    function reset() {
      send(true, 0);
      return this;
    }

    // done(timeout, callback) or done(callback)
    function done() {
      send(true, 100);

      var timeout = 1000;
      var callback;

      if (arguments.length === 1 && typeof arguments[0] === 'number') {
        // done(timeout)
        timeout = arguments[0];
      } else if (arguments.length === 1 && typeof arguments[0] === 'function') {
        // done(callback)
        callback = arguments[0];
      } else if (arguments.length === 2) {
        // done(timeout, callback)
        timeout = arguments[0];
        callback = arguments[1];
      }

      return $timeout(function() {
        hide();

        if (typeof callback === 'function') {
          return callback();
        }
      }, timeout);
    }

    /**
     * Send a message to update the progress bar with a pertentage
     * @param {object}  arguments (1) If one argument passed in, it is 0-100 percentage (2) If two arguments passed in as (x, n), it is the x'th out of n'th step
     * @return {undefned} calls send() which broadcasts an event on $rootScope
     */
    function progress() {
      var percent;

      if (arguments.length === 1) {
        percent = arguments[0];
      } else if (arguments.length === 2) {
        percent = (100 * arguments[0]) / arguments[1];
      }

      return send(true, percent);
    }

    return {
      reset: reset,
      hide: hide,
      done: done,
      progress: progress
    };
  }
]);

factory('EventManager', [
  function() {
    return {
      EVENTS: {
        UI_LOCK: 'UI_LOCK',
        EXPAND_PANEL: 'EXPAND_PANEL'
      }
    };
  }
]);

factory('StateManager', [
  'ProgressMessenger',
  'AlertMessenger',
  'EventManager',
  '$timeout',
  '$rootScope',
  function(ProgressMessenger, AlertMessenger, EventManager, $timeout, $rootScope) {
    var locked = false;

    return {
      uiIsLocked: function() {
        return locked;
      },
      lockUI: function() {
        $rootScope.$emit(EventManager.EVENTS.UI_LOCK, true);
        locked = true;
      },
      unlockUI: function() {
        $rootScope.$emit(EventManager.EVENTS.UI_LOCK, false);
        locked = false;
      },
      flashAndRedirect: function(data, afterFlash) {
        data = data || {};
        ProgressMessenger.done(function() {
          if (typeof data.content === 'string') {
            AlertMessenger.success(data.content);

            if (typeof afterFlash === 'function') {
              afterFlash(data);
            }
          }

          if (data.redirectPath) {
            $timeout(function() {
              window.location.href = data.redirectPath;
            }, 200);
          }
        });
      }
    };
  }
]);

/**
 * This factory can help organize controller/view logic for views that have SPA
 * behavior and multiple states.  Handlers can be created to perform async
 * tasks, after which the state is conditionally set.  Example:
 *
 * In controller:
 *
 * $scope.stateHelper = StateHelperAsync(['state1', 'state2', ...], 'stateN');
 * var STATES = $scope.stateHelper.STATES;
 *
 * $scope.stateHelper.before(STATES.STATE2, function(x) {
 *     var def = $q.deferred();
 *
 *     if(x) { def.resolve('Going to state2'); }
 *     else { def.reject('Abort going to state2'); }
 *
 *     return def.promise;
 * });
 *
 * $scope.stateHelper.set(STATES.STATE2, true)
 *                     .then(function(valueArr) {
 *                         console.log(valueArr[0]); // 'Going to state2'
 *                         $scope.stateHelper.currentState() === STATES.STATE2; // === true
 *                         $scope.stateHelper.is(STATES.STATE2); // === true
 *                         $scope.stateHelper.any(STATES.STATE1, STATES.STATE2); // === true
 *                     });
 * $scope.stateHelper.set(STATES.STATE2, false); // does not go to state2
 *
 * In view:
 *
 * <div ng-show="stateHelper.is(stateHelper.STATES.STATE2)"></div>
 * <button ng-click="stateHelper.set(stateHelper.STATES.STATE2, true)"></button>
 *
 */
factory('StateHelperAsync', [
  '$q',
  function($q) {
    return function(states, initialState) {
      var obj = {};
      var STATES = {};
      var callbacks = {};
      var currentState = initialState;

      states.forEach(function(s) {
        callbacks[s] = [];
        STATES[s.toUpperCase()] = s;
      });

      obj.currentState = function() {
        return currentState;
      };

      obj.is = function(state) {
        return currentState === state;
      };

      obj.any = function() {
        var checkStates = Array.prototype.slice.call(arguments, 0);
        return checkStates.indexOf(currentState) !== -1;
      };

      /**
       * Executes state's callbacks before setting new state.  All callbacks
       * must resolve for state to be set.  Returning a rejected promise from
       * any callback causes the state _not_to_be_set_.  This functionality
       * can be used in general to conditionally set a state based on the
       * outcome of an asynchronous task.
       */
      obj.set = function(state) {
        var args = Array.prototype.slice.call(arguments, 1);
        var promises = callbacks[state].map(function(cb) {
          return cb.apply(null, args);
        });

        return $q.all(promises || []).then(function(values) {
          currentState = state;
          return values;
        }); // catch --> do nothing
      };

      obj.before = function(state, cb, thisArg) {
        callbacks[state].push(cb.bind(thisArg || null));
      };

      Object.seal(STATES);
      obj.STATE = STATES;
      Object.seal(obj);

      return obj;
    };
  }
]);

directive('collapsePanel', [
  'EventManager',
  function(EventManager) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        panelTitle: '@',
        panelSubtitle: '@',
        panelId: '@',
        startExpand: '@'
      },
      link: function(scope) {
        scope.expand = scope.startExpand !== 'false';
        scope.everExpand = scope.startExpand !== 'no';
        scope.toggle = function() {
          scope.expand = !scope.expand;
        };

        // expects object with array of 'panels' with panel ids and bool 'expand'
        scope.$on(EventManager.EVENTS.EXPAND_PANEL, function(event, data) {
          if (data.panels.indexOf(scope.panelId) > -1) {
            scope.expand = data.expand;
          }
        });
      },
      template:
        '' +
        '<div class="card">' +
        '<div class="card-header collapse-header flex-wrap">' +
        '<span><label class="font-weight-bold mb-0">{{ panelTitle }}</label></span>' +
        '<button type="button" aria-disabled="false" ng-if="everExpand" ng-click="toggle()" class="btn btn-sm btn-link">' +
        "{{expand ? 'Collapse' : 'Expand'}}" +
        "<i class=\"ml-1 fa fa-caret-{{expand ? 'down' : 'left'}}\"></i>" +
        '</button>' +
        '<small class="w-100" ng-if="panelSubtitle">{{ panelSubtitle }}</small>' +
        '</div>' +
        '<div class="card-body" ng-show="expand" ng-transclude></div>' +
        '</div>'
    };
  }
]);

directive('uiLock', [
  'EventManager',
  '$rootScope',
  function(EventManager, $rootScope) {
    return {
      restrict: 'A',
      scope: {
        styleOnly: '='
      },
      link: function(scope, element) {
        $rootScope.$on(EventManager.EVENTS.UI_LOCK, function($event, disabled) {
          element.prop('readOnly', disabled);
          element.find('button').prop('disabled', disabled);
          element.find('input').prop('readOnly', disabled);
          element.find('textarea').prop('readOnly', disabled);
          element.find('select').toggleClass('readonly', !!disabled);
        });
      }
    };
  }
]);

component('alertFlashWithLink', {
  bindings: { message: '<' },
  template:
    '<div ng-if="$ctrl.showLink()">' +
    '<p>{{$ctrl.message}}<a href="docs/faq#gmei-dtcc-transfer" target="_blank" rel="noopener noreferrer">link</a> for more details. ' +
    '<a href="docs/faq#gmei-dtcc-transfer" target="_blank" rel="noopener noreferrer">Click here</a></p>' +
    '</div>' +
    '<div ng-if="!$ctrl.showLink()">' +
    '<p ng-bind="$ctrl.message"></p>' +
    '</div>',
  controller: function() {
    var $ctrl = this;
    $ctrl.showLink = () => {
      return $ctrl.message.startsWith('For any users whose LEIs are being transferred');
    };
  }
});

component('alertFlashItem', {
  bindings: { props: '<' },
  template:
    '' +
    '<div class="alert alert-dismissable" ng-class="\'alert-{{$ctrl.props.severity}}\'" role="alert">' +
    '<div class="container">' +
    '<i class="fa fa-{{$ctrl.icon}} fa-lg"></i>' +
    '<span style="width: 100%">' +
    '<alert-flash-with-link style="float: left" message="m" ng-repeat="m in $ctrl.props.messages track by $index"></alert-flash-with-link>' +
    '</span>' +
    '<button class="close" aria-label="Close" ng-click="$ctrl.props.close()">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' +
    '</div>' +
    '</div>',
  controller: function() {
    var $ctrl = this;

    $ctrl.$onInit = function() {
      $ctrl.icon = (function() {
        switch ($ctrl.props.severity) {
          case 'danger':
            return 'exclamation-triangle';
          case 'success':
            return 'check-circle';
          case 'info':
            return 'info-circle';
          default:
            return 'exclamation-circle';
        }
      })();
    };
  }
});

import views_confirm from '@lei-website-client/views/confirm.html';
component('confirmDialog', {
  bindings: { action: '<', title: '=', message: '=' },
  template: views_confirm,
  controller: [
    '$rootScope',
    function($rootScope) {
      const $dialog = angular.element('#confirmDialog');

      this.$onDestroy = $rootScope.$on('showConfirmDialog', function(event) {
        $dialog.modal('show');
      });

      this.confirm = () => {
        this.action();
        $dialog.modal('hide');
      };

      this.cancel = () => {
        $dialog.modal('hide');
      };
    }
  ]
});

component('alertFlash', {
  template:
    '' +
    '<div class="alert-flash" ng-show="$ctrl.alerts.length">' +
    '<alert-flash-item props="alert" ng-repeat="alert in $ctrl.alerts"></alert-flash-item>' +
    '</div>',
  controller: [
    '$rootScope',
    function($rootScope) {
      var $ctrl = this;
      $ctrl.alerts = [];

      function remove(obj) {
        $ctrl.alerts.some(function(e, i) {
          return e == obj ? $ctrl.alerts.splice(i, 1) : false;
        });
      }

      this.$onDestroy = $rootScope.$on('alert-flash', function($event, data) {
        if (!data || !(data.alertType || data.alertMessage)) return $ctrl.alerts.splice(0);

        // Reset alerts unless instructed
        if (!data.alertAppend) $ctrl.alerts.splice(0);

        var newAlert;

        if (typeof data === 'string') newAlert = { severity: 'danger', messages: [data] };
        else if (typeof data === 'object')
          newAlert = {
            severity: data.alertType,
            messages: Array.isArray(data.alertMessage) ? data.alertMessage : [data.alertMessage]
          };
        else return;

        newAlert.close = remove.bind($ctrl, newAlert);

        $ctrl.alerts.unshift(newAlert);
      });
    }
  ]
});

directive('progressBar', [
  '$rootScope',
  '$timeout',
  function($rootScope, $timeout) {
    return {
      restrict: 'E',
      replace: false,
      scope: {
        show: '@',
        percent: '@'
      },
      template:
        '' +
        '<div class="progress" ng-if="show">' +
        '<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" ' +
        'aria-valuenow="{{percentUI}}" aria-valuemin="0" aria-valuemax="100" style="width: 100%">' +
        '</div>' +
        '</div>',
      link: function(scope) {
        $rootScope.$on('progress-bar', function($event, data) {
          if (!data) {
            scope.show = true;
            scope.percent = 0;
          } else if (typeof data === 'object') {
            scope.show = data.show;
            scope.percent = data.percent;

            if (scope.percent === 0) {
              scope.percentUI = 0;
            } else {
              $timeout(function() {
                scope.percentUI = scope.percent;
              }, 300);
            }
          }
        });
      }
    };
  }
]);

directive('labelValue', [
  function() {
    return {
      restrict: 'E',
      scope: {
        label: '@',
        value: '@',
        multiline: '='
      },
      template:
        '' +
        '<div class="form-group">' +
        '<label ng-bind="label"></label>' +
        '<div class="form-control readonly" ng-class="{\'multiline\': multiline}">{{value}}</div>' +
        '</div>'
    };
  }
]);

directive('delimiterRender', [
  function() {
    function renderContent(text, delimiter, leftWrapper, rightWrapper) {
      delimiter = delimiter || '\\n';
      leftWrapper = leftWrapper || '<p>';
      rightWrapper = rightWrapper || '</p>';

      if (typeof text !== 'string') {
        return text;
      }

      return text
        .split(delimiter)
        .map(function(pText) {
          return leftWrapper + pText + rightWrapper;
        })
        .join('');
    }

    return {
      restrict: 'A',
      scope: {
        text: '@',
        delimiter: '@',
        wrapper: '@',
        leftWrapper: '@',
        rightWrapper: '@'
      },
      link: function(scope, element) {
        var dom = renderContent(scope.text, scope.delimiter, scope.leftWrapper, scope.rightWrapper);
        element.html(dom);
      }
    };
  }
]);

// ISO time to yyyy-MM-dd HH:mm
filter('isoLocalDatetime', [
  '$filter',
  function($filter) {
    return function(input) {
      if (typeof input === 'string' && input.slice(-1) !== 'Z') {
        input = input + 'Z';
      }

      return $filter('date')(input, 'yyyy-MM-dd HH:mm');
    };
  }
]);

// from userProfileHelper
filter('humanize', [
  function() {
    return function(input) {
      return _.startCase(input);
    };
  }
]);

import { PAYMENT_TYPES } from '../../../common/const/lei-products';
filter('paymentTypeDisplay', function() {
  return function(input) {
    return PAYMENT_TYPES[input] || input;
  };
});

import states from '../../../common/const/states';
filter('addressDisplay', function() {
  return function(input) {
    if (!input) return;
    const fullStates = (states[input.country] || []).filter(function(state) {
        return state.abbr === input.state;
      }),
      fullStateName = fullStates && fullStates[0] && fullStates[0].name;
    return [input.line1, input.line2, input.city, fullStateName || input.state, input.country, input.postal_code]
      .filter(Boolean)
      .join(', ');
  };
});

filter('actionTypeDisplay', function() {
  var actionTypeDisplays = {
    New: 'Create'
  };
  return function(input) {
    return actionTypeDisplays[input] || input;
  };
});
