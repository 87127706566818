import { directive } from '../app';
import {
  labelCustomizer as labelGet,
  postprocess,
  getSubsectionsToShowAndHide,
  customizeNoCreationDateIsAvailableFieldDisplay,
  addFaqLinks
} from '../utilities';
import { init as makeForm } from '@dtwebservices/react-bbds-form';
import angular from 'angular';
import { WORKFLOW_TAB_STEPS } from '../../../utils/constants';
import { DS_NAME } from '../utilities/shared';

export default /* @ngInject */ function leiForm($rootScope, $timeout, $document, FormService) {
  function link($scope, $element) {
    $scope.form = null;
    let currentTab = WORKFLOW_TAB_STEPS[0];
    $rootScope.$on('onChangeTab', (event, selectedTab) => updateSections(selectedTab));

    function updateSections(selectedTab) {
      currentTab = selectedTab;
      /*
        Logic to update form here
       */
      console.log('Triggered onChangeTab' + JSON.stringify(selectedTab));

      let payload = null;
      if ($scope.form && $scope.form.current) {
        payload = $scope.form.current.export(false);
        payload = payload[DS_NAME].data;
      }

      const [sectionToShow, sectionsToHide] = getSubsectionsToShowAndHide(selectedTab, $document[0], payload);

      angular.element(sectionToShow).css('display', '');
      angular.element(sectionsToHide).css('display', 'none');

      customizeNoCreationDateIsAvailableFieldDisplay(selectedTab);

      addFaqLinks($document[0]);
    }

    function close() {
      if (!$scope.form || !$scope.form.current) return;
      $scope.form.current.unmount();
      $scope.form = null;
    }

    function init(store, callbacks) {
      close();
      const formServiceFn = FormService.onDerive.bind(FormService);
      const props = {
        store,
        styles: {
          // Using 24 columns in Bootstrap, default is 12
          columns: {
            map: {
              key: 'col-12',
              value: 'col-12'
            },
            split: 'col-24',
            label: 'col-24',
            value: 'col-24'
          },
          icons: {
            menu: 'fa fa-ellipsis-v',
            form: 'fa fa-arrow-right',
            edit: 'fa fa-pencil-square-o',
            clear: 'fa fa-eraser',
            up: 'fa fa-caret-left',
            tip: 'fa fa-info-circle',
            down: 'fa fa-caret-down'
          }
        },
        labelGet,
        panels: [{ key: 'data', expand: true }],
        destructiveClear: true,
        autoExpandListIfEmpty: true,
        onDerive: (fields, model, callback) => {
          const deriveResult = formServiceFn(fields, model, callback);
          $rootScope.$emit('onChangeTab', currentTab);
          return deriveResult;
        },
        ...callbacks
      };

      $scope.form = makeForm(props, $element[0]);
      updateSections(WORKFLOW_TAB_STEPS[0]);
      addFaqLinks($document[0]);

      return $scope.form;
    }

    function expand(...args) {
      if (!$scope.form || !$scope.form.current) return null;
      return $scope.form.current.expand(...args);
    }

    function lock(...args) {
      if (!$scope.form || !$scope.form.current) return;
      return $scope.form.current.lock(...args);
    }

    function read(...args) {
      if (!$scope.form || !$scope.form.current) return null;
      return postprocess($scope.form.current.export(...args));
    }

    function readUnprocessedForm(...args) {
      if (!$scope.form || !$scope.form.current) return null;
      return $scope.form.current.export(...args);
    }

    $timeout(() =>
      $scope.onLoad({
        init,
        lock,
        read,
        expand,
        readUnprocessedForm
      })
    );
    $scope.$on('$destroy', close);
  }

  return {
    restrict: 'E',
    scope: { onLoad: '<onload' },
    link,
    template: '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>'
  };
}

directive('leiForm', leiForm);
