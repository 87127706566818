import angular from 'angular';
import { controller } from './app';
import { CHALLENGE_CATEGORIES } from '../utils/constants';
import textHash from '../../../common/lib/text-hash';
import saveDraftInterval from '../../../common/const/save-draft-interval';
import leiProducts from '../../../common/const/lei-products';
import pageLabels from '../../../common/const/page-labels';

// Controller for challenge LEI in or out
controller('ChallengeLeiController', [
  '$scope',
  '$sce',
  '$location',
  '$routeParams',
  '$interval',
  'LeiService',
  'ProgressMessenger',
  'AlertMessenger',
  'Alert',
  'loginEmail',
  'DraftCacheKey',
  'DefaultErrorHandler',
  'DraftService',
  function(
    $scope,
    $sce,
    $location,
    $routeParams,
    $interval,
    LeiService,
    ProgressMessenger,
    AlertMessenger,
    Alert,
    loginEmail,
    DraftCacheKey,
    DefaultErrorHandler,
    DraftService
  ) {
    var vm = this;
    vm.props = {};
    vm.actions = { challenge: challenge, searchLei: searchLei, save: saveSubmissionManual };

    // variables
    vm.props.labels = pageLabels.challengeLEI;
    vm.props.labels.instructionHtml = $sce.trustAsHtml(vm.props.labels.instruction);

    vm.props.dupChallenge = CHALLENGE_CATEGORIES.dupChallenge;
    vm.props.categories = [
      {
        key: vm.props.dupChallenge,
        value: 'Duplicate'
      },
      {
        key: CHALLENGE_CATEGORIES.otherChallenge,
        value: 'Missing or Incorrect Data'
      },
      {
        key: CHALLENGE_CATEGORIES.missingChallenge,
        value: 'Other'
      }
    ];

    vm.props.viewOnly = false;

    var path = $location.path().split('/');
    var leiId = path.length > 2 ? path[3] : null;

    // for save draft
    var draftInterval;
    $scope.loadDraft = loadDraft;
    var hashEmail = textHash(loginEmail);
    var draftKey = DraftCacheKey(hashEmail, leiProducts.ACTION.CHALLENGE, leiId);
    var draftData;

    load($routeParams.loadDraft);

    function loadDraft() {
      load(true);
    }

    /*
     * load initial data for the view if needed
     */
    function load(loadDraft) {
      // reset alert message
      AlertMessenger.reset();
      if (loadDraft) {
        vm.props.showDraftMsg = false;
        return DraftService.decryptAndGetDraft(draftKey)
          .then(function(draft) {
            vm.challengeInfo = angular.copy(draft.data);
            draftData = angular.copy(vm.challengeInfo);
          })
          .catch(function(error) {
            Alert.error(error == 'draftExpire' ? error : 'loadDraft');
          });
      } else {
        vm.props.showDraftMsg = DraftService.hasDraft(draftKey);
        vm.challengeInfo = {};
        vm.challengeInfo.lei = leiId;
        draftData = angular.copy(vm.challengeInfo);
      }

      if (!draftInterval) {
        try {
          draftInterval = $interval(function saveDraftSubmission() {
            if (!angular.equals(vm.challengeInfo, draftData)) {
              _saveSubmissionAuto();
            }
          }, saveDraftInterval);
        } catch (ex) {
          console.error(ex);
        }
      }

      $scope.$on('$destroy', function() {
        $interval.cancel(draftInterval);
      });
    }

    function _saveSubmissionAuto() {
      draftData = angular.copy(vm.challengeInfo);
      DraftService.encryptAndSaveDraft(draftData, draftKey, true).catch(function(ex) {
        console.error(ex);
      });
    }

    function saveSubmissionManual() {
      $interval.cancel(draftInterval);
      draftData = angular.copy(vm.challengeInfo);
      ProgressMessenger.reset().progress(20);
      DraftService.encryptAndSaveDraft(draftData, draftKey, false)
        .then(function() {
          ProgressMessenger.done(function() {
            Alert.success('saveDraft');
          });
        })
        .catch(function() {
          Alert.error('saveDraft');
        });
    }

    /**
     * transfer lei
     * @return {Promise}        Promise by of the transfer
     */
    function challenge(isValid) {
      AlertMessenger.reset();
      ProgressMessenger.reset().progress();

      if (isValid) {
        LeiService.challenge(vm.challengeInfo, vm.challengeInfo.lei)
          .then(function(res) {
            var submissionId = res.data;
            Alert.success('challengeLei', null, {
              submissionId: submissionId
            });
            vm.props.submissionId = submissionId;
            DraftService.removeDraft(draftKey);
            $interval.cancel(draftInterval);
          })
          .catch(DefaultErrorHandler);
      } else {
        AlertMessenger.alert('Please fill in required fields.');
      }
    }

    function searchLei() {
      AlertMessenger.reset();

      vm.props.dupLei = null;

      if (!vm.challengeInfo.duplicateLEI) {
        return;
      }

      vm.props.invalidDupLei = null;

      LeiService.getPublicLeiDataFromAllSource(vm.challengeInfo.duplicateLEI).then(
        function(res) {
          vm.props.dupLei = res.data;
        },
        function() {
          vm.props.invalidDupLei = true;
        }
      );
    }
  }
]);
