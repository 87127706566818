import { component } from '../app';
import { getFormPageLabels, makeOnStateChangeSelector, ifPayment, ifAuthorization } from '../utilities';
import { STEPS } from '../../../utils/constants';
import template from '@lei-website-client/views/form/header.html';

export default class FormHeaderController {
  /* @ngInject */
  constructor($rootScope, FormService) {
    this.$rootScope = $rootScope;
    this.Service = FormService;
    this.steps = STEPS;

    this._setPropsFromState = makeOnStateChangeSelector.call(
      this,
      ['action', 'renewOptIn', 'isLapsed'],
      this._setPropsFromState
    );
  }

  _setPropsFromState() {
    if (this.state.isTransfer)
      this.state.action = this.state.renewOptIn || this.state.isLapsed ? 'transferRenew' : 'transfer';

    this.labels = getFormPageLabels(this.state);
    this.title = this.labels.title;
    this.instructions = this.labels.instruction;
    this.back = this.isCart
      ? {
          url: '/users/cart',
          icon: 'fa fa-shopping-cart'
        }
      : {
          url: `/leis/entry/${this.state.action}`,
          icon: 'fa fa-home'
        };

    // Edit some state variables
    this.state.needPayment = ifPayment(this.state);
    this.state.needAuthorization = ifAuthorization(this.state);
    this.state.available = this.Service.getDraft(this.state);

    if (this.state.action === 'renew')
      this.Service.hasAutoRenew(this.state.id).then(res => (this.state.hasAutoRenew = res));
    else this.state.hasAutoRenew = false;

    this.$rootScope.$emit('onChangeTitle', this);
  }

  $doCheck() {
    this._setPropsFromState();
  }

  $onInit() {
    this._setPropsFromState();
  }
}

component('leiFormHeader', {
  controller: FormHeaderController,
  template,
  bindings: {
    onLoad: '<onload',
    state: '<'
  }
});
