import angular from 'angular';
import { factory } from './app';
import { NO_CACHE } from '../utils/constants';

factory('CardService', [
  '$http',
  '$q',
  '$rootScope',
  function($http, $q, $rootScope) {
    var CART_EVENT = 'GET_CART_WAS_CALLED';
    const EMPTY_CART = 'EMPTY_CART';

    /**
     * get stripe customer card data
     */
    function getCards() {
      var options = {
        method: 'GET',
        url: '/card?',
        headers: NO_CACHE
      };

      return $http(options);
    }

    /**
     * authorize the charge
     */
    function pay(data, id) {
      return $http.post('/card/charge/' + id, data);
    }

    function checkout(type) {
      return $http.post('/lei/checkout/' + type);
    }

    function verify(id, isShoppingCart) {
      var options = {
        method: 'GET',
        url: '/card/verify/' + id + '/' + isShoppingCart,
        headers: NO_CACHE
      };
      return $http(options);
    }

    function getCart() {
      var options = {
        method: 'GET',
        url: '/card/cart',
        headers: NO_CACHE
      };

      return $http(options).then(function(res) {
        $rootScope.$emit(CART_EVENT, res);
        return res;
      });
    }

    function clearCart() {
      $rootScope.$emit(EMPTY_CART);
    }

    function deleteCart(id) {
      return $http.delete('/card/cart/' + (id || '*'));
    }

    function validateVAT(vat, country) {
      var options = {
        method: 'GET',
        url: '/card/vat/validate/',
        params: { vat, country }
      };

      return $http(options);
    }

    function addCard(data) {
      return $http.post('/card', data);
    }

    function updateCard(card, data) {
      return $http.put('/card/' + card, data);
    }

    function deleteCard(card) {
      return $http.delete('/card/' + card);
    }

    function _checkLoaded(url, deferred, tries) {
      tries++;
      if (window.Stripe) {
        deferred.resolve();
      } else if (tries > 12) {
        deferred.resolve();
      } else {
        // exponentially retry
        setTimeout(_checkLoaded.bind(this, url, deferred, tries), Math.pow(2, tries) + 10);
      }
    }

    function injectStripeJS() {
      var tries = 0,
        deferred = $q.defer(),
        url = 'https://js.stripe.com/v3/';

      if (window.Stripe) {
        deferred.resolve();
        return deferred.promise;
      }

      var stripeScript = document.createElement('script');
      stripeScript.setAttribute('src', url);
      angular.element(document.body).append(angular.element(stripeScript));

      _checkLoaded(url, deferred, tries);
      return deferred.promise;
    }

    return {
      getCards: getCards,
      pay: pay,
      verify: verify,
      validateVAT: validateVAT,
      addCard: addCard,
      deleteCard: deleteCard,
      updateCard: updateCard,
      injectStripeJS: injectStripeJS,
      getCart: getCart,
      checkout: checkout,
      deleteCart: deleteCart,
      clearCart: clearCart,
      CART_EVENT: CART_EVENT,
      EMPTY_CART: EMPTY_CART
    };
  }
]);
