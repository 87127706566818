'use strict';

/*
 * State Code and TIN number co-relation for validation
 */
module.exports = {
  IN: {
    AN: '35',
    // 28 = AP: Andhra Pradesh
    // 37 = AD: Andhra Pradesh (New)
    AP: ['28', '37'],
    AR: '12',
    AS: '18',
    BR: '10', // = BH (Invoice)
    CH: '04',
    CT: '22',
    DN: '26',
    DD: '25',
    DL: '07',
    GA: '30',
    GJ: '24',
    HR: '06',
    HP: '02',
    JK: '01',
    JH: '20',
    KA: '29',
    KL: '32',
    LD: '31',
    MP: '23',
    MH: '27',
    MN: '14',
    ML: '17', // = ME (Invoice)
    MZ: '15', // = MI (Invoice)
    NL: '13',
    OR: '21',
    PY: '34',
    PB: '03',
    RJ: '08',
    SK: '11',
    TN: '33',
    TG: '36', // = TS (Invoice)
    TR: '16',
    UP: '09',
    UT: '05',
    WB: '19'
  }
};
