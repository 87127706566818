'use strict';

// Brazil
module.exports = [
  { abbr: 'AC', name: 'ACRE' },
  { abbr: 'AL', name: 'ALAGOAS' },
  { abbr: 'AP', name: 'AMAPA' },
  { abbr: 'AM', name: 'AMAZONAS' },
  { abbr: 'BA', name: 'BAHIA' },
  { abbr: 'CE', name: 'CEARA' },
  { abbr: 'DF', name: 'DISTRITO FEDERAL DO BRASIL' },
  { abbr: 'ES', name: 'ESPIRITO SANTO' },
  { abbr: 'GO', name: 'GOIAS' },
  { abbr: 'MA', name: 'MARANHAO' },
  { abbr: 'MT', name: 'MATO GROSSO' },
  { abbr: 'MS', name: 'MATO GROSSO DO SUL' },
  { abbr: 'MG', name: 'MINAS GERAIS' },
  { abbr: 'PA', name: 'PARA' },
  { abbr: 'PB', name: 'PARAIBA' },
  { abbr: 'PR', name: 'PARANA' },
  { abbr: 'PE', name: 'PERNAMBUCO' },
  { abbr: 'PI', name: 'PIAUI' },
  { abbr: 'RJ', name: 'RIO DE JANEIRO' },
  { abbr: 'RN', name: 'RIO GRANDE DO NORTE' },
  { abbr: 'RS', name: 'RIO GRANDE DO SUL' },
  { abbr: 'RO', name: 'RONDONIA' },
  { abbr: 'RR', name: 'RORAIMA' },
  { abbr: 'SC', name: 'SANTA CATARINA' },
  { abbr: 'SP', name: 'SAO PAULO' },
  { abbr: 'SE', name: 'SERGIPE' },
  { abbr: 'TO', name: 'TOCANTINS' }
];
