import { get } from 'lodash';
import angular from 'angular';
import { checkStore } from '../../../../../common/validate';
import getCheckErrors from '../../../../../common/lib/get-check-messages';
import { ACTION } from '../../../../../common/const/lei-products';
import PAGE_LABELS from '../../../../../common/const/page-labels';
import {
  FORM_SECTIONS,
  ENTITY_SUBSECTIONS,
  SECTION_HEADERS,
  FUND_PARENTS_SELECTORS,
  ENTITY_FORM_LABELS,
  ENTITY_FORM_FIELDS_FAQ_MAP
} from '../../../../../common/const/form-sections';
import { DS_NAME } from './shared';

export const getFormPageLabels = state => {
  const labels = PAGE_LABELS[`${state.isTransfer && !state.isLapsed ? 'transfer' : state.action}LEI`];
  // TODO: Remove 2 step page after migration
  return state.isTransfer ? labels.step2 : labels;
};

export const getAction = state => {
  const action = (state.action || '').toUpperCase();
  return action === 'TRANSFERRENEW' ? ACTION.TRANSFERRENEW : action;
};

export const makeIfActionFn = (...actions) => {
  actions = new Set(actions);
  return state => actions.has(getAction(state));
};

export const ifPayment = makeIfActionFn(ACTION.NEW, ACTION.RENEW, ACTION.TRANSFERRENEW);
export const ifAuthorization = makeIfActionFn(ACTION.NEW, ACTION.TRANSFER, ACTION.TRANSFERRENEW);
export const ifNew = makeIfActionFn(ACTION.NEW);
export const ifUpdate = makeIfActionFn(ACTION.UPDATE);
export const ifTransfer = makeIfActionFn(ACTION.TRANSFER, ACTION.TRANSFERRENEW);

export function makeOnStateChangeSelector(cacheKeys, callback) {
  const cache = cacheKeys.reduce((acc, key) => Object.assign(acc, { [key]: null }), {});
  return () => {
    // Protect against infinite error loop;
    if (cache.$error) return;

    let render = false;
    try {
      for (const key of cacheKeys)
        if (cache[key] !== this.state[key]) {
          render = true;
          cache[key] = this.state[key];
        }
    } catch (e) {
      cache.$error = true;
      throw e;
    }

    if (render) callback.call(this);
  };
}

export const makeOnApply = $scope => fn => {
  var phase = $scope.$root && $scope.$root.$$phase;
  if (phase === '$apply' || phase === '$digest') {
    if (fn && typeof fn === 'function') fn();
  } else $scope.$apply(fn);
};

const checkCustomerAgreement = getCheckErrors.bind(null, checkStore.get('customerAgreement'));
const checkTransferWaiver = getCheckErrors.bind(null, checkStore.get('transferWaiver'));

export const checkCustomerConsent = (customerConsent, isTransfer) => {
  const errors = checkCustomerAgreement(customerConsent);
  if (isTransfer) errors.push(...checkTransferWaiver(customerConsent));
  return errors.map(e => ({ severity: 'error', message: e }));
};

export const getSubsectionsToShowAndHide = (selectedTab, document, payloadData) => {
  const customizeEntitySubsectionsToShow = section => {
    if (!['ENTITY', 'ADDRESSES', 'EVENTS'].includes(section)) return section;
    const selectorPath = 'span > label';
    const filterFn = sub => sub.querySelector(selectorPath) && sub.querySelector(selectorPath).innerText;
    const subsections = document.querySelectorAll(ENTITY_SUBSECTIONS);

    const toShow = Object.values(subsections).filter(sub => FORM_SECTIONS[section].FIELDS.includes(filterFn(sub)));
    const toHide = Object.values(subsections).filter(sub => !FORM_SECTIONS[section].FIELDS.includes(filterFn(sub)));

    return [toShow, toHide];
  };

  const customizeFundRelationshipsToShow = () => {
    const { RELATIONSHIP_PARENTS } = FUND_PARENTS_SELECTORS;
    const parents = document.querySelectorAll(RELATIONSHIP_PARENTS + '> div'); // parents = Nodes[managingFundParent, ..., directParent, ...]
    const fundParentsTitle = ['Fund Manager Details', 'Umbrella Fund Details', 'Master Fund Details'];
    const sections = Object.values(parents)
      .map(parent => {
        const labelElement = parent.querySelector('div > span > label');
        labelElement.classList.add('font-weight-bold');
        return parent;
      })
      .filter(parent => {
        const title = parent.querySelector('div > span > label').innerText;
        return fundParentsTitle.includes(title);
      });

    const empty = [];
    const entityCategory = get(payloadData, 'entity.entityCategory');

    if (entityCategory !== 'FUND') {
      return [empty, sections];
    } else {
      return [sections, empty];
    }
  };

  const selectSections = selectors => {
    return selectors.map(sel => document.querySelector(sel));
  };

  let sectionToShow, sectionsToHide;

  switch (selectedTab.id) {
    case 'ENTITY':
      [sectionToShow, sectionsToHide] = customizeEntitySubsectionsToShow('ENTITY');
      sectionToShow = [...sectionToShow, ...selectSections(FORM_SECTIONS.ENTITY.SHOW)];
      sectionsToHide = [...sectionsToHide, ...selectSections(FORM_SECTIONS.ENTITY.HIDE)];
      break;
    case 'ADDRESSES':
      [sectionToShow, sectionsToHide] = customizeEntitySubsectionsToShow('ADDRESSES');
      sectionToShow = [...sectionToShow, ...selectSections(FORM_SECTIONS.ADDRESSES.SHOW)];
      sectionsToHide = [...sectionsToHide, ...selectSections(FORM_SECTIONS.ADDRESSES.HIDE)];
      break;
    case 'RELATIONSHIPS': {
      const fundParentsToShowAndHide = customizeFundRelationshipsToShow();
      sectionToShow = [...fundParentsToShowAndHide[0], ...selectSections(FORM_SECTIONS.RELATIONSHIPS.SHOW)];
      sectionsToHide = [...fundParentsToShowAndHide[1], ...selectSections(FORM_SECTIONS.RELATIONSHIPS.HIDE)];
      break;
    }
    case 'EVENTS':
      [sectionToShow, sectionsToHide] = customizeEntitySubsectionsToShow('EVENTS');
      sectionToShow = [...sectionToShow, ...selectSections(FORM_SECTIONS.EVENTS.SHOW)];
      sectionsToHide = [...sectionsToHide, ...selectSections(FORM_SECTIONS.EVENTS.HIDE)];
      break;
    case 'CONTACT':
      sectionToShow = selectSections(FORM_SECTIONS.CONTACT.SHOW);
      sectionsToHide = selectSections(FORM_SECTIONS.CONTACT.HIDE);
      break;
    case 'FILES':
      sectionToShow = selectSections(FORM_SECTIONS.FILES.SHOW);
      sectionsToHide = selectSections(FORM_SECTIONS.FILES.HIDE);
      break;
  }

  // Hide section headers
  sectionsToHide = [...sectionsToHide, ...Object.values(document.querySelectorAll(SECTION_HEADERS))];

  return [sectionToShow, sectionsToHide];
};

const getFieldByLabelText = labelText => {
  const fields = document.querySelectorAll('lei-form > .card > .card-body > div');
  const matchedFields = Object.values(fields).filter(
    field => field.querySelector('span > label') && field.querySelector('span > label').innerText === labelText
  );
  return matchedFields ? matchedFields[0] : null;
};

export const getFundLaunchDateIsNotAvailableField = () => {
  const field = getFieldByLabelText('Fund Launch Date Is Not Available');
  return field;
};

export const changeFieldDisplayMode = (element, displayMode) => {
  angular.element(element).css('display', displayMode);
};

export const customizeNoCreationDateIsAvailableFieldDisplay = selectedTab => {
  const noFundLunchDateField = getFundLaunchDateIsNotAvailableField();
  if (!noFundLunchDateField) return;

  const url = window.location.href;
  if (url.includes('/leis/new') && selectedTab.id === 'ENTITY') {
    angular.element(noFundLunchDateField).css('margin-top', '-15px');

    const fieldRow = noFundLunchDateField.querySelector('span > .row');
    angular.element(fieldRow).css('flex-direction', 'column-reverse');

    const fieldLabel = fieldRow.querySelector('div:nth-child(1)');
    angular.element(fieldLabel).css('margin-left', '35px');

    const entityCategoryField = getFieldByLabelText('Entity Category');
    const isFund = () => angular.element(entityCategoryField.querySelector('select')).val() === 'FUND';

    if (entityCategoryField && isFund()) changeFieldDisplayMode(noFundLunchDateField, 'flex');
    else changeFieldDisplayMode(noFundLunchDateField, 'none');
  } else {
    changeFieldDisplayMode(noFundLunchDateField, 'none');
  }
};

export const onEntityCategoryChange = (keyChain, fieldValue, isNew) => {
  const isEntityCategoryField = () => keyChain.peek() === 'entityCategory';
  const isFund = () => fieldValue === 'FUND';

  if (isEntityCategoryField()) {
    const field = getFundLaunchDateIsNotAvailableField();
    if (isFund() && isNew) {
      changeFieldDisplayMode(field, 'flex');
    } else {
      changeFieldDisplayMode(field, 'none');
    }

    addFaqLinks(document);
  }
};

export const getEntityAdditionalSchemaErrors = (formApi, isNew) => {
  const entity = get(formApi.readUnprocessedForm(), [DS_NAME, 'data', 'entity']);
  const { entityCategory, entityCreationDate, noCreationDateIsAvailable } = entity;
  const errors = [];
  const isRequiredMsg = '[ENTITY]: Entity Creation Date is required.';

  if (entityCategory === 'FUND' && isNew) {
    if (!entityCreationDate && !noCreationDateIsAvailable) {
      errors.push({ severity: 'error', message: isRequiredMsg });
    } else if (entityCreationDate && noCreationDateIsAvailable) {
      errors.push({
        severity: 'error',
        message:
          '[ENTITY]: Only one of Entity Creation Date or No Creation Date Is Available fields should be supplied.'
      });
    }
  } else if (!isNew && !entityCreationDate) {
    errors.push({ severity: 'error', message: isRequiredMsg });
  }
  return errors;
};

export const addFaqLinks = parentNode => {
  const formLabelNodes = parentNode.querySelectorAll(ENTITY_FORM_LABELS);

  Array.from(formLabelNodes).forEach(labelNode => {
    const faqLink = ENTITY_FORM_FIELDS_FAQ_MAP[labelNode.innerText];
    if (faqLink) {
      const iconNode = labelNode.nextElementSibling;
      // Skip if tag I element is not found
      if (iconNode.tagName != 'I') return;
      // Add visual cues indicating that elements are clickable
      iconNode.classList.replace('fa-info-circle', 'fa-question-circle');
      iconNode.classList.remove('text-muted');
      labelNode.classList.add('text-primary');
      const spanParentNode = labelNode.parentNode;
      spanParentNode.classList.add('position-relative', 'text-primary');

      // Add stretched link element that navigates to FAQ section
      const faqLinkNode = document.createElement('a');
      faqLinkNode.href = faqLink;
      faqLinkNode.classList.add('stretched-link');
      faqLinkNode.target = '_blank';

      // Update dom elements
      spanParentNode.replaceChild(faqLinkNode, iconNode);
      faqLinkNode.appendChild(iconNode);
    }
  });
};

export { ACTION };
export { postprocess } from './lei-postprocess';
export { preprocess, addContactFromProfile } from './lei-preprocess';
export { mustBeRenewed } from '../../../../../common/lib/transfer-renew-check';
export { default as labelCustomizer } from './label-customizer';
export { default as textHash } from '../../../../../common/lib/text-hash';
export { default as DRAFT_INTERVAL } from '../../../../../common/const/save-draft-interval';
export { default as FILE_UPLOAD } from '../../../../../common/const/file-upload';
export { default as MESSAGES } from '../../../../../common/const/flash-message-data';
export { FORM_SECTIONS, ENTITY_SUBSECTIONS };
export { TO_REMOVE as exceptionReasonsToRemove } from '../../../../../common/const/exception-reasons';
