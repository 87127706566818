'use strict';

// Switzerland
module.exports = [
  { abbr: 'AG', name: 'AARGAU' },
  { abbr: 'AI', name: 'APPENZELL INNERRHODEN' },
  { abbr: 'AO', name: 'APPENZELL AUSSERHODEN' },
  { abbr: 'BL', name: 'BASEL LANDSCHAFT' },
  { abbr: 'BS', name: 'BASEL STADT' },
  { abbr: 'BE', name: 'BERN' },
  { abbr: 'FR', name: 'FRIBOURG' },
  { abbr: 'GE', name: 'GENEVE' },
  { abbr: 'GL', name: 'GLARUS' },
  { abbr: 'GR', name: 'GRAUBUENDEN' },
  { abbr: 'JU', name: 'JURA' },
  { abbr: 'LU', name: 'LUZERN' },
  { abbr: 'NE', name: 'NEUCHATEL' },
  { abbr: 'NW', name: 'NIDWALDEN' },
  { abbr: 'OW', name: 'OBWALDEN' },
  { abbr: 'SH', name: 'SCHAFFHAUSEN' },
  { abbr: 'SZ', name: 'SCHWYZ' },
  { abbr: 'SO', name: 'SOLOTHURN' },
  { abbr: 'SG', name: 'ST. GALLEN' },
  { abbr: 'TG', name: 'THURGAU' },
  { abbr: 'TI', name: 'TICINO' },
  { abbr: 'UR', name: 'URI' },
  { abbr: 'VS', name: 'VALAIS' },
  { abbr: 'VD', name: 'VAUD' },
  { abbr: 'ZH', name: 'ZUERICH' },
  { abbr: 'ZG', name: 'ZUG' }
];
