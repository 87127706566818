import angular from 'angular';
import { controller, directive } from './app';

directive('healthItem', function healthItem() {
  return {
    scope: { test: '=' },
    template: [
      '<i class="fa fa-2x {{test.style}}"></i>',
      '<div>',
      '<h3>{{test.name}}</h3>',
      '<p>{{test.message}}</p>',
      '</div>'
    ].join('')
  };
});

controller(
  'HealthController',
  /* @ngInject */
  function HealthController($scope, $q, AccessLevel, PublicStorageService, UserService, phoneBackfill, injectStripeJS) {
    $scope.tests = {};

    var tests = {
      browser: 'Browser is compatible.',
      keys: 'Obtains public data.',
      stripe: 'Connects to stripe.',
      login: 'Is logged in.',
      user: 'Obtained user profile.',
      phone: 'Phone code is ok.',
      accmail: 'We have a log in email.',
      allmail: 'We have the other emails.'
    };
    var status = {
      pending: 'fa-circle text-primary',
      skipped: 'fa-circle text-muted',
      passed: 'fa-check-circle text-success',
      warning: 'fa-exclamation-circle text-warning',
      failed: 'fa-times-circle text-danger'
    };

    // Utilities
    function pending(test, message) {
      test.style = status.pending;
      test.message = message || 'Pending.';
    }

    function skipped(test, message) {
      test.style = status.skipped;
      test.message = message || 'Skipped.';
    }

    function passed(test, message) {
      test.style = status.passed;
      test.message = message || 'Ok.';
    }

    function warning(test, message) {
      test.style = status.warning;
      test.message = message || 'There might be an issue.';
    }

    function failed(test, message) {
      test.style = status.failed;
      test.message = message || 'There was a problem.';
    }

    function pass(test, message, callback) {
      return function(res) {
        passed(test, message);
        callback && callback(res);
        return res;
      };
    }

    function fail(test, message, skip) {
      return function(ex) {
        failed(test, message || ex.message);
        if (skip)
          skip.forEach(function(test) {
            skipped(test);
          });
      };
    }

    // Runtime
    load();
    function load() {
      for (var key in tests) {
        $scope.tests[key] = { name: tests[key] };
        pending($scope.tests[key]);
      }

      // Tests
      PublicStorageService.getSiteKeys()
        .then(function(res) {
          passed($scope.tests.keys);
          try {
            window.Stripe(res.data.stripePublicKey);
            passed($scope.tests.stripe);
          } catch (ex) {
            failed($scope.tests.stripe, ex.message);
          }
        })
        .catch(function(ex) {
          failed($scope.tests.keys, ex.message);
          skipped($scope.tests.stripe);
        });

      // Browser detection in compatibility.js
      if (angular.element('html').hasClass('has-msie')) {
        warning($scope.tests.browser, 'Browser has limited support.');
      } else {
        passed($scope.tests.browser);
      }

      /**
       * Logged In tests beyond this point
       */

      if (AccessLevel.get() < AccessLevel.TWO_FACTOR) {
        failed($scope.tests.login, 'User is not logged in.');
        skipped($scope.tests.user, 'No profile to view.');
        skipped($scope.tests.phone);
        skipped($scope.tests.accmail);
        skipped($scope.tests.allmail);
        return;
      }
      passed($scope.tests.login);

      UserService.getUserProfile()
        .then(pass($scope.tests.user))
        .then(function(res) {
          return $q
            .resolve(res)
            .then(phoneBackfill)
            .then(pass($scope.tests.phone))
            .catch(fail($scope.tests.phone));
        })
        .catch(fail($scope.tests.user, undefined, [$scope.tests.phone]));

      UserService.getUserInfo()
        .then(pass($scope.tests.accmail))
        .catch(fail($scope.tests.accmail));

      UserService.getAccountEmails()
        .then(pass($scope.tests.allmail))
        .catch(fail($scope.tests.allmail));
    }
  }
);
