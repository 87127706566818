import { constant, factory } from './app';

constant('gridPageSize', 50);
constant('gridMaxPages', 4);

// Side effects: gridOptions
export const gridInit = [
  '$q',
  'gridPageSize',
  function($q, gridPageSize) {
    return function(vm, opts) {
      var registered = $q.defer();

      vm.gridOptions = Object.assign(
        {
          infiniteScrollRowsFromEnd: 10,
          infiniteScrollPageSize: gridPageSize,
          infiniteScrollUp: true,
          infiniteScrollDown: true,
          columnDefs: [],
          data: 'data',
          onRegisterApi: function onRegisterApi(gridApi) {
            registered.resolve(gridApi);
          }
        },
        opts
      );

      return registered.promise;
    };
  }
];
factory('gridInit', gridInit);
