import angular from 'angular';
import { controller, filter } from './app';

const XML_TYPES = ['FULL', 'DELTA', 'CDF2 FULL', 'RR FULL', 'REPORT EXCEPTIONS FULL'];

filter('partDate', [
  function() {
    return function(iso, affix) {
      if (typeof iso !== 'string') return iso;
      return iso.substr(0, 10) + (!affix ? '' : '-' + affix);
    };
  }
]);

controller('DownloadsController', [
  '$timeout',
  'leiXml',
  function($timeout, leiXml) {
    const typeIdx = XML_TYPES.reduce((acc, type, idx) => Object.assign(acc, { [type]: idx }), {});

    this.state = {
      leiXml: angular.extend({}, leiXml, {
        items: fillXml(leiXml.items || [])
      })
    };

    this.state.leiXml.first = getFirstXml(this.state.leiXml.items);

    function getFirstXml(items) {
      const first = Array.from(XML_TYPES).map(angular.noop),
        typesLeft = [].concat(XML_TYPES);
      for (var i = 0, day, typeIndex; i < items.length && typesLeft.length; i++) {
        day = items[i];
        for (var j = 0; j < day.xmls.length && typesLeft.length; j++) {
          if (!day.xmls[j].size) continue;
          typeIndex = typesLeft.indexOf(day.xmls[j].type);
          if (typeIndex === -1) continue;
          typesLeft.splice(typeIndex, 1);
          first[typeIdx[day.xmls[j].type]] = {
            date: day.xmlDate,
            item: day.xmls[j]
          };
        }
      }
      return first;
    }

    function fillXml(items) {
      return items.map(function(it) {
        const filledData = XML_TYPES.map(function(type) {
          return { type, size: 0 };
        });

        for (const xml of it.xmls)
          filledData[typeIdx[xml.type]] = {
            type: xml.type,
            size: xml.size,
            url: `${__CDN__}/lei/xml/${
              xml.bcosUrl
                ? `file/${xml.bcosUrl.replace(/\.xml/g, '')}`
                : `download/${it.xmlDate}/${encodeURIComponent(xml.type)}`
            }`
          };

        it.xmls = filledData.sort(function(a, b) {
          return typeIdx[a.type] - typeIdx[b.type];
        });

        return it;
      });
    }
  }
]);
