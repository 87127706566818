import angular from 'angular';
import { controller } from '../controllers/app';
import { checkStore, schemaStore } from '../../../common/validate';

controller('AdminUserEmailsDirectiveController', [
  '$scope',
  'UserService',
  'AdminService',
  'ServiceErrorHandler',
  'AdminUserEmailsStore',
  'AdminUserEmailsAction',
  'Alert',
  function($scope, UserService, AdminService, ServiceErrorHandler, AdminUserEmailsStore, AdminUserEmailsAction, Alert) {
    var emailApiKeyPairs = $scope.emailApiKeyPairs;
    var loginUsername = $scope.userLoginUsername;
    var loginEmail = $scope.userLoginEmail;
    var onBehalfOfUserName = $scope.onBehalfOfUserName;

    var log;
    if (__DEV__) log = console.log.bind(console, '[AdminUserEmailsDirectiveController]');
    else log = function() {};

    var checkAccountAddEmail = checkStore.get('accountAddEmail');
    var checkAccountRemoveEmail = checkStore.get('accountRemoveEmail');

    var self = this;

    self.props = {};
    self.props.loginEmail = loginEmail;
    self.props.accountAddEmailSchema = schemaStore.get('accountAddEmail');
    self.props.accountRemoveEmailSchema = schemaStore.get('accountRemoveEmail');

    self.state = {};

    AdminUserEmailsStore.subscribe(function(state) {
      self.state.model = state.model;
      self.state.validate = state.validate;
      self.state.ui = state.ui;
    });

    (function initEmails(pairs) {
      var setEmailApiKeyPairsAction = AdminUserEmailsAction.setEmailApiKeyPairs(pairs);
      AdminUserEmailsStore.dispatch(setEmailApiKeyPairsAction);
    })(emailApiKeyPairs);

    function validateAdd() {
      var viewObj = {
        emails: AdminUserEmailsStore.getState().model.emailApiKeyPairs.map(pair => pair.email),
        newEmail: self.state.view.newEmail
      };

      // always validate on change
      var check = checkAccountAddEmail(viewObj);
      var validateAction = AdminUserEmailsAction.validate(check);
      AdminUserEmailsStore.dispatch(validateAction);

      return check;
    }

    self.props.handleNewEmailChange = function() {
      var addCheck = validateAdd();

      // update model only when valid
      if (!addCheck.$valid) {
        return;
      }

      var newEmail = self.state.view.newEmail;
      var newEmailAction = AdminUserEmailsAction.newEmail(newEmail);
      AdminUserEmailsStore.dispatch(newEmailAction);
    };

    self.props.handleAddEmail = function() {
      var newEmail = AdminUserEmailsStore.getState().model.newEmail;

      return (onBehalfOfUserName ? AdminService : UserService)
        .addAccountEmail(newEmail, onBehalfOfUserName)
        .then(function() {
          var addAction = AdminUserEmailsAction.addEmail(newEmail);
          AdminUserEmailsStore.dispatch(addAction);
          Alert.success('addEmail');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };

    self.props.handleRemoveEmail = function(email) {
      var input = {
        emails: self.state.model.emailApiKeyPairs.map(pair => pair.email),
        emailToRemove: email
      };

      var checkRemoveEmail = checkAccountRemoveEmail(input);
      var validateAction = AdminUserEmailsAction.validate(checkRemoveEmail);
      AdminUserEmailsStore.dispatch(validateAction);

      if (!checkRemoveEmail.$valid) {
        return;
      }

      log('call remove email here', AdminUserEmailsStore.getState());

      return (onBehalfOfUserName ? AdminService : UserService)
        .removeAccountEmail(email, onBehalfOfUserName)
        .then(function() {
          var removeAction = AdminUserEmailsAction.removeEmail(email);
          AdminUserEmailsStore.dispatch(removeAction);
          Alert.success('removeEmail');
        })
        .catch(ServiceErrorHandler({ alert: 'data' }));
    };

    self.props.handleGenerateApiKey = function(email) {
      log('call generate api key here', AdminUserEmailsStore.getState());

      return AdminService.generateNewApiKey(loginUsername, email)
        .then(res => {
          var setAction = AdminUserEmailsAction.setApiKey(email, res.data || '');
          AdminUserEmailsStore.dispatch(setAction);
          Alert.success('setApiKey');
        }, ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };

    self.props.handleRemoveApiKey = function(email) {
      log('call remove api key here', AdminUserEmailsStore.getState());

      return AdminService.removeApiKey(loginUsername, email)
        .then(res => {
          var setAction = AdminUserEmailsAction.setApiKey(email, null);
          AdminUserEmailsStore.dispatch(setAction);
          Alert.success('setApiKey');
        }, ServiceErrorHandler({ alert: 'data' }))
        .catch(angular.noop);
    };
  }
]);
