'use strict';
var inherits = require('util').inherits;

var ERROR_MAP = {
  E_DUPLICATION: 'There is an duplicated item.',
  E_EMAIL_DUPLICATED: 'There is an duplicated email.',
  E_EMAIL_NOT_EXISTING: 'This email does not exist in the account.',
  E_DOMAIN_DUPLICATED: 'There is an duplicated domain.',
  E_EMAIL_INVALID: 'The email is invalid.',
  E_EMAIL_DOMAIN_BLACKLIST: 'The domain of the email is not allowed.',
  E_PROFILE_INVALID: 'The profile requires valid fields.',
  E_PROFILE_INVALID_US_STATE: 'Please provide a valid US state.',
  E_PROFILE_INVALID_COUNTRY: 'Please provide a valid country.',
  E_PROFILE_INVALID_EMAIL: 'Please provide a valid email.',
  E_PROFILE_FIELD_TOO_LONG: 'There are one or more fields too long. '
};

function BConnectWorkflowError(type, data) {
  if (!ERROR_MAP[type]) {
    throw new Error('Error code not found.');
  }

  this.type = type;
  this.message = ERROR_MAP[type];
  this.data = data;

  Error.captureStackTrace(this, BConnectWorkflowError);
}

inherits(BConnectWorkflowError, Error);

(function createTypeMirror() {
  BConnectWorkflowError.TYPE = Object.keys(ERROR_MAP).reduce(function(map, type) {
    map[type] = type;
    return map;
  }, {});
})();

module.exports = BConnectWorkflowError;
