'use strict';

var inherits = require('util').inherits;

// message: text or array of text
function ValidationError(message, messages = null) {
  Error.captureStackTrace(this, ValidationError);

  if (Array.isArray(message) && message.length) {
    this.message = message.join(' ');
    this.messages = [].concat(message);
  } else if (typeof message === 'string' && message) {
    this.message = message;
    this.messages = [].concat(messages || message);
  } else {
    throw Error(
      'Error initiating ValidationError, ' +
        'message must be non-empty array of strings, ' +
        'or a non-empty string, got ' +
        message
    );
  }
}

inherits(ValidationError, Error);

module.exports = ValidationError;
