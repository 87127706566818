import { factory } from './app';

factory('FeedbackService', [
  '$http',
  function($http) {
    function postFeedback(feedback, captchaResponse) {
      var options = {
        method: 'POST',
        url: '/feedback',
        data: {
          feedback: feedback,
          captchaResponse: captchaResponse
        }
      };

      return $http(options);
    }

    return {
      postFeedback: postFeedback
    };
  }
]);
