import { factory } from './app';
import { NO_CACHE } from '../utils/constants';

factory('SecurityService', [
  '$http',
  function($http) {
    return {
      isAdmin: isAdmin,
      isUser: isUser,
      isLoggedIn: isLoggedIn,
      checkAccessLevel: checkAccessLevel
    };

    function checkAccessLevel() {
      var options = {
        method: 'GET',
        url: '/security/accessLevel',
        headers: NO_CACHE
      };

      return $http(options);
    }

    function isLoggedIn() {
      var options = {
        method: 'GET',
        url: '/security/isLoggedIn',
        headers: NO_CACHE
      };

      return $http(options);
    }

    function isAdmin() {
      var options = {
        method: 'GET',
        url: '/security/isAdmin',
        headers: NO_CACHE
      };

      return $http(options);
    }

    function isUser() {
      var options = {
        method: 'GET',
        url: '/security/isUser',
        headers: NO_CACHE
      };

      return $http(options);
    }
  }
]);
