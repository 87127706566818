// contains misc front-end configurations, mapping, constants and etc.
export { STRIPE_BANK_MAP, STRIPE_CARD_MAP } from '../../../common/const/stripe-mappings';
export { TAX_ENTITY_CATEGORY_MAP } from '../../../common/const/tax-entity-category-mappings';

/*
 * lei challenge categories
 */
export const CHALLENGE_CATEGORIES = (function() {
  const schemaName = 'com.bloomberg.ds.LEIWorkflowv2',
    duplicateChallenge = schemaName + '.DuplicateChallenge',
    otherChallenge = schemaName + '.OtherChallenge',
    missingChallenge = schemaName + '.MissingOrIncorrectDataChallenge';

  return {
    schemaName: schemaName,
    dupChallengeCategory: duplicateChallenge,
    duplicateAction: schemaName + '.ChallengeLEI',
    dupChallenge: '{"' + duplicateChallenge + '":{}}',
    otherChallenge: '{"' + otherChallenge + '":{}}',
    missingChallenge: '{"' + missingChallenge + '":{}}'
  };
})();

export const NO_CACHE = { Pragma: 'no-cache' };

// regular LEI action stages
export const STAGES = { SUBMIT: 1, PAY: 2, CONFIRM: 3 };

export const STEPS = [
  { active: true, stage: STAGES.SUBMIT, text: '1. LEI Details' },
  { active: false, stage: STAGES.PAY, text: '2. Payment Details' }
];

export const TRANSFER_STAGES = { SEARCH: 1, SUBMIT: 2, CONFIRM: 3 };

export const TRANSFER_STEPS = [
  { active: true, stage: TRANSFER_STAGES.SEARCH, text: '1. LEI Transfer Details' },
  { active: false, stage: TRANSFER_STAGES.SUBMIT, text: '2. Submit' },
  { active: false, stage: TRANSFER_STAGES.CONFIRM, text: '3. Confirmation' }
];

export const TRANSFER_RENEW_STAGES = { SEARCH: 1, SUBMIT: 2, PAY: 3, CONFIRM: 4 };

export const TRANSFER_RENEW_STEPS = [
  { active: true, stage: TRANSFER_RENEW_STAGES.SEARCH, text: '1. LEI Transfer Details' },
  { active: false, stage: TRANSFER_RENEW_STAGES.SUBMIT, text: '2. Submit' },
  { active: false, stage: TRANSFER_RENEW_STAGES.PAY, text: '3. Payment Details' }
];

export const BULK_STAGES = { SUBMIT: 1, PUBLISH: 2, PAY: 3, CONFIRM: 4 };

export const BULK_STEPS = [
  { active: true, stage: BULK_STAGES.SUBMIT, text: '1. Provide Submission Data' },
  { active: false, stage: BULK_STAGES.PUBLISH, text: '2. Review and Publish' },
  { active: false, stage: BULK_STAGES.PAY, text: '3. Payment Details' }
];

export const BULK_UPDATE_STAGES = STAGES;

export const BULK_UPDATE_STEPS = [
  { active: true, stage: BULK_UPDATE_STAGES.SUBMIT, text: '1. Provide Submission Data' },
  { active: false, stage: BULK_UPDATE_STAGES.PUBLISH, text: '2. Review and Publish' },
  { active: false, stage: BULK_UPDATE_STAGES.CONFIRM, text: '3. Confirmation' }
];

export const VALIDATION_STATUS = {
  UNTOUCHED: 'UNTOUCHED',
  PASSED: 'PASSED',
  FAILED: 'FAILED'
};

export const WORKFLOW_TAB_STEPS = [
  {
    label: 'General',
    id: 'ENTITY',
    active: true,
    hasNext: true,
    validationStatus: VALIDATION_STATUS.UNTOUCHED
  },
  {
    label: 'Addresses',
    id: 'ADDRESSES',
    active: false,
    hasNext: true,
    validationStatus: VALIDATION_STATUS.UNTOUCHED
  },
  {
    label: 'Relationships',
    id: 'RELATIONSHIPS',
    active: false,
    hasNext: true,
    validationStatus: VALIDATION_STATUS.UNTOUCHED
  },
  {
    label: 'Entity Events',
    id: 'EVENTS',
    active: false,
    hasNext: true,
    validationStatus: VALIDATION_STATUS.UNTOUCHED
  },
  {
    label: 'Contact Information',
    id: 'CONTACT',
    active: false,
    hasNext: true,
    validationStatus: VALIDATION_STATUS.UNTOUCHED
  },
  {
    label: 'Files',
    id: 'FILES',
    active: false,
    hasNext: false,
    validationStatus: VALIDATION_STATUS.UNTOUCHED
  }
];
