import angular from 'angular';
import fileUpload from '../../../common/const/file-upload';
import { directive, controller } from '../controllers/app';
import { schemaStore } from '../../../common/validate';

function makeOtRowActions($scope) {
  return {
    onEditRow: function onEditRow(id) {
      $scope.editing = $scope.editing === id ? null : id;
    },
    onDeleteRow: function onDeleteRow(id) {
      delete $scope.data[id];
      $scope.active = Object.keys($scope.data).length > 0;
      $scope.editing = null;
    },
    onSaveRow: function(id, obj) {
      $scope.editing = null;
      delete $scope.data[id];
      id = obj.lei;
      delete obj.lei;
      $scope.data[id] = obj;
    }
  };
}

function parseFiles(files) {
  var file = files[0]; // FileList object.
  this.error.other = '';
  this.error.maxSize = file.size >= this.fileUpload.max_file_size;
  this.error.pattern = !/\.csv$/.test(file.name);
  this.file = this.error.maxSize || this.error.pattern ? null : file;
}

function onUpload(AM, PM, callback, clear) {
  AM.reset();
  PM.reset().progress(25);

  var reader = new FileReader();
  reader.onerror = function onError(event) {
    AM.reset();
    switch (event.target.error.code) {
      case event.target.error.NOT_FOUND_ERR:
        return (this.error = 'File Not Found!');
      case event.target.error.NOT_READABLE_ERR:
        return (this.error = 'File is not readable');
      case event.target.error.ABORT_ERR:
        break; // noop
      default:
        this.error = 'An error occurred reading this file.';
    }
  };
  reader.onloadend = function onLoad() {
    AM.reset();
    callback(reader.result, clear);
  };
  reader.onabort = function onAbort() {
    AM.reset();
    this.error = 'File read cancelled';
  };

  // Read in the image file as a binary string.
  reader.readAsText(this.file);
}

import views_users_ownershipTransfer from '@lei-website-client/views/users/ownershipTransfer.html';

directive('ownershipTransfer', [
  'UserService',
  'ProgressMessenger',
  'AlertMessenger',
  'Alert',
  'DefaultErrorHandler',
  function(UserService, ProgressMessenger, AlertMessenger, Alert, DefaultErrorHandler) {
    return {
      restrict: 'A',
      scope: true,
      template: views_users_ownershipTransfer,
      link: link
    };

    function link($scope) {
      var notified = false;
      $scope.file;
      $scope.editing = null;
      $scope.active = false;
      $scope.single = {};
      $scope.data = {};
      $scope.fileUpload = fileUpload;
      $scope.error = {
        maxSize: false,
        pattern: false,
        other: ''
      };
      $scope.schema = schemaStore.get('ownershipTransfer');

      $scope.onSubmit = function onSubmit() {
        AlertMessenger.reset();
        return UserService.ownershipTransfer($scope.data)
          .then(function() {
            Alert.success('bulkOwnershipTransfer');
            $scope.data = {};
          }, DefaultErrorHandler)
          .catch(angular.noop);
      };

      $scope.onAddElement = function onAddElement() {
        $scope.data[$scope.single.lei] = {
          user: $scope.single.user,
          email: $scope.single.email
        };
        delete $scope.single.lei;
        $scope.ownershipForm.lei.$setUntouched();
        $scope.active = Object.keys($scope.data).length > 0;
        if (!notified) {
          AlertMessenger.info('Please verify your ownership transfers in the table below.');
          notified = true;
        }
      };

      $scope.rowActions = makeOtRowActions($scope);
      $scope.parseFiles = parseFiles.bind($scope);
      $scope.onUpload = onUpload.bind($scope, AlertMessenger, ProgressMessenger, function parseContent(content, clear) {
        var data = {};
        var lineNo = 0;
        var lines = content.split('\n');
        for (var i = 0; i < lines.length; i++) {
          if (!lines[i]) continue;

          lineNo++;
          if (lineNo === 1) continue;

          // lei, tousername, touseremail
          var item = lines[i].split(',');
          if (item.length < 2) return Alert.error('fileFormatOwnershipTransfer', null, [lineNo]);

          item = item.map(function(s) {
            return s.trim();
          });

          !item[0] && Alert.error('mandatoryOwnershipTransfer', null, ['LEI', lineNo]);
          !item[1] && Alert.error('mandatoryOwnershipTransfer', null, ['destination username', lineNo]);
          data[item[0]] = { user: item[1], email: item[2] };

          if (!notified) {
            AlertMessenger.info('Please verify your ownership transfers in the table below.');
            notified = true;
          }
        }

        if (lineNo < 2) return Alert.error('fileFormatOwnershipTransfer');

        if (lineNo > $scope.fileUpload.max_ownership_transfer_size + 1) return Alert.error('maxSizeOwnershipTransfer');

        $scope.data = clear ? data : Object.assign($scope.data, data);
        $scope.active = Object.keys($scope.data).length > 0;
      });
    }
  }
]);

import views_templates_ownershipTransferRow from '@lei-website-client/views/templates/ownershipTransferRow.html';

directive('ownershipTransferRow', [
  function() {
    return {
      restrict: 'A',
      scope: {
        active: '<',
        schema: '<',
        owner: '<?',
        data: '<',
        lei: '<',
        actions: '<',
        form: '<'
      },
      link: link,
      template: views_templates_ownershipTransferRow
    };

    function link($scope) {
      $scope.obj = Object.assign({ lei: $scope.lei }, $scope.data);
      $scope.onBlue = function() {
        if (!$scope.active) {
          $scope.obj = Object.assign({ lei: $scope.lei }, $scope.data);
          return $scope.actions.onEditRow($scope.lei);
        }
        return $scope.actions.onSaveRow($scope.lei, $scope.obj);
      };
      $scope.onRed = function() {
        return !$scope.active ? $scope.actions.onDeleteRow($scope.lei) : $scope.actions.onEditRow($scope.lei);
      };
    }
  }
]);

angular.module('leiApp.controllers');
controller('AdminTransferLeiController', [
  '$scope',
  'AdminService',
  'DefaultErrorHandler',
  'ProgressMessenger',
  'AlertMessenger',
  'Alert',
  function($scope, AdminService, DefaultErrorHandler, ProgressMessenger, AlertMessenger, Alert) {
    var notified = false;
    $scope.file;
    $scope.active = false;
    $scope.editing = null;
    $scope.single = {};
    $scope.data = {};
    $scope.fileUpload = fileUpload;
    $scope.error = {
      maxSize: false,
      pattern: false,
      other: ''
    };
    $scope.schema = schemaStore.get('ownershipTransfer');

    $scope.onSubmit = function onSubmit() {
      AlertMessenger.reset();
      return AdminService.ownershipTransfer($scope.data).then(function() {
        Alert.success('bulkOwnershipTransfer');
        $scope.data = {};
      }, DefaultErrorHandler);
    };

    $scope.onAddElement = function onAddElement() {
      $scope.data[$scope.single.lei] = {
        owner: $scope.single.owner,
        user: $scope.single.user,
        email: $scope.single.email
      };
      delete $scope.single.lei;
      $scope.ownershipForm.lei.$setUntouched();
      $scope.active = Object.keys($scope.data).length > 0;
      if (!notified) {
        AlertMessenger.info('Please verify your ownership transfers in the table below.');
        notified = true;
      }
    };

    $scope.rowActions = makeOtRowActions($scope);
    $scope.parseFiles = parseFiles.bind($scope);
    $scope.onUpload = onUpload.bind($scope, AlertMessenger, ProgressMessenger, function parseContent(content, clear) {
      var data = {};
      var lineNo = 0;
      var lines = content.split('\n');
      for (var i = 0; i < lines.length; i++) {
        if (!lines[i]) continue;

        lineNo++;
        if (lineNo === 1) continue;

        // lei, fromusername, tousername, touseremail
        var item = lines[i].split(',');
        if (item.length < 3) return Alert.error('fileFormatOwnershipTransfer', null, [lineNo]);

        item = item.map(function(s) {
          return s.trim();
        });

        !item[0] && Alert.error('mandatoryOwnershipTransfer', null, ['LEI', lineNo]);
        !item[1] && Alert.error('mandatoryOwnershipTransfer', null, ['original username', lineNo]);
        !item[2] && Alert.error('mandatoryOwnershipTransfer', null, ['destination username', lineNo]);

        if (item[1] === item[2]) return Alert.error('selfOwnershipTransfer', [lineNo]);

        data[item[0]] = {
          owner: item[1],
          user: item[2],
          email: item[3]
        };

        if (!notified) {
          AlertMessenger.info('Please verify your ownership transfers in the table below.');
          notified = true;
        }
      }

      if (lineNo < 2) return Alert.error('fileFormatOwnershipTransfer');

      if (lineNo > $scope.fileUpload.max_ownership_transfer_size + 1) return Alert.error('maxSizeOwnershipTransfer');

      $scope.data = clear ? data : Object.assign($scope.data, data);
      $scope.active = Object.keys($scope.data).length > 0;
    });
  }
]);
