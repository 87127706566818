import { factory } from './app';
import _ from 'lodash';
import leiProducts from '../../../common/const/lei-products';
import draftLifeTime from '../../../common/const/draft-life-time';
import textHash from '../../../common/lib/text-hash';

factory('DraftService', [
  '$q',
  '$http',
  'UserService',
  'TimedCache',
  function($q, $http, UserService, TimedCache) {
    function _getDraftSubmissionData(draftKey) {
      var draft = TimedCache.get(draftKey);
      if (!draft) return;
      try {
        return JSON.parse(draft).submission;
      } catch (e) {
        return draft;
      }
    }

    function _getDraftSubmissionTime(draftKey) {
      var draft = TimedCache.get(draftKey);
      if (!draft) return;
      try {
        return JSON.parse(draft).draftTime;
      } catch (e) {
        return TimedCache.getExpireDate(draftKey) - draftLifeTime.IN_MS;
      }
    }

    function _getDraftMetaData(fullKey, keyForAction, type, email) {
      // get metdata from non encrypted value from local storage.
      var draft = JSON.parse(TimedCache.get(fullKey));
      var lei = type === leiProducts.ACTION.NEW ? '' : keyForAction;
      return {
        type: type,
        finalId: lei,
        submissionTime: draft.draftTime,
        legalName: draft.legalName,
        email: email,
        keyForAction: keyForAction
      };
    }

    function _getKeyParts(draftKey, hashedEmail) {
      var keyParts = draftKey.split('_');
      if (keyParts.length > 1 && hashedEmail === keyParts[0]) {
        return { type: keyParts[1], keyForAction: keyParts.length > 2 ? keyParts[2] : '' };
      }
    }

    function encryptUserSubmission(submission, metadata) {
      var options = {
        method: 'POST',
        url: '/user/encryptUserSubmission',
        data: {
          submission: submission,
          metadata: metadata
        }
      };
      return $http(options);
    }

    function decryptUserSubmission(submission, metadata) {
      var options = {
        method: 'POST',
        url: '/user/decryptAndGetUserSubmission',
        data: {
          submission: submission,
          metadata: metadata
        }
      };
      return $http(options);
    }

    function encryptAndSaveDraft(submissionData, draftKey, isAuto) {
      // encrypt the actual submission, but store metadata in local storage w/o encrypting.
      var metadata = { draftKey: draftKey, isAuto: isAuto };
      return encryptUserSubmission(submissionData, metadata).then(function(res) {
        TimedCache.set(
          draftKey,
          JSON.stringify({
            submission: res.data.encryptedVal,
            draftTime: Date.now(),
            legalName: _.get(submissionData, 'data.entity.legalName', '')
          }),
          draftLifeTime.IN_MS
        );
      });
    }

    function decryptAndGetDraft(draftKey) {
      var draft = _getDraftSubmissionData(draftKey);
      var metadata = { draftKey: draftKey };
      if (!draft) return $q.reject('draftExpire');
      return decryptUserSubmission(draft, metadata);
    }

    function hasDraft(draftKey) {
      return TimedCache.isKeyValid(draftKey);
    }

    function hasAnyDraftForType(type, hashedEmail) {
      return TimedCache.allStorage().some(function(elem) {
        var keyParts = _getKeyParts(elem, hashedEmail);
        return keyParts && keyParts.type === type;
      });
    }

    function getLatestDraftkeyForAction(type, hashedEmail) {
      // get the latest draft for this action type.
      var latestDraft = TimedCache.allStorage().reduce(function(latestKeyObj, currKey) {
        var keyParts = _getKeyParts(currKey, hashedEmail);
        if (keyParts && keyParts.type === type) {
          var draftTime = _getDraftSubmissionTime(currKey);
          if (
            !latestKeyObj.draftTime ||
            !latestKeyObj.keyForAction ||
            (draftTime > latestKeyObj.draftTime && keyParts.keyForAction)
          ) {
            return { fullKey: currKey, draftTime: _getDraftSubmissionTime(currKey) };
          }
        }
        return latestKeyObj;
      }, {});
      return latestDraft && latestDraft.fullKey;
    }

    function removeDraft(draftKey) {
      TimedCache.remove(draftKey);
    }

    function getDraftSubmissions(offset, limit, sortDef) {
      // get all draft submissions - get metdata related to the drafts and not the actual submission.
      var allKeys = TimedCache.allStorage();

      return UserService.getUserInfo().then(function(response) {
        var email = response.data.email;
        var hashedEmail = textHash(email);
        var draftArr = allKeys.reduce(function(myDrafts, key) {
          var keyParts = _getKeyParts(key, hashedEmail);
          if (keyParts) {
            myDrafts.push(_getDraftMetaData(key, keyParts.keyForAction, keyParts.type, email));
          }
          return myDrafts;
        }, []);

        draftArr = draftArr.filter(function(elem) {
          return elem !== undefined;
        });

        if (Array.isArray(sortDef) && sortDef.length && sortDef[0].length > 1) {
          var isDesc = sortDef[0].charAt(0) === '-';
          var colName = isDesc ? sortDef[0].substring(1) : sortDef[0];
          draftArr.sort(function(a, b) {
            if (a[colName] > b[colName]) {
              return isDesc ? -1 : 1;
            } else if (a[colName] < b[colName]) {
              return isDesc ? 1 : -1;
            }
            return 0;
          });
        }
        draftArr = draftArr.slice(offset, offset + limit);
        return { data: draftArr, totalResults: draftArr.length };
      });
    }

    return {
      encryptAndSaveDraft: encryptAndSaveDraft,
      decryptAndGetDraft: decryptAndGetDraft,
      hasDraft: hasDraft,
      removeDraft: removeDraft,
      getDraftSubmissions: getDraftSubmissions,
      hasAnyDraftForType: hasAnyDraftForType,
      getLatestDraftkeyForAction: getLatestDraftkeyForAction
    };
  }
]);
