'use strict';
// from a schema... to an array of checkers,
// that are ready for checkProps

var _ = require('lodash');

module.exports = function makeCheckers(schema) {
  return Object.keys(schema).map(function toCheckers(key) {
    var sch = schema[key];

    var checker = _.assign({}, sch);

    checker.path = key;

    if (sch.checkFn) {
      checker.checkFn = sch.checkFn;
      return checker;
    }

    checker.required = sch.$required;
    checker.type = sch.$type;
    checker.pattern = sch.$pattern;
    checker.maxlength = sch.$maxlength;
    checker.message = sch.$message;

    return checker;
  });
};
